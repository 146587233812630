import { FilterOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../../../i18n";
import { doFetchTenantDashboard } from "../../../../redux/actions/dashboard/tenant/fetch";
import { getCurrentWorkingFilter, getFilterFlags } from "../../../../redux/selectors/dashboard/tenant";
import { TagsFilter } from "./TagsFilter";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Filters = (): JSX.Element => {
  const dispatch = useDispatch();
  const [visible, setVisible] = React.useState(false);
  const filterFlags = useSelector(getFilterFlags);
  const workingFilter = useSelector(getCurrentWorkingFilter);
  const ref = React.useRef<HTMLButtonElement>(null);

  React.useLayoutEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [workingFilter]);

  return (
    <>
      <Button
        data-qa-label="filtersBtn"
        onClick={() => {
          setVisible(true);
        }}
      >
        <FilterOutlined />
        {MESSAGES.FilterBtnLabel}
      </Button>
      <Drawer
        closable
        width={430}
        title={MESSAGES.FiltersDrawerTitle}
        placement="right"
        open={visible}
        onClose={() => {
          setVisible(false);
        }}
      >
        <form
          className="flex flex-col h-full"
          onSubmit={(e: React.SyntheticEvent) => {
            e.preventDefault();
          }}
        >
          <div className="flex overflow-auto">
            <TagsFilter />
          </div>
          <div className="flex flex-row justify-center gap-x-2">
            <Button
              disabled={!filterFlags.canResetFilter}
              htmlType="button"
              onClick={() => {
                dispatch(doFetchTenantDashboard(true));
                setVisible(false);
              }}
            >
              Reset
            </Button>
            <Button
              ref={ref}
              disabled={!filterFlags.canApplyFilter}
              htmlType="submit"
              type="primary"
              onClick={() => {
                dispatch(doFetchTenantDashboard());
                setVisible(false);
              }}
            >
              Apply
            </Button>
          </div>
        </form>
      </Drawer>
    </>
  );
};
