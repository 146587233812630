import toDate from "date-fns-tz/toDate";
import _ from "lodash";
import { createSelector } from "reselect";
import { AltID, sectionNameByAltId } from "../../constants/courses";
import { ExamStatus } from "../../services/nav-api/student-details/types";
import { RdxStudentDetails } from "../reducers/student-details";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "./../types/status";
import { getStudentEnrollments } from "./student-enrollments";

export type Exam = {
  examDate?: Date;
  examGrade?: number;
  examStatus?: ExamStatus;
};

export type StudentExam = {
  course: string;
  purchaseDate?: Date | undefined;
  expirationDate?: Date | undefined;
  isUnlimited?: boolean;
  exams: Exam[];
};

const orderWeightsByAltId: Record<string, number> = {
  F: 1,
  FAR: 1,
  A: 2,
  AUD: 2,
  R: 3,
  REG: 3,
  B: 4,
  BEC: 4
};

export const getStudentExams: (state: RdxStoreState) => { exams?: StudentExam[]; loading?: boolean } = createSelector(
  getStudentEnrollments,
  (state: RdxStoreState) => state.studentDetails,
  ({ enrollments }, { curStudentId, details, status }: RdxStudentDetails) => {
    if (!curStudentId) {
      return { loading: true };
    }

    if (!details || status === RdxFetchStatus.LOADING) {
      return { loading: true };
    }

    if (!enrollments) {
      return { loading: true };
    }

    const exams = _.map(details.examInfo?.sections, section => {
      const e = enrollments?.filter(e => section.sectionAltId === e.sectionAltId)[0];
      const course = e?.course ?? sectionNameByAltId[section.sectionAltId as AltID];
      return {
        course,
        sectionAltId: section.sectionAltId,
        purchaseDate: e?.purchaseDate,
        expirationDate: e?.expirationDate,
        isUnlimited: e?.isUnlimited ?? false,
        exams: _.map(section.attempts, a => ({
          examDate: a.examDate ? toDate(a.examDate) : undefined,
          examGrade: a.examGrade,
          examStatus: a.examStatus
        }))
      };
    });

    return { exams: (exams || []).flat() };
  }
);
