import { Row, Select, Skeleton, Table } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { MESSAGES } from "../../../i18n";
import { doSelectPartner } from "../../../redux/actions/dashboard/global";
import {
  getGlobalDashboardStats,
  getSelectedPartner,
  getSelectedPartnerTop5Firms,
  isFetchCompleted
} from "../../../redux/selectors/dashboard/global";
import { DashboardCardTitle } from "../common/DashboardCardTitle";
import { DashboardCol } from "../common/DashboardCol";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Top5FirmsTable = (): JSX.Element => {
  const isDataReady = useSelector(isFetchCompleted);
  return <React.Fragment>{isDataReady ? <DataView /> : <WaitingView />}</React.Fragment>;
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const DataView = () => {
  const stats = useSelector(getGlobalDashboardStats);
  const top5Firms = useSelector(getSelectedPartnerTop5Firms);
  const selectedPartner = useSelector(getSelectedPartner);
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <StyledHeader>
        <DashboardCol span={16}>
          <DashboardCardTitle alignment="left">{MESSAGES.Top5Firms}</DashboardCardTitle>
        </DashboardCol>
        <DashboardCol span={8}>
          <StyledSelect
            value={selectedPartner}
            data-qa-label="top-5-firms-select"
            onChange={(e: string) => dispatch(doSelectPartner(e))}
          >
            {_.chain(_.keys(stats?.topTenantsByType))
              .sortBy()
              .map((type: string) => (
                <Select.Option key={type} value={type} data-qa-label={type}>
                  {type}
                </Select.Option>
              ))
              .value()}
          </StyledSelect>
        </DashboardCol>
      </StyledHeader>
      <StyledTable
        rowClassName="cursor-pointer"
        pagination={false}
        dataSource={_.map(top5Firms, (pair, idx) => ({ key: idx, ...pair }))}
        columns={[
          { title: MESSAGES.Name, dataIndex: "name", render: Column0Renderer },
          {
            title: MESSAGES.Students,
            dataIndex: "studentCount",
            render: Column1Renderer,
            onHeaderCell: data => {
              return {
                style: {
                  textAlign: "right"
                }
              };
            }
          }
        ]}
      />
    </React.Fragment>
  );
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const Column0Renderer = (value: any, record: any): React.ReactNode => (
  <StyledTCol0 data-qa-label={`top5firms-column0-${record.key}`} data-qa-label-value={value}>
    {value}
  </StyledTCol0>
);

const Column1Renderer = (value: any, record: any): React.ReactNode => (
  <StyledTCol1 data-qa-label={`top5firms-column1-${record.key}`} data-qa-label-value={value}>
    {value}
  </StyledTCol1>
);

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const WaitingView = () => (
  <React.Fragment>
    <StyledHeader>
      <DashboardCol span={24}>
        <DashboardCardTitle alignment="left">{MESSAGES.Top5Firms}</DashboardCardTitle>
      </DashboardCol>
    </StyledHeader>
    <Skeleton />
  </React.Fragment>
);

// -------------------------------------------------------------------------------------------------
// - Styled Components
// -------------------------------------------------------------------------------------------------

const StyledHeader = styled(Row)`
  padding-bottom: 5pt;
`;

const StyledTable = styled(Table)`
  flex-grow: 1;
`;

const StyledSelect = styled(Select)`
  flex-grow: 2;
  width: 100%;
`;

const StyledTCol0 = styled.div`
  text-align: left;
`;

const StyledTCol1 = styled.div`
  width: 100%;
  text-align: right;
`;
