import { Tag } from "antd";
import React from "react";

interface ComponentProps {
  disabled: boolean;
}

export const VisibilityBadge: React.FC<ComponentProps> = ({ disabled }) => {
  const props = {
    children: disabled ? "Hidden" : "Visible",
    color: disabled ? undefined : "green"
  };
  return <Tag {...props} />;
};
