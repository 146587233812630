import { createSelector } from "reselect";
import { TenantProducts } from "../../services/nav-api/tenants";
import { RdxStoreState } from "../types/state";
import { getIsGlobalUser } from "./permissions/is-global-user";

type Response =
  | {
      active: TenantProducts;
      otherProducts: TenantProducts[];
    }
  | undefined;

const ALL_PRODUCTS: TenantProducts[] = ["cpa", "cma"];

export const getProductSwitcherOptions: (state: RdxStoreState) => Response = createSelector(
  (state: RdxStoreState) => state.myself,
  getIsGlobalUser,
  (myself, isGlobalUser) => {
    if (isGlobalUser) {
      return {
        active: myself?.userSettings?.activeProduct!,
        otherProducts: ALL_PRODUCTS.filter(product => product !== myself?.userSettings?.activeProduct)
      };
    }

    if (!myself.user) {
      return;
    }

    if (!myself.user.tenant?.products?.length) {
      return;
    }

    return {
      active: myself?.userSettings?.activeProduct!,
      otherProducts: myself.user.tenant.products.filter(product => product !== myself?.userSettings?.activeProduct)
    };
  }
);
