import { Card, Empty, Select } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AltID, altIdsByProduct, sectionNameByAltId } from "../../../constants/courses";
import { MESSAGES } from "../../../i18n";
import { getActiveProduct } from "../../../redux/selectors/active-product";
import { getStudentInfo } from "../../../redux/selectors/student-info";
import { DailyStudyTimeResponse, externalApi } from "../../../services/external-api";
import { BarChart } from "./bar-chart";
import { LineChart } from "./line-chart";
import { Charts, ChartsSpacer } from "./styles";
import { ChartRange } from "./types";
const { Option } = Select;

export const StudentStudyTimeCard: React.FC = () => {
  const { info, loading } = useSelector(getStudentInfo);
  const activeProduct = useSelector(getActiveProduct);
  const [data, setData] = useState<DailyStudyTimeResponse | undefined>(undefined);
  const [loadingData, setLoadingData] = useState(false);
  const [range, setRange] = useState<ChartRange>("week");
  const monthRangeInitialDate = moment().subtract("29", "day");
  const [sections, setSections] = useState<AltID[]>([]);
  const [section, setSection] = useState<AltID | undefined>(undefined);

  useEffect(() => {
    const allSections = altIdsByProduct[activeProduct];
    setSections(allSections);
    setSection(_.head(allSections));
  }, [activeProduct]);

  useEffect(() => {
    async function fetch() {
      if (!info?.studentToken) {
        return;
      }
      setLoadingData(true);
      const apiData = await externalApi.dailyStudyTime(
        info?.studentToken,
        activeProduct,
        range === "month" ? monthRangeInitialDate : undefined
      );

      setData(apiData);
      setLoadingData(false);
    }
    fetch();
  }, [info?.studentToken, loading, range]);

  const noData =
    true && //
    !loadingData &&
    data !== undefined &&
    data.days?.length === 0 &&
    data.sections?.length === 0;

  return (
    <Card
      loading={loadingData}
      title={
        <div className="ant-card-head-wrapper">
          <div className="ant-card-head-title" data-qa-label="sd-study-time-title">
            Study Time
          </div>
        </div>
      }
    >
      <Select
        defaultValue={range}
        value={range}
        style={{ width: 130 }}
        onChange={value => setRange(value)}
        className="sd-study-time-selector"
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      >
        <Option value="week">{MESSAGES.Last7Days}</Option>
        <Option value="month">{MESSAGES.Last30Days}</Option>
      </Select>
      {sections?.length > 0 ? (
        <Select
          defaultValue={section}
          value={section}
          style={{ width: 130, paddingLeft: "4pt" }}
          onChange={value => setSection(value)}
          data-qa-label="sd-study-time-section-selector"
          className="sd-section-selector"
          getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        >
          {_.map(sections, section => (
            <Option key={section} value={section} data-qa-label={`sd-study-time-section-${section}`}>
              {sectionNameByAltId[section]}
            </Option>
          ))}
        </Select>
      ) : null}

      <ChartsSpacer />
      {noData ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Charts>
          <LineChart loading={loadingData} section={section} rawData={data} range={range} />
          <BarChart loading={loadingData} section={section} rawData={data} />
        </Charts>
      )}
    </Card>
  );
};
