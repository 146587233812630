import { PlusOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";
import { ErrorMessage, Field, FieldProps, Formik } from "formik";
import React from "react";
import { WorkingFilterTypeWithoutAll } from "../../../redux/actions/partners";
import { PartnersSelect } from "../../shared/partners";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

export interface AddPartnerFormProps {
  partner: string | undefined;
}

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const initialValues: AddPartnerFormProps = {
  partner: undefined
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AddPartnerForm = (props: {
  type: WorkingFilterTypeWithoutAll;
  onSubmit: (props: AddPartnerFormProps) => void;
}) => (
  <Formik<AddPartnerFormProps>
    initialValues={initialValues}
    onSubmit={(values: AddPartnerFormProps, { resetForm }) => {
      props.onSubmit(values);
      resetForm(initialValues as any);
    }}
  >
    {formProps => (
      <Form onFinish={(e: React.FormEvent<any>) => formProps.handleSubmit(e!)}>
        <Form.Item>
          <Field
            label="Partner*"
            name="partner"
            className="assign-partner-field"
            validate={(v: string) => (!v || parseInt(v) === -1 ? "Partner must be defined" : undefined)}
            validateStatus={formProps.submitCount && formProps.errors.partner ? "error" : "success"}
          >
            {({ field }: FieldProps<number, AddPartnerFormProps>) =>
              formProps.values.partner === null ? (
                "Please select a type first"
              ) : (
                <PartnersSelect
                  value={field.value}
                  onChange={(value: number) => formProps.setFieldValue("partner", value)}
                  type={props.type}
                />
              )
            }
          </Field>
          {formProps.submitCount && formProps.errors.partner ? (
            <ErrorMessage component={() => <div className="text-red" />} name="partner" />
          ) : null}
        </Form.Item>
        <Button type="primary" htmlType="submit" disabled={!formProps.isValid} icon={<PlusOutlined />}>
          Assign
        </Button>
      </Form>
    )}
  </Formik>
);
