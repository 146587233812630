import { Form, Select } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { MESSAGES } from "../../../../../i18n";
import { doUpdateWorkingFilter } from "../../../../../redux/actions/students-list";
import { ExamTrackerValue } from "../../../../../redux/actions/students-list/index";
import { useOptions } from "./options";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const ExamTrackerFilter = () => {
  const dispatch = useDispatch();
  const { options, selectedValue } = useOptions();
  return (
    <Form.Item label={MESSAGES.ExamTracker}>
      <Select<string>
        data-qa-label="exam-tracker"
        value={selectedValue}
        getPopupContainer={({ parentNode }) => {
          return parentNode as HTMLElement;
        }}
        onChange={(examTracker: ExamTrackerValue) => {
          dispatch(doUpdateWorkingFilter({ examTracker }));
        }}
      >
        {_.map(options, ({ label, value }) => (
          <Select.Option value={value} key={value} data-qa-label={`et-sfilter-${value}`}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
