import { Form, Select } from "antd";
import React from "react";

// -------------------------------------------------------------------------------------------------
// - OwnProps
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  value: "clear" | "assign";
  onChange: (type: "clear" | "assign") => void;
}

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const types: Array<{ label: string; value: "clear" | "assign" }> = [
  {
    label: "Assign",
    value: "assign"
  },
  {
    label: "Reset",
    value: "clear"
  }
];

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TagsOperationOption = ({ value, onChange }: OwnProps) => {
  return (
    <Form.Item label="Operation">
      <Select<string>
        value={value}
        onChange={onChange}
        style={{ width: "100%" }}
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      >
        {types.map(({ label, value }) => (
          <Select.Option value={value} key={value} className={`option-${value}`}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
