import { Table } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getHasAlertsOperation } from "../../../../redux/selectors/alerts-writer/operation";
import { RdxFetchStatus } from "../../../../redux/types/status";
import { Alert } from "../../../../services/nav-api/alerts-writer/model";
import { TableContext } from "./context";
import { createColumns } from "./table-pure-columns";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const PureTable = () => {
  const hasOperation = useSelector(getHasAlertsOperation);
  return (
    <TableContext.Consumer>
      {({
        eventHandlers: eh,
        alertsState: {
          alerts,
          status,
          paging: {
            //
            page: current,
            itemsPerPage: pageSize,
            totalItems: total
          }
        }
      }) => (
        <StyledTable
          rowClassName="cursor-pointer"
          dataSource={alerts}
          columns={createColumns()}
          loading={_.isEqual(RdxFetchStatus.LOADING, status)}
          onRow={(alert: Alert) => ({
            onClick: () => {
              if (!hasOperation) {
                eh.onEditRow(alert);
              }
            }
          })}
          onChange={eh.onChange}
          pagination={{
            current,
            pageSize,
            total,
            showSizeChanger: true,
            pageSizeOptions: ["50", "100", "250"],
            showQuickJumper: true,
            showLessItems: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
        />
      )}
    </TableContext.Consumer>
  );
};

// -------------------------------------------------------------------------------------------------
// - Styled Component
// -------------------------------------------------------------------------------------------------

const StyledTable = styled(Table)`
  td {
    white-space: nowrap;
  }

  td,
  th {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
