import { SaveOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Form } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { MESSAGES } from "../../../i18n";
import { getActiveUser } from "../../../redux/selectors/active-user";

// -------------------------------------------------------------------------------------------------
// - OwnProps
// -------------------------------------------------------------------------------------------------

export type OwnProps = {
  onSubmit: (props: ChangeSettingsFormProps) => void;
};

export interface ChangeSettingsFormProps {
  unsubscribeNotifications: boolean;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const ChangeSettingsForm = (props: OwnProps) => {
  const user = useSelector(getActiveUser);
  const [formState, setFormState] = useState<ChangeSettingsFormProps>({
    unsubscribeNotifications: user!.unsubscribeAlerts
  });

  return (
    <Card title={MESSAGES.NotificationsCenter}>
      <Form
        onFinish={e => {
          props.onSubmit(formState);
        }}
      >
        <Form.Item>
          <Checkbox
            checked={formState.unsubscribeNotifications}
            onChange={(e: CheckboxChangeEvent) => {
              setFormState({ ...formState, unsubscribeNotifications: e.target.checked });
            }}
          >
            {MESSAGES.UnsubscribeEmailNotifications}
          </Checkbox>
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          icon={<SaveOutlined />}
          disabled={_.isEqual(user!.unsubscribeAlerts, formState.unsubscribeNotifications)}
        >
          {MESSAGES.Save}
        </Button>
      </Form>
    </Card>
  );
};
