import { Alert } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getStudentFilterTagsTree } from "../../../../../redux/selectors/student-filter-tags-tree";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const NoTagsAlert = () => {
  const studentsTree = useSelector(getStudentFilterTagsTree);
  return !studentsTree.loading && (studentsTree.tree ?? []).length == 0 ? (
    <StyledAlert message="No tags" type="info" />
  ) : null;
};

// -------------------------------------------------------------------------------------------------
// - Styled Component
// -------------------------------------------------------------------------------------------------

const StyledAlert = styled(Alert)`
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.06);
  width: 90%;
  margin: 0 auto;
`;
