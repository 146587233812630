import { RdxStore } from "../types/store";

let store: RdxStore | undefined;

const storeRegistry = {
  register(storeInstance: RdxStore) {
    store = storeInstance;
  },
  getStore() {
    return store;
  },
  getState() {
    if (!store) {
      return;
    }
    return store.getState();
  }
};

export { storeRegistry };
