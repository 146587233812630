import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { formatMsg, MESSAGES } from "../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../redux/actions/users-list";
import { getUsersListActiveFilter } from "../../../redux/selectors/users-list";
import { Status } from "../../../services/nav-api/types";
import { FilterTag } from "./model";

// -------------------------------------------------------------------------------------------------
// - Hooks: useStatusFilter
// -------------------------------------------------------------------------------------------------

export const useStatusFilter = (): FilterTag[] => {
  const dispatch = useDispatch();
  const activeFilter = useSelector(getUsersListActiveFilter);
  const status = activeFilter?.filter?.status;

  return !_.isEqual(status, "All")
    ? [
        {
          onClear: () => {
            dispatch(
              doUpdateWorkingFilter({
                status: "All" as Status
              })
            );
            dispatch(doApplyFilter());
          },
          text: formatMsg(
            MESSAGES.FilterTagStatus,
            !_.isEqual(status, Status.Enabled) && !_.isEqual(status, Status.Disabled) //
              ? MESSAGES.AllLabel
              : status
          )
        }
      ]
    : [];
};
