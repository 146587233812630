import { Empty } from "antd";
import React from "react";
import styled from "styled-components";

type Props = React.HTMLAttributes<HTMLElement>;

const Component: React.FC<Props> = htmlProps => (
  <div {...htmlProps}>
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  </div>
);

export const NoDataOverlay = styled(Component)`
  background: rgba(255, 255, 255, 0.8);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
