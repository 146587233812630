import { Card, Descriptions } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { getActiveUser } from "../../../redux/selectors/active-user";
import { getHasTenant } from "../../../redux/selectors/has-tenant";
import { RdxStoreState } from "../../../redux/types/state";
import { Role } from "../../../services/nav-api/roles";
import { EMPTY_CELL_SYMBOL } from "../../shared/table/table-cell-value";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const UserProfileInfo = () => {
  const user = useSelector(getActiveUser);
  const hasTenant = useSelector(getHasTenant);
  const userRoles: Role[] = useSelector(({ myself: { userRoles } }: RdxStoreState) => userRoles ?? []);
  return (
    <Card title={<div className="flex justify-between items-center">Information</div>}>
      <Descriptions bordered size="small" column={1}>
        <Descriptions.Item label="First Name">{user ? user.firstName : EMPTY_CELL_SYMBOL}</Descriptions.Item>
        <Descriptions.Item label="Last Name">{user ? user.lastName : EMPTY_CELL_SYMBOL}</Descriptions.Item>
        <Descriptions.Item label="Username">{user ? user.username : EMPTY_CELL_SYMBOL}</Descriptions.Item>
        <Descriptions.Item label="Role">
          {user
            ? userRoles
              ? userRoles.filter(r => r.id === user.roleId)[0].name
              : EMPTY_CELL_SYMBOL
            : EMPTY_CELL_SYMBOL}
        </Descriptions.Item>
        {hasTenant ? (
          <Descriptions.Item label="Tenant">
            {user && user.tenant ? user.tenant.name : EMPTY_CELL_SYMBOL}
          </Descriptions.Item>
        ) : null}
      </Descriptions>
    </Card>
  );
};
