import { Button, Descriptions, Modal } from "antd";
import React, { useEffect, useState } from "react";
import eulaVersion from "../../assets/eula.embed.checksum.json";
import { SYSTEM_CONFIG } from "../../constants/system-config";
import { navApi } from "../../services/nav-api";

const envParams: { [key: string]: any } = {
  ...SYSTEM_CONFIG,
  frontEndBuild: __BUILD_STRING__,
  eulaVersion: eulaVersion.checksum,
  eulaVersionLastUpdate: eulaVersion.lastUpdated
};

const useEnvironmentInfo = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const evtHandler = (e: KeyboardEvent) => {
      const key = String.fromCharCode(e.which || e.charCode);
      if (e.ctrlKey && e.altKey && key.toUpperCase() === "E") {
        setVisible(true);
      }
    };
    window.addEventListener("keydown", evtHandler);

    // hack for automation.
    (window as any).__showEnvInfo = () => setVisible(true);

    return () => window.removeEventListener("keydown", evtHandler);
  }, []);

  return { visible, setVisible };
};

export const EnvironmentInfo: React.FC = () => {
  const { visible, setVisible } = useEnvironmentInfo();

  const handleResetEula = async () => {
    try {
      await navApi.myself.updateMySelfSettings({ acceptedEulaVersion: "invalid" });
    } catch (e) {
      //
    }
    window.location.reload();
  };

  return (
    <Modal title="Environment Info" open={visible} onCancel={() => setVisible(false)} footer={<div />}>
      <Descriptions bordered size="small">
        {Object.keys(envParams).map(key => (
          <Descriptions.Item label={key} key={key} span={3}>
            {envParams[key]}
          </Descriptions.Item>
        ))}
        <Descriptions.Item label="Reset EULA Acceptance" span={3}>
          <Button onClick={handleResetEula} data-qa-label="reset-eula">
            Reset
          </Button>
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};
