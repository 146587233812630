import { navApi } from "../../../../../../services/nav-api";
import { getTaskBlobWhenReady } from "../../../../../../services/task";
import { getCurDateForReportFilename } from "../../../../../../utils/date-utils";
import { ReportParams, ReportResponse } from "../../types";
import { reduceAttempts } from "./utils";

// -------------------------------------------------------------------------------------------------
// - Generator
// -------------------------------------------------------------------------------------------------

export const genMiniExamScoreReport = async (params: ReportParams): Promise<ReportResponse> => ({
  filename: `mini-exams-score-${reduceAttempts(params)}-${getCurDateForReportFilename()}.xlsx`,
  blob: await getTaskBlobWhenReady((await navApi.studentRoster.miniExamScoreReport(params)).taskToken)
});
