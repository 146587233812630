import { Action } from "../../types/action";

export const OPEN_NAVIGATION_DRAWER = "OPEN_NAVIGATION_DRAWER";
export const CLOSE_NAVIGATION_DRAWER = "CLOSE_NAVIGATION_DRAWER";

export const doOpenNavigationDrawer = (): Action => ({
  type: OPEN_NAVIGATION_DRAWER
});

export const doCloseNavigationDrawer = (): Action => ({
  type: CLOSE_NAVIGATION_DRAWER
});
