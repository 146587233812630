import { message } from "antd";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { doBulkChangeStudentStatus } from "../../../../redux/actions/students-list";
import { doBulkChangeTags } from "../../../../redux/actions/tenants";
import { getMyTenantTagsTree } from "../../../../redux/selectors/my-tenant-tags-tree";
import { excludeRedundantNodes } from "../../../../utils/tree-utils/assign/exclude-redundant-nodes";
import { excludeRedundantNodes as excludeRedundantNodesOnClear } from "../../../../utils/tree-utils/filter/exclude-redundant-nodes";
import { StudentTableActionsContext } from "../table-actions-context";
import { BulkChangeForm } from "./types";

export const useBulkChangeSubmission = (token: string) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { onBulkChangeModalClose } = useContext(StudentTableActionsContext);
  const { tree = [] } = useSelector(getMyTenantTagsTree);

  const onSubmit = async (formProps: BulkChangeForm) => {
    let doAction: () => Promise<any>;

    if (formProps.field === "tags") {
      doAction = async () => {
        const del = formProps.tagsAction === "clear";
        const tags = del
          ? excludeRedundantNodesOnClear(tree, formProps.tags!)
          : excludeRedundantNodes(tree, formProps.tags!);

        await (dispatch(doBulkChangeTags(tags, token, del)) as any);
      };
    } else {
      doAction = async () => {
        await (dispatch(doBulkChangeStudentStatus(Boolean(formProps.hidden), token)) as any);
      };
    }

    setLoading(true);
    try {
      await doAction();
      onBulkChangeModalClose();
      message.success("Bulk change has been performed successfully.");
    } catch (e) {
      setLoading(false);
      message.error("Something went wrong while performing the bulk change.");
    }
  };

  return {
    loading,
    onSubmit
  };
};
