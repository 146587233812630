import _ from "lodash";
import { createSelector } from "reselect";
import { doFetchTenantTags } from "../actions/tenants";
import { RdxTenantsState } from "../reducers/tenants";
import { getStudentFilterTenantId } from "../selectors/student-filter-tenant-id";
import { getUserFetchStatus } from "../selectors/user-fetch-status";
import { ThunkAction } from "../types/action";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";

// -------------------------------------------------------------------------------------------------
// - Reactor
// -------------------------------------------------------------------------------------------------

export const reFetchStudentFilterTenantTags: (state: RdxStoreState) => ThunkAction | undefined = createSelector(
  getStudentFilterTenantId,
  (state: RdxStoreState) => state.tenants,
  getUserFetchStatus,
  (tenantId, { tenantTags }: RdxTenantsState, mySelfFetchStatus) => {
    if (!tenantId || !_.isEqual(RdxFetchStatus.COMPLETED, mySelfFetchStatus)) {
      return;
    }

    const status = tenantTags!.statusByTenantId[tenantId];
    const alreadyHasTenantTags = Boolean(tenantTags!.tagsIdsbyTenantId[tenantId]);
    const isFetchingOrHasFailed = status && (status === RdxFetchStatus.LOADING || status === RdxFetchStatus.FAILED);
    const isDataStale = tenantTags!.staleByTenantId[tenantId];

    if (!isFetchingOrHasFailed && (!alreadyHasTenantTags || isDataStale)) {
      return doFetchTenantTags(tenantId);
    }
  }
);
