import { createReducer } from "../../utils/redux-utils";
import { OPEN_NAVIGATION_DRAWER, CLOSE_NAVIGATION_DRAWER } from "./../../actions/navigation-drawer";

export interface RdxNavigationDrawerState {
  open: boolean;
}

const initialState: RdxNavigationDrawerState = {
  open: true
};

export default createReducer(initialState, {
  [OPEN_NAVIGATION_DRAWER]: () => ({
    open: true
  }),
  [CLOSE_NAVIGATION_DRAWER]: () => ({
    open: false
  })
});
