import _ from "lodash";
import { useSelector } from "react-redux";
import { getPlainUnitsList } from "../../../../../redux/selectors/sections-units";
import { getStudentRosterToken } from "../../../../../redux/selectors/student-roster-token";
import { ReportType } from "../types";
import { handleGenerateReport } from "./api-wrapper";
import { useAttemptByReportType, useMultipleAttemptsByReportType } from "./use-attempt";
import { useDescriptionByReportType } from "./use-description";
import { useExamNumber } from "./use-exam-number";
import { useHierarchyLevel } from "./use-hierarchy-level";
import { useHorizontalSeparatorByReportType } from "./use-horizontal-separator";
import { useLimits } from "./use-limits";
import { useLoadingStatus } from "./use-loading";
import { useReportType } from "./use-report-type";
import { useSectionUnitReportType } from "./use-section-unit";
import { useMultipleSections, useSingleSection } from "./use-sections";
import { useTimesheetByReportType } from "./use-timesheet";

// -------------------------------------------------------------------------------------------------
// - Hooks: useReports
// -------------------------------------------------------------------------------------------------

export function useReports() {
  const { token, itemsPerPage: limit = 0, page = 1, sort, total } = useSelector(getStudentRosterToken);

  const reportType = useReportType();
  const loadingStatus = useLoadingStatus();
  const attempt = useAttemptByReportType(reportType.value);
  const multipleAttempts = useMultipleAttemptsByReportType(reportType.value);
  const timesheet = useTimesheetByReportType(reportType.value);
  const multipleSections = useMultipleSections(reportType.value);
  const singleSection = useSingleSection(reportType.value);
  const hierarchyLevel = useHierarchyLevel(reportType.value);
  const examNumber = useExamNumber(reportType.value);
  const sectionUnit = useSectionUnitReportType(reportType.value);
  const limits = useLimits(limit, total!, reportType.value);
  const horizontalSeparator = useHorizontalSeparatorByReportType(reportType.value);
  const description = useDescriptionByReportType(reportType.value);
  const plainUnitsList = useSelector(getPlainUnitsList);

  return {
    handleGenerateReport: async () => {
      loadingStatus.runBlock(
        async () =>
          await handleGenerateReport({
            type: reportType.value,
            token: token!,
            limit: limits.value,
            offset: limit * (page - 1),
            sort: sort!,
            attemptByReportType: attempt.value,
            multipleAttemptsIncludedByReportType: multipleAttempts.value,
            timesheetPeriodByReportType: timesheet.value,
            multipleSectionsIncludedByReportType: multipleSections.value,
            singleSectionIncludedByReportType: singleSection.value,
            sectionUnitIncludedByReportType: sectionUnit.value,
            hierarchyLevelIncludedByReportType: hierarchyLevel.value,
            examNumberByReportType: examNumber.value,
            plainUnitsList
          })
      );
    },
    limits,
    reportType,
    isLoadingReport: loadingStatus,
    attemptByReportType: attempt,
    multipleAttemptsByReportType: multipleAttempts,
    timesheetPeriodByReportType: timesheet,
    multipleSectionsIncludedByReportType: multipleSections,
    singleSectionIncludedByReportType: singleSection,
    hierarchyLevelIncludedByReportType: hierarchyLevel,
    examNumberByReportType: examNumber,
    sectionUnitIncludedByReportType: sectionUnit,
    descriptionByReportType: description,
    horizontalSeparatorByReportType: horizontalSeparator,
    isCreateButtonAvailable:
      !!reportType.value &&
      // -------------------------------------------------------------------------------------------
      //
      //  ReportType.HOMEWORK_MCQ_FIRST_ATTEMPT_REPORT
      //
      // -------------------------------------------------------------------------------------------

      (_.isEqual(reportType.value, ReportType.HOMEWORK_MCQ_FIRST_ATTEMPT_REPORT)
        ? sectionUnit.value[reportType.value] != null
        : true) &&
      // -------------------------------------------------------------------------------------------
      //
      //  ReportType.PERFORMANCE_DASHBOARD_REPORT,
      //  ReportType.PERFORMANCE_DASHBOARD_FR_REPORT
      //
      // -------------------------------------------------------------------------------------------
      (_.includes(
        [
          ReportType.HOMEWORK_PERFORMANCE_REPORT,
          ReportType.PERFORMANCE_DASHBOARD_REPORT,
          ReportType.PERFORMANCE_DASHBOARD_FR_REPORT
          //
        ],
        reportType.value
      )
        ? (multipleSections.value[reportType.value] ?? [])?.length >= 1 &&
          hierarchyLevel.value[reportType.value] != null
        : true) &&
      // -------------------------------------------------------------------------------------------
      //
      //  ReportType.SIMULATED_EXAMS_ANSWERS_REPORT,
      //  ReportType.MINI_EXAMS_ANSWERS_REPORT
      //
      // -------------------------------------------------------------------------------------------
      (_.includes(
        [
          ReportType.SIMULATED_EXAMS_ANSWERS_REPORT,
          ReportType.MINI_EXAMS_ANSWERS_REPORT
          //
        ],
        reportType.value
      )
        ? !_.isNil(singleSection.value[reportType.value]) && !_.isNil(examNumber.value[reportType.value])
        : true) &&
      // -------------------------------------------------------------------------------------------
      //
      //  ReportType.SIMULATED_EXAMS_SCORE_REPORT,
      //  ReportType.SIMULATED_EXAMS_SCORE_FR_REPORT
      //  ReportType.MINI_EXAMS_SCORE_REPORT
      //
      // -------------------------------------------------------------------------------------------

      (_.includes(
        [
          ReportType.SIMULATED_EXAMS_SCORE_REPORT,
          ReportType.SIMULATED_EXAMS_SCORE_FR_REPORT,
          ReportType.MINI_EXAMS_SCORE_REPORT
        ],
        reportType.value
      )
        ? (multipleSections.value[reportType.value] ?? []).length > 0 &&
          (multipleAttempts.value[reportType.value] ?? []).length > 0
        : true) &&
      // -------------------------------------------------------------------------------------------
      //
      //  ReportType.STUDY_TIME_REPORT
      //
      // -------------------------------------------------------------------------------------------

      (_.isEqual(reportType.value, ReportType.STUDY_TIME_REPORT)
        ? (multipleSections.value[reportType.value] ?? []).length > 0 &&
          (hierarchyLevel.value[reportType.value] ?? []).length > 0
        : true)
  };
}
