export type UseReducerAction<P = any, A extends string = string> = {
  type: A;
  payload?: P;
};

export function createReducer<S, A extends string = string>(mapping: { [P in A]: (state: S, payload: any) => S }) {
  return (state: S, action: UseReducerAction<any, A>): S => {
    const mappedReducer = mapping[action.type];
    return mappedReducer === undefined ? state : mappedReducer(state, action.payload);
  };
}
