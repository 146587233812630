import { Tree } from "antd";
import React, { useMemo } from "react";

import { getAllCheckedIds as getAllCheckedIdsAssign } from "../../../utils/tree-utils/assign/get-all-checked-ids";
import { calcIndeterminatedNodes } from "../../../utils/tree-utils/filter/calc-interminate-ids";
import { getAllCheckedIds as getAllCheckedIdsFilter } from "../../../utils/tree-utils/filter/get-all-checked-ids";
import { getRootsWithCheckedDescendants } from "../../../utils/tree-utils/get-roots-with-checked-descendants";
import { Node } from "../../../utils/tree-utils/types";
import { Loader } from "../../loader";
import { createOnCheckHandlers } from "./check-handlers-by-behavior";
import { CheckBehavior, CheckedNodes } from "./types";

const { TreeNode } = Tree;

type ComponentProps<T extends Node = Node> = {
  tree?: T[];
  loading: boolean;
  checked: number[];
  onChange: (value: CheckedNodes) => void;
  renderNode: (node: T) => JSX.Element | string | undefined | null;
  checkBehavior: CheckBehavior;
};

type RenderTreeNodesOptions = Pick<ComponentProps, "renderNode" | "checked">;
const renderTreeNodes = (nodes: Node[], { renderNode, checked }: RenderTreeNodesOptions) =>
  nodes.map(node => (
    <TreeNode
      title={renderNode(node)}
      key={node.id.toString()}
      selectable={false}
      data-qa-label={`tree-node-${node.name}`}
    >
      {renderTreeNodes(node.children, { renderNode, checked })}
    </TreeNode>
  ));

const renderRootLevelTreeNodes = (nodes: Node[], { renderNode, checked }: RenderTreeNodesOptions) => {
  const rootIds = getRootsWithCheckedDescendants(nodes, checked);

  return nodes.map(node => (
    <TreeNode
      title={rootIds.indexOf(node.id) !== -1 ? <strong>{renderNode(node)}</strong> : renderNode(node)}
      key={node.id.toString()}
      selectable={false}
      checkable={false}
      data-qa-label={`tree-node-${node.name}`}
    >
      {renderTreeNodes(node.children, { renderNode, checked })}
    </TreeNode>
  ));
};

function computeUIHalfCheckedKeys(checkBehavior: CheckBehavior, checkedIds: number[], tree: Node[]) {
  if (checkBehavior === "assign") {
    return [];
  }
  return calcIndeterminatedNodes(tree, checkedIds).map(id => id.toString());
}

function computeUICheckedKeys(checkBehavior: CheckBehavior, checkedIds: number[], tree: Node[]) {
  if (checkBehavior === "assign") {
    return getAllCheckedIdsAssign(tree, checkedIds);
  }
  if (checkBehavior === "filter") {
    return getAllCheckedIdsFilter(tree, checkedIds);
  }
  return checkedIds;
}

export const CheckableTreeView: React.FC<ComponentProps> = ({ tree = [], checkBehavior, ...props }) => {
  const onCheckHandler = useMemo(() => createOnCheckHandlers(tree)[checkBehavior], [tree, checkBehavior]);
  const checked = computeUICheckedKeys(checkBehavior, props.checked, tree);

  return props.loading ? (
    <Loader />
  ) : (
    <Tree
      checkStrictly
      selectable={false}
      checkable
      onCheck={(checkedKeys, e) => {
        const checkedKeysCast = checkedKeys as CheckedNodes<string>;
        props.onChange(onCheckHandler(checkedKeysCast, e as any, checked));
      }}
      checkedKeys={{
        checked: checked.map(id => id.toString()),
        halfChecked: computeUIHalfCheckedKeys(checkBehavior, props.checked, tree)
      }}
    >
      {renderRootLevelTreeNodes(tree, props)}
    </Tree>
  );
};
