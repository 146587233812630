import cloneDeep from "lodash/cloneDeep";
import includes from "lodash/includes";
import uniq from "lodash/uniq";
import difference from "lodash/difference";

import { createNodeParentById } from "../create-mappers";
import { getAllAncestorIds } from "../get-all-ancestors";
import { getAllDescendantIds } from "../get-all-descendants";
import { getAllRootIds } from "../get-all-roots";
import { Node } from "../types";

export type Action = { id: number; check: boolean };

function calcCheckedIdsBasedOnAction(tree: Node[], curCheckedIds: number[], { id, check }: Action) {
  tree = cloneDeep(tree);
  const nodeParentById = createNodeParentById(tree);

  // means node doesn't exist in tree
  if (!nodeParentById.hasOwnProperty(id)) {
    return curCheckedIds;
  }

  if (check) {
    const nodesToCheck = [id];
    nodesToCheck.push(...getAllAncestorIds(tree, id));
    return difference(uniq([...curCheckedIds, ...nodesToCheck]), getAllRootIds(tree));
  }

  const nodesToUncheck = [id];
  nodesToUncheck.push(...getAllDescendantIds(tree, id));

  return curCheckedIds.filter(id => !includes(nodesToUncheck, id));
}

export { calcCheckedIdsBasedOnAction };
