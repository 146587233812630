import { useDispatch, useSelector } from "react-redux";
import { MESSAGES, formatMsg } from "../../../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../../../redux/actions/students-list";
import { initialState } from "../../../../../redux/reducers/students-list";
import { RdxStoreState } from "../../../../../redux/types/state";
import { FilterTag } from "../model";

// -------------------------------------------------------------------------------------------------
// - Hooks: useTags
// -------------------------------------------------------------------------------------------------

export const useTags = (): FilterTag[] => {
  const dispatch = useDispatch();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);
  return activeFilter?.filter?.withoutTags || activeFilter?.filter?.tags?.length
    ? [
        {
          onClear: () => {
            dispatch(doUpdateWorkingFilter({ tags: initialState.workingFilter.tags, withoutTags: undefined }));
            dispatch(doApplyFilter());
          },
          text: formatMsg(
            MESSAGES.FilterTagTags,
            activeFilter.filter.withoutTags //
              ? MESSAGES.WithoutTags
              : activeFilter.filter.tags.length
          )
        }
      ]
    : [];
};
