import { Checkbox, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { doUpdateWorkingFilter } from "../../../../../redux/actions/students-list";
import { getStudentFilterTenantId } from "../../../../../redux/selectors/student-filter-tenant-id";
import { RdxStoreState } from "../../../../../redux/types/state";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantFilters = () => {
  const dispatch = useDispatch();
  const selectedTenantId = useSelector(getStudentFilterTenantId);
  const showTenantFilters = Boolean(selectedTenantId);
  const checkedByKey = useSelector(
    ({
      studentsList: {
        workingFilter: {
          metrics //
        }
      }
    }: RdxStoreState) => metrics
  );

  return showTenantFilters ? (
    <div className="flex">
      <StyledCheckbox
        data-qa-label="cbx-hidden-students"
        checked={checkedByKey.hiddenStudents !== "no"}
        onChange={() => {
          dispatch(
            doUpdateWorkingFilter({
              metrics: {
                ...checkedByKey,
                hiddenStudents: checkedByKey.hiddenStudents !== "no" ? "no" : "include"
              }
            })
          );
        }}
      />
      <Select
        size="small"
        value={checkedByKey.hiddenStudents !== "only" ? "include" : "only"}
        onChange={(hiddenStudents: any) => {
          dispatch(
            doUpdateWorkingFilter({
              metrics: {
                ...checkedByKey,
                hiddenStudents
              }
            })
          );
        }}
        style={{ width: 85 }}
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        data-qa-label="select-hidden-students"
      >
        <Select.Option value="include">Include</Select.Option>
        <Select.Option value="only">Only</Select.Option>
      </Select>{" "}
      hidden students
    </div>
  ) : null;
};

// -------------------------------------------------------------------------------------------------
// - Styled Component
// -------------------------------------------------------------------------------------------------

const StyledCheckbox = styled(Checkbox)`
  margin-left: 10px !important;
  margin-bottom: 3px !important;
`;
