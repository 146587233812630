import React from "react";
import styled from "styled-components";
import noop from "lodash/noop";

const Component: React.FC<{
  className?: string;
  onClick: () => void;
  disabled?: boolean;
  children: string;
}> = props => (
  <span
    title={props.children}
    onClick={props.disabled ? noop : props.onClick}
    className={props.className}
    data-qa-label={`move-tree-node-${props.children}`}
  >
    {props.children}
  </span>
);

export const MoveStudentsTreeNode = styled(Component)`
  display: block;
`;
