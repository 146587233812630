import { Typography } from "antd";
import React from "react";
import { Alert } from "../../../services/nav-api/alerts-reader/model";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertsHeader = ({ alert }: { alert: Alert }): JSX.Element => (
  <div className="flex items-center w-full h-full p-5">
    <Typography.Text className="line-clamp-2">{alert.title}</Typography.Text>
  </div>
);
