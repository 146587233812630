import noop from "lodash/noop";
import React from "react";
import { StudentTableActionTypes } from "./context-provider";

export type StudentTableActionsContextProps = {
  bulkChange: {
    modalOpen: boolean;
    token?: string;
    total?: number;
  };
  operation?: StudentTableActionTypes;
  selection: { active: boolean; ids: number[]; loading: boolean };
  showFilters: boolean;
  onShowFiltersChange: (value: boolean) => void;
  onBulkCurPage: () => void;
  onBulkAllItems: () => void;
  onSelectionConfirm: () => void;
  onSelectionChange: (ids: number[]) => void;
  onSelectionCancel: () => void;
  onBulkChangeModalClose: () => void;
};

export const StudentTableActionsContext = React.createContext<StudentTableActionsContextProps>({
  onBulkCurPage: noop,
  onBulkAllItems: noop,
  onSelectionConfirm: noop,
  onSelectionChange: noop,
  onSelectionCancel: noop,
  onBulkChangeModalClose: noop,
  onShowFiltersChange: noop,
  selection: { active: false, ids: [], loading: false },
  bulkChange: { modalOpen: false },
  showFilters: false
});
