import { CloseOutlined } from "@ant-design/icons";
import { Tag, Typography } from "@beckersdp/becker-ds";
import { Button } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { MESSAGES } from "../../../../i18n";
import { doFetchTenantDashboard } from "../../../../redux/actions/dashboard/tenant/fetch";
import { isFetching } from "../../../../redux/selectors/dashboard/tenant";
import { useFilterTags } from "./useFilterTags";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TagsList = () => {
  const dispatch = useDispatch();
  const filterTags = useFilterTags();
  const isLoading = useSelector(isFetching);

  return React.useMemo(
    () =>
      filterTags?.length > 0 ? (
        <Wrapper>
          <Typography.Text level="2" style={{ marginRight: "1rem" }}>
            {MESSAGES.FilteredBy}
          </Typography.Text>
          {_.map(filterTags, ({ text, onClear }) => (
            <Tag key={text} disabled={isLoading} onClose={onClear}>
              {text}
            </Tag>
          ))}
          <Button
            icon={<CloseOutlined />}
            type="text"
            disabled={isLoading}
            onClick={() => {
              dispatch(doFetchTenantDashboard(true));
            }}
          >
            {MESSAGES.ResetFilters}
          </Button>
          <hr />
        </Wrapper>
      ) : null,
    [filterTags, isLoading]
  );
};

// -------------------------------------------------------------------------------------------------
// - Styled Components
// -------------------------------------------------------------------------------------------------

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -5px;
  margin-bottom: 8px;

  > button {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;
