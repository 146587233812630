import _ from "lodash";
import React from "react";

// -------------------------------------------------------------------------------------------------
// - Context
// -------------------------------------------------------------------------------------------------

interface Context {
  active?: string;
  setLogoutModalVisible: (value: boolean) => void;
}

// -------------------------------------------------------------------------------------------------
// - AppMenuContext
// -------------------------------------------------------------------------------------------------

export const AppMenuContext = React.createContext<Context>({
  setLogoutModalVisible: _.noop
});
