import { Col, Row, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { MESSAGES } from "../../../../i18n";
import { getActiveProduct } from "../../../../redux/selectors/active-product";
import { FollowedStudents } from "./components/FollowedStudents";
import { StudentsWithoutTime } from "./components/StudentsWithoutTime";
import { StudyPlanStats } from "./components/StudyPlansStats";
import { StatsScope } from "./components/StudyPlansStats/model";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const MoreDetails = () => {
  const activeProduct = useSelector(getActiveProduct);
  return (
    <div>
      <Typography.Title level={5}>{MESSAGES.MoreDetails}</Typography.Title>
      <Row gutter={[16, 16]}>
        {_.isEqual(activeProduct, "cpa") ? (
          <>
            <Col xs={24} lg={12} xl={12} xxl={10}>
              <StudyPlanStats scope={StatsScope.LICENSES} />
            </Col>

            <Col xs={24} lg={12} xl={12} xxl={10}>
              <StudyPlanStats scope={StatsScope.SPS_USAGE} />
            </Col>

            <Col xs={24} lg={12} xl={12} xxl={10}>
              <StudyPlanStats scope={StatsScope.SPS_PERFORMANCE} />
            </Col>

            <Col xs={24} md={12} lg={6} xl={6} xxl={5}>
              <StudentsWithoutTime />
            </Col>

            <Col xs={24} md={12} lg={6} xl={6} xxl={5}>
              <FollowedStudents />
            </Col>
          </>
        ) : (
          <>
            <Col xs={24} xl={12} xxl={12}>
              <StudyPlanStats scope={StatsScope.LICENSES} />
            </Col>
            <Col xs={24} lg={12} xl={6} xxl={4}>
              <StudentsWithoutTime />
            </Col>
            <Col xs={24} lg={12} xl={6} xxl={4}>
              <FollowedStudents />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
