import React from "react";
import { useSelector } from "react-redux";
import { getActiveProduct } from "../../../../../redux/selectors/active-product";
import { RdxStoreState } from "../../../../../redux/types/state";
import { ExamTrackerOptionUtils } from "./option-utils";

// -------------------------------------------------------------------------------------------------
// - Hook: useOptions
// -------------------------------------------------------------------------------------------------

export const useOptions = () => {
  const activeProduct = useSelector(getActiveProduct);
  const examTracker = useSelector(
    ({
      studentsList: {
        workingFilter: {
          examTracker //
        }
      }
    }: RdxStoreState) => examTracker
  );

  return {
    selectedValue: examTracker,
    options: React.useMemo(() => {
      return ExamTrackerOptionUtils.getOptionsByProduct(activeProduct);
    }, [activeProduct])
  };
};
