import { Tag } from "antd";
import _ from "lodash";
import React from "react";
import { UserType } from "../../../services/nav-api/users";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AccountTypeTag = (props: { userType: UserType }) => (
  <Tag color={_.isEqual(props.userType, UserType.Local) ? "blue" : "green"}>
    {_.isEqual(props.userType, UserType.Local) ? "Local" : "BIM"}
  </Tag>
);
