import { createSelector } from "reselect";

import { RdxStoreState } from "../types/state";
import { getIsTenantsListLoading } from "./is-tenants-list-loading";
import { getIsTenantsListFilterEqualsToAppliedFilter } from "./is-tenants-list-filter-equals-to-active";

export const getIsApplyTenantsListFilterDisabled: (state: RdxStoreState) => boolean = createSelector(
  getIsTenantsListLoading,
  getIsTenantsListFilterEqualsToAppliedFilter,
  (loading: boolean, equals: boolean): boolean => {
    return equals || loading;
  }
);
