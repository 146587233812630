import { useDispatch, useSelector } from "react-redux";
import { formatMsg, MESSAGES } from "../../../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../../../redux/actions/students-list";
import { initialState } from "../../../../../redux/reducers/students-list";
import { RdxStoreState } from "../../../../../redux/types/state";
import { format } from "../../../../../utils/date-utils";
import { FilterTag } from "../model";

// -------------------------------------------------------------------------------------------------
// - Hooks: usePurchasedDate
// -------------------------------------------------------------------------------------------------

export const usePurchasedDate = (): FilterTag[] => {
  const dispatch = useDispatch();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);
  return activeFilter?.filter?.purchasedDate?.startDate
    ? [
        {
          onClear: () => {
            dispatch(doUpdateWorkingFilter({ purchasedDate: initialState.workingFilter.purchasedDate }));
            dispatch(doApplyFilter());
          },
          text: formatMsg(
            MESSAGES.FilterTagPurDate,
            `${format(activeFilter.filter.purchasedDate.startDate, "P")} - ${format(
              activeFilter.filter.purchasedDate.endDate!,
              "P"
            )}`
          )
        }
      ]
    : [];
};
