import { useRef, useState } from "react";
import { MESSAGES } from "../i18n";

export type SetPasswordHookResult = {
  password: string;
  retypePassword: string;
  onPasswordChange: (value: string) => void;
  onRetypePasswordChange: (value: string) => void;
  passwordHelp: {
    text: string;
    error: boolean;
    touched: boolean;
  };
  retypePasswordHelp: {
    text: string;
    error: boolean;
    touched: boolean;
  };
};

const PASSWORD_MIN_LENGTH = 8;

export const useSetPassword = (): SetPasswordHookResult => {
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const fieldsTouched = useRef({
    password: false,
    retypePassword: false
  });

  if (password.length) {
    fieldsTouched.current.password = true;
  }
  if (retypePassword.length) {
    fieldsTouched.current.retypePassword = true;
  }

  const passwordHelp = {
    text: MESSAGES.PasswordMinCharsText,
    error: password.length < PASSWORD_MIN_LENGTH,
    touched: fieldsTouched.current.password
  };

  const retypePasswordHelp = {
    text: MESSAGES.PasswordDidNotMatchText,
    error: password !== retypePassword,
    touched: fieldsTouched.current.retypePassword
  };

  return {
    password,
    retypePassword,
    passwordHelp,
    retypePasswordHelp,
    onPasswordChange: setPassword,
    onRetypePasswordChange: setRetypePassword
  };
};
