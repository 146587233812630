import { Button, Card, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { MESSAGES } from "../../../../../i18n";
import { doSetFilter } from "../../../../../redux/actions/students-list/index";
import {
  getCurrentWorkingFilter,
  getTenantDashboardStats,
  isFetchCompleted
} from "../../../../../redux/selectors/dashboard/tenant";
import { routes } from "../../../../../routes";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const ActiveStudents = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ready = useSelector(isFetchCompleted);
  const stats = useSelector(getTenantDashboardStats);
  const filter = useSelector(getCurrentWorkingFilter);

  return (
    <Card
      loading={!ready}
      className="h-full"
      bodyStyle={{
        display: "flex",
        height: "100%",
        flexDirection: "column"
      }}
    >
      <Typography.Text className="uppercase" type="secondary" style={{ minHeight: "30px" }}>
        {MESSAGES.ActiveStudents}
      </Typography.Text>

      <div
        className={_.join(
          [
            "flex",
            "text-center",
            "items-center",
            "h-full" //
          ],
          " "
        )}
      >
        <Button
          type="link"
          className={_.join(
            [
              "text-5xl",
              "text-slate-600",
              "font-semibold",
              "w-full",
              "h-full" //
            ],
            " "
          )}
          onClick={() => {
            dispatch(doSetFilter({ ...filter, license: "active" }));
            navigate(routes.students.resolve());
          }}
        >
          {stats?.studentsActivity?.activeStudents}
        </Button>
      </div>
    </Card>
  );
};
