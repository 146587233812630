import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { externalApi } from "../../../services/external-api";
import { getIsCPAPreEvolutionSelected } from "../../selectors/contentversion";
import { Action, ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "../../types/status";
import { HttpClientErrorResponse } from "./../../../services/http-client/types";
import {
  STUDENT_DETAILS_PERF_DASHBOARD_FETCH_COMPLETED,
  STUDENT_DETAILS_PERF_DASHBOARD_FETCH_STARTED
} from "./constants";
import { StudentHierarchyProgress } from "./types";

export type FetchStudentPerfDashboardCompletedPayload = {
  error?: HttpClientErrorResponse;
  hierarchy?: StudentHierarchyProgress;
  timestamp: number;
  studentId: number;
};

export const doFetchStudentPerfDashboardStarted = (studentId: number): Action<number> => ({
  type: STUDENT_DETAILS_PERF_DASHBOARD_FETCH_STARTED,
  payload: studentId
});

export const doFetchStudentPerfDashboardCompleted = (data: {
  studentId: number;
  hierarchy?: StudentHierarchyProgress;
  error?: HttpClientErrorResponse;
}): Action<FetchStudentPerfDashboardCompletedPayload> => ({
  type: STUDENT_DETAILS_PERF_DASHBOARD_FETCH_COMPLETED,
  payload: { ...data, timestamp: Date.now() }
});

const shouldFetchPerfDashboard = (state: RdxStoreState) => {
  const hierarchyProgress = state.studentDetails.hierarchyProgress;
  const student = state.studentDetails.details;
  const isFetching = hierarchyProgress && hierarchyProgress.status === RdxFetchStatus.LOADING;
  const hasStudentToken = student && student.studentToken;

  return !isFetching && hasStudentToken;
};

export const doFetchStudentPerformanceDashboard = (
  studentId: number,
  studentToken: string,
  activeProduct: string
): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { httpClient }
  ): Promise<void> => {
    if (!shouldFetchPerfDashboard(getState())) {
      return;
    }
    dispatch(doFetchStudentPerfDashboardStarted(studentId));

    try {
      const hierarchy: StudentHierarchyProgress = await externalApi.hierarchyProgress(
        studentToken,
        activeProduct,
        getIsCPAPreEvolutionSelected(getState())
      );
      dispatch(doFetchStudentPerfDashboardCompleted({ studentId, hierarchy }));
    } catch (ex) {
      const error: HttpClientErrorResponse = ex;
      dispatch(doFetchStudentPerfDashboardCompleted({ studentId, error }));
    }
  };
};
