import { Row, RowProps } from "antd";
import React from "react";
import styled from "styled-components";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CustomRow = (props: RowProps & { bp?: boolean }) => {
  const { bp, ...baseProps } = props;
  return <Row {...baseProps} gutter={[16, 16]} />;
};

// -------------------------------------------------------------------------------------------------
// - Styled Components
// -------------------------------------------------------------------------------------------------

export const DashboardRow = styled(CustomRow)`
  padding-bottom: ${props => (props.bp ? 16 : 0)}px;
`;
