import { Button, Card, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { MESSAGES } from "../../../../../i18n";
import { doSetFilter } from "../../../../../redux/actions/students-list";
import {
  getCurrentWorkingFilter,
  getExamTrackerStats,
  isFetchCompleted
} from "../../../../../redux/selectors/dashboard/tenant";
import { routes } from "../../../../../routes";

// -------------------------------------------------------------------------------------------------
// - Model
// -------------------------------------------------------------------------------------------------

export enum Param {
  PASSED_ALL_PARTS = "PASSED_ALL_PARTS",
  NO_EXAM_TRACKER_DATA = "NO_EXAM_TRACKER_DATA",
  PASSED_ANY_PART = "PASSED_ANY_PART",
  ABOUT_TO_LOSE_OR_LOST_CREDITS = "ABOUT_TO_LOSE_OR_LOST_CREDITS"
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const NumericParam = (props: { param: Param }): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stats = useSelector(getExamTrackerStats);
  const ready = useSelector(isFetchCompleted);
  const filter = useSelector(getCurrentWorkingFilter);
  return (
    <Card
      loading={!ready}
      className="h-full"
      bodyStyle={{
        height: "100%",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <div className="text-center truncate" style={{ minHeight: "30px" }}>
        <Typography.Text className="uppercase" type="secondary">
          {props.param == Param.PASSED_ALL_PARTS
            ? MESSAGES.PassedAllParts
            : props.param == Param.NO_EXAM_TRACKER_DATA
              ? MESSAGES.NoExamTrackerData
              : props.param == Param.PASSED_ANY_PART
                ? MESSAGES.PassedAnyPart
                : props.param == Param.ABOUT_TO_LOSE_OR_LOST_CREDITS
                  ? MESSAGES.AboutToLoseOrLostCredits
                  : ""}
        </Typography.Text>
      </div>

      <div className="flex items-center h-full">
        <Button
          type="link"
          data-qa-label={`ex-${_.kebabCase(props.param)}`}
          className={_.join(["text-3xl", "text-slate-600", "font-medium", "w-full"], " ")}
          onClick={() => {
            dispatch(
              doSetFilter({
                ...filter,
                license: "active",
                examTracker:
                  props.param == Param.PASSED_ALL_PARTS
                    ? "passed_all_parts"
                    : props.param == Param.NO_EXAM_TRACKER_DATA
                      ? "no_data"
                      : props.param == Param.PASSED_ANY_PART
                        ? "passed_any_part"
                        : props.param == Param.ABOUT_TO_LOSE_OR_LOST_CREDITS
                          ? "about_to_lose_or_lost_credits"
                          : "any"
              })
            );
            navigate(routes.students.resolve());
          }}
        >
          {props.param == Param.PASSED_ALL_PARTS
            ? stats?.passedAllParts
            : props.param == Param.NO_EXAM_TRACKER_DATA
              ? stats?.noExamTrackerData
              : props.param == Param.PASSED_ANY_PART
                ? stats?.passedAnyPart
                : props.param == Param.ABOUT_TO_LOSE_OR_LOST_CREDITS
                  ? stats?.aboutToLooseCredits
                  : null}
        </Button>
      </div>
    </Card>
  );
};
