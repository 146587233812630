import * as HttpClient from "../../http-client";
import { getUrlWithEncodedParams } from "../../http-client/utils";
import { createUnAuthHeaders } from "../headers";

export type PostInitiateParams = {
  username: string;
};

export type PostInitiateResponse = {
  userMsg: string;
  success: boolean;
};

export type GetPasswordStrength = {
  score: number;
  warning: string | null;
  suggestions: string[];
  isValid: number;
};

const initiate = async (args: PostInitiateParams): Promise<PostInitiateResponse> => {
  const url = getUrlWithEncodedParams("/rest/unauth/forgotPassword");
  return HttpClient.post(url, createUnAuthHeaders(), args);
};

const passwordStrength = async (password: string): Promise<GetPasswordStrength> => {
  return HttpClient.post(`/rest/unauth/passwordStrength`, createUnAuthHeaders(), { password });
};

const resetPassword = async (password: string, token: string): Promise<void> => {
  return HttpClient.put(`/rest/unauth/forgotPassword/${token}`, createUnAuthHeaders(), { password });
};

const validateToken = async (token: string): Promise<{ username: string }> => {
  return HttpClient.get(`/rest/unauth/forgotPassword/${token}`, createUnAuthHeaders(), { token });
};

export { initiate, passwordStrength, resetPassword, validateToken };
