import keyBy from "lodash/keyBy";
import uniq from "lodash/uniq";
import { Tag } from "../../../services/nav-api/tenants";
import { FetchTenantTagsCompleted, TENANT_TAGS_FETCH_COMPLETED } from "../../actions/tenants";
import { RdxFetchStatus } from "../../types/status";
import { createReducer } from "../../utils/redux-utils";
import {
  TagEditorDragCompletedPayload,
  TagEditorDragStartedPayload,
  TAG_EDITOR_TAG_DRAG_COMPLETED,
  TAG_EDITOR_TAG_DRAG_STARTED
} from "./../../actions/tenants/editor-tags";
import {
  CloneTagCompletedPayload,
  CloneTagStartedPayload,
  CreateTagCompletedPayload,
  DeleteTagCompletedPayload,
  MoveStudentsCompletedPayload,
  TENANT_TAGS_CLONE_TAG_COMPLETED,
  TENANT_TAGS_CLONE_TAG_STARTED,
  TENANT_TAGS_CREATE_TAG_COMPLETED,
  TENANT_TAGS_DELETE_TAG_COMPLETED,
  TENANT_TAGS_DELETE_TAG_STARTED,
  TENANT_TAGS_MOVE_STUDENTS_COMPLETED,
  TENANT_TAGS_MOVE_STUDENTS_STARTED,
  TENANT_TAGS_UPDATE_TAG_COMPLETED,
  TENANT_TAGS_UPDATE_TAG_STARTED,
  UpdateTagCompletedPayload
} from "./../../actions/tenants/tags";

export interface RdxTagsSubState {
  byId: { [id: number]: Tag };
  statusById: { [id: number]: RdxFetchStatus };
  allIds: number[];
  restrictedIds: number[] | undefined; // this is used to filter tags when user is Tenant User
  status: RdxFetchStatus;
}

const initialState: RdxTagsSubState = {
  byId: {},
  statusById: {},
  allIds: [],
  restrictedIds: undefined,
  status: RdxFetchStatus.NONE
};

export default createReducer<RdxTagsSubState>(initialState, {
  [TENANT_TAGS_FETCH_COMPLETED]: (state, { error, tags, restrictedTagIds }: FetchTenantTagsCompleted) => {
    if (error) {
      return { ...state, status: RdxFetchStatus.FAILED };
    }
    const partialState = {
      ...state,
      status: RdxFetchStatus.COMPLETED,
      byId: { ...state.byId, ...keyBy(tags, "id") },
      allIds: uniq([...state.allIds, ...tags!.map(t => t.id)])
    };

    if (!restrictedTagIds) {
      return partialState;
    }

    return {
      ...partialState,
      restrictedIds: uniq([...(state.restrictedIds || []), ...restrictedTagIds])
    };
  },
  [TAG_EDITOR_TAG_DRAG_STARTED]: (state, { id }: TagEditorDragStartedPayload) => {
    return {
      ...state,
      statusById: {
        ...state.statusById,
        [id]: RdxFetchStatus.LOADING
      }
    };
  },
  [TAG_EDITOR_TAG_DRAG_COMPLETED]: (state, { id, parentId, error }: TagEditorDragCompletedPayload) => {
    if (error) {
      return {
        ...state,
        statusById: {
          ...state.statusById,
          [id]: RdxFetchStatus.FAILED
        }
      };
    }
    return {
      ...state,
      byId: {
        ...state.byId,
        [id]: { ...state.byId[id], parentId }
      },
      statusById: {
        ...state.statusById,
        [id]: RdxFetchStatus.COMPLETED
      }
    };
  },
  [TENANT_TAGS_UPDATE_TAG_STARTED]: (state, tagId: number) => {
    return {
      ...state,
      statusById: {
        ...state.statusById,
        [tagId]: RdxFetchStatus.LOADING
      }
    };
  },
  [TENANT_TAGS_UPDATE_TAG_COMPLETED]: (state, { tagId, data, error }: UpdateTagCompletedPayload) => {
    if (error) {
      return {
        ...state,
        statusById: {
          ...state.statusById,
          [tagId]: RdxFetchStatus.FAILED
        }
      };
    }
    return {
      ...state,
      byId: {
        ...state.byId,
        [tagId]: {
          ...state.byId[tagId],
          name: data!.name,
          parentId: data!.parentId
        }
      },
      statusById: {
        ...state.statusById,
        [tagId]: RdxFetchStatus.COMPLETED
      }
    };
  },
  [TENANT_TAGS_DELETE_TAG_STARTED]: (state, tagId: number) => ({
    ...state,
    statusById: {
      ...state.statusById,
      [tagId]: RdxFetchStatus.LOADING
    }
  }),
  [TENANT_TAGS_MOVE_STUDENTS_STARTED]: (state, tagId: number) => ({
    ...state,
    statusById: {
      ...state.statusById,
      [tagId]: RdxFetchStatus.LOADING
    }
  }),
  [TENANT_TAGS_MOVE_STUDENTS_COMPLETED]: (
    state,
    { srcTagId, destTagId, enrolledStudentCount, error }: MoveStudentsCompletedPayload
  ) => {
    if (error) {
      return {
        ...state,
        statusById: {
          ...state.statusById,
          [srcTagId]: RdxFetchStatus.FAILED
        }
      };
    }
    const byId = { ...state.byId };

    byId[srcTagId] = {
      ...byId[srcTagId],
      enrolledStudentCount: 0
    };
    byId[destTagId] = {
      ...byId[destTagId],
      enrolledStudentCount: (byId[destTagId].enrolledStudentCount || 0) + enrolledStudentCount!
    };

    return {
      ...state,
      byId,
      statusById: {
        ...state.statusById,
        [srcTagId]: RdxFetchStatus.COMPLETED
      }
    };
  },
  [TENANT_TAGS_DELETE_TAG_COMPLETED]: (state, { error, tagId }: DeleteTagCompletedPayload) => {
    if (error) {
      return {
        ...state,
        statusById: {
          ...state.statusById,
          [tagId]: RdxFetchStatus.FAILED
        }
      };
    }
    const byId = { ...state.byId };
    delete byId[tagId];

    return {
      ...state,
      byId,
      allIds: state.allIds.filter(id => id !== tagId),
      statusById: {
        ...state.statusById,
        [tagId]: RdxFetchStatus.COMPLETED
      }
    };
  },
  [TENANT_TAGS_CREATE_TAG_COMPLETED]: (state, { error, ...tag }: CreateTagCompletedPayload) => {
    if (error) {
      return state;
    }
    const byId = { ...state.byId, [tag.id]: { ...tag, enrolledStudentCount: 0 } };

    return {
      ...state,
      byId,
      allIds: [...state.allIds, tag.id],
      statusById: {
        ...state.statusById,
        [tag.id]: RdxFetchStatus.COMPLETED
      }
    };
  },
  [TENANT_TAGS_CLONE_TAG_STARTED]: (state, { tagId }: CloneTagStartedPayload) => ({
    ...state,
    statusById: {
      ...state.statusById,
      [tagId]: RdxFetchStatus.LOADING
    }
  }),
  [TENANT_TAGS_CLONE_TAG_COMPLETED]: (state, { tagId }: CloneTagCompletedPayload) => {
    return {
      ...state,
      statusById: {
        ...state.statusById,
        [tagId]: RdxFetchStatus.COMPLETED
      }
    };
  }
});
