import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getStudyPlanUsageStats, isFetchCompleted } from "../../../../../../../redux/selectors/dashboard/tenant";
import { StatsData, StatsScope, StatsSlice } from "../model";

// -------------------------------------------------------------------------------------------------
// - Hook: useUsage
// -------------------------------------------------------------------------------------------------

export const useUsageData = (scope: StatsScope): StatsData[] | undefined => {
  const isReady = useSelector(isFetchCompleted);
  const stats = useSelector(getStudyPlanUsageStats);
  return React.useMemo(
    () =>
      _.isEqual(scope, StatsScope.SPS_USAGE) && isReady && !!stats
        ? [
            { slice: StatsSlice.S1, value: stats.withStudyPlan },
            { slice: StatsSlice.S2, value: stats.withoutStudyPlan }
          ]
        : undefined,
    [stats, scope]
  );
};
