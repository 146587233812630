import { BellOutlined } from "@ant-design/icons";
import { Button, Typography, notification } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { doPushAlert } from "../../redux/actions/alerts-reader/pusher";
import { getLatestUnreadAlert, getListOfPushedAlerts } from "../../redux/selectors/alerts-reader/alerts";
import { routes } from "../../routes";
import { paths } from "../../routes/paths";
import { StringUtils } from "../../utils/stringUtils";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertsToasts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const isInAlertsPage = React.useMemo(() => location.pathname.startsWith(paths.alertsReader), [location.pathname]);

  const latestUnreadAlert = useSelector(getLatestUnreadAlert);
  const listOfPushedAlerts = useSelector(getListOfPushedAlerts);
  const [api, contextHolder] = notification.useNotification();
  const [runOnce, setRunOnce] = React.useState(false);

  React.useLayoutEffect(() => {
    if (
      !runOnce && //
      !isInAlertsPage &&
      !!latestUnreadAlert &&
      !_.includes(listOfPushedAlerts, latestUnreadAlert.id)
    ) {
      setRunOnce(true);
    }
  }, [isInAlertsPage, latestUnreadAlert, listOfPushedAlerts]);

  React.useLayoutEffect(() => {
    if (!runOnce) {
      return;
    }

    const key = `notification-${uuid()}`;
    api.warning({
      key,
      duration: 0,
      icon: <BellOutlined />,
      message: <Typography.Text strong>{latestUnreadAlert.title}</Typography.Text>,
      description: (
        <div className="flex flex-col">
          <div className="line-clamp-2">{StringUtils.htmlToPlainText(latestUnreadAlert.body)}</div>
          <div className="flex w-full justify-end">
            <Button
              type="link"
              onClick={() => {
                api.destroy(key);
                setTimeout(() => {
                  navigate(routes.alertsReader.resolve({ id: latestUnreadAlert.id.toString() }));
                }, 500);
              }}
            >
              Read more...
            </Button>
          </div>
        </div>
      )
    });

    dispatch(doPushAlert(latestUnreadAlert.id));
  }, [runOnce]);

  return <>{contextHolder}</>;
};
