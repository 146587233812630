import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { navApi } from "../../../services/nav-api";
import { Alert } from "../../../services/nav-api/alerts-reader/model";
import { Action, ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";

// -------------------------------------------------------------------------------------------------
// - Actions
// -------------------------------------------------------------------------------------------------

export const USER_FETCH_ALERTS_STARTED = "USER_FETCH_ALERTS_STARTED";
export const USER_FETCH_ALERTS_COMPLETED = "USER_FETCH_ALERTS_COMPLETED";
export const USER_FETCH_ALERTS_FAILED = "USER_FETCH_ALERTS_FAILED";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doStartFetchAlerts = (): Action => ({
  type: USER_FETCH_ALERTS_STARTED
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doFetchAlerts = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    dispatch(doStartFetchAlerts());
    try {
      dispatch(
        doCompleteFetchAlerts(
          _.orderBy(await navApi.alertsReader.fetchAlerts(), ["publishDate", "id"], ["desc", "desc"])
        )
      );
    } catch (ex) {
      dispatch(doFailFetchAlerts((ex as HttpClientErrorResponse).message || "error"));
    }
  };
};

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doCompleteFetchAlerts = (alerts: Alert[]): Action<Alert[]> => ({
  type: USER_FETCH_ALERTS_COMPLETED,
  payload: alerts
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doFailFetchAlerts = (payload: string): Action<string> => ({
  type: USER_FETCH_ALERTS_FAILED,
  payload
});
