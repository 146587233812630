import _ from "lodash";
import { createSelector } from "reselect";
import { AltID, sectionAltIdsOrder } from "../../constants/courses";
import { RdxStoreState } from "../types/state";
import { getActiveProduct } from "./active-product";

export const getSectionsSelectOptions = createSelector(
  ({ hierarchyContent: { content } }: RdxStoreState) => content,
  content =>
    _.chain(content?.sections)
      .sortBy(({ altId }) => _.indexOf(sectionAltIdsOrder, altId))
      .reduce(
        (a, s) => [
          ...a,
          {
            label: s.abbrev,
            value: s.altId as AltID
          }
        ],
        []
      )
      .value()
);

export const getPlainUnitsList = createSelector(
  ({ hierarchyContent: { content } }: RdxStoreState) => content,
  getActiveProduct,
  (content, product) =>
    _.chain(content?.sections)
      .sortBy(({ altId }) => _.indexOf(sectionAltIdsOrder, altId))
      .reduce(
        (a, s) => [
          ...a,
          ..._.map(s.units, unit => ({
            unitAltId: unit.altId,
            filenameSuffix: _.isEqual(product, "cma") //
              ? `${s.abbrev}-${unit.abbrev}`
              : unit.abbrev
          }))
        ],
        []
      )
      .value()
);

export const getUnitsGroupedBySection = createSelector(
  ({ hierarchyContent: { content } }: RdxStoreState) => content,
  content =>
    _.chain(content?.sections)
      .sortBy(({ altId }) => _.indexOf(sectionAltIdsOrder, altId))
      .reduce(
        (a, s) => [
          ...a,
          {
            label: s.abbrev,
            value: s.altId,
            units: _.map(s.units, unit => ({
              label: `${unit.abbrev} - ${unit.name}`,
              value: unit.altId
            }))
          }
        ],
        []
      )
      .value()
);
