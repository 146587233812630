import { DownOutlined, EditOutlined, FilterOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Menu } from "antd";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FEATURES } from "../../../../constants/ui-features";
import { MESSAGES, formatMsg } from "../../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../../redux/actions/students-list";
import { getAllowedFeatures } from "../../../../redux/selectors/allowed_features";
import { getStudentsTableDetails } from "../../../../redux/selectors/students-table-data-source";
import { RdxStoreState } from "../../../../redux/types/state";
import { ReportsModal } from "../reports-modal";
import { StudentTableActionsContext } from "../table-actions-context";
import { TagsList } from "./tags-list";

const SEARCH_MIN_CHARS = 3;

// -------------------------------------------------------------------------------------------------
// - OwnProps
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  className?: string;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentTableActions = (props: OwnProps) => {
  const { onBulkAllItems, onBulkCurPage, onShowFiltersChange } = useContext(StudentTableActionsContext);
  const workingFilter = useSelector((state: RdxStoreState) => state.studentsList.workingFilter);
  const allowedFeatures = useSelector(getAllowedFeatures) || {};
  const { paging } = useSelector(getStudentsTableDetails);
  const totalItems = paging && paging.total ? paging.total : 0;
  const dispatch = useDispatch();

  function handleSearchSubmit(e?: React.SyntheticEvent) {
    e?.preventDefault();
    if (workingFilter.searchTerm.length >= SEARCH_MIN_CHARS) {
      dispatch(doApplyFilter());
    }
  }

  return (
    <>
      <div className="flex items-center gap-x-2 my-[1rem]">
        <div className="flex flex-1 gap-x-2">
          <form className="flex-1" onSubmit={handleSearchSubmit}>
            <Input.Search
              value={workingFilter.searchTerm}
              enterButton={<Button type="primary" icon={<SearchOutlined />} data-qa-label="search-btn" />}
              onChange={e => dispatch(doUpdateWorkingFilter({ searchTerm: e.currentTarget.value }))}
              placeholder={formatMsg(MESSAGES.FilterSearchPlaceholderWMinChars, SEARCH_MIN_CHARS)}
              data-qa-label="search-field"
              onSearch={() => handleSearchSubmit()}
            />
          </form>

          <Button data-qa-label="filtersBtn" onClick={() => onShowFiltersChange(true)}>
            <FilterOutlined />
            {MESSAGES.FilterBtnLabel}
          </Button>
          <ReportsModal />
        </div>

        {allowedFeatures[FEATURES.MANAGE_TENANT_STUDENTS] ? (
          <Dropdown
            placement="bottomRight"
            overlay={
              <Menu>
                <Menu.SubMenu
                  key="4"
                  title={
                    <>
                      <EditOutlined />
                      {MESSAGES.BulkChange}
                    </>
                  }
                  data-qa-label="bulkChangeBtn"
                >
                  <Menu.Item
                    disabled={!totalItems}
                    onClick={onBulkAllItems}
                    data-qa-label="bulkChangeAllBtn"
                  >{`all (${totalItems} items)`}</Menu.Item>
                  <Menu.Item disabled={!totalItems} onClick={onBulkCurPage} data-qa-label="bulkChangeCurPageBtn">
                    {MESSAGES.CurrentPage}
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
            }
          >
            <Button data-qa-label="actionsBtn">
              <SettingOutlined />
              {MESSAGES.Actions} <DownOutlined />
            </Button>
          </Dropdown>
        ) : null}
      </div>
      <TagsList />
    </>
  );
};
