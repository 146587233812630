import _ from "lodash";
import { createSelector } from "reselect";
import { StudentTag, StudentTenant } from "../../services/nav-api/student-details/types";
import { RdxStudentDetails } from "../reducers/student-details";
import { RdxStudentsListState } from "../reducers/students-list";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";

export type StudentInfo = {
  id: number;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  firstActivity?: number;
  lastActivity?: number;
  tags?: Array<{
    tenantName: string;
    tagsFullPath: string[];
  }>;
  tenants?: Array<{
    name: string;
    isExcluded: boolean;
  }>;
  studyTimeSecs?: number;
  isExcluded?: boolean;
  licensePurchaseDate?: string;
  studentToken?: string;
  studyPlan: {
    sections: Array<{
      sectionAltId: string;
      examDate: string;
      isActualExamDate: boolean;
      isBehindSchedule: boolean;
    }>;
    hasStudyPlan: boolean;
    isBehindSchedule: boolean;
  };
};

const getFullTagsPath = (tag: StudentTag) => {
  const path = [];
  let curTag: StudentTag | undefined = tag;
  while (curTag) {
    path.push(curTag);
    curTag = curTag.parent;
  }

  return path
    .reverse()
    .map(t => t.name)
    .join(" > ");
};

export const getStudentInfo: (state: RdxStoreState) => { info?: StudentInfo; loading?: boolean } = createSelector(
  (state: RdxStoreState) => state.studentsList,
  (state: RdxStoreState) => state.studentDetails,
  ({ studentById: basicInfoById }: RdxStudentsListState, { curStudentId, details, status }: RdxStudentDetails) => {
    if (!curStudentId) {
      return { loading: true };
    }

    if (!details && basicInfoById[curStudentId]) {
      const { ...readOnlyProps } = basicInfoById[curStudentId];
      return {
        info: {
          ...readOnlyProps,
          tenants: readOnlyProps?.tenants?.map(({ name, isExcluded }) => ({ name, isExcluded }))
        }
      };
    }

    if (details && details.id === curStudentId) {
      const { tenants, ...otherDetails } = details;

      const info: StudentInfo = {
        ...otherDetails,
        tenants: _.map(tenants, ({ name, isExcluded }) => ({ name, isExcluded })),
        tags: details.tenants
          .map((tenant: StudentTenant) => ({
            tenantName: tenant.name,
            tagsFullPath: tenant.tags.map(getFullTagsPath)
          }))
          .filter(({ tagsFullPath }) => tagsFullPath.length)
      };
      return { info };
    }

    return { loading: status === RdxFetchStatus.LOADING };
  }
);
