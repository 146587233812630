import { Menu } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";

import { MESSAGES } from "../../../i18n";
import { getActiveUser } from "../../../redux/selectors/active-user";
import { Permission } from "../../../redux/selectors/permissions/model";
import { RdxStoreState } from "../../../redux/types/state";
import { Role } from "../../../services/nav-api/roles";
import { User } from "../../../services/nav-api/users";
import { useDeleteUserApi } from "./api";

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const isGlobalUser = (userRoleId: number, roles: Role[]): boolean =>
  _.chain(roles).filter({ id: userRoleId }).head().value().isGlobal;

const isSysAdmin = (userRoleId: number, roles: Role[]): boolean =>
  _.includes(_.chain(roles).filter({ id: userRoleId }).head().value().permissions, Permission.SYSTEM_ADMIN);

const isTenantAdmin = (userRoleId: number, roles: Role[]): boolean =>
  _.includes(_.chain(roles).filter({ id: userRoleId }).head().value().permissions, Permission.MANAGE_TENANT_STUDENTS);

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

const useConditions = (otherUser: User) => {
  const userRoles: Role[] = useSelector(({ myself: { userRoles } }: RdxStoreState) => userRoles ?? []);
  const myUser = useSelector(getActiveUser);

  return {
    isOptionEnabled:
      !_.isEqual(myUser?.userId, otherUser.id) &&
      (isSysAdmin(myUser!.roleId, userRoles) ||
        (isGlobalUser(myUser!.roleId, userRoles) && !isSysAdmin(otherUser.roleId, userRoles)) ||
        (isTenantAdmin(myUser!.roleId, userRoles) && _.isEqual(myUser?.tenant?.id, otherUser.tenantId)))
  };
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const DeleteUser = (props: { user: User }) => {
  const { deleteUser } = useDeleteUserApi(props.user);
  const conditions = useConditions(props.user);

  return (
    <Menu.Item danger key="delete-user" {...props} onClick={deleteUser} disabled={!conditions.isOptionEnabled}>
      {MESSAGES.Delete}
    </Menu.Item>
  );
};
