import { SaveOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { ErrorMessage, Field, FieldProps, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { MESSAGES } from "../../../i18n";
import { TagNode, getMyTenantTagsTree } from "../../../redux/selectors/my-tenant-tags-tree";
import { routes } from "../../../routes";
import { CheckableTreeView } from "../../shared/checkable-tree-view";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

export type CommentProps = {
  onSubmit: (props: StudentsAddFormProps) => void;
};

export interface StudentsAddFormProps {
  usernames: string;
  tagIds: number[];
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentsAddForm = (props: CommentProps) => {
  const navigate = useNavigate();
  const { tree = [], loading } = useSelector(getMyTenantTagsTree);

  return (
    <Formik<StudentsAddFormProps>
      onSubmit={(values, actions) => {
        props.onSubmit({ ...values });
        actions.resetForm();
      }}
      initialValues={{
        usernames: "",
        tagIds: []
      }}
    >
      {formProps => {
        return (
          <Form
            layout="vertical"
            onFinish={(e: React.FormEvent<any>) => {
              formProps.handleSubmit(e!);
            }}
          >
            <Form.Item required label={MESSAGES.StudentEmails}>
              <Field
                name="usernames"
                validateStatus={formProps.submitCount && formProps.errors.usernames ? "error" : "success"}
                validate={(v: string) => (!v.trim() ? MESSAGES.StudentEmailsMustBeDefined : undefined)}
              >
                {({ field }: FieldProps<string[], StudentsAddFormProps>) => (
                  <Input.TextArea {...field} rows={6} placeholder={MESSAGES.StudentsAddPlaceholder} />
                )}
              </Field>
              {formProps.submitCount && formProps.errors.usernames ? (
                <ErrorMessage component={() => <div className="text.red" />} name="usernames" />
              ) : null}
            </Form.Item>
            <Form.Item label={MESSAGES.Tags}>
              <Field
                name="tagIds"
                validateStatus={formProps.submitCount && formProps.errors.tagIds ? "error" : "success"}
              >
                {({ field }: FieldProps<number[], StudentsAddFormProps>) => (
                  <CheckableTreeView
                    loading={loading}
                    tree={tree}
                    renderNode={(n: TagNode) => n.name}
                    checkBehavior="assign"
                    checked={field.value}
                    onChange={({ checked }) => formProps.setFieldValue(field.name, checked)}
                  />
                )}
              </Field>
            </Form.Item>
            <div className="w-full flex gap-x-2">
              <Button onClick={() => navigate(routes.students.resolve())}>{MESSAGES.CancelLabel}</Button>
              <Button type="primary" htmlType="submit" disabled={!formProps.isValid} icon={<SaveOutlined />}>
                {MESSAGES.AddLabel}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
