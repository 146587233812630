import { Breadcrumb, Layout } from "antd";
import _ from "lodash";
import React, { ErrorInfo } from "react";
import { Link } from "react-router-dom";
import { errorTracker } from "../../services/error-tracking";
import { NavigationDrawer } from "../navigation-drawer";
import { NavigationDrawerOptionKey } from "../navigation-drawer/types";
import { RenderContentError } from "./render-content-error";

const { Content } = Layout;

type ComponentProps = React.HTMLProps<HTMLDivElement> & {
  activeNavDrawerKey: NavigationDrawerOptionKey;
  breadcrumb?: { name: string; href?: string }[];
};

export const ScrollableContentDivId = "scrollable-content";

export class BaseLayout extends React.Component<
  ComponentProps,
  { catchedError: { error: Error; info: ErrorInfo } | undefined }
> {
  state = {
    catchedError: undefined
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    errorTracker.notifyError(error);
    this.setState({ catchedError: { error, info } });
  }

  renderContent() {
    const { children, breadcrumb } = this.props;

    if (this.state.catchedError) {
      return <RenderContentError />;
    }

    return (
      <>
        {breadcrumb && breadcrumb.length ? (
          <Breadcrumb
            style={{ marginBottom: "16px" }}
            items={_.map(breadcrumb, ({ name, href }) => ({ title: <Link to={href!}>{name}</Link> }))}
          />
        ) : null}
        {children}
      </>
    );
  }

  render() {
    const { activeNavDrawerKey } = this.props;

    return (
      <Layout style={{ minHeight: "100vh" }}>
        <NavigationDrawer active={activeNavDrawerKey} />
        <Layout style={{ maxHeight: "100vh", overflow: "auto" }} id={ScrollableContentDivId}>
          <Content style={{ padding: "16px" }}>{this.renderContent()}</Content>
        </Layout>
      </Layout>
    );
  }
}
