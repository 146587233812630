import { Form, Select } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getActiveUserRoles } from "../../../redux/selectors/active-user-roles";
import { Role } from "../../../services/nav-api/roles";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

export const RoleIdFilterAllValue = -1;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const RoleFilter = (props: { onChange: (value: number) => void; value: number | undefined }) => {
  const roles: Role[] = useSelector(getActiveUserRoles);
  return (
    <Form.Item label="Role">
      <Select
        value={props.value}
        onChange={props.onChange}
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      >
        <Select.Option value={RoleIdFilterAllValue}>All</Select.Option>
        {_.map(roles, role => (
          <Select.Option key={role.id} value={role.id}>
            {role.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
