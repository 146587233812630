import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { GetTenantTagsResponse } from "../../../services/nav-api/tenants";
import { getMyTenant } from "../../selectors/my-tenant";
import { Action, ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "../../types/status";

export const TENANT_TAGS_FETCH_STARTED = "TENANT_TAGS_FETCH_STARTED";
export const TENANT_TAGS_UPDATE_TAG_STARTED = "TENANT_TAGS_UPDATE_TAG_STARTED";
export const TENANT_TAGS_DELETE_TAG_STARTED = "TENANT_TAGS_DELETE_TAG_STARTED";
export const TENANT_TAGS_CREATE_TAG_STARTED = "TENANT_TAGS_CREATE_TAG_STARTED";
export const TENANT_TAGS_MOVE_STUDENTS_STARTED = "TENANT_TAGS_MOVE_STUDENTS_STARTED";
export const TENANT_TAGS_CLONE_TAG_STARTED = "TENANT_TAGS_CLONE_TAG_STARTED";

export const TENANT_TAGS_CLONE_TAG_COMPLETED = "TENANT_TAGS_CLONE_TAG_COMPLETED";
export const TENANT_TAGS_FETCH_COMPLETED = "TENANT_TAGS_FETCH_COMPLETED";
export const TENANT_TAGS_UPDATE_TAG_COMPLETED = "TENANT_TAGS_UPDATE_TAG_COMPLETED";
export const TENANT_TAGS_DELETE_TAG_COMPLETED = "TENANT_TAGS_DELETE_TAG_COMPLETED";
export const TENANT_TAGS_CREATE_TAG_COMPLETED = "TENANT_TAGS_CREATE_TAG_COMPLETED";
export const TENANT_TAGS_MOVE_STUDENTS_COMPLETED = "TENANT_TAGS_MOVE_STUDENTS_COMPLETED";

export const BULK_CHANGE_STUDENT_TAGS_COMPLETED = "BULK_CHANGE_STUDENT_TAGS_COMPLETED";
export const BULK_CHANGE_STUDENT_TAGS_STARTED = "BULK_CHANGE_STUDENT_TAGS_STARTED";

export type BulkChangeCompletedPayload = {
  error?: HttpClientErrorResponse;
  tenantId?: number;
};

export const doStartBulkChangeStudentTags = (): Action => ({
  type: BULK_CHANGE_STUDENT_TAGS_STARTED
});

export const doCompleteBulkChangeStudentTags = (
  payload: BulkChangeCompletedPayload
): Action<BulkChangeCompletedPayload> => ({
  type: BULK_CHANGE_STUDENT_TAGS_COMPLETED,
  payload
});

export const doBulkChangeTags = (tagIds: number[], token: string, del: boolean): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    dispatch(doStartBulkChangeStudentTags());
    const tenant = getMyTenant(getState());
    try {
      await navApi.studentRoster.addDeleteTagsFromStudents(token, tagIds, del);
      dispatch(doCompleteBulkChangeStudentTags({ tenantId: tenant?.id }));
    } catch (ex) {
      const error: HttpClientErrorResponse = ex;
      dispatch(doCompleteBulkChangeStudentTags({ error }));
      throw error;
    }
  };
};

export type FetchTenantTagsCompleted = {
  tenantId: number;
  restrictedTagIds?: number[];
} & Partial<GetTenantTagsResponse> & {
    error?: HttpClientErrorResponse;
  };

export const doStartFetchTenantTags = (tenantId: number): Action => ({
  type: TENANT_TAGS_FETCH_STARTED,
  payload: tenantId
});

const doCompleteFetchTenantTags = (
  tenantId: number,
  response: GetTenantTagsResponse,
  restrictedTagIds?: number[]
): Action<FetchTenantTagsCompleted> => ({
  type: TENANT_TAGS_FETCH_COMPLETED,
  payload: { ...response, tenantId, restrictedTagIds }
});

const doFailFetchTenantTags = (tenantId: number, error: HttpClientErrorResponse): Action<FetchTenantTagsCompleted> => ({
  type: TENANT_TAGS_FETCH_COMPLETED,
  payload: { error, tenantId }
});

export const shouldFetchTenantTags = (state: RdxStoreState, tenantId: number) =>
  state.tenants.tenantTags!.statusByTenantId[tenantId] !== RdxFetchStatus.LOADING;

export const doFetchTenantTags = (tenantId: number, checkForTagsRestrictions = false): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    if (!shouldFetchTenantTags(getState(), tenantId)) {
      return;
    }

    dispatch(doStartFetchTenantTags(tenantId));

    try {
      const [response, restrictionsResponse] = await Promise.all([
        navApi.tenants.getTenantTags(tenantId),
        checkForTagsRestrictions ? navApi.myself.getMyTagIds() : Promise.resolve(null)
      ]);

      dispatch(
        doCompleteFetchTenantTags(
          tenantId,
          response,
          checkForTagsRestrictions ? restrictionsResponse?.tagIds : undefined
        )
      );
    } catch (ex) {
      const error: HttpClientErrorResponse = ex;
      dispatch(doFailFetchTenantTags(tenantId, error));
    }
  };
};

export const doFetchMyTenantTags = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    const tenant = getMyTenant(getState());
    if (!tenant) {
      return;
    }
    dispatch(doFetchTenantTags(tenant.id));
  };
};

export type UpdateTagCompletedPayload = {
  tagId: number;
  data?: { name: string; parentId?: number };
  error?: HttpClientErrorResponse;
};

export const doUpdateTag = (tenantId: number, tagId: number, name: string, parentId?: number): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, _, { navApi }): Promise<void> => {
    if (!name || !name.trim()) {
      return;
    }
    dispatch({
      type: TENANT_TAGS_UPDATE_TAG_STARTED,
      payload: tagId
    });
    try {
      await navApi.tenants.updateTenantTag(tenantId, tagId, name, parentId);
      const payload: UpdateTagCompletedPayload = {
        tagId,
        data: {
          name,
          parentId
        }
      };
      dispatch({
        type: TENANT_TAGS_UPDATE_TAG_COMPLETED,
        payload
      });
    } catch (error) {
      dispatch({
        type: TENANT_TAGS_UPDATE_TAG_COMPLETED,
        payload: { error, tagId }
      });
    }
  };
};

export type DeleteTagCompletedPayload = {
  tenantId: number;
  tagId: number;
  error?: HttpClientErrorResponse;
};

export const doDeleteTag = (tenantId: number, tagId: number): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, _, { navApi }): Promise<void> => {
    dispatch({
      type: TENANT_TAGS_DELETE_TAG_STARTED,
      payload: tagId
    });
    try {
      await navApi.tenants.deleteTenantTag(tenantId, tagId);
      const payload: DeleteTagCompletedPayload = {
        tagId,
        tenantId
      };
      dispatch({
        type: TENANT_TAGS_DELETE_TAG_COMPLETED,
        payload
      });
    } catch (error) {
      dispatch({
        type: TENANT_TAGS_DELETE_TAG_COMPLETED,
        payload: { error, tagId }
      });
    }
  };
};

export type CreateTagCompletedPayload = {
  id: number;
  name: string;
  parentId?: number;
  tenantId: number;
  error?: HttpClientErrorResponse;
};

export const doCreateTag = (tenantId: number, name: string, parentId?: number): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, _, { navApi }): Promise<void> => {
    dispatch({
      type: TENANT_TAGS_CREATE_TAG_STARTED
    });
    try {
      const response = await navApi.tenants.createTenantTag(tenantId, name, parentId);
      const payload: CreateTagCompletedPayload = {
        id: response.id,
        parentId,
        name,
        tenantId
      };
      dispatch({
        type: TENANT_TAGS_CREATE_TAG_COMPLETED,
        payload
      });
    } catch (error) {
      dispatch({
        type: TENANT_TAGS_CREATE_TAG_COMPLETED,
        payload: { error }
      });
    }
  };
};

export const doSubmitEditingTag = (tenantId: number, name: string): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    const state = getState();

    const editingTag = state.tenants.tagEditor;

    if (!editingTag) {
      return;
    }

    const { id: tagId, parentId } = editingTag;

    if (!tagId) {
      dispatch(doCreateTag(tenantId, name, parentId));
    } else {
      dispatch(doUpdateTag(tenantId, tagId, name, parentId));
    }
  };
};

export type MoveStudentsCompletedPayload = {
  srcTagId: number;
  destTagId: number;
  enrolledStudentCount?: number;
  error?: HttpClientErrorResponse;
};

export const doMoveStudents = (tenantId: number, srcTagId: number, destTagId: number): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    dispatch({
      type: TENANT_TAGS_MOVE_STUDENTS_STARTED,
      payload: srcTagId
    });

    const { byId } = getState().tenants.tags!;

    try {
      await navApi.tenants.moveStudentsToTag(tenantId, srcTagId, destTagId);

      const { enrolledStudentCount = 0 } = byId[srcTagId];
      const payload: MoveStudentsCompletedPayload = {
        destTagId,
        srcTagId,
        enrolledStudentCount
      };

      dispatch({
        type: TENANT_TAGS_MOVE_STUDENTS_COMPLETED,
        payload
      });
    } catch (error) {
      dispatch({
        type: TENANT_TAGS_MOVE_STUDENTS_COMPLETED,
        payload: { destTagId, srcTagId, error }
      });
    }
  };
};

export type CloneTagStartedPayload = {
  tenantId: number;
  tagId: number;
};

export type CloneTagCompletedPayload = {
  tenantId: number;
  tagId: number;
  error?: HttpClientErrorResponse;
};

export const doCloneTag = (tenantId: number, name: string, tagIdToClone: number, parentId?: number): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    dispatch<Action<CloneTagStartedPayload>>({
      type: TENANT_TAGS_CLONE_TAG_STARTED,
      payload: {
        tenantId,
        tagId: tagIdToClone
      }
    });

    let payload: CloneTagCompletedPayload;

    try {
      const { id } = await navApi.tenants.createTenantTag(tenantId, name, parentId);
      payload = {
        tenantId,
        tagId: tagIdToClone
      };
      await navApi.tenants.cloneTag(tenantId, tagIdToClone, id);
      // refresh data
      const response = await navApi.tenants.getTenantTags(tenantId);
      dispatch(doCompleteFetchTenantTags(tenantId, response));
    } catch (error) {
      payload = {
        error,
        tenantId,
        tagId: tagIdToClone
      };
    }

    dispatch({
      type: TENANT_TAGS_CLONE_TAG_COMPLETED,
      payload
    });
  };
};
