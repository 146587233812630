import differenceInMinutes from "date-fns/differenceInMinutes";
import cloneDeep from "lodash/cloneDeep";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { sentryErrorTracker } from "../../../services/error-tracking/sentry";
import { StudentRosterDetails } from "../../../services/nav-api/student-roster/types";
import { RdxStudentsListSortProps } from "../../reducers/students-list";
import { getShouldFetchStudents } from "../../selectors/should-fetch-students";
import { getRosterStudentFilter } from "../../selectors/student-roster-filter";
import { Action, ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "../../types/status";
import { AltID } from "./../../../constants/courses";
import { HttpClientErrorResponse } from "./../../../services/http-client/types";
import { getIsStudentsListLoading } from "./../../selectors/is-students-list-loading";

export const WORKING_STUDENTS_FILTER_UPDATED = "WORKING_STUDENTS_FILTER_UPDATED";
export const WORKING_STUDENTS_FILTER_CLEARED = "WORKING_STUDENTS_FILTER_CLEARED";
export const STUDENTS_LIST_SETTINGS_CHANGED = "STUDENTS_LIST_SETTINGS_CHANGED";
export const STUDENTS_LIST_FILTER_STARTED = "STUDENTS_LIST_FILTER_STARTED";
export const STUDENTS_LIST_FILTER_COMPLETED = "STUDENTS_LIST_FILTER_COMPLETED";
export const LOAD_STUDENTS_LIST_STARTED = "LOAD_STUDENTS_LIST_STARTED";
export const LOAD_STUDENTS_LIST_COMPLETED = "LOAD_STUDENTS_LIST_COMPLETED";
export const LOAD_STUDENTS_LIST_FAILED = "LOAD_STUDENTS_LIST_FAILED";
export const STUDENTS_LIST_PAGE_CHANGE_STARTED = "STUDENTS_LIST_PAGE_CHANGE_STARTED";
export const STUDENTS_LIST_PAGE_CHANGE_COMPLETED = "STUDENTS_LIST_PAGE_CHANGE_COMPLETED";
export const BULK_CHANGE_STUDENT_STATUS_COMPLETED = "BULK_CHANGE_STUDENT_STATUS_COMPLETED";
export const BULK_CHANGE_STUDENT_STATUS_STARTED = "BULK_CHANGE_STUDENT_STATUS_STARTED";
export const ADD_NEW_STUDENTS_COMPLETED = "ADD_NEW_STUDENTS_COMPLETED";
export const UPDATE_STUDENT_FOLLOWED = "UPDATE_STUDENT_FOLLOWED";

export type UpdateStudentFollowedPayload = {
  studentId: number;
  followed: boolean;
};

export type WorkingFilterStatus = "all" | "included" | "excluded";
export type StudyTimeValue = "any" | "none" | "up_to_4_hours" | "4_to_48_hours" | "48_to_240_hours" | "over_240_hours";
export type LicenseValue =
  | "any"
  | "active"
  | "expired"
  | "in_less_than_3_months"
  | "in_more_than_3_months"
  | "length_under_6_months";

export type StudyPlanValue = "any" | "no" | "yes" | "yes_on_track" | "yes_behind_schedule";
export type CurrentActivityValue =
  | "any"
  | AltID.F
  | AltID.A
  | AltID.R
  | AltID.B
  | AltID.BAR
  | AltID.ISC
  | AltID.TCP
  | AltID.P1
  | AltID.P2
  | "no_activity";

export type ExamTrackerValue =
  | AltID.F
  | AltID.A
  | AltID.R
  | AltID.B
  | AltID.BAR
  | AltID.ISC
  | AltID.TCP
  | AltID.P1
  | AltID.P2
  | "any"
  | "no_data"
  | "passed_all_parts"
  | "passed_any_part"
  | "about_to_lose_or_lost_credits";

export type WorkingFilterOtherFilters = {
  passedAllExams: boolean;
  hiddenStudents: "no" | "include" | "only";
  followedStudents: boolean;
};

export type WorkingFilter = {
  tenantId?: number; // undefined if tenant user
  license: LicenseValue;
  searchFields: string[];
  searchTerm: string;
  status: WorkingFilterStatus;
  studyTime: StudyTimeValue;
  studyPlan: StudyPlanValue;
  currentActivity: CurrentActivityValue;
  examTracker: ExamTrackerValue;
  tags: number[];
  withoutTags: boolean;
  metrics: WorkingFilterOtherFilters;
  purchasedDate: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  };
};

export type UpdateWorkingFilterPayload = Partial<WorkingFilter>;

export type CompleteListStudentsPayload = {
  students: StudentRosterDetails[];
  total: number;
  timestamp: number;
};

export type CompleteStudentsListFilterPayload = {
  error?: HttpClientErrorResponse;
  token?: string;
  filter?: WorkingFilter;
};

export type DoChangeListSettingsPayload = {
  paging: { page: number; pageSize: number };
  sort?: RdxStudentsListSortProps;
};

export const doUpdateWorkingFilter = (filter: UpdateWorkingFilterPayload): Action<UpdateWorkingFilterPayload> => ({
  type: WORKING_STUDENTS_FILTER_UPDATED,
  payload: filter
});

export const doStartListStudents = (): Action => ({
  type: LOAD_STUDENTS_LIST_STARTED
});

export const doCompleteListStudents = (
  students: StudentRosterDetails[],
  total: number
): Action<CompleteListStudentsPayload> => ({
  type: LOAD_STUDENTS_LIST_COMPLETED,
  payload: { students, total, timestamp: Date.now() }
});

export const doFailListStudents = (error: HttpClientErrorResponse): Action<{ error: HttpClientErrorResponse }> => ({
  type: LOAD_STUDENTS_LIST_FAILED,
  payload: { error }
});

export const doListStudents = (): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    if (!getShouldFetchStudents(getState())) {
      return;
    }

    dispatch(doStartListStudents());

    try {
      const { paging, sort, activeFilter } = getState().studentsList;
      const offset = (paging.page - 1) * paging.itemsPerPage;
      const limit = paging.itemsPerPage;

      const { total, students } = await navApi.studentRoster.getStudentRosterListingOfStudents(
        activeFilter!.token!,
        limit,
        offset,
        sort
      );
      dispatch(doCompleteListStudents(students, total));
    } catch (ex) {
      const error: HttpClientErrorResponse = ex;
      dispatch(doFailListStudents(error));
    }
  };
};

export const doUpdateStudentFollowed = (studentId: number, followed: boolean): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    const payload: UpdateStudentFollowedPayload = {
      followed,
      studentId
    };

    dispatch({
      type: UPDATE_STUDENT_FOLLOWED,
      payload
    });

    try {
      if (followed) {
        await navApi.studentDetails.putStudentFollowed(studentId);
      } else {
        await navApi.studentDetails.deleteStudentFollowed(studentId);
      }
    } catch (ex) {
      sentryErrorTracker.notifyError(ex);
    }
  };
};

export const doApplyFilter = (): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    // or working filter equals to activeFilter and last Fetch was 5min ago
    if (getIsStudentsListLoading(getState())) {
      return;
    }
    dispatch({
      type: STUDENTS_LIST_FILTER_STARTED
    });
    const filter = cloneDeep(getState().studentsList.workingFilter);
    try {
      const apiFilter = getRosterStudentFilter(getState());
      const { token } = await navApi.studentRoster.createStudentRoster(apiFilter);
      const payload: CompleteStudentsListFilterPayload = {
        token,
        filter
      };
      dispatch({
        type: STUDENTS_LIST_FILTER_COMPLETED,
        payload
      });
      dispatch(doListStudents());
    } catch (ex) {
      const payload: CompleteStudentsListFilterPayload = {
        error: ex
      };
      dispatch({
        type: STUDENTS_LIST_FILTER_COMPLETED,
        payload
      });
    }
  };
};

export const doClearFilter = (): Action => ({
  type: WORKING_STUDENTS_FILTER_CLEARED
});

export const doResetFilter = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(doClearFilter());
    dispatch(doApplyFilter());
  };
};

export const doSetFilter = (filter: UpdateWorkingFilterPayload): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(doClearFilter());
    dispatch(doUpdateWorkingFilter(filter));
    dispatch(doApplyFilter());
  };
};

export const doRefreshStudentsListIfNeeded = (force: boolean = false): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    const state = getState();
    const shouldRefresh =
      !state.studentsList?.lastFetch ||
      state.studentsList?.status === RdxFetchStatus.STALE ||
      differenceInMinutes(Date.now(), state.studentsList.lastFetch) > 5;

    if (shouldRefresh || force) {
      dispatch(doApplyFilter());
    }
  };
};

export const doChangeListSettings = (page: number, pageSize: number, sort?: RdxStudentsListSortProps): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    if (!getShouldFetchStudents(getState())) {
      return;
    }
    const payload: DoChangeListSettingsPayload = {
      paging: { page, pageSize },
      sort
    };

    dispatch({
      type: STUDENTS_LIST_SETTINGS_CHANGED,
      payload
    });

    dispatch(doListStudents());
  };
};

export type BulkChangeCompletedPayload = {
  error?: HttpClientErrorResponse;
};

export const doStartBulkChangeStudentStatus = (): Action => ({
  type: BULK_CHANGE_STUDENT_STATUS_STARTED
});

export const doCompleteBulkChangeStudentStatus = (
  error?: HttpClientErrorResponse
): Action<BulkChangeCompletedPayload> => ({
  type: BULK_CHANGE_STUDENT_STATUS_COMPLETED,
  payload: { error }
});

export const doBulkChangeStudentStatus = (excluded: boolean, token: string): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    dispatch(doStartBulkChangeStudentStatus());

    try {
      await navApi.studentRoster.assignStatusToRosterStudents(token, excluded);
      dispatch(doCompleteBulkChangeStudentStatus());
    } catch (ex) {
      const error: HttpClientErrorResponse = ex;
      dispatch(doCompleteBulkChangeStudentStatus(error));
      throw error;
    }
  };
};
