import { Alert, Button, Input } from "@beckersdp/becker-ds";
import { Form, Typography } from "antd";
import { Field, FieldProps, Formik } from "formik";
import React, { useState } from "react";
import { MESSAGES, formatMsg } from "../../i18n";
import { HttpClientErrorResponse } from "../../services/http-client/types";
import { navApi } from "../../services/nav-api";
import { isEmailValid } from "../../utils/validators-utils";
import { ReturnToLoginButton } from "./return-login-btn";

interface FormProps {
  email: string;
}

interface Props {
  onEmailSent: () => void;
}

export const ForgotPasswordForm: React.FC<Props> = props => {
  const [errorMsg, setErrorMsg] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState("");

  const onSubmit = async (values: FormProps) => {
    setLoading(true);
    setErrorMsg("");
    try {
      await navApi.unauth.initiate({ username: values.email });
      setSucceeded(true);
      setSubmittedEmail(values.email);
      props.onEmailSent();
    } catch (ex) {
      const error: HttpClientErrorResponse = ex;
      setErrorMsg((error && error.message) || MESSAGES.GenericRequestFailedDefaultMessage);
    }
    setLoading(false);
  };

  const renderAlert = () => {
    if (errorMsg) {
      return (
        <Alert type="danger" className="max-h-52 overflow-hidden relative top-0" onClose={() => setErrorMsg("")}>
          {errorMsg}
        </Alert>
      );
    }
    if (succeeded) {
      return (
        <Alert type="success" className="max-h-52 overflow-hidden relative top-0">
          <Typography.Paragraph className="break-words">
            {formatMsg(MESSAGES.ForgotPasswordSuccess, submittedEmail)}
          </Typography.Paragraph>
        </Alert>
      );
    }
    return null;
  };

  return (
    <div>
      <Formik<FormProps> onSubmit={onSubmit} initialValues={{ email: "" }}>
        {formProps => (
          <>
            <Form onFinish={e => formProps.handleSubmit(e!)}>
              {renderAlert()}
              {!succeeded && (
                <Field
                  name="email"
                  validate={(v: string) => (!isEmailValid(v) ? "Email format is invalid" : undefined)}
                >
                  {({ field }: FieldProps<string, FormProps>) => (
                    <Input
                      {...field}
                      placeholder="Email Address"
                      autoComplete="off"
                      onClear={() => formProps.setFieldValue(field.name, "")}
                    />
                  )}
                </Field>
              )}
              <div className="mt-2">
                {!succeeded && (
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={Boolean(formProps.errors.email) || !formProps.values.email.length}
                  >
                    {MESSAGES.ForgotPasswordBtnCaption}
                  </Button>
                )}
                <ReturnToLoginButton />
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};
