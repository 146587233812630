export type NavAccount = {
  id: number;
  isDisabled: boolean;
  tenantId: number;
  tenantName: string;
  roleIds: number[];
  expirationDate?: number;
};

export enum Status {
  Enabled = "Enabled",
  Disabled = "Disabled"
}
