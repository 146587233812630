import * as HttpClient from "../http-client";
import { getUrlWithEncodedParams } from "../http-client/utils";
import { createHeaders } from "./headers";
import { Status } from "./types";

export enum UserType {
  Local = "LOCAL",
  Bim = "BIM"
}

export type User = {
  id: number;
  idp: UserType;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  roleId: number;
  tenantId: number | null;
  tagIds: number[];
  created: number;
  lastLogin: number;
  pendingInviteToken?: string;
  status: Status;
  userId?: number;
  unsubscribeAlerts: boolean;
  allowAddStudents?: boolean;
};

export type GetUsersResponse = {
  users: User[];
  total: number;
};

export type GetUsersReportResponse = {
  taskToken: string;
};

export type GetUsersParams = {
  status?: string;
  filter?: string;
  tenantId?: string | number;
  roleId?: number;
  limit?: number;
  offset?: number;
  lastLoginDateMin?: string;
  lastLoginDateMax?: string;
  creationDateMin?: string;
  creationDateMax?: string;
};

export type PutUsersParams = {
  idp: UserType;
  email: string;
  firstName: string;
  lastName: string;
  roleId: number;
  tagIds: number[];
};

const getUsers = async (params: GetUsersParams): Promise<GetUsersResponse> => {
  return HttpClient.get("/rest/admin/users", createHeaders(), params);
};

const getUser = async (userId: number): Promise<User> => {
  return HttpClient.get(`/rest/admin/users/${userId}`, createHeaders());
};

const putUser = async (userId: number, params: PutUsersParams): Promise<GetUsersResponse> => {
  return HttpClient.put(`/rest/admin/users/${userId}`, createHeaders(), params);
};

const enableUser = async (userId: number): Promise<GetUsersResponse> => {
  return HttpClient.post(`/rest/admin/users/${userId}/enabled`, createHeaders());
};

const disableUser = async (userId: number): Promise<GetUsersResponse> => {
  return HttpClient.del(`/rest/admin/users/${userId}/enabled`, createHeaders());
};

const deleteUser = async (userId: number) => {
  return HttpClient.del(`/rest/admin/users/${userId}`, createHeaders());
};

const generateReport = async (params: GetUsersParams): Promise<GetUsersReportResponse> => {
  return HttpClient.post(getUrlWithEncodedParams("/rest/admin/users/report", params), createHeaders());
};

export {
  deleteUser,
  disableUser,
  enableUser,
  generateReport,
  getUser,
  getUsers,
  putUser
  //
};
