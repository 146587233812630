import { Col, Row } from "antd";
import React from "react";
import { useParams } from "react-router";
import { BaseLayout } from "../../components/layout/base-layout";
import { PageTitle } from "../../components/layout/page-title";
import { NavigationDrawerOptionKey } from "../../components/navigation-drawer/types";
import { MESSAGES } from "../../i18n";
import { AlertsList } from "./components/AlertsList";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const AlertsReader = () => {
  const { id } = useParams<{ id: string }>();
  const alertId = id ? parseInt(id) : undefined;
  return React.useMemo(
    () => (
      <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.NOTIFICATIONS}>
        <PageTitle>{MESSAGES.NavNotifications}</PageTitle>
        <Row>
          <Col xs={24} lg={18}>
            <AlertsList alertId={alertId} />
          </Col>
        </Row>
      </BaseLayout>
    ),
    [alertId]
  );
};

export default AlertsReader;
