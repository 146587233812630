import { Card, Typography } from "antd";
import styled from "styled-components";

const ButtonsHeight = "3.125rem";

export const ChartsSpacer = styled.div`
  height: 1.25rem;
`;

export const Charts = styled.div`
  display: flex;
  justify-content: space-between;
  > * {
    flex-basis: 48%;
  }
`;

export const ChartWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
`;

export const ChartButtons = styled.div`
  height: ${ButtonsHeight};
  display: flex;
  align-items: center;

  > * {
    margin-right: 0.625rem;
  }
`;

export const ButtonsPlaceholder = styled.div`
  height: ${ButtonsHeight};
`;

export const ChartTitle = styled(Typography.Text)`
  margin-bottom: 0.625rem;
  display: block;
`;

export const TimeInfo = styled.div`
  margin-bottom: 1.25rem;
  strong {
    margin-right: 0.625rem;
    font-size: 1.375rem;
  }
`;
