import dayjs from "dayjs";
import _ from "lodash";
import { AltID } from "../../../../constants/courses";
import { MESSAGES, formatMsg } from "../../../../i18n";
import { SimulatedExamsReportOption } from "../../../../services/nav-api/student-roster/types";
import { TenantProducts } from "./../../../../services/nav-api/tenants/tenants";
import {
  AttemptByReportType,
  ExamNumber,
  ExamNumberByReportType,
  HierarchyLevel,
  HierarchyLevelIncludedByReportType,
  MultipleAttemptsByReportType,
  SectionsIncludedByReportType as MultipleSectionsIncludedByReportType,
  ReportType,
  SectionUnitIncludedByReportType,
  SectionIncludedByReportType as SingleSectionIncludedByReportType,
  YearMonthByReportType
} from "./types";

export const labelByReportType: { [key in ReportType]: string } = {
  [ReportType.EXAM_TRACKER_REPORT]: MESSAGES.ExamTrackerReportLabel,
  [ReportType.HOMEWORK_MCQ_FIRST_ATTEMPT_REPORT]: MESSAGES.HomeworkMCQFirstAttemptLabel,
  [ReportType.HOMEWORK_PERFORMANCE_REPORT]: MESSAGES.HomeworkPerformanceReportLabel,
  [ReportType.MINI_EXAMS_ANSWERS_REPORT]: MESSAGES.MiniExamsAnswersReportLabel,
  [ReportType.MINI_EXAMS_PERFORMANCE_REPORT]: MESSAGES.MiniExamPerformanceReportLabel,
  [ReportType.MINI_EXAMS_SCORE_REPORT]: MESSAGES.MiniExamScoreReportLabel,
  [ReportType.PERFORMANCE_DASHBOARD_FR_REPORT]: MESSAGES.PerfDashFrReportLabel,
  [ReportType.PERFORMANCE_DASHBOARD_REPORT]: MESSAGES.PerfDashReportLabel,
  [ReportType.SIMULATED_EXAMS_ANSWERS_REPORT]: MESSAGES.SimulatedExamsAnswersReportLabel,
  [ReportType.SIMULATED_EXAMS_PERFORMANCE_REPORT]: MESSAGES.SimulatedExamPerformanceReportLabel,
  [ReportType.SIMULATED_EXAMS_SCORE_REPORT]: MESSAGES.SimulatedExamsScoreReportLabel,
  [ReportType.SIMULATED_EXAMS_SCORE_FR_REPORT]: MESSAGES.SimulatedExamsScoreFrReportLabel,
  [ReportType.STUDENTS_LIST_REPORT]: MESSAGES.StudentsListLabel,
  [ReportType.STUDY_TIME_REPORT]: MESSAGES.StudyTimeReportLabel,
  [ReportType.TIMESHEET_REPORT]: MESSAGES.TimesheetReportLabel
};

export const descriptionByReportType: { [key in ReportType]: string } = {
  [ReportType.EXAM_TRACKER_REPORT]: MESSAGES.ExamTrackerReportDescription,
  [ReportType.HOMEWORK_MCQ_FIRST_ATTEMPT_REPORT]: MESSAGES.HomeworkMCQFirstAttemptDescription,
  [ReportType.HOMEWORK_PERFORMANCE_REPORT]: MESSAGES.HomeworkPerformanceReportDescription,
  [ReportType.MINI_EXAMS_ANSWERS_REPORT]: MESSAGES.MiniExamsAnswersReportDescription,
  [ReportType.MINI_EXAMS_PERFORMANCE_REPORT]: MESSAGES.MiniExamPerformanceReportDescription,
  [ReportType.MINI_EXAMS_SCORE_REPORT]: MESSAGES.MiniExamScoreReportDescription,
  [ReportType.PERFORMANCE_DASHBOARD_FR_REPORT]: MESSAGES.PerfDashFrReportDescription,
  [ReportType.PERFORMANCE_DASHBOARD_REPORT]: MESSAGES.PerfDashReportDescription,
  [ReportType.SIMULATED_EXAMS_ANSWERS_REPORT]: MESSAGES.SimulatedExamsAnswersReportDescription,
  [ReportType.SIMULATED_EXAMS_PERFORMANCE_REPORT]: MESSAGES.SimulatedExamPerformanceReportDescription,
  [ReportType.SIMULATED_EXAMS_SCORE_REPORT]: MESSAGES.SimulatedExamsScoreReportDescription,
  [ReportType.SIMULATED_EXAMS_SCORE_FR_REPORT]: MESSAGES.SimulatedExamsScoreFrReportDescription,
  [ReportType.STUDENTS_LIST_REPORT]: MESSAGES.StudentsListDescription,
  [ReportType.STUDY_TIME_REPORT]: MESSAGES.StudyTimeReportDescription,
  [ReportType.TIMESHEET_REPORT]: MESSAGES.TimesheetReportDescription
};

export const initialAttemptByReportType: AttemptByReportType = {
  [ReportType.MINI_EXAMS_ANSWERS_REPORT]: SimulatedExamsReportOption.FirstAttempt,
  [ReportType.MINI_EXAMS_PERFORMANCE_REPORT]: SimulatedExamsReportOption.FirstAttempt,
  [ReportType.SIMULATED_EXAMS_ANSWERS_REPORT]: SimulatedExamsReportOption.FirstAttempt,
  [ReportType.SIMULATED_EXAMS_PERFORMANCE_REPORT]: SimulatedExamsReportOption.FirstAttempt
};

export const initialMultipleAttemptsByReportType: MultipleAttemptsByReportType = {
  [ReportType.SIMULATED_EXAMS_SCORE_REPORT]: [],
  [ReportType.SIMULATED_EXAMS_SCORE_FR_REPORT]: [],
  [ReportType.MINI_EXAMS_SCORE_REPORT]: []
};

export const initialSectionUnitIncludedByReportType: SectionUnitIncludedByReportType = {
  [ReportType.HOMEWORK_MCQ_FIRST_ATTEMPT_REPORT]: undefined
};

export const initialMultipleSectionsIncludedByReportType = (
  sectionsAvailable: AltID[]
): MultipleSectionsIncludedByReportType => ({
  [ReportType.HOMEWORK_PERFORMANCE_REPORT]: [],
  [ReportType.PERFORMANCE_DASHBOARD_FR_REPORT]: [],
  [ReportType.PERFORMANCE_DASHBOARD_REPORT]: [],
  [ReportType.SIMULATED_EXAMS_SCORE_REPORT]: [],
  [ReportType.SIMULATED_EXAMS_SCORE_FR_REPORT]: [],
  [ReportType.MINI_EXAMS_SCORE_REPORT]: [],
  [ReportType.STUDY_TIME_REPORT]: sectionsAvailable
});

export const initialSingleSectionIncludedByReportType: SingleSectionIncludedByReportType = {
  [ReportType.SIMULATED_EXAMS_ANSWERS_REPORT]: undefined,
  [ReportType.MINI_EXAMS_ANSWERS_REPORT]: undefined
};

export const initialHierarchyLevelIncludedByReportType: HierarchyLevelIncludedByReportType = {
  [ReportType.PERFORMANCE_DASHBOARD_FR_REPORT]: HierarchyLevel.MODULE,
  [ReportType.PERFORMANCE_DASHBOARD_REPORT]: HierarchyLevel.MODULE,
  [ReportType.STUDY_TIME_REPORT]: HierarchyLevel.UNIT,
  [ReportType.HOMEWORK_PERFORMANCE_REPORT]: HierarchyLevel.MODULE
};

export const initialTimesheetDatesByReportType: YearMonthByReportType = {
  [ReportType.TIMESHEET_REPORT]: dayjs()
};

export const initialExamNumberByReportType: ExamNumberByReportType = {
  [ReportType.SIMULATED_EXAMS_ANSWERS_REPORT]: ExamNumber._1,
  [ReportType.MINI_EXAMS_ANSWERS_REPORT]: ExamNumber._1
};

export const labelByAttemptType: { [key in SimulatedExamsReportOption]: string } = {
  [SimulatedExamsReportOption.BestAttempt]: MESSAGES.BestAttempt,
  [SimulatedExamsReportOption.FirstAttempt]: MESSAGES.FirstAttempt,
  [SimulatedExamsReportOption.LastAttempt]: MESSAGES.LastAttempt
};

export const limitsByReportType: { [key in ReportType]: number } = {
  [ReportType.EXAM_TRACKER_REPORT]: 15000,
  [ReportType.HOMEWORK_MCQ_FIRST_ATTEMPT_REPORT]: 500,
  [ReportType.HOMEWORK_PERFORMANCE_REPORT]: 2000,

  [ReportType.MINI_EXAMS_ANSWERS_REPORT]: 2000,
  [ReportType.MINI_EXAMS_PERFORMANCE_REPORT]: 15000,
  [ReportType.MINI_EXAMS_SCORE_REPORT]: 2000,

  [ReportType.SIMULATED_EXAMS_ANSWERS_REPORT]: 2000,
  [ReportType.SIMULATED_EXAMS_SCORE_REPORT]: 2000,
  [ReportType.SIMULATED_EXAMS_SCORE_FR_REPORT]: 2000,
  [ReportType.SIMULATED_EXAMS_PERFORMANCE_REPORT]: 15000,
  [ReportType.STUDENTS_LIST_REPORT]: 15000,
  [ReportType.STUDY_TIME_REPORT]: 2000,
  [ReportType.TIMESHEET_REPORT]: 2000,
  [ReportType.PERFORMANCE_DASHBOARD_FR_REPORT]: 2000,
  [ReportType.PERFORMANCE_DASHBOARD_REPORT]: 2000
};

export const messageByReportType: { [key in ReportType]: string } = {
  [ReportType.EXAM_TRACKER_REPORT]: formatMsg(
    MESSAGES.ReportTypeMessage_EXAM_TRACKER_REPORT,
    limitsByReportType[ReportType.EXAM_TRACKER_REPORT]
  ),
  [ReportType.HOMEWORK_MCQ_FIRST_ATTEMPT_REPORT]: formatMsg(
    MESSAGES.ReportTypeMessage_HOMEWORK_MCQ_FIRST_ATTEMPT_REPORT,
    limitsByReportType[ReportType.HOMEWORK_MCQ_FIRST_ATTEMPT_REPORT]
  ),
  [ReportType.HOMEWORK_PERFORMANCE_REPORT]: formatMsg(
    MESSAGES.ReportTypeMessage_HOMEWORK_PERFORMANCE_REPORT,
    limitsByReportType[ReportType.HOMEWORK_PERFORMANCE_REPORT]
  ),

  [ReportType.MINI_EXAMS_PERFORMANCE_REPORT]: formatMsg(
    MESSAGES.ReportTypeMessage_MINI_EXAMS_PERFORMANCE_REPORT,
    limitsByReportType[ReportType.MINI_EXAMS_PERFORMANCE_REPORT]
  ),
  [ReportType.MINI_EXAMS_SCORE_REPORT]: formatMsg(
    MESSAGES.ReportTypeMessage_MINI_EXAMS_SCORE_REPORT,
    limitsByReportType[ReportType.MINI_EXAMS_SCORE_REPORT]
  ),
  [ReportType.MINI_EXAMS_ANSWERS_REPORT]: formatMsg(
    MESSAGES.ReportTypeMessage_MINI_EXAMS_ANSWERS_REPORT,
    limitsByReportType[ReportType.MINI_EXAMS_ANSWERS_REPORT]
  ),

  [ReportType.PERFORMANCE_DASHBOARD_FR_REPORT]: formatMsg(
    MESSAGES.ReportTypeMessage_PERFORMANCE_DASHBOARD_FR_REPORT,
    limitsByReportType[ReportType.PERFORMANCE_DASHBOARD_FR_REPORT]
  ),
  [ReportType.PERFORMANCE_DASHBOARD_REPORT]: formatMsg(
    MESSAGES.ReportTypeMessage_PERFORMANCE_DASHBOARD_REPORT,
    limitsByReportType[ReportType.PERFORMANCE_DASHBOARD_REPORT]
  ),
  [ReportType.SIMULATED_EXAMS_ANSWERS_REPORT]: formatMsg(
    MESSAGES.ReportTypeMessage_SIMULATED_EXAMS_ANSWERS_REPORT,
    limitsByReportType[ReportType.SIMULATED_EXAMS_ANSWERS_REPORT]
  ),
  [ReportType.SIMULATED_EXAMS_PERFORMANCE_REPORT]: formatMsg(
    MESSAGES.ReportTypeMessage_SIMULATED_EXAMS_PERFORMANCE_REPORT,
    limitsByReportType[ReportType.SIMULATED_EXAMS_PERFORMANCE_REPORT]
  ),
  [ReportType.SIMULATED_EXAMS_SCORE_REPORT]: formatMsg(
    MESSAGES.ReportTypeMessage_SIMULATED_EXAMS_SCORE_REPORT,
    limitsByReportType[ReportType.SIMULATED_EXAMS_SCORE_REPORT]
  ),
  [ReportType.SIMULATED_EXAMS_SCORE_FR_REPORT]: formatMsg(
    MESSAGES.ReportTypeMessage_SIMULATED_EXAMS_SCORE_FR_REPORT,
    limitsByReportType[ReportType.SIMULATED_EXAMS_SCORE_FR_REPORT]
  ),
  [ReportType.STUDENTS_LIST_REPORT]: formatMsg(
    MESSAGES.ReportTypeMessage_STUDENTS_LIST_REPORT,
    limitsByReportType[ReportType.STUDENTS_LIST_REPORT]
  ),
  [ReportType.STUDY_TIME_REPORT]: formatMsg(
    MESSAGES.ReportTypeMessage_STUDY_TIME_REPORT,
    limitsByReportType[ReportType.STUDY_TIME_REPORT]
  ),
  [ReportType.TIMESHEET_REPORT]: formatMsg(
    MESSAGES.ReportTypeMessage_TIMESHEET_REPORT,
    limitsByReportType[ReportType.TIMESHEET_REPORT]
  )
};

export const isReportAvailableForProduct = (p: TenantProducts) => (type: ReportType) =>
  !(
    _.isEqual(p, "cma") &&
    _.includes(
      [
        //
        ReportType.MINI_EXAMS_SCORE_REPORT,
        ReportType.MINI_EXAMS_PERFORMANCE_REPORT,
        ReportType.PERFORMANCE_DASHBOARD_FR_REPORT,
        ReportType.SIMULATED_EXAMS_SCORE_FR_REPORT,
        ReportType.MINI_EXAMS_ANSWERS_REPORT,
        ReportType.MINI_EXAMS_SCORE_REPORT
      ],
      type
    )
  );

export const reportsWithTopHzDivider: ReportType[] = [
  ReportType.HOMEWORK_PERFORMANCE_REPORT,
  ReportType.PERFORMANCE_DASHBOARD_FR_REPORT,
  ReportType.PERFORMANCE_DASHBOARD_REPORT,
  ReportType.SIMULATED_EXAMS_ANSWERS_REPORT,
  ReportType.SIMULATED_EXAMS_SCORE_REPORT,
  ReportType.SIMULATED_EXAMS_SCORE_FR_REPORT,
  ReportType.STUDY_TIME_REPORT,
  ReportType.MINI_EXAMS_ANSWERS_REPORT,
  ReportType.MINI_EXAMS_SCORE_REPORT
];

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

export const defaultReportType: ReportType = ReportType.STUDENTS_LIST_REPORT;
