import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Row, Select, Switch, Typography } from "antd";
import React, { useEffect, useMemo, useReducer } from "react";
import { useNavigate } from "react-router";
import { MESSAGES } from "../../../i18n";
import { routes } from "../../../routes";
import reducer, {
  ADD,
  DELETE,
  RESET,
  SELECT_CHANGED,
  TOGGLE_ENABLED,
  TOGGLE_REQUIRED,
  getDropdownOptions,
  getFormDisabled,
  getHasNoTags,
  getIsLoading,
  getTagsTableItems,
  initialState,
  loadInitialState,
  submitChanges
} from "./reducer";
import { TagsTable } from "./tags-table";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CPATagForm = (props: { tenantId: number }) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const loading = getIsLoading(state);
  const dropdownOptions = useMemo(() => getDropdownOptions(state), [state]);
  const tableTagItems = useMemo(() => getTagsTableItems(state), [state]);
  const formDisabled = useMemo(() => getFormDisabled(state), [state]);

  useEffect(() => {
    loadInitialState(dispatch, props.tenantId);
  }, []);

  const handleSave = () => {
    submitChanges(dispatch, props.tenantId, state);
  };

  return (
    <Card title={MESSAGES.TenantCPATagFormTitle} style={{ marginTop: "20px" }}>
      {getHasNoTags(state) && (
        <Row className="mb-2">
          <Col xs={24}>
            <Alert showIcon type="warning" message="This tenant has no associated tags." />
          </Col>
        </Row>
      )}

      {/* Switch */}
      <Row className="mb-2">
        <Col xs={24} className="flex gap-x-2">
          <Typography.Text>Enabled</Typography.Text>
          <Switch
            checked={state.enabled}
            onChange={() => {
              dispatch({ type: TOGGLE_ENABLED });
            }}
            loading={loading}
          />
        </Col>
      </Row>

      {/* Add Tags */}
      <Row className="mb-2">
        <Col xs={24} className="flex gap-x-2">
          <Select
            style={{ minWidth: "200px" }}
            onChange={(tagId: number) => dispatch({ type: SELECT_CHANGED, payload: { tagId } })}
            value={state.selectedId}
            placeholder={MESSAGES.SelectTagPlaceholder}
            loading={loading}
          >
            {dropdownOptions.map(({ id, name }) => (
              <Select.Option value={id} key={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={() => dispatch({ type: ADD })}
            icon={<PlusOutlined />}
            disabled={!state.selectedId || loading}
          >
            Add
          </Button>
        </Col>
      </Row>

      {/* Tags Table */}
      <Row className="mb-2">
        <Col xs={24}>
          <TagsTable
            tags={tableTagItems}
            loading={loading}
            onRequiredChange={(tagId: number) => dispatch({ type: TOGGLE_REQUIRED, payload: { tagId } })}
            onRemove={(tagId: number) => dispatch({ type: DELETE, payload: { tagId } })}
            disabled={loading}
          />
        </Col>
      </Row>

      {/* Actions */}
      <Row className="mb-2">
        <Col xs={24} className="flex gap-x-2">
          <Button
            disabled={loading}
            onClick={() => {
              dispatch({ type: RESET });
              navigate(routes.tenants.resolve());
            }}
          >
            Cancel
          </Button>
          <Button icon={<SaveOutlined />} onClick={handleSave} type="primary" loading={loading} disabled={formDisabled}>
            Save
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
