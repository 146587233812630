import { Table } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { doUpdateStudentFollowed } from "../../../redux/actions/students-list";
import { getIsGlobalUser } from "../../../redux/selectors/permissions/is-global-user";
import { getStudentsTableDetails } from "../../../redux/selectors/students-table-data-source";
import { RdxStoreState } from "../../../redux/types/state";
import { BulkChangeModal } from "./bulk-change-modal";
import { createColumns } from "./columns";
import { StudentTableActionsContext } from "./table-actions-context";
import { SelectionBar } from "./table-actions/table-selection-bar";
import { useStudentTableHandlers } from "./use-table-handlers";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentsTable = () => {
  const { dataSource, loading, paging, sort } = useSelector(getStudentsTableDetails);
  const activeFilter = useSelector((state: RdxStoreState) => state.studentsList.activeFilter);
  const { onChange, onRow } = useStudentTableHandlers();
  const isGlobalUser = useSelector(getIsGlobalUser);
  const dispatch = useDispatch();
  const handleFollowUpdate = (studentId: number, followed: boolean) => {
    dispatch(doUpdateStudentFollowed(studentId, followed));
  };

  return (
    <StudentTableActionsContext.Consumer>
      {props => {
        const selectable = props.selection.active;
        const showTenants = Boolean(isGlobalUser);
        const showFollowed = !isGlobalUser;
        const showVisibility = Boolean(
          activeFilter &&
            activeFilter.filter && //
            _.isEqual(activeFilter.filter.metrics.hiddenStudents, "include")
        );

        const columns = createColumns(
          sort,
          showVisibility,
          showTenants,
          showFollowed ? handleFollowUpdate : undefined
          //
        );

        return (
          <>
            {props.selection.active ? <SelectionBar /> : null}
            <Table
              rowClassName="cursor-pointer"
              key={showVisibility.toString()}
              columns={columns}
              dataSource={dataSource}
              loading={loading}
              onChange={onChange}
              rowKey="id"
              pagination={{
                ...paging,
                disabled: props.selection.active,
                pageSizeOptions: ["50", "100", "250"],
                showQuickJumper: true,
                showLessItems: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
              }}
              onRow={!props.selection.active ? onRow : undefined}
              rowSelection={
                selectable
                  ? {
                      onChange: keys => {
                        props.onSelectionChange(keys as number[]);
                      },
                      selectedRowKeys: props.selection.ids
                    }
                  : undefined
              }
            />
            {props.bulkChange.modalOpen ? <BulkChangeModal /> : null}
          </>
        );
      }}
    </StudentTableActionsContext.Consumer>
  );
};
