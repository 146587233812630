function roundWithDecimalPlaces(value: number, places = 1) {
  return Math.round(value * Math.pow(10, places)) / Math.pow(10, places);
}

export function roundToMinutes(valueSecs: number) {
  const nearest60Multiple = Math.round(valueSecs / 60) * 60;
  return nearest60Multiple / 60;
}

export function minutesToHM(minutes: number) {
  if (minutes === 0) {
    return "0";
  }
  const value = minutes / 60;
  if (value < 1) {
    return `${Math.ceil(value * 60)}m`;
  }

  const decimalPart = value - Math.floor(value);
  if (decimalPart === 0) {
    return `${value}h`;
  }
  return `${Math.floor(value)}h${Math.ceil(decimalPart * 60)}m`;
}

export function formatter(val: string, k?: number) {
  const value = parseFloat(val);
  return minutesToHM(value);
}
