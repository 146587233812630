import { createSelector } from "reselect";
import { RdxAlertsWriterState } from "../../reducers/alerts-writer";
import { RdxStoreState } from "../../types/state";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getAlerts: (state: RdxStoreState) => RdxAlertsWriterState = createSelector(
  ({ alertsWriter: alerts }: RdxStoreState) => alerts,
  (state: RdxAlertsWriterState): RdxAlertsWriterState => {
    return state;
  }
);
