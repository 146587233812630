import cloneDeep from "lodash/cloneDeep";
import includes from "lodash/includes";
import uniq from "lodash/uniq";

import { createNodeParentById } from "../create-mappers";
import { getAllAncestorIds } from "../get-all-ancestors";
import { getAllDescendantIds } from "../get-all-descendants";
import { Node } from "../types";

export type Action = { id: number; check: boolean };

function calcCheckedIdsBasedOnAction(tree: Node[], curCheckedIds: number[], { id, check }: Action) {
  tree = cloneDeep(tree);
  const nodeParentById = createNodeParentById(tree);

  // means node doesnt exist in tree
  if (!nodeParentById.hasOwnProperty(id)) {
    return curCheckedIds;
  }

  if (check) {
    const nodesToCheck = [...curCheckedIds, id];
    nodesToCheck.push(...getAllDescendantIds(tree, id));
    return uniq(nodesToCheck);
  }

  const nodesToUncheck = [id];
  nodesToUncheck.push(...getAllAncestorIds(tree, id));
  nodesToUncheck.push(...getAllDescendantIds(tree, id));
  return curCheckedIds.filter(id => !includes(nodesToUncheck, id));
}

export { calcCheckedIdsBasedOnAction };
