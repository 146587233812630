import { createSelector } from "reselect";
import { doRefreshStudentsListIfNeeded } from "../actions/students-list";
import { RdxStudentsListState } from "../reducers/students-list";
import { getHasUserData } from "../selectors/has-user-data";
import { ThunkAction } from "../types/action";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";

export const reFetchStudentsList: (state: RdxStoreState) => ThunkAction | undefined = createSelector(
  (state: RdxStoreState) => state.studentsList,
  getHasUserData,
  (students: RdxStudentsListState, hasValidUser: boolean): ThunkAction | undefined => {
    if (hasValidUser && !students.activeFilter?.loading && students.status === RdxFetchStatus.STALE) {
      return doRefreshStudentsListIfNeeded();
    }
  }
);
