import { Checkbox, Col, DatePicker, Form, Input, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../../../i18n";
import { doUpdateAlertInOperation } from "../../../../redux/actions/alerts-writer/operation";
import {
  getHasAlertsOperation,
  getOperationAlert,
  getOperationStatusLoading
} from "../../../../redux/selectors/alerts-writer/operation";
import { AlertError } from "./AlertError";
import { AlertTextEditor } from "./AlertTextEditor";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

import dayjs from "dayjs";
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
const dateFormat = "MM/DD/YYYY";
const apiDateFormat = "YYYY-MM-DD";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isLoading = useSelector(getOperationStatusLoading);
  const alert = useSelector(getOperationAlert);
  const hasAlertsOperation = useSelector(getHasAlertsOperation);

  return hasAlertsOperation ? (
    <Form form={form} layout="vertical">
      <AlertError />
      <Form.Item label={MESSAGES.AlertsFormItemTitle} required>
        <Input
          maxLength={255}
          disabled={isLoading}
          placeholder={MESSAGES.AlertsFormItemTitlePlaceholder}
          value={alert?.title ?? ""}
          onChange={e => {
            dispatch(
              doUpdateAlertInOperation({
                title: e.currentTarget.value ?? ""
              })
            );
          }}
        />
      </Form.Item>
      <Form.Item label={MESSAGES.AlertsFormItemMessage} required>
        <AlertTextEditor
          disabled={isLoading}
          value={alert?.body ?? ""}
          onChange={body => {
            dispatch(doUpdateAlertInOperation({ body }));
          }}
        />
      </Form.Item>
      <Form.Item label={MESSAGES.AlertsFormItemDatesRange} required>
        <Row gutter={8}>
          <Col xs={10}>
            <RangePicker
              disabled={isLoading}
              style={{ width: "100%" }}
              format={dateFormat}
              disabledDate={target => {
                const now = dayjs();
                const cur = dayjs(target?.toDate());
                return target && now.diff(cur) > 0;
              }}
              placeholder={[
                MESSAGES.AlertsFormItemPublishDatePlaceholder,
                MESSAGES.AlertsFormItemExpirationDatePlaceholder //
              ]}
              value={
                [
                  alert?.publishDate ? dayjs(alert.publishDate) : null,
                  alert?.expirationDate ? dayjs(alert?.expirationDate) : null
                ] as any
              }
              onChange={dates => {
                const d1 = dates?.[0]?.toDate();
                const d2 = dates?.[1]?.toDate();

                const publishDate = d1 ? dayjs(d1).format(apiDateFormat) : "";
                const expirationDate = d2 ? dayjs(d2).format(apiDateFormat) : "";

                dispatch(
                  doUpdateAlertInOperation({
                    publishDate,
                    expirationDate
                  })
                );
              }}
            />
          </Col>
          <Col xs={14} style={{ alignSelf: "center", display: "flex", justifyContent: "end" }}>
            <Checkbox
              checked={Boolean(alert?.sendEmail)}
              onChange={(e: CheckboxChangeEvent) => {
                dispatch(
                  doUpdateAlertInOperation({
                    sendEmail: e.target.checked
                  })
                );
              }}
            >
              {MESSAGES.AlertsFormItemSendEmail}
            </Checkbox>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  ) : null;
};
