import _ from "lodash";
import { createSelector } from "reselect";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";
import { getHasUserData } from "./has-user-data";
import { getUserFetchStatus } from "./user-fetch-status";

// -------------------------------------------------------------------------------------------------
// - Reactors
// -------------------------------------------------------------------------------------------------

export enum RxAppRoutesStatus {
  LOADING = "LOADING",
  UNAUTHENTICATED = "UNAUTHENTICATED",
  APP_READY = "APP_READY"
}

// -------------------------------------------------------------------------------------------------
// - Reactors
// -------------------------------------------------------------------------------------------------

export const getAppRoutesStatus: (state: RdxStoreState) => RxAppRoutesStatus = createSelector(
  getUserFetchStatus,
  getHasUserData,
  (userFetchStatus, hasUserInfo) => {
    if (!userFetchStatus || _.isEqual(RdxFetchStatus.LOADING, userFetchStatus)) {
      return RxAppRoutesStatus.LOADING;
    }

    return hasUserInfo //
      ? RxAppRoutesStatus.APP_READY
      : RxAppRoutesStatus.UNAUTHENTICATED;
  }
);
