import { GetMySelfResponse } from "../../../services/nav-api/myself";
import { Action } from "../../types/action";
import { UPDATE_ACTIVE_USER } from "./actions";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doUpdateActiveUser = (settings: Partial<GetMySelfResponse>): Action => ({
  type: UPDATE_ACTIVE_USER,
  payload: settings
});
