import { Line, LineConfig } from "@ant-design/charts";
import { Empty } from "antd";
import _ from "lodash";
import isNil from "lodash/isNil";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AltID, sectionColorByAltId } from "../../../../constants/courses";
import { MESSAGES } from "../../../../i18n";
import { DailyStudyTimeResponse } from "../../../../services/external-api";
import { ChartTitle, ChartWrapper, TimeInfo } from "../styles";
import { ChartRange } from "../types";
import { formatter, minutesToHM, roundToMinutes } from "../utils";

type ChartData = Array<{ xField: number | string; studyTime: number }>;

type Props = {
  rawData: DailyStudyTimeResponse | undefined;
  range: ChartRange;
  loading: boolean;
  section?: AltID;
};
export const LineChart: React.FC<Props> = ({ rawData, range, loading, section }) => {
  const [data, setData] = useState<ChartData>([]);
  const [average, setAverage] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!rawData || !section) {
      return;
    }

    if (range === "week") {
      const weekData = (rawData.days || []).map(({ day, sections }) => {
        const date = moment(day);
        const time =
          _.chain(sections) //
            .filter({ sectionAltId: section })
            .head()
            .value()?.secs ?? 0;

        return {
          xField: date.format("DD-MM"),
          studyTime: roundToMinutes(time)
        };
      }) as ChartData;
      setData(weekData);
      return;
    }

    const monthData = (rawData.days || []).map(({ day, sections }) => {
      const date = moment(day);
      const time =
        _.chain(sections) //
          .filter({ sectionAltId: section })
          .head()
          .value()?.secs ?? 0;

      return {
        xField: date.format("DD-MM"),
        studyTime: roundToMinutes(time)
      };
    }) as ChartData;
    setData(monthData);
  }, [loading, rawData, section]);

  useEffect(() => {
    if (data) {
      const total = data.map(d => d.studyTime).reduce((a, b) => a + b, 0);
      setAverage(Math.ceil(total / data.length));
    }
  }, [data]);

  const config: LineConfig = {
    data,
    loading,
    height: 200,
    xField: "xField",
    yField: "studyTime",
    yAxis: {
      tickCount: Math.min(Math.max(...data.map(d => d.studyTime)) + 1, 5)
    },
    smooth: true,
    point: {
      size: 5,
      shape: "diamond"
    },

    color: sectionColorByAltId[section!],

    tooltip: {
      formatter: ({ studyTime }) => ({
        name: MESSAGES.StudyTime,
        value: formatter(studyTime)
      })
    },
    meta: {
      studyTime: {
        formatter
      }
    }
  };

  const showNoData = !loading && (isNaN(average!) || isNil(average) || average === 0);

  return (
    <ChartWrapper>
      <ChartTitle type="secondary">{MESSAGES.DailyStudyTime.toUpperCase()}</ChartTitle>
      {showNoData ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          <TimeInfo>
            <strong>{minutesToHM(average!)}</strong>
            {MESSAGES.AverageDailyTime}
          </TimeInfo>
          <Line {...config} />
        </>
      )}
    </ChartWrapper>
  );
};
