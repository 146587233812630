import { Affix, Button, Card, Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../../i18n";
import { SearchFilter } from "../../../pages/Students/shared/search";
import {
  doApplyFilter,
  doClearFilter,
  doResetListSettings,
  doUpdateWorkingFilter
} from "../../../redux/actions/partners";
import { getIsApplyPartnersListFilterDisabled } from "../../../redux/selectors/is-apply-partners-list-filter-disabled";
import { getIsClearPartnersListFilterDisabled } from "../../../redux/selectors/is-clear-partners-list-filter-disabled";
import { RdxStoreState } from "../../../redux/types/state";
import { ScrollableContentDivId } from "../../layout/base-layout";
import { StatusFilter } from "../../shared/status";
import { TypeFilter } from "./type";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const PartnersSidebar = () => {
  const dispatch = useDispatch();
  const clearFilterDisabled = useSelector(getIsClearPartnersListFilterDisabled);
  const applyFilterDisabled = useSelector(getIsApplyPartnersListFilterDisabled);
  const workingFilter = useSelector((state: RdxStoreState) => state.partners.workingFilter);

  return (
    <Affix target={() => document.querySelector<HTMLElement>(`#${ScrollableContentDivId}`)}>
      <Card>
        <Form layout="vertical">
          <SearchFilter
            value={workingFilter.name as string}
            onChange={name => dispatch(doUpdateWorkingFilter({ name }))}
            placeholder={MESSAGES.FilterSearchNamePlaceholder}
            minChars={3}
          />
          <TypeFilter value={workingFilter.type} onChange={type => dispatch(doUpdateWorkingFilter({ type }))} />
          <StatusFilter value={workingFilter.status} onChange={status => dispatch(doUpdateWorkingFilter({ status }))} />
          <div className="flex justify-center w-full gap-x-2">
            <Button disabled={clearFilterDisabled} htmlType="button" onClick={() => dispatch(doClearFilter())}>
              {MESSAGES.Clear}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              disabled={applyFilterDisabled}
              onClick={(e: React.FormEvent) => {
                e.preventDefault();
                if (applyFilterDisabled) {
                  return;
                }
                dispatch(doResetListSettings());
                dispatch(doApplyFilter());
              }}
            >
              {MESSAGES.Apply}
            </Button>
          </div>
        </Form>
      </Card>
    </Affix>
  );
};
