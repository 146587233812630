import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getStudyPlanPerformanceStats, isFetchCompleted } from "../../../../../../../redux/selectors/dashboard/tenant";
import { StatsData, StatsScope, StatsSlice } from "../model";

// -------------------------------------------------------------------------------------------------
// - Hook: useUsage
// -------------------------------------------------------------------------------------------------

export const usePerformanceData = (scope: StatsScope): StatsData[] | undefined => {
  const isReady = useSelector(isFetchCompleted);
  const stats = useSelector(getStudyPlanPerformanceStats);
  return React.useMemo(
    () =>
      _.isEqual(scope, StatsScope.SPS_PERFORMANCE) && isReady && !!stats
        ? [
            { slice: StatsSlice.S1, value: stats.onTrack },
            { slice: StatsSlice.S2, value: stats.behindSchedule }
          ]
        : undefined,
    [stats]
  );
};
