import { Action } from "../../../types/action";
import { WorkingFilter } from "../../students-list";

// -------------------------------------------------------------------------------------------------
// - Actions
// -------------------------------------------------------------------------------------------------

export const DASHBOARD_ACTIVATE_FILTER = "DASHBOARD_ACTIVATE_FILTER";
export const DASHBOARD_UPDATE_FILTER = "DASHBOARD_UPDATE_FILTER";
export const DASHBOARD_CLEAR_FILTER = "DASHBOARD_CLEAR_FILTER";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doActivateFilter = (): Action => ({
  type: DASHBOARD_ACTIVATE_FILTER
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doClearFilter = (): Action<void> => ({
  type: DASHBOARD_CLEAR_FILTER
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doUpdateFilter = (payload: Partial<WorkingFilter>): Action<Partial<WorkingFilter>> => ({
  type: DASHBOARD_UPDATE_FILTER,
  payload
});
