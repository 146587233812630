import { createSelector } from "reselect";

import { RdxStudentsListState } from "../reducers/students-list";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";

export const getIsStudentsListLoading: (state: RdxStoreState) => boolean = createSelector(
  (state: RdxStoreState) => state.studentsList,
  (students: RdxStudentsListState) => {
    return (
      students.status === RdxFetchStatus.LOADING || Boolean(students.activeFilter && students.activeFilter.loading)
    );
  }
);
