import _ from "lodash";
import { navApi } from "../../../../../../services/nav-api";
import { getTaskBlobWhenReady } from "../../../../../../services/task";
import { getCurDateForReportFilename } from "../../../../../../utils/date-utils";
import { ReportParams, ReportResponse } from "../../types";

// -------------------------------------------------------------------------------------------------
// - Generator
// -------------------------------------------------------------------------------------------------

export const genHomeworkMcqFirstAttemptReport = async (params: ReportParams): Promise<ReportResponse> => {
  const selectedUnitAltId = params.sectionUnitIncludedByReportType[params.type];
  const filenameSuffix = _.chain(params.plainUnitsList)
    .filter(eu => _.isEqual(eu.unitAltId, selectedUnitAltId))
    .head()
    .value().filenameSuffix;

  return {
    filename: `homework-mcq-first-attempt-${filenameSuffix}-${getCurDateForReportFilename()}.xlsx`,
    blob: await getTaskBlobWhenReady((await navApi.studentRoster.homeworkMcqFirstAttemptReport(params)).taskToken)
  };
};
