import cloneDeep from "lodash/cloneDeep";

export function createTagsTree<T extends { parentId?: number; children: T[] }>(
  byId: { [id: number]: T },
  ids: number[]
): T[] {
  const tree: T[] = [];
  byId = cloneDeep(byId);
  ids.forEach(tagId => {
    const tag = byId[tagId];
    if (!tag.parentId) {
      tree.push(tag);
    } else {
      byId[tag.parentId]?.children?.push(tag);
    }
  });

  return tree;
}
