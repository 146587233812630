import { useDispatch, useSelector } from "react-redux";
import { MESSAGES, formatMsg } from "../../../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../../../redux/actions/students-list";
import { initialState } from "../../../../../redux/reducers/students-list";
import { RdxStoreState } from "../../../../../redux/types/state";
import { studyTimeLabelByValue } from "../../../students-filter/metadata";

// -------------------------------------------------------------------------------------------------
// - Model
// -------------------------------------------------------------------------------------------------

type FilterTag = {
  text: string;
  onClear: () => void;
};

// -------------------------------------------------------------------------------------------------
// - Hooks: useStudyTime
// -------------------------------------------------------------------------------------------------

export const useStudyTime = (): FilterTag[] => {
  const dispatch = useDispatch();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);
  return activeFilter?.filter?.studyTime && activeFilter?.filter?.studyTime !== initialState.workingFilter.studyTime
    ? [
        {
          onClear: () => {
            dispatch(doUpdateWorkingFilter({ studyTime: initialState.workingFilter.studyTime }));
            dispatch(doApplyFilter());
          },
          text: formatMsg(MESSAGES.FilterTagStudyTime, studyTimeLabelByValue[activeFilter.filter.studyTime])
        }
      ]
    : [];
};
