import { CloseOutlined } from "@ant-design/icons";
import { Tag, Typography } from "@beckersdp/becker-ds";
import { Button } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../i18n";
import { doClearFilter } from "../../redux/actions/users-list";
import { initialState } from "../../redux/reducers/users-list";
import { RdxStoreState } from "../../redux/types/state";
import { RdxFetchStatus } from "../../redux/types/status";
import { useFilterTags } from "./useFilterTags";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const FilterTags = () => {
  const dispatch = useDispatch();
  const filterTags = useFilterTags();
  const isLoading = useSelector(
    ({ usersList: { activeFilter, status } }: RdxStoreState) =>
      _.isEqual(status, RdxFetchStatus.LOADING) || activeFilter?.loading
  );
  const isResetDisabled = useSelector(({ usersList }: RdxStoreState) =>
    _.isEqual(usersList.workingFilter, initialState.workingFilter)
  );

  return filterTags?.length > 0 ? (
    <div className="flex items-center flex-wrap my-1">
      <Typography.Text level="2" style={{ marginRight: "1rem" }}>
        {MESSAGES.FilteredBy}
      </Typography.Text>
      {_.map(filterTags, ({ text, onClear, disabled }, idx) => (
        <Tag key={`${text}-${idx}`} disabled={isLoading || disabled} onClose={onClear}>
          {text}
        </Tag>
      ))}
      <Button
        icon={<CloseOutlined />}
        type="text"
        disabled={isLoading || isResetDisabled}
        onClick={() => {
          dispatch(doClearFilter());
        }}
      >
        {MESSAGES.ResetFilters}
      </Button>
    </div>
  ) : null;
};
