import includes from "lodash/includes";

import { createNodeById } from "../create-mappers";
import { Node } from "../types";

function isAnyDescendentChecked(node: Node, checkedIds: number[]) {
  if (!node || !node.children.length) {
    return false;
  }

  const queue = node.children;

  while (queue.length) {
    const curNode = queue.shift()!;
    if (includes(checkedIds, curNode.id)) {
      return true;
    }
    queue.push(...curNode.children);
  }
  return false;
}

function excludeRedundantNodes(tree: Node[], checkedIds: number[]) {
  const nodeById = createNodeById(tree);
  return checkedIds.filter(n => !isAnyDescendentChecked(nodeById[n], checkedIds));
}

export { excludeRedundantNodes };
