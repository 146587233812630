import { Pie } from "@ant-design/charts";
import { Skeleton } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getGlobalDashboardStats, isFetchCompleted } from "../../../redux/selectors/dashboard/global";

// -------------------------------------------------------------------------------------------------
// - Model
// -------------------------------------------------------------------------------------------------

interface Data {
  partner: string;
  label: string;
  value: number;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantsByTypeChart = (): JSX.Element => {
  const stats = useSelector(getGlobalDashboardStats);
  const isDataReady = useSelector(isFetchCompleted);
  return isDataReady ? (
    <StyledContainer>
      <Pie
        height={350}
        data={_.map(
          _.map(_.keys(stats?.tenantCountByType), (type: string) => ({
            type,
            value: stats?.tenantCountByType[type]
          }))
        )}
        appendPadding={10}
        angleField="value"
        colorField="type"
        radius={0.8}
        label={{
          type: "inner",
          offset: "-30%",
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: "center"
          }
        }}
        interactions={[
          {
            type: "element-active"
          }
        ]}
      />
    </StyledContainer>
  ) : (
    <Skeleton />
  );
};

// -------------------------------------------------------------------------------------------------
// - Styled Components
// -------------------------------------------------------------------------------------------------

const StyledContainer = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
`;
