import { ForgotPasswordTemplate } from "@beckersdp/becker-ds";
import React from "react";
import styled from "styled-components";
import { unauthLayoutProps } from "./unauth-layout-props";

type Props = {
  step: "forgot" | "emailSent" | "reset";
  children: JSX.Element;
  title?: string;
};

export const ForgotPasswordLayout: React.FC<Props> = ({ step, title, children }) => {
  return (
    <StyledForgotPasswordTemplate mainPanelContent={children} {...unauthLayoutProps} step={step} customTitle={title} />
  );
};

const StyledForgotPasswordTemplate = styled(ForgotPasswordTemplate)`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;
