import { Form, Select } from "antd";
import React from "react";
import { WorkingFilterTypeTypes } from "../../../redux/actions/partners";
const { Option } = Select;

export const TypeFilterAllValue = "All";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TypeFilter = (props: { onChange: (value?: any) => void; value: string | undefined }) => (
  <Form.Item label="Type">
    <Select
      className="tenants-type-dp"
      value={props.value}
      onChange={props.onChange}
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
    >
      {WorkingFilterTypeTypes.map(status => (
        <Option key={status} value={status} className={`option-type-${status}`}>
          {status.replace(/([A-Z])/g, " $1").trim()}
        </Option>
      ))}
    </Select>
  </Form.Item>
);
