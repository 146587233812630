import { Alert } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { getStudentFilterTagsTree } from "../../../../../redux/selectors/student-filter-tags-tree";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const NoTagsAlert = () => {
  const studentsTree = useSelector(getStudentFilterTagsTree);
  return !studentsTree.loading && (studentsTree.tree ?? []).length == 0 ? (
    <Alert message="No tags" type="warning" />
  ) : null;
};
