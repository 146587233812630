import _ from "lodash";
import { reportsWithTopHzDivider } from "../settings";
import { ReportType } from "../types";

// -------------------------------------------------------------------------------------------------
// - Hook
// -------------------------------------------------------------------------------------------------

export const useHorizontalSeparatorByReportType = (reportType: ReportType) => ({
  isEnabled: _.includes(reportsWithTopHzDivider, reportType)
});
