import { Card, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { altIdsByProduct, sectionAltIdsOrder } from "../../../../../constants/courses";
import { MESSAGES } from "../../../../../i18n";
import { getActiveProduct } from "../../../../../redux/selectors/active-product";
import { isFetchCompleted } from "../../../../../redux/selectors/dashboard/tenant";
import { StudentsActivityChartBySection } from "./StudentsActivityChartBySection";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentsActivityBySection = (): JSX.Element => {
  const dataReady = useSelector(isFetchCompleted);
  const activeProduct = useSelector(getActiveProduct);
  return (
    <Card loading={!dataReady}>
      <Typography.Text className="uppercase" type="secondary" style={{ minHeight: "30px" }}>
        {MESSAGES.StudentActivityBySection}
      </Typography.Text>
      <div className="flex flex-wrap pt-2">
        {_.chain(altIdsByProduct[activeProduct])
          .sortBy(altId => _.indexOf(sectionAltIdsOrder, altId))
          .map(altId => <StudentsActivityChartBySection key={altId} altId={altId} />)
          .value()}
        <StudentsActivityChartBySection />
      </div>
    </Card>
  );
};
