import { Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LicenseValue, doUpdateWorkingFilter } from "../../../../redux/actions/students-list";
import { RdxStoreState } from "../../../../redux/types/state";
import { licenseOptions } from "../metadata";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const LicenseFilter = () => {
  const dispatch = useDispatch();
  const workingFilter = useSelector(
    ({
      studentsList: {
        workingFilter //
      }
    }: RdxStoreState) => workingFilter
  );

  return (
    <Form.Item label="License">
      <Select<string>
        value={workingFilter.license}
        data-qa-label="select-license"
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        onChange={(license: LicenseValue) => {
          dispatch(doUpdateWorkingFilter({ license }));
        }}
      >
        {licenseOptions.map(({ label, value }) => (
          <Select.Option value={value} key={value} data-qa-label={`license-sfilter-${value}`}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
