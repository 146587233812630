import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { ThunkAction } from "../../types/action";
import { doCompleteLogout } from "./logoutCompletion";
import { doStartLogout } from "./logoutStart";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doLogout = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, _, { navApi }): Promise<void> => {
    dispatch(doStartLogout());
    try {
      await navApi.session.logout();
      dispatch(doCompleteLogout());
      window.location.reload();
    } catch (ex) {
      const error: HttpClientErrorResponse = ex;
      dispatch(doCompleteLogout({ error: error.message }));
    }
  };
};
