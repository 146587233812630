import { Button, Card, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { MESSAGES } from "../../../../../i18n";
import { doSetFilter } from "../../../../../redux/actions/students-list";
import {
  getCurrentWorkingFilter,
  getStudentsWithouStudyTime,
  isFetchCompleted
} from "../../../../../redux/selectors/dashboard/tenant";
import { routes } from "../../../../../routes";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentsWithoutTime = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const value = useSelector(getStudentsWithouStudyTime);
  const filter = useSelector(getCurrentWorkingFilter);
  const ready = useSelector(isFetchCompleted);
  return (
    <Card
      loading={!ready}
      className="h-full"
      bodyStyle={{
        height: "100%",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <div className="text-center truncate">
        <Typography.Text className="uppercase" type="secondary">
          {MESSAGES.StudentsWithoutStudyTime}
        </Typography.Text>
      </div>
      <div className="flex items-center h-full">
        <Button
          type="link"
          className={_.join(
            [
              "text-3xl",
              "text-slate-600",
              "font-medium",
              "w-full" //
            ],
            " "
          )}
          data-qa-label="students-without-study-time-count"
          onClick={() => {
            dispatch(doSetFilter({ ...filter, studyTime: "none" }));
            navigate(routes.students.resolve());
          }}
        >
          {value}
        </Button>
      </div>
    </Card>
  );
};
