import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import React, { Suspense } from "react";
import { useDispatch } from "react-redux";
import { BaseLayout } from "../../components/layout/base-layout";
import { PageTitle } from "../../components/layout/page-title";
import { NavigationDrawerOptionKey } from "../../components/navigation-drawer/types";
import { MESSAGES } from "../../i18n";
import { doStartEditingOperation } from "../../redux/actions/alerts-writer/operation";
import { AlertsEditor } from "./components/AlertsEditor/index";
import { Filters } from "./components/Filters";
import { NotificationsTable } from "./components/Table";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const AlertsWriter = (): JSX.Element => {
  const dispatch = useDispatch();
  return (
    <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_NOTIFICATIONS}>
      <PageTitle>{MESSAGES.NavNotifications}</PageTitle>
      <div className="float-right -mt-12 gap-2">
        <Button
          icon={<PlusOutlined />}
          data-qa-label="createAlertBtn"
          onClick={() => {
            dispatch(doStartEditingOperation());
          }}
        >
          Add Notification
        </Button>
      </div>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={18} xxl={19}>
          <Card bodyStyle={{ overflowX: "auto" }}>
            <Suspense fallback={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
              <NotificationsTable />
            </Suspense>
          </Card>
        </Col>
        <Col xs={24} lg={6} xxl={5}>
          <Filters />
        </Col>
      </Row>
      <AlertsEditor />
    </BaseLayout>
  );
};

export default AlertsWriter;
