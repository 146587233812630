import { useDispatch, useSelector } from "react-redux";
import { MESSAGES, formatMsg } from "../../../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../../../redux/actions/students-list";
import { defaultWorkingFilter } from "../../../../../redux/reducers/students-list";
import { RdxStoreState } from "../../../../../redux/types/state";
import { licenseLabelByValue } from "../../../students-filter/metadata";
import { FilterTag } from "../model";

// -------------------------------------------------------------------------------------------------
// - Hooks: useLicense
// -------------------------------------------------------------------------------------------------

export const useLicense = (): FilterTag[] => {
  const dispatch = useDispatch();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);
  return activeFilter?.filter?.license && activeFilter.filter.license !== defaultWorkingFilter.license
    ? [
        {
          onClear: () => {
            dispatch(doUpdateWorkingFilter({ license: defaultWorkingFilter.license }));
            dispatch(doApplyFilter());
          },
          text: formatMsg(MESSAGES.FilterTagLicense, licenseLabelByValue[activeFilter.filter.license])
        }
      ]
    : [];
};
