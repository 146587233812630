import { PaginationProps } from "antd/lib/pagination";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { doChangeListSettings, doFetchTenants } from "../../../redux/actions/tenants/tenants";
import { RdxTenantsState } from "../../../redux/reducers/tenants";
import { RdxStoreState } from "../../../redux/types/state";
import { RdxFetchStatus } from "../../../redux/types/status";
import { routes } from "../../../routes";
import { Tenant } from "../../../services/nav-api/tenants";
import { TenantsPureTable } from "./table-pure";

const useUsersTableHandlers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return {
    onChange: ({ current: page, pageSize }: PaginationProps) => {
      dispatch(doChangeListSettings(page!, pageSize!));
    },
    onRow: (record: Tenant) => ({
      onClick: () => {
        navigate(routes.tenant.resolve({ id: record.id.toString() }));
      }
    })
  };
};

export const TenantsTable: React.FC = () => {
  const dispatch = useDispatch();
  const tenantsData: RdxTenantsState = useSelector((state: RdxStoreState) => state.tenants);

  const { onChange, onRow } = useUsersTableHandlers();

  useEffect(() => {
    dispatch(doFetchTenants());
  }, []);

  return (
    <TenantsPureTable
      dataSource={_.map(tenantsData.tenants ? tenantsData.tenants.tenants : [], (r, i) => ({ ...r, key: i }))}
      loading={tenantsData.tenants ? tenantsData.tenants.status === RdxFetchStatus.LOADING : false}
      onChange={onChange}
      onRow={onRow}
      paging={{
        current: tenantsData.tenants ? tenantsData.tenants.paging.page : 0,
        pageSize: tenantsData.tenants ? tenantsData.tenants.paging.itemsPerPage : 0,
        total: tenantsData.tenants ? tenantsData.tenants.paging.totalItems : 0,
        showSizeChanger: true
      }}
    />
  );
};
