import { createSelector } from "reselect";

import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";
import { RdxTenantsSubState } from "../reducers/tenants/tenants";

export const getIsTenantsListLoading: (state: RdxStoreState) => boolean = createSelector(
  (state: RdxStoreState) => state.tenants.tenants,
  (tenants: RdxTenantsSubState) =>
    tenants.status === RdxFetchStatus.LOADING || Boolean(tenants.activeFilter && tenants.activeFilter.loading)
);
