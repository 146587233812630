import { Button, Modal, Popconfirm } from "antd";
import { Field, Formik } from "formik";
import isEmpty from "lodash/isEmpty";
import React, { useContext } from "react";
import { formatMsg, MESSAGES } from "../../../../i18n";
import { StudentTableActionsContext } from "../table-actions-context";
import { FieldSelect } from "./field-select";
import { TagsOptions } from "./tags";
import { BulkChangeForm, BulkChangeTagsAction, BulkFields } from "./types";
import { useBulkChangeSubmission } from "./use-bulk-change-submission";
import { VisibilityOptions } from "./visibility";

type ComponentProps = {};

const initialFormData: BulkChangeForm = {
  field: "tags",
  tagsAction: "assign",
  tags: [],
  hidden: false
};

const BULK_FORM_HTML_ID = "bulk-changes-form";

export const BulkChangeModal: React.FC<ComponentProps> = () => {
  const { bulkChange, onBulkChangeModalClose } = useContext(StudentTableActionsContext);
  const { loading, onSubmit } = useBulkChangeSubmission(bulkChange.token!);

  const renderCustomOptionsBasedOnOperation = (field: BulkFields, tagsOperations?: BulkChangeTagsAction) => {
    if (field === "tags") {
      return <TagsOptions operation={tagsOperations!} />;
    }

    return <VisibilityOptions />;
  };

  return (
    <Formik<BulkChangeForm> onSubmit={onSubmit} initialValues={initialFormData}>
      {formProps => (
        <Modal
          open
          title={MESSAGES.BulkChangeModalTitle}
          onCancel={onBulkChangeModalClose}
          footer={[
            <Button key="cancel" onClick={onBulkChangeModalClose}>
              Cancel
            </Button>,
            <Popconfirm
              key="submit"
              title={
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formatMsg(
                        MESSAGES.BulkChangeConfirmationPromptLine1,
                        formProps.values.field === "tags" ? "Tags" : "Visibility",
                        bulkChange.total!
                      )
                    }}
                  />
                  <p>{MESSAGES.BulkChangeConfirmationPromptLine2}</p>
                </div>
              }
              onConfirm={() => onSubmit(formProps.values)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" loading={loading}>
                Submit
              </Button>
            </Popconfirm>
          ]}
          okButtonProps={{
            disabled: !isEmpty(formProps.errors) || !formProps.dirty,
            form: BULK_FORM_HTML_ID,
            htmlType: "submit",
            loading
          }}
          okText="Confirm"
        >
          <form id={BULK_FORM_HTML_ID} onSubmit={formProps.handleSubmit}>
            <Field name="field" className="bulk-change-field">
              {({ field }: any) => (
                <FieldSelect
                  value={field.value}
                  onChange={v => {
                    formProps.setFieldTouched(field.name);
                    formProps.setFieldValue(field.name, v);
                  }}
                />
              )}
            </Field>
            {renderCustomOptionsBasedOnOperation(formProps.values.field, formProps.values.tagsAction)}
          </form>
        </Modal>
      )}
    </Formik>
  );
};
