import { navApi } from "../../../../../../services/nav-api";
import { getTaskBlobWhenReady } from "../../../../../../services/task";
import { getCurDateForReportFilename } from "../../../../../../utils/date-utils";
import { ReportParams, ReportResponse } from "../../types";

// -------------------------------------------------------------------------------------------------
// - Generator
// -------------------------------------------------------------------------------------------------

export const genStudyTimeReport = async (params: ReportParams): Promise<ReportResponse> => ({
  filename: `study-time-${getCurDateForReportFilename()}.xlsx`,
  blob: await getTaskBlobWhenReady((await navApi.studentRoster.studyTimeReport(params)).taskToken)
});
