import { Card, Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { getProductConfig } from "../../../product-config";
import { Exam, getStudentExams } from "../../../redux/selectors/student-exams";
import { columns } from "./columns";

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const isPositiveExam = (e: Exam) => {
  return e.examGrade! >= getProductConfig().examTrackerMinExamGrade;
};

export const StudentExamsTable = () => {
  const studentExams = useSelector(getStudentExams);
  const loading = !studentExams || studentExams.loading;

  return (
    <Card
      loading={loading}
      title={
        <div className="ant-card-head-wrapper">
          <div className="ant-card-head-title" data-qa-label="sd-exam-tracker-title">
            Exam Tracker
          </div>
        </div>
      }
    >
      <Table
        rowClassName="cursor-pointer"
        loading={loading}
        columns={columns}
        dataSource={studentExams?.exams ?? []}
        pagination={false}
        rowKey="course"
        size="small"
      />
    </Card>
  );
};
