import { MESSAGES } from "../../../i18n";
import { HttpClientErrorResponse, HttpClientResponse } from "../types";

type Parser = (input: Response) => any;

const parseJsonResponse: Parser = async (response: Response) => {
  const text = await response.text();
  if (!text) {
    return;
  }

  try {
    return JSON.parse(text);
  } catch (ex) {
    throw {
      message: MESSAGES.HttpInvalidJsonResponse
    };
  }
};

const parseTextResponse: Parser = async (response: Response) => {
  return response.text();
};

const parseBlobResponse: Parser = async (response: Response) => {
  return response.blob();
};

const getParserByContentType = (contentType: string): Parser => {
  if (contentType.includes("application/json")) {
    return parseJsonResponse;
  } else if (contentType.includes("application/")) {
    return parseBlobResponse;
  }

  return parseTextResponse;
};

export async function parseResponse(response: Response): Promise<HttpClientResponse | HttpClientErrorResponse> {
  const contentType = response.headers.get("content-type") || "application/json";
  const parser = getParserByContentType(contentType!);
  return parser(response);
}

export async function parseErrorResponse(response: Response): Promise<HttpClientResponse | HttpClientErrorResponse> {
  const baseError = {
    statusCode: response.status,
    requestUrl: response.url
  };

  try {
    // assume that all error responses are json;
    const text = await response.text();
    const errorObj = JSON.parse(text);
    return {
      ...baseError,
      ...errorObj
    };
  } catch (ex) {
    return baseError;
  }
}
