import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";

export type Tag = {
  id: number;
  name: string;
  parentId?: number;
  enrolledStudentCount: number;
};

export type GetTenantTagsResponse = {
  tags: Tag[];
};

const getTenantTags = async (tenantId: number): Promise<GetTenantTagsResponse> => {
  const url = `/rest/admin/tenants/${tenantId}/tags`;
  return HttpClient.get(url, createHeaders());
};

const createTenantTag = async (tenantId: number, name: string, parentId?: number): Promise<{ id: number }> => {
  const url = `/rest/admin/tenants/${tenantId}/tags`;
  return HttpClient.post(url, createHeaders(), { name, parentId });
};

const getTenantTag = async (tenantId: number, tagId: number): Promise<Tag> => {
  const url = `/rest/admin/tenants/${tenantId}/tags/${tagId}`;
  return HttpClient.get(url, createHeaders());
};

const deleteTenantTag = async (tenantId: number, tagId: number): Promise<void> => {
  const url = `/rest/admin/tenants/${tenantId}/tags/${tagId}`;
  return HttpClient.del(url, createHeaders());
};

const updateTenantTag = async (tenantId: number, tagId: number, name: string, parentId?: number): Promise<void> => {
  const url = `/rest/admin/tenants/${tenantId}/tags/${tagId}`;
  return HttpClient.put(url, createHeaders(), { name, parentId });
};

const moveStudentsToTag = async (tenantId: number, srcTagId: number, destTagId: number): Promise<void> => {
  const url = `/rest/admin/tenants/${tenantId}/tags/${destTagId}/students`;
  return HttpClient.put(url, createHeaders(), { moveFromTagId: srcTagId });
};

const cloneTag = async (tenantId: number, tagId: number, intoTagId: number): Promise<void> => {
  const url = `/rest/admin/tenants/${tenantId}/tags/${tagId}/clone`;
  return HttpClient.post(url, createHeaders(), { intoTagId });
};

export { getTenantTags, moveStudentsToTag, createTenantTag, getTenantTag, deleteTenantTag, updateTenantTag, cloneTag };
