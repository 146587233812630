import { useDispatch, useSelector } from "react-redux";
import { formatMsg, MESSAGES } from "../../../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../../../redux/actions/students-list";
import { RdxStoreState } from "../../../../../redux/types/state";
import { FilterTag } from "../model";

// -------------------------------------------------------------------------------------------------
// - Hooks: useTenant
// -------------------------------------------------------------------------------------------------

export const useTenant = (): FilterTag[] => {
  const dispatch = useDispatch();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);
  const { tenantById = {} } = useSelector((state: RdxStoreState) => state.tenants.tenantsDropdown!);

  return activeFilter?.filter?.tenantId
    ? [
        {
          onClear: () => {
            dispatch(
              doUpdateWorkingFilter({
                tenantId: undefined
              })
            );
            dispatch(doApplyFilter());
          },
          text: formatMsg(MESSAGES.FilterTagTenant, tenantById[activeFilter.filter.tenantId]?.name || "")
        }
      ]
    : [];
};
