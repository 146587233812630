import _ from "lodash";
import difference from "lodash/difference";
import uniq from "lodash/uniq";
import { getAllRootIds } from "../get-all-roots";
import { getFullPath } from "../get-full-path";
import { Node } from "../types";

function getAllCheckedIds(tree: Node[], initialCheckedIds: number[]) {
  const ids = _.reduce(
    initialCheckedIds,
    (allCheckedIds: number[], id) => {
      const fullPath = getFullPath(tree, id);
      if (!fullPath) {
        return allCheckedIds;
      }
      return [...allCheckedIds, ...fullPath];
    },
    []
  );

  return difference(uniq(ids), getAllRootIds(tree));
}

export { getAllCheckedIds };
