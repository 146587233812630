import { createSelector } from "reselect";
import { RdxMySelfState } from "../reducers/myself";
import { RdxStoreState } from "../types/state";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getUserId: (state: RdxStoreState) => number | undefined = createSelector(
  (state: RdxStoreState) => state.myself,
  (myself: RdxMySelfState) => (myself && myself.user ? myself.user.userId : undefined)
);
