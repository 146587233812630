import { InfoCircleTwoTone } from "@ant-design/icons";
import { Card, Col, Row, Tooltip, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { altIdsByProduct, sectionAltIdsOrder } from "../../../../../constants/courses";
import { MESSAGES, formatMsg } from "../../../../../i18n";
import { getActiveProduct } from "../../../../../redux/selectors/active-product";
import { isFetchCompleted } from "../../../../../redux/selectors/dashboard/tenant";
import { PassRateChartBySection } from "./PassRateChartBySection";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const PassRateBySection = (): JSX.Element => {
  const dataReady = useSelector(isFetchCompleted);
  const activeProduct = useSelector(getActiveProduct);
  return (
    <Card loading={!dataReady} bodyStyle={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Row>
        <Col className="flex">
          <Typography.Text className="uppercase" type="secondary" style={{ minHeight: "30px" }}>
            {MESSAGES.PassRateBySection}{" "}
            <Tooltip title={formatMsg(MESSAGES.PassRateBySectionInfo, activeProduct.toUpperCase())}>
              <InfoCircleTwoTone />
            </Tooltip>
          </Typography.Text>
        </Col>
      </Row>
      <StyledChartsContainer>
        {_.chain(altIdsByProduct[activeProduct])
          .sortBy(altId => _.indexOf(sectionAltIdsOrder, altId))
          .map(altId => <PassRateChartBySection key={altId} altId={altId} />)
          .value()}
      </StyledChartsContainer>
    </Card>
  );
};

// -------------------------------------------------------------------------------------------------
// - Styled Components
// -------------------------------------------------------------------------------------------------

const StyledCard = styled(Card)`
  height: 100%;
  text-align: center;
`;

const StyledTitleContainer = styled.div`
  height: 100%;
  flex-grow: 1;
`;

const StyledRow = styled(Row)`
  display: flex;
`;

const StyledChartsContainer = styled(Row)`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  align-items: center;
  flex-grow: 1;
`;
