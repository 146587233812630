import { configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { reFetchAlerts } from "../reactors/re-fetch-alerts";
import { reFetchHierarchyContent } from "../reactors/re-fetch-hierarchy-content";
import { reFetchMyTenantsTags } from "../reactors/re-fetch-my-tenant-tags";
import { reFetchStudentFilterTenantTags } from "../reactors/re-fetch-student-filter-tenant-tags";
import { reFetchStudentsList } from "../reactors/re-fetch-students-list";
import { reOnTrustCtl } from "../reactors/re-onetrust-ctl";
import { reRefreshStudentDetails } from "../reactors/re-refresh-student-details";
import { addReactorsToStore } from "./add-reactors-to-store";
import { createExtraArgs } from "./extra-args";
import createRootReducer from "./root-reducer";

export const history = createBrowserHistory();

export const initializeStore = () => {
  const preloadedState = undefined;
  const reducer = createRootReducer(history);
  const store = configureStore({
    reducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: createExtraArgs()
        }
      })
  });

  addReactorsToStore(store, [
    reFetchStudentsList,
    reFetchMyTenantsTags,
    reFetchHierarchyContent,
    reFetchStudentFilterTenantTags,
    reRefreshStudentDetails,
    reFetchAlerts,
    reOnTrustCtl as any
  ]);

  return store;
};
