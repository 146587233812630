import { Card, Modal } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { BaseLayout } from "../../components/layout/base-layout";
import { PageTitle } from "../../components/layout/page-title";
import { NavigationDrawerOptionKey } from "../../components/navigation-drawer/types";
import { TenantFormProps, TenantsForm } from "../../components/tenants/form";
import { routes } from "../../routes";
import { HttpClientErrorResponse } from "../../services/http-client/types";
import { navApi } from "../../services/nav-api";

export const TenantNew: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = (props: TenantFormProps) => {
    navApi.tenants
      .postTenant(props.name, props.type!, props.products)
      .then(({ tenantId }) =>
        Modal.success({
          title: "Tenant created with success",
          onOk() {
            navigate(routes.tenant.resolve({ id: tenantId.toString() }));
          }
        })
      )
      .catch((err: HttpClientErrorResponse) =>
        Modal.error({
          title: "Error creating tenant",
          content: err.message
        })
      );
  };

  return (
    <BaseLayout
      activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_TENANTS}
      breadcrumb={[{ name: "Tenants", href: routes.tenants.resolve() }, { name: "New Tenant" }]}
    >
      <PageTitle>New Tenant</PageTitle>
      <Card>
        <TenantsForm onSubmit={handleSubmit} />
      </Card>
    </BaseLayout>
  );
};
