import { LicenseValue } from "../../../actions/students-list";
import { RdxFetchStatus } from "../../../types/status";
import * as StudentsLists from "../../students-list";
import { RdxTenantDashboardState } from "./model";

// -------------------------------------------------------------------------------------------------
// - Default filter
// -------------------------------------------------------------------------------------------------

const defaultFilter = {
  ...StudentsLists.initialState.workingFilter,
  license: "active" as LicenseValue
};

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

export const initialState: RdxTenantDashboardState = {
  status: RdxFetchStatus.NONE,
  workingFilter: defaultFilter,
  activeFilter: defaultFilter
};
