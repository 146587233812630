import React from "react";
import { useSelector } from "react-redux";
import { FEATURES } from "../../constants/ui-features";
import { getAllowedFeatures } from "../../redux/selectors/allowed_features";

type ComponentProps = {
  forceFeatureOff?: boolean;
  feature: FEATURES;
  children: React.ReactElement;
};

export const Can: React.FC<ComponentProps> = ({ feature, children, forceFeatureOff }) => {
  const allowedFeatures = useSelector(getAllowedFeatures);

  if (!allowedFeatures || !allowedFeatures[feature] || forceFeatureOff) {
    return null;
  }

  return children;
};
