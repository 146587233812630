import { Action } from "../../types/action";
import { FETCH_MYSELF_STARTED } from "./actions";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doStartFetchMySelf = (): Action => ({
  type: FETCH_MYSELF_STARTED
});
