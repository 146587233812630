import { Skeleton } from "antd";
import React from "react";
import styled from "styled-components";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const DashboardNumber = ({ value, loading }: { value: number; loading?: boolean }) =>
  loading ? (
    <Skeleton />
  ) : (
    <StyledNumberContainer>
      <StyledNumber>{value}</StyledNumber>
    </StyledNumberContainer>
  );

// -------------------------------------------------------------------------------------------------
// - Styled Components
// -------------------------------------------------------------------------------------------------

const StyledNumberContainer = styled.div`
  text-align: center;
`;

const StyledNumber = styled.div`
  font-size: 28.5pt;
  padding: 0;
  margin: 0;
  color: #505d68;
`;
