import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import download from "downloadjs";
import isString from "lodash/isString";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { RdxStoreState } from "../../../redux/types/state";
import { routes } from "../../../routes";
import { navApi } from "../../../services/nav-api";
import { getTaskBlobWhenReady } from "../../../services/task";
import { createNotifier } from "../../../services/task/notifier";
import { getCurDateForReportFilename } from "../../../utils/date-utils";

type Props = {
  className?: string;
};

const Component: React.FC<Props> = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tenants = useSelector((state: RdxStoreState) => state.tenants?.tenants);
  const hasRecords = Boolean(tenants?.paging?.totalItems);

  const handleCreateReport = async () => {
    const filter = tenants?.activeFilter?.filter?.filter;
    const status = tenants?.activeFilter?.filter?.status;
    const notifier = createNotifier();
    notifier.loading();
    try {
      const { taskToken } = await navApi.tenants.createTenantsReport({ filter, status });
      const blob = await getTaskBlobWhenReady(taskToken);
      download(blob, `tenants-report-${getCurDateForReportFilename()}.xlsx`, blob.type);
      notifier.success();
    } catch (err) {
      notifier.error(isString(err) ? err : undefined);
    }
  };

  return (
    <div className={className}>
      <Button
        onClick={handleCreateReport}
        icon={<FileExcelOutlined />}
        disabled={!hasRecords}
        data-qa-label="createReportBtn"
      >
        Create Report
      </Button>
      <Button
        icon={<PlusOutlined />}
        data-qa-label="newTenantBtn"
        onClick={() => {
          navigate(routes.tenantNew.resolve());
        }}
      >
        New Tenant
      </Button>
    </div>
  );
};

export const TenantsTableActions = styled(Component)`
  > * {
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
    }
  }
`;
