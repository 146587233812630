import { useDispatch, useSelector } from "react-redux";
import { formatMsg, MESSAGES } from "../../../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../../../redux/actions/students-list";
import { initialState } from "../../../../../redux/reducers/students-list";
import { getActiveProduct } from "../../../../../redux/selectors/active-product";
import { RdxStoreState } from "../../../../../redux/types/state";
import { currentActivityLabelByValue } from "../../../students-filter/metadata";
import { FilterTag } from "../model";

// -------------------------------------------------------------------------------------------------
// - Hooks: useFilterTags
// -------------------------------------------------------------------------------------------------

export const useCurrentActivity = (): FilterTag[] => {
  const dispatch = useDispatch();
  const activeProduct = useSelector(getActiveProduct);
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);

  return activeFilter?.filter?.currentActivity &&
    activeFilter.filter.currentActivity !== initialState.workingFilter.currentActivity
    ? [
        {
          onClear: () => {
            dispatch(doUpdateWorkingFilter({ currentActivity: initialState.workingFilter.currentActivity }));
            dispatch(doApplyFilter());
          },
          text: formatMsg(
            MESSAGES.FilterTagCurrentActivity,
            currentActivityLabelByValue(activeProduct)[activeFilter.filter.currentActivity]
          )
        }
      ]
    : [];
};
