import { createSelector } from "reselect";

import { RdxStudentDetails } from "../reducers/student-details";
import { RdxStoreState } from "../types/state";
import { StudentAffiliation } from "./../../services/nav-api/student-details/types";
import { RdxFetchStatus } from "./../types/status";

export const getStudentAffiliation: (
  state: RdxStoreState
) => { affiliation?: StudentAffiliation; loading?: boolean } | undefined = createSelector(
  (state: RdxStoreState) => state.studentDetails,
  ({ curStudentId, details, status }: RdxStudentDetails) => {
    if (!curStudentId || !details) {
      return;
    }

    return status === RdxFetchStatus.LOADING
      ? {
          loading: true
        }
      : details
        ? { affiliation: details.affiliation }
        : undefined;
  }
);
