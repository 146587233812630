import React from "react";
import { Link } from "react-router-dom";
import { MESSAGES } from "../../../i18n";
import { routes } from "../../../routes";
import { NavigationDrawerOptionKey } from "../types";
import { AlertsBadge } from "./common";

const key = NavigationDrawerOptionKey.NOTIFICATIONS;
const qaLabel = "menu-item-notifications-reader";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default [
  {
    key,
    label: (
      <Link to={routes.alertsReader.resolve()} data-qa-label={qaLabel}>
        <AlertsBadge>{MESSAGES.NavNotifications}</AlertsBadge>
      </Link>
    )
  }
];
