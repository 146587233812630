import { FilterTag } from "../model";
import { useCurrentActivity as useCurrentActivityFilter } from "./currentActivityFilter";
import { useExamTracker as useExamTrackerFilter } from "./examTrackerFilter";
import { useSearchTerm } from "./searchTermFilter";
import { useLicense as useLicenseFilter } from "./useLicenseFilter";
import { useMetricsFilter } from "./useMetricsFilter";
import { usePurchasedDate } from "./usePurchasedDateFilter";
import { useStudyPlan } from "./useStudyPlanFilter";
import { useStudyTime } from "./useStudyTimeFilter";
import { useTags } from "./useTagsFilter";
import { useTenant } from "./useTenantFilter";

// -------------------------------------------------------------------------------------------------
// - Hooks: useFilterTags
// -------------------------------------------------------------------------------------------------

export const useFilterTags = (): FilterTag[] => {
  const currentActivity = useCurrentActivityFilter();
  const examTracker = useExamTrackerFilter();
  const license = useLicenseFilter();
  const metricsFilter = useMetricsFilter();
  const purchasedDate = usePurchasedDate();
  const searchTerm = useSearchTerm();
  const studyPlan = useStudyPlan();
  const studyTime = useStudyTime();
  const tags = useTags();
  const tenant = useTenant();

  return [
    ...currentActivity,
    ...examTracker,
    ...license,
    ...metricsFilter,
    ...purchasedDate,
    ...searchTerm,
    ...studyPlan,
    ...studyTime,
    ...tags,
    ...tenant
  ];
};
