import { Col, Modal, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BaseLayout } from "../../components/layout/base-layout";
import { PageTitle } from "../../components/layout/page-title";
import { NavigationDrawerOptionKey } from "../../components/navigation-drawer/types";
import { ChangePasswordForm } from "../../components/profile/ChangePasswordForm";
import { ChangeSettingsForm, ChangeSettingsFormProps } from "../../components/profile/ChangeSettingsForm";
import { UserProfileInfo } from "../../components/profile/details";
import { MESSAGES } from "../../i18n";
import { doUpdateActiveUser } from "../../redux/actions/myself/updateActiveUser";
import { getActiveUser } from "../../redux/selectors/active-user";
import { HttpClientErrorResponse } from "../../services/http-client/types";
import { navApi } from "../../services/nav-api";
import { UserType } from "../../services/nav-api/users";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(getActiveUser);
  const [pwdSubmitCount, setPwdSubmitCount] = useState(0);
  const [ntsSubmitCount, setNtsSubmitCount] = useState(0);

  // -----------------------------------------------------------------------------------------------
  // - Event Handlers
  // -----------------------------------------------------------------------------------------------

  const handlePasswordChangeSubmit = (props: { oldPassword: string; newPassword: string }) => {
    navApi.myself
      .updateMySelfPassword(props.oldPassword, props.newPassword)
      .then(() => {
        Modal.success({
          title: MESSAGES.Success,
          content: MESSAGES.ChangePasswordSuccess
        });
        setPwdSubmitCount(pwdSubmitCount + 1);
      })
      .catch((err: HttpClientErrorResponse) =>
        Modal.error({
          title: MESSAGES.Error,
          content: err.message ? err.message : MESSAGES.UnknownError
        })
      );
  };

  // -----------------------------------------------------------------------------------------------
  // - Event Handlers
  // -----------------------------------------------------------------------------------------------

  const handleSettingsChangeSubmit = (props: ChangeSettingsFormProps) => {
    (async () => {
      try {
        // Update the user settings
        await navApi.myself.updateMySelfNotificationsSettings(props.unsubscribeNotifications);

        // Fetch the user again to update the state
        dispatch(
          doUpdateActiveUser({
            unsubscribeAlerts: props.unsubscribeNotifications
          })
        );

        // Show a success message
        Modal.success({
          title: MESSAGES.Success,
          content: MESSAGES.ChangeSettingsSuccess
        });
        setNtsSubmitCount(ntsSubmitCount + 1);
      } catch (err) {
        Modal.error({
          title: MESSAGES.Error,
          content: err.message ? err.message : MESSAGES.UnknownError
        });
      }
    })();
  };

  // -----------------------------------------------------------------------------------------------
  // - Renderer
  // -----------------------------------------------------------------------------------------------

  return (
    <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.PROFILE}>
      <PageTitle>{MESSAGES.NavMyAccount}</PageTitle>
      <Row>
        <Col span={12}>
          <div className="mb-3">
            <UserProfileInfo />
          </div>
        </Col>
      </Row>
      {user && user.idp === UserType.Local ? (
        <Row>
          <Col span={12}>
            <div className="mb-3">
              <ChangePasswordForm
                key={pwdSubmitCount}
                onSubmit={props => {
                  handlePasswordChangeSubmit(
                    props as {
                      oldPassword: string;
                      newPassword: string;
                    }
                  );
                }}
              />
            </div>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col span={12}>
          <div className="mb-3">
            <ChangeSettingsForm onSubmit={handleSettingsChangeSubmit} key={ntsSubmitCount} />
          </div>
        </Col>
      </Row>
    </BaseLayout>
  );
};
export default Profile;
