import { isHttpError } from "../../../services/http-client/types";
import { API_FAILURE_RESET, API_FAILURE_REMOVE_ERROR } from "../../actions/api-failure";
import { createReducer, ANY_ACTION_TYPE } from "../../utils/redux-utils";

export type ApiFailure = {
  id: string;
  message: string;
  statusCode: number;
  title?: string;
  requestUrl?: string;
};

export type RdxApiFailureState = {
  failures: ApiFailure[];
};

const initialState: RdxApiFailureState = { failures: [] };

export default createReducer<RdxApiFailureState>(initialState, {
  [API_FAILURE_RESET]: () => ({ failures: [] }),
  [API_FAILURE_REMOVE_ERROR]: (state: RdxApiFailureState, id: string) => ({
    failures: state.failures.filter(({ id: fId }) => id !== fId)
  }),
  // Whenever a dispatched action contains a property named "error" of type HttpClientErrorResponse
  // then this reducer will intercept  that action;
  [ANY_ACTION_TYPE]: (state, payload: any) => {
    if (payload && isHttpError(payload.error) && !state.failures.some(f => f.id === payload.id)) {
      return {
        failures: [
          ...state.failures,
          {
            ...payload.error
          }
        ]
      };
    }

    return state;
  }
});
