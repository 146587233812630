import {
  HTTP_HEADER_ACCEPT,
  HTTP_HEADER_USER,
  HTTP_HEADER_CONTENT_TYPE,
  HTTP_HEADER_CONTENT_TYPE_JSON,
  HTTP_HEADER_VERSION,
  HTTP_HEADER_VERSION_LENGTH,
  HTTP_HEADER_PRODUCT
} from "../constant";

import { getUserId } from "../../redux/selectors/account-id";
import { storeRegistry } from "../../redux/store/store-registry";
import { SYSTEM_CONFIG } from "../../constants/system-config";
import { calcMD5 } from "../../utils/md5";
import { getActiveProduct } from "../../redux/selectors/active-product";

export type HeadersMap = { [header: string]: string };

const baseHeaders = {
  [HTTP_HEADER_CONTENT_TYPE]: HTTP_HEADER_CONTENT_TYPE_JSON,
  [HTTP_HEADER_ACCEPT]: HTTP_HEADER_CONTENT_TYPE_JSON
};

const createXVersionHeader = (): HeadersMap | {} => {
  if (SYSTEM_CONFIG.frontEndCommit) {
    return { [HTTP_HEADER_VERSION]: calcMD5(SYSTEM_CONFIG.frontEndCommit || "").substr(0, HTTP_HEADER_VERSION_LENGTH) };
  }
  return {};
};

const createXProductHeader = (): HeadersMap | {} => {
  const state = storeRegistry.getState();
  if (!state) {
    return {};
  }
  return {
    [HTTP_HEADER_PRODUCT]: getActiveProduct(state)
  };
};

const createUserHeader = (): HeadersMap | {} => {
  const state = storeRegistry.getState();
  if (!state) {
    return {};
  }
  const userId = getUserId(state);
  return userId ? { [HTTP_HEADER_USER]: userId.toString() } : {};
};

export function createHeaders(customConfig: { [name: string]: string } = {}): HeadersMap {
  return {
    ...baseHeaders,
    ...createXVersionHeader(),
    ...createUserHeader(),
    ...createXProductHeader(),
    ...customConfig
  };
}

export function createUnAuthHeaders(customConfig: { [name: string]: string } = {}): HeadersMap {
  return { ...baseHeaders, ...customConfig };
}
