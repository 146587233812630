import fnsToDate from "date-fns-tz/toDate";
import fnsFormat from "date-fns/format";
import fnsFormatDistanceToNow from "date-fns/formatDistanceToNow";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import en from "date-fns/locale/en-US";
import fnsParse from "date-fns/parse";
import prettyMs from "pretty-ms";
import moment from "moment";

export const locale = en;

type DateArg = Date | number | string;

export const format = (d: DateArg, formatStr: string) => {
  return fnsFormat(fnsToDate(d), formatStr, { locale });
};

export const formatDistanceToNow = (d: DateArg, options: { addSuffix?: boolean } = {}) => {
  return fnsFormatDistanceToNow(fnsToDate(d), { locale, ...options });
};

export const parse = (d: string, formatStr: string) => fnsParse(d, formatStr, new Date(), { locale });

export const formatDistanceToNowInDaysOrBigger = (d: DateArg, options: { addSuffix?: boolean } = {}) => {
  d = fnsToDate(d);
  if (isToday(d)) {
    return "today";
  }
  if (isYesterday(d)) {
    return "yesterday";
  }
  return fnsFormatDistanceToNow(d, { locale, ...options });
};

export const getReadableDuration = (ms: number) => prettyMs(ms);
export const getCurDateForReportFilename = () => fnsFormat(new Date(), "MMddyyyy");

export const millisecondsToHours = (ms: number) => {
  const tempTime = moment.duration(ms);
  const hours: number = Math.round(tempTime.asHours());
  return `${hours}h`;
};

export const millisecondsToHoursMin = (ms: number) => {
  const tempTime = moment.duration(ms);
  const hours: number = Math.round(tempTime.asHours());
  const min: number = Math.round(tempTime.minutes());
  return `${hours}h ${min}m`;
};
