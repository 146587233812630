import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { navApi } from "../../../services/nav-api";
import { Alert, ApiAlertStatus } from "../../../services/nav-api/alerts-writer/model";
import { AlertStatus, RdxFetchCompletionPayload } from "../../reducers/alerts-writer/model";
import { Action, ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "../../types/status";

// -------------------------------------------------------------------------------------------------
// - Actions
// -------------------------------------------------------------------------------------------------

export const ADMIN_FETCH_ALERTS_STARTED = "ADMIN_FETCH_ALERTS_STARTED";
export const ADMIN_FETCH_ALERTS_COMPLETED = "ADMIN_FETCH_ALERTS_COMPLETED";
export const ADMIN_FETCH_ALERTS_FAILED = "ADMIN_FETCH_ALERTS_FAILED";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doStartFetchAlerts = (): Action => ({
  type: ADMIN_FETCH_ALERTS_STARTED
});

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const toApiStatus = (status: AlertStatus): ApiAlertStatus[] => {
  switch (status) {
    case AlertStatus.ACTIVE:
      return [ApiAlertStatus.PUBLISHED, ApiAlertStatus.SCHEDULED];
    case AlertStatus.EXPIRED:
      return [ApiAlertStatus.EXPIRED];
    case AlertStatus.ALL:
      return [ApiAlertStatus.PUBLISHED, ApiAlertStatus.SCHEDULED, ApiAlertStatus.EXPIRED];
  }
};

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doFetchAlerts = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    const {
      alertsWriter: {
        paging: { itemsPerPage, page }
      }
    } = getState();
    const size = itemsPerPage;
    const offset = size * (page - 1);
    let status: ApiAlertStatus[] = toApiStatus(getState().alertsWriter.activeFilters?.status);

    if (_.isEqual(getState().myself.status, RdxFetchStatus.LOADING)) {
      return;
    }

    dispatch(doStartFetchAlerts());
    try {
      const { alerts, total } = await navApi.alertsWriter.fetchAlerts(size, offset, status);
      dispatch(doCompleteFetchAlerts(alerts, total));
    } catch (ex) {
      dispatch(doFailFetchAlerts((ex as HttpClientErrorResponse).message || "error"));
    }
  };
};

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doCompleteFetchAlerts = (alerts: Alert[], total: number): Action<RdxFetchCompletionPayload> => ({
  type: ADMIN_FETCH_ALERTS_COMPLETED,
  payload: { alerts, total }
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doFailFetchAlerts = (payload: string): Action<string> => ({
  type: ADMIN_FETCH_ALERTS_FAILED,
  payload
});
