import { message } from "antd";
import download from "downloadjs";
import _ from "lodash";
import React from "react";
import { MESSAGES } from "../../../../../../i18n";
import { ReportGenerator, ReportParams, ReportType } from "../../types";
import { genExamTrackerReport } from "./genExamTrackerReport";
import { genHomeworkMcqFirstAttemptReport } from "./genHomeworkMcqFirstAttemptReport";
import { genHomeworkPerformanceReport } from "./genHomeworkPerformanceReport";
import { genMiniExamPerformanceReport } from "./genMiniExamPerformanceReport";
import { genMiniExamScoreReport } from "./genMiniExamScoreReport";
import { genMiniExamsAnswersReport } from "./genMiniExamsAnswersReport";
import { genPerformanceFrReport, genPerformanceReport } from "./genPerformanceDashboardReport";
import { genSimulatedExamPerformanceReport } from "./genSimulatedExamPerformanceReport";
import { genSimulatedExamsAnswersReport } from "./genSimulatedExamsAnswersReport";
import { genSimulatedExamsScoreFrReport, genSimulatedExamsScoreReport } from "./genSimulatedExamsScoreReport";
import { genStudentsListReport } from "./genStudentsListReport";
import { genStudyTimeReport } from "./genStudyTimeReport";
import { genTimesheetReport } from "./genTimesheetReport";

// -------------------------------------------------------------------------------------------------
// - Proxy
// -------------------------------------------------------------------------------------------------

const reportGeneratorByType: { [type in ReportType]: ReportGenerator } = {
  [ReportType.EXAM_TRACKER_REPORT]: genExamTrackerReport,
  [ReportType.HOMEWORK_MCQ_FIRST_ATTEMPT_REPORT]: genHomeworkMcqFirstAttemptReport,
  [ReportType.HOMEWORK_PERFORMANCE_REPORT]: genHomeworkPerformanceReport,
  [ReportType.MINI_EXAMS_PERFORMANCE_REPORT]: genMiniExamPerformanceReport,
  [ReportType.MINI_EXAMS_SCORE_REPORT]: genMiniExamScoreReport,
  [ReportType.PERFORMANCE_DASHBOARD_FR_REPORT]: genPerformanceFrReport,
  [ReportType.PERFORMANCE_DASHBOARD_REPORT]: genPerformanceReport,
  [ReportType.SIMULATED_EXAMS_ANSWERS_REPORT]: genSimulatedExamsAnswersReport,
  [ReportType.SIMULATED_EXAMS_PERFORMANCE_REPORT]: genSimulatedExamPerformanceReport,
  [ReportType.SIMULATED_EXAMS_SCORE_REPORT]: genSimulatedExamsScoreReport,
  [ReportType.SIMULATED_EXAMS_SCORE_FR_REPORT]: genSimulatedExamsScoreFrReport,
  [ReportType.STUDENTS_LIST_REPORT]: genStudentsListReport,
  [ReportType.STUDY_TIME_REPORT]: genStudyTimeReport,
  [ReportType.TIMESHEET_REPORT]: genTimesheetReport,
  [ReportType.MINI_EXAMS_ANSWERS_REPORT]: genMiniExamsAnswersReport
};

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const showSuccess = () => {
  message.success(<span data-qa-label="reportSuccessMsg">{MESSAGES.ReportSuccess}</span>);
};

const showError = (err: any) => {
  message.error(<span data-qa-label="reportErrorMsg">{_.isString(err) ? err : MESSAGES.ReportError}</span>);
};

// -------------------------------------------------------------------------------------------------
// - Report Generator
// -------------------------------------------------------------------------------------------------

export async function handleGenerateReport(params: ReportParams) {
  if (!_.isNil(params.token)) {
    try {
      const response = await reportGeneratorByType[params.type](params);

      download(
        response.blob,
        response.filename,
        response.blob.type
        //
      );

      showSuccess();
    } catch (err) {
      showError(err);
    }
  }
}
