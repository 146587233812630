import { Reactor } from "../types/reactors";
import { RdxStore } from "../types/store";

let unsubscribe: any;

const addReactorsToStore = (store: RdxStore, reactors: Reactor[]) => {
  if (unsubscribe) {
    return;
  }
  unsubscribe = store.subscribe(() => {
    const state = store.getState();

    let nextReaction: any;
    reactors.some(reactor => {
      const result = reactor(state);
      if (result) {
        nextReaction = result;
        return true;
      }
      return false;
    });

    if (nextReaction) {
      window.requestIdleCallback(() => {
        store.dispatch(nextReaction!);
      });
    }
  });
};

export { addReactorsToStore };
