import { TagEditorNode } from "./types";

function setLevelToTreeNodes(nodes: TagEditorNode[], curLevel = 1) {
  nodes.forEach(node => {
    node.depthLevel = curLevel;
    if (node.children.length) {
      setLevelToTreeNodes(node.children, curLevel + 1);
    }
  });
}

function getMaxDepth(node: TagEditorNode, maxDepth = 1) {
  if (!node.children || !node.children.length) {
    return maxDepth;
  }
  const childMaxLevel: number = (node.children || []).reduce((max: number, cur) => {
    const curMaxDepth = getMaxDepth(cur, maxDepth + 1);
    return max > curMaxDepth ? max : curMaxDepth;
  }, 0);
  return childMaxLevel;
}

function setMaxDepth(nodes: TagEditorNode[]) {
  nodes.forEach(node => {
    node.maxDepth = getMaxDepth(node);
    if (node.children) {
      setMaxDepth(node.children);
    }
  });
}

function setNodesDepth(nodes: TagEditorNode[]) {
  setLevelToTreeNodes(nodes);
  setMaxDepth(nodes);
}

export { setNodesDepth };
