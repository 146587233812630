import isEqual from "lodash/isEqual";
import { createSelector } from "reselect";
import { RdxStoreState } from "../types/state";
import { RdxPartnersState } from "../reducers/partners";

export const getIsPartnersListFilterEqualsToAppliedFilter: (state: RdxStoreState) => boolean = createSelector(
  (state: RdxStoreState) => state.partners,
  ({ workingFilter, activeFilter }: RdxPartnersState): boolean => {
    if (!activeFilter) {
      return false;
    }
    return isEqual(activeFilter.filter, workingFilter);
  }
);
