import { PageHeader } from "@ant-design/pro-layout";
import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { doChangeCurrentStudent, doFetchStudentDetails } from "../../redux/actions/student-details/student-details";
import { StudentAffiliationCard } from "../Students/student-affiliation";
import { StudentExamsTable } from "../Students/student-exams";
import { StudentInfoCard } from "../Students/student-info";
import { StudentPerformanceDashboard } from "../Students/student-perf-dashboard";
import { StudentStudyTimeCard } from "../Students/student-study-time";

// -------------------------------------------------------------------------------------------------
// - OwnProps
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  id: number;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentDetails = ({ id }: OwnProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(doChangeCurrentStudent(id));
    dispatch(doFetchStudentDetails());
  }, [id]);

  return (
    <Row gutter={16}>
      <Col xs={24}>
        <CardWrapper>
          <StudentInfoCard />
        </CardWrapper>
      </Col>
      <Col lg={24}>
        <CardWrapper>
          <StudentAffiliationCard />
        </CardWrapper>
      </Col>
      <Col lg={24}>
        <CardWrapper>
          <StudentStudyTimeCard />
        </CardWrapper>
      </Col>
      <Col xs={24}>
        <CardWrapper>
          <StudentPerformanceDashboard />
        </CardWrapper>
      </Col>
      <Col xs={24}>
        <CardWrapper>
          <StudentExamsTable />
        </CardWrapper>
      </Col>
    </Row>
  );
};

// -------------------------------------------------------------------------------------------------
// - Styled Components
// -------------------------------------------------------------------------------------------------

const CardWrapper = styled.div`
  margin-bottom: 16px;
`;

const StyledPageHeader = styled(PageHeader)`
  padding-left: 0;
`;
