import { AltID } from "../../constants/courses";
import { getProductConfig } from "../../product-config";
import { RosterStudentFilter } from "../../services/nav-api/student-roster/types";
import { format } from "../../utils/date-utils";
import { excludeRedundantNodes } from "../../utils/tree-utils/assign/exclude-redundant-nodes";
import { ExamTrackerValue } from "../actions/students-list/index";
import { RdxTenantTagsSubState } from "../reducers/tenants/tenant-tags";
import { LicenseValue, StudyTimeValue, WorkingFilter } from "./../actions/students-list";
import { CurrentActivityValue } from "./../actions/students-list/index";
import { TagNode } from "./my-tenant-tags-tree";

// -------------------------------------------------------------------------------------------------
// - getStudyTimeParams
// -------------------------------------------------------------------------------------------------

const getStudyTimeParams = (
  studyTime: StudyTimeValue
): { studyTimeMoreThanSecs?: number; studyTimeLessThanSecs?: number } => {
  if (studyTime === "none") {
    return { studyTimeLessThanSecs: 1 };
  }
  if (studyTime === "up_to_4_hours") {
    return { studyTimeMoreThanSecs: 1, studyTimeLessThanSecs: 14400 };
  }
  if (studyTime === "4_to_48_hours") {
    return { studyTimeMoreThanSecs: 14400, studyTimeLessThanSecs: 172800 };
  }
  if (studyTime === "48_to_240_hours") {
    return { studyTimeMoreThanSecs: 172800, studyTimeLessThanSecs: 864000 };
  }
  if (studyTime === "over_240_hours") {
    return { studyTimeMoreThanSecs: 864000 };
  }
  return {};
};

// -------------------------------------------------------------------------------------------------
// - getCurrentActivityParams
// -------------------------------------------------------------------------------------------------

const getCurrentActivityParams = (activeSection: CurrentActivityValue) => {
  if (activeSection == "any") {
    return {};
  }

  if (activeSection == "no_activity") {
    return { activeSection: "-" };
  }

  return { activeSection };
};

// -------------------------------------------------------------------------------------------------
// - getExamTrackerParams
// -------------------------------------------------------------------------------------------------

const getExamTrackerParams = (value: ExamTrackerValue, partsCount: number) => {
  switch (value) {
    case AltID.F:
    case AltID.A:
    case AltID.R:
    case AltID.B:
    case AltID.BAR:
    case AltID.ISC:
    case AltID.TCP:

    case AltID.P1:
    case AltID.P2:
      return { passedSection: value };
    case "any":
      return {};
    case "no_data":
      return { hasReportedScores: false };
    case "passed_all_parts":
      return { passedMinParts: partsCount };
    case "passed_any_part":
      return { passedMinParts: 1 };
    case "about_to_lose_or_lost_credits":
      return { creditExpirationInLessThanDays: 90 };
  }

  return {};
};

// -------------------------------------------------------------------------------------------------
// - getLicenseParams
// -------------------------------------------------------------------------------------------------

const getLicenseParams = (
  license: LicenseValue
): {
  licenseExpireInLessThanDays?: number;
  licenseExpireInMoreThanDays?: number;
  licenseDurationLessThanMonths?: number;
} => {
  switch (license) {
    case "active":
      return { licenseExpireInMoreThanDays: 0 };
    case "expired":
      return { licenseExpireInLessThanDays: 0 };
    case "in_less_than_3_months":
      return {
        licenseExpireInLessThanDays: 90,
        licenseExpireInMoreThanDays: 0
      };
    case "in_more_than_3_months":
      return { licenseExpireInMoreThanDays: 90 };
    case "length_under_6_months":
      return {
        licenseDurationLessThanMonths: 6,
        licenseExpireInMoreThanDays: 0
      };
  }

  return {};
};

// -------------------------------------------------------------------------------------------------
// - getTagsParams
// -------------------------------------------------------------------------------------------------

const getTagsParams = (
  allTagIds: number[],
  checkedIds: number[],
  tagsNegate: boolean
): { tagIds: number[]; tagsNegate?: boolean } => {
  if (!tagsNegate) {
    return {
      tagIds: checkedIds
    };
  }

  if (tagsNegate) {
    return {
      tagsNegate: true,
      tagIds: allTagIds
    };
  }

  return { tagIds: [] };
};

// -------------------------------------------------------------------------------------------------
// - getIsExcluded
// -------------------------------------------------------------------------------------------------

const getIsExcluded = (hiddenStudents: "no" | "include" | "only") => {
  let value: boolean | undefined;

  if (hiddenStudents === "no") {
    value = false;
  }
  if (hiddenStudents === "only") {
    value = true;
  }

  return {
    isExcluded: value
  };
};

// -------------------------------------------------------------------------------------------------
// - getPurchasedDateParams
// -------------------------------------------------------------------------------------------------

const getPurchasedDateParams = (purchasedDate: WorkingFilter["purchasedDate"]) => {
  const params: Partial<Pick<RosterStudentFilter, "licensePurchaseDateMin" | "licensePurchaseDateMax">> = {};

  if (purchasedDate.startDate) {
    params.licensePurchaseDateMin = format(purchasedDate.startDate, "yyyy-MM-dd");
  }

  if (purchasedDate.endDate) {
    params.licensePurchaseDateMax = format(purchasedDate.endDate, "yyyy-MM-dd");
  }

  return params;
};

// -------------------------------------------------------------------------------------------------
// - getPurchasedDateParams
// -------------------------------------------------------------------------------------------------

const getContentVersionParams = (isCpaPreEvolution: boolean) => {
  return isCpaPreEvolution ? { ccontent: "Stable:4337" } : {};
};

// -------------------------------------------------------------------------------------------------
// - buildStudentRosterFilter
// -------------------------------------------------------------------------------------------------

export const buildStudentRosterFilter = (
  workingFilter: WorkingFilter,
  tree: TagNode[],
  tenantId: number,
  tenantTags: RdxTenantTagsSubState,
  isCpaPreEvolution: boolean
): RosterStudentFilter => {
  const searchTerm = workingFilter.searchTerm.trim();
  const allTagIds = tenantId ? tenantTags!.tagsIdsbyTenantId[tenantId] || [] : [];
  return {
    searchTerm: searchTerm || undefined,
    searchFields: searchTerm ? workingFilter.searchFields : undefined,
    tenantId,
    passedParts: workingFilter.metrics.passedAllExams ? getProductConfig().numberOfSections : undefined,
    hasStudyPlan: workingFilter.studyPlan === "any" ? undefined : workingFilter.studyPlan !== "no",
    isFollowed: workingFilter.metrics.followedStudents ? true : undefined,
    behindSchedule: ["any", "no", "yes"].includes(workingFilter.studyPlan)
      ? undefined
      : workingFilter.studyPlan === "yes_behind_schedule",
    ...getIsExcluded(workingFilter.metrics.hiddenStudents),
    ...getTagsParams(allTagIds, excludeRedundantNodes(tree, workingFilter.tags || []), workingFilter.withoutTags),
    ...getLicenseParams(workingFilter.license),
    ...getStudyTimeParams(workingFilter.studyTime),
    ...getPurchasedDateParams(workingFilter.purchasedDate),
    ...getCurrentActivityParams(workingFilter.currentActivity),
    ...getExamTrackerParams(workingFilter.examTracker, getProductConfig().numberOfSections),
    ...getContentVersionParams(isCpaPreEvolution)
  };
};
