import React, { useState } from "react";
import { Switch, Modal } from "antd";
import { navApi } from "../../../services/nav-api";
import { MESSAGES } from "../../../i18n";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { Status } from "../../../services/nav-api/types";
import { useDispatch } from "react-redux";
import { doFetchPartners } from "../../../redux/actions/partners";

type ComponentProps = {
  id: number;
  value: boolean;
};

export const Checked: React.FC<ComponentProps> = props => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  return (
    <Switch
      checkedChildren={MESSAGES.Enabled}
      unCheckedChildren={MESSAGES.Disabled}
      checked={props.value}
      loading={loading}
      onChange={value => {
        setLoading(true);
        navApi.partners
          .putPartnerStatus(props.id, value === false ? Status.Disabled : Status.Enabled)
          .then(() => {
            Modal.success({
              title: MESSAGES.PartnerStatusTitle,
              content: MESSAGES.PartnerStatusSuccess
            });
            dispatch(doFetchPartners());
          })
          .catch((err: HttpClientErrorResponse) =>
            Modal.error({
              title: MESSAGES.PartnerStatusTitle,
              content: err.message ? err.message : MESSAGES.UnknownError
            })
          )
          .finally(() => setLoading(false));
      }}
    />
  );
};
