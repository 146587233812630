export enum NavigationDrawerOptionKey {
  ADMIN_NOTIFICATIONS = "admin:notifications",
  ADMIN_PARTNERS = "admin:partners",
  ADMIN_TAGS = "admin:tags",
  ADMIN_TENANTS = "admin:tenants",
  ADMIN_USERS = "admin:users",
  DASHBOARD = "dashboard",
  NOTIFICATIONS = "consumer:notifications",
  PROFILE = "profile",
  STUDENTS = "students"
}
