import { RingProgress } from "@ant-design/charts";
import { Button, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AltID, sectionColorByAltId, sectionNameByAltId } from "../../../../../constants/courses";
import { MESSAGES } from "../../../../../i18n";
import { doSetFilter } from "../../../../../redux/actions/students-list";
import { getCurrentWorkingFilter, getTenantDashboardStats } from "../../../../../redux/selectors/dashboard/tenant";
import { routes } from "../../../../../routes";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  altId?: AltID;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentsActivityChartBySection = ({ altId }: OwnProps): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stats = useSelector(getTenantDashboardStats);
  const filter = useSelector(getCurrentWorkingFilter);

  const label = altId ? sectionNameByAltId[altId] : MESSAGES.NoActivity;
  const color = [altId ? sectionColorByAltId[altId] : "#AAAAAA", "#E8EDF3"];
  const total = stats?.studentsActivity?.activeStudents ?? 0;

  let percent = 0.0;
  let count = 0;
  if (altId) {
    count = altId ? stats?.studentsActivity?.activeStudentsBySection?.[altId] ?? 0 : 0;
    percent = (count! * 1.0) / total!;
  } else {
    count = stats?.studentsActivity?.inactiveStudents ?? 0;
    percent = (count! * 1.0) / total!;
  }

  return (
    <div className="flex flex-col pr-2 grow">
      <div className="flex flex-col items-center">
        <Button
          type="link"
          className="m-0 p-0"
          style={{
            width: 80,
            height: 80
          }}
          onClick={() => {
            dispatch(doSetFilter({ ...filter, license: "active", currentActivity: altId ?? "no_activity" }));
            navigate(routes.students.resolve());
          }}
        >
          <RingProgress
            width={80}
            height={80}
            autoFit={false}
            percent={percent}
            color={color}
            style={{ pointerEvents: "none" }}
            statistic={{
              content: {
                customHtml: (container, view) =>
                  `<span data-qa-label="${altId?.toLocaleLowerCase() ?? "no"}-activity-count">${count}</span>`,
                style: {
                  fontSize: "15.75pt",
                  color: "#A1AEB7"
                }
              }
            }}
          />
        </Button>
      </div>
      <Typography.Text
        className={_.join(
          [
            "p-2",
            "text-sm",
            "text-center",
            "text-slate-600" //
          ],
          " "
        )}
      >
        {label}
      </Typography.Text>
    </div>
  );
};
