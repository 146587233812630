import { createSelector } from "reselect";
import { Role } from "../../../services/nav-api/roles";
import { RdxStoreState } from "../../types/state";
import { ActiveUser, getActiveUser } from "../active-user";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getIsGlobalUser: (state: RdxStoreState) => boolean | undefined = createSelector(
  getActiveUser,
  ({ myself }: RdxStoreState) => myself.userRoles,
  (user: ActiveUser, roles: Role[]): boolean => {
    if (!user || !roles) {
      return false;
    }
    const role: Role = roles.filter(r => r.id === user.roleId)[0];
    return role.isGlobal === true;
  }
);
