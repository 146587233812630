import _ from "lodash";
import { createSelector } from "reselect";
import { initialState } from "../../reducers/dashboard/tenant/constants";
import {
  RdxExamTracker,
  RdxLicenses,
  RdxStats,
  RdxStudentsActivity,
  RdxStudyPlanPerformance,
  RdxStudyPlanUsage,
  RdxTenantDashboardState
} from "../../reducers/dashboard/tenant/model";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "../../types/status";

export const shouldFetch: (state: RdxStoreState) => boolean = createSelector(
  ({ tenantDashboard }: RdxStoreState) => tenantDashboard,
  ({ status }: RdxTenantDashboardState): boolean => !_.isEqual(status, RdxFetchStatus.LOADING)
);

export const isFetching: (state: RdxStoreState) => boolean = createSelector(
  ({ tenantDashboard }: RdxStoreState) => tenantDashboard,
  ({ status }: RdxTenantDashboardState): boolean => _.isEqual(status, RdxFetchStatus.LOADING)
);

export const isFetchCompleted: (state: RdxStoreState) => boolean = createSelector(
  ({ tenantDashboard }: RdxStoreState) => tenantDashboard,
  ({ status }: RdxTenantDashboardState): boolean => _.isEqual(status, RdxFetchStatus.COMPLETED)
);

export const getTenantDashboardStats: (state: RdxStoreState) => RdxStats = createSelector(
  ({ tenantDashboard }: RdxStoreState) => tenantDashboard,
  ({ stats }: RdxTenantDashboardState): RdxStats => stats!
);

export const getStudentsActivityStats: (state: RdxStoreState) => RdxStudentsActivity = createSelector(
  ({ tenantDashboard }: RdxStoreState) => tenantDashboard,
  ({ stats }: RdxTenantDashboardState): RdxStudentsActivity => stats?.studentsActivity!
);

export const getExamTrackerStats: (state: RdxStoreState) => RdxExamTracker = createSelector(
  ({ tenantDashboard }: RdxStoreState) => tenantDashboard,
  ({ stats }: RdxTenantDashboardState): RdxExamTracker => stats?.examTracker!
);

export const getLicensesStats: (state: RdxStoreState) => RdxLicenses = createSelector(
  ({ tenantDashboard }: RdxStoreState) => tenantDashboard,
  ({ stats }: RdxTenantDashboardState): RdxLicenses => stats?.licensesStats!
);

export const getStudyPlanUsageStats: (state: RdxStoreState) => RdxStudyPlanUsage = createSelector(
  ({ tenantDashboard }: RdxStoreState) => tenantDashboard,
  ({ stats }: RdxTenantDashboardState): RdxStudyPlanUsage => stats?.studyPlanUsageStats!
);

export const getStudyPlanPerformanceStats: (state: RdxStoreState) => RdxStudyPlanPerformance = createSelector(
  ({ tenantDashboard }: RdxStoreState) => tenantDashboard,
  ({ stats }: RdxTenantDashboardState): RdxStudyPlanPerformance => stats?.studyPlanPerformanceStats!
);

export const getStudentsWithouStudyTime: (state: RdxStoreState) => number = createSelector(
  ({ tenantDashboard }: RdxStoreState) => tenantDashboard,
  ({ stats }: RdxTenantDashboardState): number => stats?.studentsWithoutStudyTime!
);

export const getFollowedStudents: (state: RdxStoreState) => number = createSelector(
  ({ tenantDashboard }: RdxStoreState) => tenantDashboard,
  ({ stats }: RdxTenantDashboardState): number => stats?.followedStudents!
);

export const getCurrentWorkingFilter = createSelector(
  ({ tenantDashboard }: RdxStoreState) => tenantDashboard,
  ({ workingFilter }: RdxTenantDashboardState) => workingFilter
);

export const getCurrentActiveFilter = createSelector(
  ({ tenantDashboard }: RdxStoreState) => tenantDashboard,
  ({ activeFilter }: RdxTenantDashboardState) => activeFilter
);

export const getFilterFlags = createSelector(
  getCurrentWorkingFilter,
  getCurrentActiveFilter,
  (workingFilter, activeFilter) => ({
    canApplyFilter: !_.isEqual(workingFilter, activeFilter),
    canResetFilter: !_.isEqual(activeFilter, initialState.activeFilter)
  })
);
