import { Button, Checkbox, Form, Modal } from "antd";
import sortBy from "lodash/sortBy";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useEditStudentRequest } from "../../../hooks/use-edit-student-request";
import { MESSAGES } from "../../../i18n";
import { getStudentEditData } from "../../../redux/selectors/student-tags";
import { StudentEditTags } from "./tags";

// -------------------------------------------------------------------------------------------------
// - OwnProps
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  onClose: () => void;
  studentId: number;
  tenantId: number;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentEditModal = ({ onClose, tenantId, studentId }: OwnProps) => {
  const { assignedTagIds = [], excluded: initialExcluded, tree } = useSelector(getStudentEditData);
  const { loading, onFormSubmit } = useEditStudentRequest(tenantId, studentId, tree ? tree.data : []);
  const [checkedIds, setCheckedIds] = useState(assignedTagIds);
  const [excluded, setExcluded] = useState(Boolean(initialExcluded));
  useEffect(() => {
    setCheckedIds(assignedTagIds);
  }, [assignedTagIds]);

  const isFormDirty = () =>
    excluded !== Boolean(initialExcluded) || sortBy([...assignedTagIds]).join("") !== sortBy([...checkedIds]).join("");

  return (
    <Modal title={MESSAGES.EditStudentTitle} open onCancel={onClose} footer={null}>
      <form
        onSubmit={async e => {
          e.preventDefault();
          await onFormSubmit(checkedIds, excluded);
          onClose();
        }}
      >
        <Form.Item label="Visibility">
          <Checkbox onChange={() => setExcluded(!excluded)} checked={excluded}>
            Hidden
          </Checkbox>
        </Form.Item>

        <StudentEditTags
          tree={tree && tree.data ? tree.data : []}
          checked={checkedIds}
          loading={Boolean(tree) && tree!.loading}
          onChange={({ checked }) => setCheckedIds(checked)}
        />

        <ActionsWrapper>
          <Button htmlType="button" onClick={onClose}>
            {MESSAGES.CancelLabel}
          </Button>
          <Button type="primary" htmlType="submit" disabled={!isFormDirty()} loading={loading}>
            {MESSAGES.SubmitLabel}
          </Button>
        </ActionsWrapper>
      </form>
    </Modal>
  );
};

// -------------------------------------------------------------------------------------------------
// - Styled Components
// -------------------------------------------------------------------------------------------------

const ActionsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;

  > *:first-child {
    margin-right: 20px;
  }
`;
