import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { PostMannuallyAssociatedRequest, PostMannuallyAssociatedErrorResponse } from "./types";

const postMannuallyAssociated = async (
  args: PostMannuallyAssociatedRequest
): Promise<PostMannuallyAssociatedErrorResponse> => {
  const url = `/rest/admin/manuallyAssociatedStudents`;
  return HttpClient.post(url, createHeaders(), args);
};

export { postMannuallyAssociated };
