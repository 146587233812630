import { Action } from "../../types/action";
import { LOGIN_FAILED } from "./actions";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doCompleteLoginWithError = (payload: string): Action<string> => ({
  type: LOGIN_FAILED,
  payload
});
