import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { MESSAGES } from "../../../i18n";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { ThunkAction } from "../../types/action";
import { doCompleteLogin } from "./loginCompletion";
import { doCompleteLoginWithError } from "./loginFailed";
import { doStartLogin } from "./loginStart";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doLogin = (email: string, password: string, keepMeSignedIn: boolean): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, _, { navApi }): Promise<void> => {
    dispatch(doStartLogin());
    try {
      await navApi.session.login(email, password, keepMeSignedIn);
      dispatch(doCompleteLogin());
      window.location.reload();
    } catch (ex) {
      const error: HttpClientErrorResponse = ex;
      dispatch(doCompleteLoginWithError(error.message || MESSAGES.LoginGenericErrorMessage));
    }
  };
};
