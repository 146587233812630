import {
  handleCellText,
  handleDateCell,
  handleStudentExamExamDateCell,
  handleStudentExamExamGradeCell,
  handleStudentExamExpirationDateCell
} from "../../../components/shared/table/table-cell-value";

export const columns = [
  {
    dataIndex: "course",
    key: "course",
    title: "Section",
    render: handleCellText
  },
  {
    dataIndex: "purchaseDate",
    key: "purchaseDate",
    title: "Purchase Date",
    render: handleDateCell
  },
  {
    dataIndex: "expirationDate",
    key: "expirationDate",
    title: "Expiration Date",
    render: handleStudentExamExpirationDateCell
  },
  {
    dataIndex: "examDate",
    key: "examDate",
    title: "Exam Date",
    render: handleStudentExamExamDateCell
  },
  {
    dataIndex: "examGrade",
    key: "examGrade",
    title: "Exam Grade",
    render: handleStudentExamExamGradeCell
  }
];
