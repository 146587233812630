import { createSelector } from "reselect";
import { setOnetrustFlag } from "../actions/core/onetrust";
import { RdxStoreState } from "../types/state";

// -------------------------------------------------------------------------------------------------
// - Reactor
// -------------------------------------------------------------------------------------------------

export const reOnTrustCtl = createSelector(
  (state: RdxStoreState) => state,
  (s: RdxStoreState) => {
    return setOnetrustFlag(/isGpcEnabled=1/gi.test(document.cookie));
  }
);
