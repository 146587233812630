import { DatePicker, Form } from "antd";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React from "react";
import { WorkingFilter } from "../../../redux/actions/users-list";

import dayjs from "dayjs";
dayjs.extend(customParseFormat);

const dateFormat = "MM/DD/YYYY";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const RangeDateFilter = ({
  value,
  onChange,
  label,
  id
}: {
  id: string;
  label: string;
  onChange: (value: WorkingFilter["lastLoginDate"]) => void;
  value: WorkingFilter["lastLoginDate"];
}) => (
  <Form.Item label={label} wrapperCol={{ sm: 24 }}>
    <DatePicker.RangePicker
      id={id}
      className="w-full"
      data-qa-label={id}
      popupClassName={`dp-${id}`}
      format={dateFormat}
      defaultValue={null}
      onChange={dates => {
        onChange({
          startDate: dates?.[0]?.toDate(),
          endDate: dates?.[1]?.toDate()
        });
      }}
      value={
        [
          value.startDate //
            ? dayjs(value.startDate)
            : undefined,

          value.endDate //
            ? dayjs(value.endDate)
            : undefined
        ] as any
      }
    />
  </Form.Item>
);
