import { LoadingOutlined } from "@ant-design/icons";
import { Card } from "antd";
import React, { Suspense } from "react";
import { BaseLayout } from "../../components/layout/base-layout";
import { PageTitle } from "../../components/layout/page-title";
import { NavigationDrawerOptionKey } from "../../components/navigation-drawer/types";
import { UsersTable } from "../../components/users/list";
import { UsersTableActions } from "../../components/users/list/actions";
import { FilterTags } from "./FilterTags";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const UsersList = () => (
  <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_USERS}>
    <PageTitle>Users</PageTitle>
    <UsersTableActions />
    <FilterTags />
    <Card bodyStyle={{ overflowX: "auto" }}>
      <Suspense fallback={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
        <UsersTable />
      </Suspense>
    </Card>
  </BaseLayout>
);

export default UsersList;
