import { createBrowserHistory } from "history";
import _ from "lodash";
import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { AlertsToasts } from "../components/alerts-toasts";
import { FullScreenLoader } from "../components/loader/full-screen-loader";
import AlertsReader from "../pages/AlertsReader";
import AlertsWriter from "../pages/AlertsWriter";
import Dashboard from "../pages/Dashboard";
import Eula from "../pages/Eula";
import ForgotPassword from "../pages/ForgotPassword";
import Login from "../pages/Login";
import { Partners } from "../pages/Partners";
import Profile from "../pages/Profile";
import SetPassword from "../pages/SetPassword";
import { Students } from "../pages/Students/Students";
import StudentsAdd from "../pages/StudentsAdd";
import Tags from "../pages/Tags";
import { TenantDetails } from "../pages/Tenant";
import { TenantEdit } from "../pages/TenantEdit";
import { TenantNew } from "../pages/TenantNew";
import { Tenants } from "../pages/Tenants";
import UserDetails from "../pages/UserDetails";
import UsersList from "../pages/UsersList";
import UsersNew from "../pages/UsersNew";
import { doFetchMySelf } from "../redux/actions/myself";
import { getHasAcceptedEula } from "../redux/selectors/accepted-eula";
import { RxAppRoutesStatus, getAppRoutesStatus } from "../redux/selectors/app-routes-status";
import { getHasPartnersPermission } from "../redux/selectors/permissions/has-manage-partners";
import { getHasTagsPermission } from "../redux/selectors/permissions/has-manage-tags";
import { getHasTenantsPermission } from "../redux/selectors/permissions/has-manage-tenants";
import { getHasUsersPermission } from "../redux/selectors/permissions/has-manage-users";
import { getIsSystemAdmin } from "../redux/selectors/permissions/is-system-admin";
import { routes } from "../routes";

// ----------------------------------------------------------------
// - Create History
// ----------------------------------------------------------------

const appHistory = createBrowserHistory({ window });

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Router = () => {
  const dispatch = useDispatch();
  const routeStatus = useSelector(getAppRoutesStatus);
  const hasTenantsPermission = useSelector(getHasTenantsPermission);
  const hasPartnersPermission = useSelector(getHasPartnersPermission);
  const hasTagsPermission = useSelector(getHasTagsPermission);
  const hasUsersPermission = useSelector(getHasUsersPermission);
  const hasAcceptedEula = useSelector(getHasAcceptedEula);
  const isSystemAdmin = useSelector(getIsSystemAdmin);

  React.useEffect(() => {
    dispatch(doFetchMySelf());
  }, []);

  return (
    <React.StrictMode>
      <Suspense fallback={<FullScreenLoader />}>
        <HistoryRouter history={appHistory as any}>
          {_.isEqual(RxAppRoutesStatus.LOADING, routeStatus) ? null : _.isEqual(
              RxAppRoutesStatus.UNAUTHENTICATED,
              routeStatus
            ) ? (
            <Routes>
              <Route path={routes.login.path} element={<Login />} />
              <Route path={routes.forgotPassword.path} element={<ForgotPassword />} />
              <Route path={routes.setPassword.path} element={<SetPassword />} />
              <Route path="*" element={<Navigate to={routes.login.path} replace />} />
            </Routes>
          ) : _.isEqual(RxAppRoutesStatus.APP_READY, routeStatus) && !hasAcceptedEula ? (
            <Routes>
              <Route path={routes.eula.path} element={<Eula />} />
              <Route path="*" element={<Navigate to={routes.eula.path} replace />} />
            </Routes>
          ) : _.isEqual(RxAppRoutesStatus.APP_READY, routeStatus) && hasAcceptedEula ? (
            <>
              <AlertsToasts />
              <Routes>
                <Route path={routes.students.path} element={<Students />} />
                <Route path={routes.dashboard.path} element={<Dashboard />} />
                <Route path={routes.studentsAdd.path} element={<StudentsAdd />} />
                <Route path={routes.studentDetails.path} element={<Students showStudentDetails />} />
                <Route path={routes.tenantUsers.path} element={<UsersList />} />
                <Route path={routes.profile.path} element={<Profile />} />

                {hasUsersPermission
                  ? [
                      <Route key="user-1" path={routes.users.path} element={<UsersList />} />,
                      <Route key="user-2" path={routes.usersNew.path} element={<UsersNew />} />,
                      <Route key="user-3" path={routes.userDetails.path} element={<UserDetails />} />
                    ]
                  : null}

                {hasTagsPermission ? ( //
                  <Route path={routes.tags.path} element={<Tags />} />
                ) : null}

                {hasTenantsPermission
                  ? [
                      <Route key="tenant-p" path={routes.tenants.path} element={<Tenants />} />,
                      <Route key="tenant-s" path={routes.tenant.path} element={<TenantDetails />} />,
                      <Route key="tenant-n" path={routes.tenantNew.path} element={<TenantNew />} />,
                      <Route key="tenant-e" path={routes.tenantEdit.path} element={<TenantEdit />} />
                    ]
                  : null}

                {hasPartnersPermission ? ( // Parters
                  <Route path={routes.partners.path} element={<Partners />} />
                ) : null}

                {isSystemAdmin ? ( // Alerts Writer
                  <Route path={routes.alertsWriter.path} element={<AlertsWriter />} />
                ) : null}

                {_.map(routes.alertsReader.paths, path => (
                  <Route key={path} path={path} element={<AlertsReader />} />
                ))}

                <Route path="*" element={<Navigate to={routes.dashboard.path} replace />} />
              </Routes>
            </>
          ) : null}
        </HistoryRouter>
      </Suspense>
    </React.StrictMode>
  );
};
