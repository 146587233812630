import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { StudentFullDetails } from "./types";

const getStudentDetails = async (studentId: number): Promise<StudentFullDetails> => {
  const url = `/rest/students/${studentId}/details`;
  return HttpClient.get(url, createHeaders());
};

const editStudent = async (tenantId: number, studentId: number, tagIds: number[], excluded: boolean): Promise<void> => {
  const url = `/rest/students/${studentId}/tenants/${tenantId}`;
  return HttpClient.put(url, createHeaders(), {
    tagIds,
    excluded
  });
};

const putStudentFollowed = async (studentId: number): Promise<void> => {
  const url = `/rest/students/${studentId}/details/followed`;
  return HttpClient.put(url, createHeaders());
};

const deleteStudentFollowed = async (studentId: number): Promise<void> => {
  const url = `/rest/students/${studentId}/details/followed`;
  return HttpClient.del(url, createHeaders());
};

export { getStudentDetails, editStudent, putStudentFollowed, deleteStudentFollowed };
