import { StarFilled, StarOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { MESSAGES } from "../../../i18n";
import { doUpdateStudentFollowed } from "../../../redux/actions/students-list";
import { getIsCurStudentFollowed } from "../../../redux/selectors/student-followed";

type Props = {} & React.HTMLAttributes<HTMLElement>;

export const FollowStudent: React.FC<Props> = props => {
  const { followed, loading, curStudentId } = useSelector(getIsCurStudentFollowed);
  const dispatch = useDispatch();
  const handleFollowClick = () => {
    dispatch(doUpdateStudentFollowed(curStudentId!, !followed));
  };
  return (
    <Button {...props} onClick={handleFollowClick} disabled={loading}>
      {followed ? MESSAGES.UnfollowThisStudent : MESSAGES.FollowThisStudent}
      {followed ? <StarFilled /> : <StarOutlined />}
    </Button>
  );
};

const Button = styled.button<{ disabled?: boolean }>`
  border: none;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  transition: opacity 0.25s ease-in-out;
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  opacity: ${props => (props.disabled ? "0.25!important" : 1)};

  &:hover {
    opacity: 0.5;
  }

  > * {
    width: 1rem;
    margin-left: 0.5rem;
    height: 1rem;
  }
`;
