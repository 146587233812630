import * as HttpClient from "../http-client";
import { createHeaders } from "./headers";

export type PostUserInviteParams = {
  email: string;
  firstName?: string;
  lastName?: string;
  roleId: number;
  tagIds?: number[];
  tenantId?: number | null;
};

export type PostUserInviteResponse = {
  userId: number;
};

export type GetUserInviteParams = {
  token: string;
};

const postUserInvite = async (params: PostUserInviteParams): Promise<PostUserInviteResponse> => {
  return HttpClient.post(`/rest/admin/users`, createHeaders(), params);
};

export { postUserInvite };
