import _ from "lodash";
import { createSelector } from "reselect";
import { eulaVersion } from "../../constants/eula";
import { UserSettings } from "../../services/nav-api/myself/types";
import { RdxStoreState } from "../types/state";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getHasAcceptedEula: (state: RdxStoreState) => boolean = createSelector(
  ({ myself }: RdxStoreState) => myself.userSettings,
  (userSettings: UserSettings): boolean => _.isEqual(userSettings?.acceptedEulaVersion, eulaVersion.checksum)
);
