import { navApi } from "../../../../../../services/nav-api";
import { getTaskBlobWhenReady } from "../../../../../../services/task";
import { getCurDateForReportFilename } from "../../../../../../utils/date-utils";
import { ReportParams, ReportResponse } from "../../types";
import { reduceAttempts } from "./utils";

// -------------------------------------------------------------------------------------------------
// - Generator
// -------------------------------------------------------------------------------------------------

export const genSimulatedExamsScoreReport = async (params: ReportParams): Promise<ReportResponse> => ({
  filename: `simulated-exams-scores-${reduceAttempts(params)}-${getCurDateForReportFilename()}.xlsx`,
  blob: await getTaskBlobWhenReady((await navApi.studentRoster.simulatedExamsScoreReport(params)).taskToken)
});

// -------------------------------------------------------------------------------------------------
// - Generator (For The Final Review)
// -------------------------------------------------------------------------------------------------

export const genSimulatedExamsScoreFrReport = async (params: ReportParams): Promise<ReportResponse> => ({
  filename: `simulated-exams-scores-fr-${reduceAttempts(params)}-${getCurDateForReportFilename()}.xlsx`,
  blob: await getTaskBlobWhenReady((await navApi.studentRoster.frSimulatedExamsScoreReport(params)).taskToken)
});
