import React from "react";
import { defaultReportType } from "../settings";
import { ReportType } from "../types";

// -------------------------------------------------------------------------------------------------
// - Hooks: useReports
// -------------------------------------------------------------------------------------------------

export const useReportType = () => {
  const [reportType, setReportType] = React.useState<ReportType>(defaultReportType);
  return {
    value: reportType!,
    setValue: setReportType,
    reset: () => setReportType(defaultReportType)
  };
};
