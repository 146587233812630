import { Route } from "./types";

export class ParamlessRoute implements Route {
  public path: string;
  constructor(path: string) {
    this.path = path;
  }
  resolve() {
    return this.path;
  }
}
