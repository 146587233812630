import { Alert, Card, Col, Row } from "antd";
import React from "react";
import { BaseLayout } from "../../components/layout/base-layout";
import { PageTitle } from "../../components/layout/page-title";
import { NavigationDrawerOptionKey } from "../../components/navigation-drawer/types";
import { TagsEditor } from "../../components/tenants/tags-editor";
import { MESSAGES } from "../../i18n";

const Tags = () => (
  <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_TAGS}>
    <PageTitle>Tags</PageTitle>
    <Row gutter={16}>
      <Col xs={12}>
        <Card style={{ marginBottom: "50px" }}>
          <Alert
            message={
              <>
                {MESSAGES.RootLevelTagsInfo}
                <br />
                {MESSAGES.RootLevelTagsInfo2}
              </>
            }
            type="info"
            style={{ marginBottom: "20px" }}
          />
          <TagsEditor />
        </Card>
      </Col>
    </Row>
  </BaseLayout>
);

export default Tags;
