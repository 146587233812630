import * as alertsReader from "./alerts-reader";
import * as alertsWriter from "./alerts-writer";
import * as globalCounters from "./global-counters";
import * as hierarchyContent from "./hierarchy-content";
import * as myself from "./myself";
import * as partners from "./partners";
import * as roles from "./roles";
import * as session from "./session";
import * as studentDetails from "./student-details";
import * as studentRoster from "./student-roster";
import * as studentsMannualyAssociated from "./students-mannually-associated";
import * as tasks from "./tasks";
import * as tenantDashboard from "./tenant-dashboard";
import * as tenants from "./tenants";
import * as unauth from "./unauth";
import * as userInvites from "./user-invites";
import * as users from "./users";
import * as webSockets from "./web-sockets";

export const navApi = {
  alertsReader,
  alertsWriter,
  globalCounters,
  hierarchyContent,
  myself,
  partners,
  roles,
  session,
  studentDetails,
  studentRoster,
  studentsMannualyAssociated,
  tasks,
  tenantDashboard,
  tenants,
  unauth,
  userInvites,
  users,
  webSockets
};
