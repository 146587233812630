import { Badge } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getNewAlertsCount } from "../../../redux/selectors/alerts-reader/alerts";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  qaLabel?: string;
  children: React.ReactNode | React.ReactNode[];
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertsBadge = ({ qaLabel, children }: OwnProps) => {
  const count = useSelector(getNewAlertsCount);
  return (
    <StyledBadge
      count={count}
      overflowCount={100}
      data-qa-label={qaLabel}
      offset={[0, 10]} //
    >
      {children}
    </StyledBadge>
  );
};

// -------------------------------------------------------------------------------------------------
// - Styled Components
// -------------------------------------------------------------------------------------------------

const StyledBadge = styled(Badge)`
  display: flex;
  width: fit-content;
  max-width: calc(100% - 20px);
  height: 40px;
  align-items: center;
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.8);

  text-decoration: none;
  -webkit-transition: color 1s; /* For Safari 3.0 to 6.0 */
  transition: color 0.5s;
  &:hover {
    color: #1890ff;
  }
`;
