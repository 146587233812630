import { createSelector } from "reselect";

import { RdxStoreState } from "../types/state";
import { getIsPartnersListLoading } from "./is-partners-list-loading";
import { getIsPartnersListFilterEqualsToAppliedFilter } from "./is-partners-list-filter-equals-to-active";

export const getIsApplyPartnersListFilterDisabled: (state: RdxStoreState) => boolean = createSelector(
  getIsPartnersListLoading,
  getIsPartnersListFilterEqualsToAppliedFilter,
  (loading: boolean, equals: boolean): boolean => {
    return equals || loading;
  }
);
