import { Tag } from "antd";
import React from "react";
import { EMPTY_CELL_SYMBOL } from "../../../components/shared/table/table-cell-value";

interface OwnProps {
  tags?: string[];
}

export const StudentInfoTags = ({ tags }: OwnProps) => {
  if (!tags || !tags.length) {
    return <>{EMPTY_CELL_SYMBOL}</>;
  }

  return (
    <>
      {tags.map((value, idx) => (
        <Tag key={idx} color="blue" style={{ margin: "5px" }}>
          {value}
        </Tag>
      ))}
    </>
  );
};
