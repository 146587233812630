import React from "react";
import { MESSAGES } from "../../../i18n";
import menuItemCookiePolicy from "./mi-account-cookie-policy";
import menuItemCookiePref from "./mi-account-cookie-pref";
import menuItemPrivacyPolicy from "./mi-account-privacy-policy";

const key = "privacy-group";
const qaLabel = "privacyBtn";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default (isCookiePrefVisible: boolean) => [
  {
    key,
    label: <span data-qa-label={qaLabel}>{MESSAGES.NavPrivacy}</span>,
    children: [
      //
      ...menuItemPrivacyPolicy,
      ...menuItemCookiePolicy,
      ...menuItemCookiePref(isCookiePrefVisible)
    ]
  }
];
