import { Col, Row, Typography } from "antd";
import React from "react";
import { MESSAGES } from "../../../../i18n";
import { ActiveStudents } from "./components/ActiveStudents";
import { StudentsActivityBySection } from "./components/StudentsActivityBySection";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentsActivity = () => (
  <div>
    <Typography.Title level={5}>{MESSAGES.StudentsActivity}</Typography.Title>
    <Row gutter={[16, 16]}>
      <Col xs={24} md={8} lg={6}>
        <ActiveStudents />
      </Col>
      <Col xs={24} md={16} lg={18} xxl={14}>
        <StudentsActivityBySection />
      </Col>
    </Row>
  </div>
);
