import { Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { doUpdateWorkingFilter } from "../../../redux/actions/students-list";
import { RdxStoreState } from "../../../redux/types/state";
import { RangeDateFilter } from "../shared/range-date-filter";
import { CurrentActivityFilter } from "./components/CurrentActivityFilter";
import { ExamTrackerFilter } from "./components/ExamTrackerFilter/index";
import { FormActions } from "./components/FormActions";
import { LicenseFilter } from "./components/LicenseFilter";
import { OtherFilters } from "./components/OtherFilters";
import { StudyPlanFilter } from "./components/StudyPlanFilter";
import { StudyTimeFilter } from "./components/StudyTimeFilter";
import { TagsFilter } from "./components/TagsFilter";
import { TenantFilter } from "./components/TenantFilter";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentsFilter = () => {
  const dispatch = useDispatch();
  const workingFilter = useSelector((state: RdxStoreState) => state.studentsList.workingFilter);

  return (
    <Form layout="vertical" className="flex flex-col h-full">
      <TenantFilter />
      <TagsFilter />
      <StudyTimeFilter />
      <LicenseFilter />
      <RangeDateFilter
        label="Purchased Date"
        id="purchased-date-filter"
        value={workingFilter.purchasedDate}
        onChange={purchasedDate => {
          dispatch(doUpdateWorkingFilter({ purchasedDate }));
        }}
      />
      <StudyPlanFilter />
      <CurrentActivityFilter />
      <ExamTrackerFilter />
      <OtherFilters />
      <FormActions />
    </Form>
  );
};
