import { createSelector } from "reselect";
import { createTagsTree } from "../../utils/tree-utils/create-tree";
import { RdxTenantsState } from "../reducers/tenants";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";
import { getMyTenantTagsTree } from "./my-tenant-tags-tree";
import { getStudentFilterTenantId } from "./student-filter-tenant-id";
import { getStudentFilterTenantTags } from "./student-filter-tenant-tags";

export interface TagNode {
  id: number;
  name: string;
  enrolledStudentCount: number;
  children: TagNode[];
  parentId?: number;
}

export const getStudentFilterTagsTree: (state: RdxStoreState) => { tree: TagNode[] | undefined; loading: boolean } =
  createSelector(
    getMyTenantTagsTree,
    getStudentFilterTenantId,
    (state: RdxStoreState) => state.tenants,
    getStudentFilterTenantTags,
    (myTenantTagsTree, tenantId, { tenantTags }: RdxTenantsState, { byId, ids }) => {
      if (myTenantTagsTree && myTenantTagsTree.tree) {
        return myTenantTagsTree;
      }

      if (!tenantId || !byId || !ids) {
        return { tree: undefined, loading: false };
      }

      const tree = createTagsTree<TagNode>(byId, ids);
      const status = tenantTags!.statusByTenantId[tenantId];

      return {
        tree,
        loading: Boolean(!status || status === RdxFetchStatus.LOADING)
      };
    }
  );
