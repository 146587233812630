import { ProductConfig } from "./types";

export const configCMA: () => ProductConfig = () => ({
  numberOfSections: 2,
  examTrackerMinExamGrade: 360,
  performanceDashboard: {
    simulations: false,
    essays: true
  },
  hasStudyPlan: false
});
