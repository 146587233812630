import React from "react";
import { MESSAGES } from "../../../i18n";
import { AppMenuContext } from "./context";

const key = "logout";
const qaLabel = "menu-logout";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default [
  {
    key,
    label: (
      <AppMenuContext.Consumer>
        {({ setLogoutModalVisible }) => (
          <a
            data-qa-label={qaLabel}
            onClick={() => {
              setLogoutModalVisible(true);
            }}
          >
            {MESSAGES.NavLogout}
          </a>
        )}
      </AppMenuContext.Consumer>
    )
  }
];
