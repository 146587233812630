export const FETCH_MYSELF_STARTED = "FETCH_MYSELF_STARTED";
export const FETCH_MYSELF_COMPLETED = "FETCH_MYSELF_COMPLETED";
export const FETCH_MYSELF_FAILED = "FETCH_MYSELF_FAILED";
export const UPDATE_ACTIVE_USER = "UPDATE_ACTIVE_USER";
export const UPDATE_PUSHED_ALERTS = "UPDATE_PUSHED_ALERTS";

export const UPDATE_EULA_ACCEPTED_STARTED = "UPDATE_EULA_ACCEPTED_STARTED";
export const UPDATE_EULA_ACCEPTED_COMPLETED = "UPDATE_EULA_ACCEPTED_COMPLETED";
export const UPDATE_ACTIVE_PRODUCT = "UPDATE_ACTIVE_PRODUCT";
export const UPDATE_CONTENT_VERSION = "UPDATE_CONTENT_VERSION";
