export enum ApiAlertStatus {
  SCHEDULED = "Scheduled",
  PUBLISHED = "Published",
  EXPIRED = "Expired"
}

export interface Alert {
  id: number;
  title: string;
  body: string;
  publishDate: string;
  expirationDate: string;
  status?: ApiAlertStatus;
  sendEmail?: boolean;
}

export interface GetAlertResponse {
  alerts: Alert[];
  total: number;
}
