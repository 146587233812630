import { Alert } from "../../../services/nav-api/alerts-writer/model";
import { RdxFetchStatus } from "../../types/status";

export interface AlertsPagination {
  page: number;
  totalItems?: number;
  itemsPerPage: number;
}

export enum OperationType {
  DELETE = "DELETE",
  EDIT = "EDIT",
  EXPIRE = "EXPIRE"
}

export interface AlertsOperation {
  type: OperationType;
  alert: Alert;
  error?: string;
  status?: RdxFetchStatus;
}

export enum AlertStatus {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  ALL = "ALL"
}

export interface AlertsFilters {
  status: AlertStatus;
}

export interface RdxAlertsWriterState {
  status: RdxFetchStatus;
  alerts: Alert[];
  paging: AlertsPagination;
  filters: AlertsFilters;
  activeFilters: AlertsFilters;
  operation?: AlertsOperation;
  error?: string;
}

export type RdxFetchFailedPayload = Omit<RdxAlertsWriterState, "status" | "alerts" | "pagination">;
export type RdxPaginationPayload = Omit<RdxAlertsWriterState, "status" | "alerts" | "error">;
export type RdxFetchCompletionPayload = {
  alerts: Alert[];
  total: number;
};
