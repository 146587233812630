import * as HttpClient from "../http-client";
import { createHeaders } from "./headers";

export const TenantUserName = "Tenant User";

export type Role = {
  id: number;
  name: string;
  permissions: string[];
  role: boolean;
  isGlobal: boolean;
};

export type GetRolesResponse = {
  roles: Role[];
};

const getRoles = async (): Promise<GetRolesResponse> => {
  return HttpClient.get("/rest/admin/roles", createHeaders());
};

export { getRoles };
