import styled from "styled-components";
import { Loader } from ".";

export const FullScreenLoader = styled(Loader).attrs({
  fontSize: 72
})`
  position: absolute;
  top: calc(50% - 2.25rem);
  left: calc(50% - 2.25rem);
`;
