import { createSelector } from "reselect";
import moment from "moment";
import { RdxStoreState } from "../types/state";
import { getStudentInfo } from "./student-info";

export const getIsEnrolledAfterDate: (state: RdxStoreState) => boolean = createSelector(
  getStudentInfo,
  ({ info }): boolean => {
    if (!info) {
      return true;
    }
    return moment(info.licensePurchaseDate).isAfter(moment("FEB-18-2019", "MMM-DD-YYYY"));
  }
);
