import { Button } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { doApplyFilter, doResetFilter } from "../../../../redux/actions/students-list";
import { getIsApplyStudentsListFilterDisabled } from "../../../../redux/selectors/is-apply-students-list-filter-disabled";
import { getIsClearStudentsListFilterDisabled } from "../../../../redux/selectors/is-clear-students-list-filter-disabled";
import { RdxStoreState } from "../../../../redux/types/state";
import { StudentTableActionsContext } from "../../students-table/table-actions-context";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const FormActions = () => {
  const dispatch = useDispatch();
  const applyFilterDisabled = useSelector(getIsApplyStudentsListFilterDisabled);
  const clearFilterDisabled = useSelector(getIsClearStudentsListFilterDisabled);
  const workingFilter = useSelector((state: RdxStoreState) => state.studentsList.workingFilter);
  const applyButtonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (applyButtonRef.current) {
      applyButtonRef.current.focus();
    }
  }, [workingFilter]);

  return (
    <div className="flex flex-row justify-center pt-[0.5rem] gap-x-2">
      <StudentTableActionsContext.Consumer>
        {({ onSelectionCancel, onShowFiltersChange }) => (
          <>
            <Button
              disabled={clearFilterDisabled}
              htmlType="button"
              onClick={() => {
                dispatch(doResetFilter());
                onShowFiltersChange(false);
              }}
            >
              Reset
            </Button>
            <Button
              ref={applyButtonRef}
              htmlType="submit"
              type="primary"
              onClick={() => {
                onSelectionCancel();
                onShowFiltersChange(false);
                dispatch(doApplyFilter());
              }}
              disabled={applyFilterDisabled}
            >
              Apply
            </Button>
          </>
        )}
      </StudentTableActionsContext.Consumer>
    </div>
  );
};
