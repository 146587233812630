import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { TenantProducts } from "../../../services/nav-api/tenants";
import { ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doChangeActiveProduct = (activeProduct: TenantProducts): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    try {
      const settings = { ...getState().myself.userSettings, activeProduct };
      await navApi.myself.updateMySelfSettings(settings);
    } catch (error) {
      console.error(error);
    } finally {
      window.location.reload();
    }
  };
};
