import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { Status } from "../types";
import { getUrlWithEncodedParams } from "../../http-client/utils";
import { WorkingFilterType, WorkingFilterTypeWithoutAll } from "../../../redux/actions/partners";

export const TenantProducts = ["cma", "cpa"] as const;
export type TenantProducts = (typeof TenantProducts)[number];

export type Tenant = {
  id: number;
  name: string;
  status: Status;
  created: number;
  products: TenantProducts[];
  type: WorkingFilterTypeWithoutAll;
};

export type GetTenantsResponse = {
  tenants: Tenant[];
  total: number;
};

export type GetTenantsRequest = {
  status?: "Enabled" | "Disabled";
  filter?: string;
  limit?: number;
  offset?: number;
  type?: WorkingFilterType;
};

export type CreateTenantsReportRequest = {
  status?: "Enabled" | "Disabled";
  filter?: string;
  type?: WorkingFilterType;
};

export type PostTenantsResponse = {
  tenantId: number;
};

export type GetTenantStudentEntryTagsResponse = {
  activeRootTags: Array<{
    id: number;
    name: string;
    minSelections: number;
    maxSelections: number;
  }>;
  availableRootTags: Array<{
    id: number;
    name: string;
  }>;
  mode: "off" | "on" | "staffOnly";
};

export type PutTenantStudentEntryTagsRequest = {
  activeRootTags: Array<{
    id: number;
    minSelections: number;
    maxSelections: number;
  }>;
  mode: GetTenantStudentEntryTagsResponse["mode"];
};

const getTenants = async (args?: GetTenantsRequest): Promise<GetTenantsResponse> => {
  const url = `/rest/admin/tenants`;
  return HttpClient.get(url, createHeaders(), args);
};

const postTenant = async (
  name: string,
  type: WorkingFilterTypeWithoutAll,
  products: TenantProducts[]
): Promise<PostTenantsResponse> => {
  const url = `/rest/admin/tenants`;
  return HttpClient.post(url, createHeaders(), { name, type, products });
};

const getTenant = async (id: number): Promise<Tenant> => {
  const url = `/rest/admin/tenants/${id}`;
  return HttpClient.get(url, createHeaders());
};

const editTenant = async (
  id: number,
  name: string,
  status: Status,
  products: TenantProducts[],
  type: WorkingFilterType
): Promise<void> => {
  const url = `/rest/admin/tenants/${id}`;
  return HttpClient.put(url, createHeaders(), { name, status, products, type });
};

const getTenantStudentEntryTags = async (id: number): Promise<GetTenantStudentEntryTagsResponse> => {
  const url = `/rest/admin/tenants/${id}/studentEntryTags`;
  return HttpClient.get(url, createHeaders());
};

const putTenantStudentEntryTags = async (id: number, request: PutTenantStudentEntryTagsRequest): Promise<void> => {
  const url = `/rest/admin/tenants/${id}/studentEntryTags`;
  return HttpClient.put(url, createHeaders(), request);
};

const createTenantsReport = async (request: CreateTenantsReportRequest): Promise<{ taskToken: string }> => {
  const url = `/rest/admin/tenants/report`;
  return HttpClient.post(getUrlWithEncodedParams(url, request), createHeaders(), request);
};

export {
  getTenants,
  postTenant,
  getTenant,
  editTenant,
  getTenantStudentEntryTags,
  putTenantStudentEntryTags,
  createTenantsReport
};
