import { LOGOUT_COMPLETED } from "./actions";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doCompleteLogout = ({ error }: { error?: string } = {}) =>
  error
    ? {
        type: LOGOUT_COMPLETED,
        payload: { error }
      }
    : { type: LOGOUT_COMPLETED };
