import isEqual from "lodash/isEqual";
import { createSelector } from "reselect";

import { RdxStoreState } from "../types/state";
import { RdxTenantsSubState } from "../reducers/tenants/tenants";

export const getIsTenantsListFilterEqualsToAppliedFilter: (state: RdxStoreState) => boolean = createSelector(
  (state: RdxStoreState) => state.tenants.tenants,
  ({ workingFilter, activeFilter }: RdxTenantsSubState): boolean => {
    if (!activeFilter) {
      return false;
    }
    return isEqual(activeFilter.filter, workingFilter);
  }
);
