import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RdxGlobalDashboardState } from "../../../reducers/dashboard/global/model";
import { shouldFetch } from "../../../selectors/dashboard/global";
import { Action, ThunkAction } from "../../../types/action";
import { RdxStoreState } from "../../../types/state";
import { GLOBAL_DASHBOARD_FETCH_COMPLETED, GLOBAL_DASHBOARD_FETCH_STARTED } from "./actions";
import { doSelectPartner } from "./selectPartner";

// -------------------------------------------------------------------------------------------------
// - Action Builders
// -------------------------------------------------------------------------------------------------

export const doFetchStarted = (): Action<number> => ({
  type: GLOBAL_DASHBOARD_FETCH_STARTED,
  payload: 0
});

// -------------------------------------------------------------------------------------------------
// - Action Builders
// -------------------------------------------------------------------------------------------------

export const doFetchCompleted = (
  payload: Partial<RdxGlobalDashboardState> = {}
): Action<Partial<RdxGlobalDashboardState>> => ({
  type: GLOBAL_DASHBOARD_FETCH_COMPLETED,
  payload
});

// -------------------------------------------------------------------------------------------------
// - Action Builders
// -------------------------------------------------------------------------------------------------

export const doFetchDataAsync =
  (): ThunkAction =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState, { navApi }): Promise<void> => {
    if (!shouldFetch(getState())) {
      return;
    }

    dispatch(doFetchStarted());

    try {
      const stats = await navApi.globalCounters.fetchGlobalCounters();

      if (_.includes(_.keys(stats.topTenantsByType), "Firm")) {
        dispatch(doSelectPartner("Firm"));
      } else {
        dispatch(doSelectPartner(_.head(_.keys(stats.topTenantsByType))!));
      }

      dispatch(
        doFetchCompleted({
          stats
        })
      );
    } catch (e) {
      // nothing to do
    }

    dispatch(doFetchCompleted());
  };
