import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { navApi } from "../../../services/nav-api";
import { ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { ReadStatus } from "./model";

// -------------------------------------------------------------------------------------------------
// - Actions
// -------------------------------------------------------------------------------------------------

export const FORCE_ALERTS_RELOAD = "FORCE_ALERTS_RELOAD";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doChangeAlertReadStatus = (alertId?: number, status?: ReadStatus): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    if (alertId! > 0) {
      try {
        if (_.isEqual(ReadStatus.UNREAD, status)) {
          await navApi.alertsReader.markAsUnRead(alertId!);
        } else {
          await navApi.alertsReader.markAsRead(alertId!);
        }

        dispatch({ type: FORCE_ALERTS_RELOAD });
      } catch (ex) {
        dispatch({ type: FORCE_ALERTS_RELOAD, error: (ex as HttpClientErrorResponse).message || "error" });
      }
    }
  };
};
