import _ from "lodash";
import { createSelector } from "reselect";
import { doFetchHierarchyContent } from "../actions/hierarchy-content";
import { RdxHierarchyContent } from "../reducers/hierarchy-content";
import { ActiveUser, getActiveUser } from "../selectors/active-user";
import { getUserFetchStatus } from "../selectors/user-fetch-status";
import { ThunkAction } from "../types/action";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";

// -------------------------------------------------------------------------------------------------
// - Reactor
// -------------------------------------------------------------------------------------------------

export const reFetchHierarchyContent: (state: RdxStoreState) => ThunkAction | undefined = createSelector(
  ({ hierarchyContent }: RdxStoreState) => hierarchyContent,
  getActiveUser,
  getUserFetchStatus,
  (hContent: RdxHierarchyContent, user: ActiveUser, userFetchStatus: RdxFetchStatus) => {
    if (
      Boolean(user) &&
      _.includes([RdxFetchStatus.NONE, RdxFetchStatus.STALE], hContent.status) &&
      _.isEqual(RdxFetchStatus.COMPLETED, userFetchStatus)
    ) {
      return doFetchHierarchyContent();
    }
  }
);
