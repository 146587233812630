import tagEditor, { RdxTagEditorSubState } from "./tag-editor";
import { Action } from "../../types/action";
import tags, { RdxTagsSubState } from "./tags";
import tenantTags, { RdxTenantTagsSubState } from "./tenant-tags";
import tenants, { RdxTenantsSubState } from "./tenants";
import tenantsDropdown, { RdxTenantsDropdownSubState } from "./tenants-dropdown";

export interface RdxTenantsState {
  tenants?: RdxTenantsSubState;
  tags?: RdxTagsSubState;
  tenantTags?: RdxTenantTagsSubState;
  tagEditor?: RdxTagEditorSubState;
  tenantsDropdown?: RdxTenantsDropdownSubState;
}

export const initialState: RdxTenantsState = {};

export default (state: RdxTenantsState = initialState, action: Action<any>): RdxTenantsState => ({
  tenants: tenants(state.tenants, action),
  tags: tags(state.tags, action),
  tenantTags: tenantTags(state.tenantTags, action),
  tagEditor: tagEditor(state.tagEditor, action),
  tenantsDropdown: tenantsDropdown(state.tenantsDropdown, action)
});
