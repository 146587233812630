import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { MESSAGES } from "../../i18n";
import { routes } from "../../routes";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const RenderContentError = () => (
  <Result
    status="error"
    title={MESSAGES.RenderContentErrorTitle}
    subTitle={MESSAGES.RenderContentErrorSubTitle}
    extra={[
      <Link to={routes.home.resolve()} key="back-home">
        <Button type="primary">Back Home</Button>
      </Link>
    ]}
  />
);
