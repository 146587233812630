import { Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../../../i18n";
import { CurrentActivityValue, doUpdateWorkingFilter } from "../../../../redux/actions/students-list";
import { getActiveProduct } from "../../../../redux/selectors/active-product";
import { RdxStoreState } from "../../../../redux/types/state";
import { currentActivityOptions as currentActivityOptionsByProduct } from "../metadata";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CurrentActivityFilter = () => {
  const dispatch = useDispatch();
  const activeProduct = useSelector(getActiveProduct);
  const currentActivity = useSelector(
    ({
      studentsList: {
        workingFilter: {
          currentActivity //
        }
      }
    }: RdxStoreState) => currentActivity
  );

  return (
    <Form.Item label={MESSAGES.CurrentActivityFilter}>
      <Select<string>
        value={currentActivity}
        data-qa-label="current-activity"
        getPopupContainer={({ parentNode }) => {
          return parentNode as HTMLElement;
        }}
        onChange={(currentActivity: CurrentActivityValue) => {
          dispatch(doUpdateWorkingFilter({ currentActivity }));
        }}
      >
        {currentActivityOptionsByProduct(activeProduct).map(({ label, value }) => (
          <Select.Option value={value} key={value} data-qa-label={`ca-sfilter-${value}`}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
