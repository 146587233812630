import { Layout, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { SIDER_WIDTH } from "../../constants/ui-constants";
import { MESSAGES } from "../../i18n";
import { doLogout } from "../../redux/actions/authentication";
import { doCloseNavigationDrawer, doOpenNavigationDrawer } from "../../redux/actions/navigation-drawer";
import { getIsNavigationDrawerOpen } from "../../redux/selectors/navigation-drawer-open";
import { AppMenu } from "./menu";
import { AppMenuContext } from "./menu/context";
import { NavigatorLogo } from "./navigator-logo";
import { ProductSwitcher } from "./product-switcher";
import { NavigationDrawerOptionKey } from "./types";

const { Sider } = Layout;

type ComponentProps = {
  active: NavigationDrawerOptionKey;
};

export const NavigationDrawer: React.FC<ComponentProps> = ({ active }) => {
  const dispatch = useDispatch();
  const open = useSelector(getIsNavigationDrawerOpen);
  const [isLogoutModalVisible, setLogoutModalVisible] = useState(false);

  const handleButtonClick = () => {
    dispatch(open ? doCloseNavigationDrawer() : doOpenNavigationDrawer());
  };

  return (
    <>
      <NonAnimatedSider
        collapsible
        width={SIDER_WIDTH}
        collapsed={!open}
        onCollapse={handleButtonClick}
        {...({ theme: "light" } as any)}
      >
        <NavigatorLogo collapsed={!open} />
        <ProductSwitcher />
        <AppMenuContext.Provider
          value={{
            active,
            setLogoutModalVisible
          }}
        >
          <AppMenu />
        </AppMenuContext.Provider>
      </NonAnimatedSider>
      <Modal
        title={MESSAGES.LogoutTitle}
        open={isLogoutModalVisible}
        onOk={() => {
          setLogoutModalVisible(false);
          dispatch(doLogout());
        }}
        onCancel={() => {
          setLogoutModalVisible(false);
        }}
      >
        <p>{MESSAGES.LogoutConfirmTitle}</p>
      </Modal>
    </>
  );
};

const NonAnimatedSider = styled(Sider)`
  min-height: "100vh";
  transition: none !important;
  background: #fff;
  .ant-layout-sider-trigger {
    transition: none !important;
    color: rgba(0, 0, 0, 0.8);
    background: inherit;
  }
`;
