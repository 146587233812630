import { HttpClientErrorResponse } from "./../../../services/http-client/types";
export const API_FAILURE_RESET = "API_FAILURE_RESET";
export const API_FAILURE_REMOVE_ERROR = "API_FAILURE_REMOVE_ERROR";
export const API_FAILURE_NOTIFIED = "API_FAILURE_NOTIFIED";

export const doReset = () => ({
  type: API_FAILURE_RESET
});

export const doRemoveError = (id: string) => ({
  type: API_FAILURE_REMOVE_ERROR,
  payload: id
});

export const doNotifyApiFailure = (error: HttpClientErrorResponse) => ({
  type: API_FAILURE_NOTIFIED,
  payload: { error }
});
