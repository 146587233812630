import { LoginTemplate } from "@beckersdp/becker-ds";
import React, { SyntheticEvent, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { unauthLayoutProps } from "../../components/layout/unauth/unauth-layout-props";
import { doLogin } from "../../redux/actions/authentication";
import { RdxStoreState } from "../../redux/types/state";
import { RdxFetchStatus } from "../../redux/types/status";
import { routes } from "../../routes";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const Login: React.FC = (): JSX.Element => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const isFetching = useSelector((state: RdxStoreState) => state.authentication.status === RdxFetchStatus.LOADING);
  const errorMsg = useSelector((state: RdxStoreState) => state.authentication.error);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(doLogin(email, password, rememberMe));
  };

  const inputRef = useRef<HTMLElement | null>(null);
  const pwdRef = useRef<HTMLElement | null>(null);

  const loginForm = {
    email: {
      value: email,
      onChange: (e: SyntheticEvent<HTMLInputElement>) => {
        setEmail((e.target as HTMLInputElement).value);
      },
      onClear: () => setEmail(""),
      inputRef: inputRef,
      name: "username"
    },
    password: {
      value: password,
      onChange: (e: SyntheticEvent<HTMLInputElement>) => {
        setPassword((e.target as HTMLInputElement).value);
      },
      inputRef: pwdRef,
      name: "password"
    },
    forgotPwd: {
      href: routes.forgotPassword.resolve(),
      className: "text-blue-900",
      onClick: (e: SyntheticEvent) => {
        e.preventDefault();
        navigate(routes.forgotPassword.resolve());
      }
    },
    rememberMe: {
      checked: rememberMe,
      onChange: () => setRememberMe(!rememberMe),
      name: "remember-me"
    },
    form: {
      onSubmit: handleSubmit,
      name: "login-form"
    },
    submitBtn: {
      loading: isFetching,
      name: "submit-btn"
    },
    error: errorMsg
  };

  return <StyledLoginTemplate loginForm={loginForm} {...unauthLayoutProps} />;
};

// -------------------------------------------------------------------------------------------------
// - Styled Component
// -------------------------------------------------------------------------------------------------

const StyledLoginTemplate = styled(LoginTemplate)`
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .right-side-wrapper {
    min-height: unset !important;
  }
`;

export default Login;
