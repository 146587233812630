import React, { useState } from "react";
import { ForgotPasswordForm } from "../../components/forgot-password/forgot-password-form";
import { ForgotPasswordLayout } from "../../components/layout/unauth/forgot-password-Layout";

const ForgotPassword: React.FC = () => {
  const [step, setStep] = useState<"forgot" | "emailSent">("forgot");
  return (
    <ForgotPasswordLayout step={step}>
      <ForgotPasswordForm onEmailSent={() => setStep("emailSent")} />
    </ForgotPasswordLayout>
  );
};

export default ForgotPassword;
