// HTML set configuration
export const SYSTEM_CONFIG: {
  apiBaseUrl?: string;
  backEndBuild?: string;
  backEndBranch?: string;
  environment?: string; // prod, qa, dev, ...
  featureFlags?: string[];
  frontEndBaseUrl?: string; // to allow override, assets base url
  frontEndCommit?: string;
  version?: string;
  [key: string]: any;
} = (window as any).__config__ || {};
