import React from "react";

import { MESSAGES } from "../../i18n";
import { GenericApiFailureModal } from "./modal";

export const NeedReloadModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <GenericApiFailureModal
      message={MESSAGES.ErrPageNeedsReload}
      buttons={[
        {
          onClick: () => {
            onClose();
            window.location.reload();
          },
          label: MESSAGES.ApiFailureMultipleSessionBtnCaption
        }
      ]}
    />
  );
};
