import _ from "lodash";
import qString from "query-string";
import { ExamNumber, ReportParams } from "../../../pages/Students/students-table/reports-modal/types";
import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { getSortParam } from "../utils/sort-utils";
import {
  CreateStudentRosterResponse,
  GetStudentRosterListingOfStudentsResponse,
  ReportTaskResponse,
  RosterStudentFilter
} from "./types";

// -------------------------------------------------------------------------------------------------
// - Create student roaster
// -------------------------------------------------------------------------------------------------

export const createStudentRoster = async (filter: RosterStudentFilter): Promise<CreateStudentRosterResponse> =>
  await HttpClient.post("/rest/studentRosters", createHeaders(), { filter });

// -------------------------------------------------------------------------------------------------
// - GetStudentRoosterListingOfStudents
// -------------------------------------------------------------------------------------------------

export const getStudentRosterListingOfStudents = async (
  token: string,
  limit = 20,
  offset = 0,
  sort?: { key: string; order: "ascend" | "descend" } // asc: +field | desc: -field
): Promise<GetStudentRosterListingOfStudentsResponse> =>
  await HttpClient.get(`/rest/studentRosters/${token}/students`, createHeaders(), {
    limit,
    offset: offset || undefined,
    sort: getSortParam(sort)
  });

// -------------------------------------------------------------------------------------------------
// - Assign status to RosterStudents
// -------------------------------------------------------------------------------------------------

export const assignStatusToRosterStudents = async (
  token: string,
  excluded: boolean,
  tenantId?: number
): Promise<void> =>
  await HttpClient.put(
    `/rest/studentRosters/${token}/students/all/status${_.isNil(tenantId) ? "" : `?tenantId=${tenantId}`}`,
    createHeaders(),
    { excluded }
  );

// -------------------------------------------------------------------------------------------------
// - Add or delete tags from students
// -------------------------------------------------------------------------------------------------

export const addDeleteTagsFromStudents = async (token: string, tagIds: number[], del?: boolean): Promise<void> =>
  await HttpClient.put(`/rest/studentRosters/${token}/students/all/tags`, createHeaders(), {
    addTagIds: del ? [] : tagIds,
    deleteTagIds: del ? tagIds : []
  });

// -------------------------------------------------------------------------------------------------
// - PerformanceDashboard Report
// -------------------------------------------------------------------------------------------------

export const performanceDashboardReport = async (params: ReportParams): Promise<ReportTaskResponse> =>
  await HttpClient.post(
    `/rest/studentRosters/${params.token}/performanceSummary/report?${qString.stringify({
      limit: params.limit,
      offset: params.offset,
      sort: params.sort
    })}`,
    createHeaders(),
    {
      level: params.hierarchyLevelIncludedByReportType[params.type],
      sections: params.multipleSectionsIncludedByReportType[params.type]
    }
  );

// -------------------------------------------------------------------------------------------------
// - FrPerformanceSummary Report
// -------------------------------------------------------------------------------------------------

export const frPerformanceSummary = async (params: ReportParams): Promise<ReportTaskResponse> => {
  return HttpClient.post(
    `/rest/studentRosters/${params.token}/frPerformanceSummary/report?${qString.stringify({
      limit: params.limit,
      offset: params.offset,
      sort: params.sort
    })}`,
    createHeaders(),
    {
      level: params.hierarchyLevelIncludedByReportType[params.type],
      sections: params.multipleSectionsIncludedByReportType[params.type]
    }
  );
};

// -------------------------------------------------------------------------------------------------
// - ExamTracker Report
// -------------------------------------------------------------------------------------------------

export const examTrackerReport = async (params: ReportParams): Promise<ReportTaskResponse> =>
  await HttpClient.post(
    `/rest/studentRosters/${params.token}/examtracker/report?${qString.stringify({
      limit: params.limit,
      offset: params.offset,
      sort: params.sort
    })}`,
    createHeaders()
  );

// -------------------------------------------------------------------------------------------------
// - StudentsList Report
// -------------------------------------------------------------------------------------------------

export const studentsListReport = async (params: ReportParams): Promise<ReportTaskResponse> =>
  await HttpClient.post(
    `/rest/studentRosters/${params.token}/list/report?${qString.stringify({
      limit: params.limit,
      offset: params.offset,
      sort: params.sort
    })}`,
    createHeaders()
  );

// -------------------------------------------------------------------------------------------------
// - StudyTime Report
// -------------------------------------------------------------------------------------------------

export const studyTimeReport = async (params: ReportParams): Promise<ReportTaskResponse> =>
  await HttpClient.post(
    `/rest/studentRosters/${params.token}/studytime/report?${qString.stringify({
      limit: params.limit,
      offset: params.offset,
      sort: params.sort
    })}`,
    createHeaders(),
    {
      level: params.hierarchyLevelIncludedByReportType[params.type],
      sections: params.multipleSectionsIncludedByReportType[params.type]
    }
  );

// -------------------------------------------------------------------------------------------------
// - Timesheet Report
// -------------------------------------------------------------------------------------------------

export const timesheetReport = async (params: ReportParams): Promise<ReportTaskResponse> =>
  await HttpClient.post(
    `/rest/studentRosters/${params.token}/timesheet/report?${qString.stringify({
      limit: params.limit,
      offset: params.offset,
      sort: params.sort
    })}`,
    createHeaders(),
    {
      yearMonth: params.timesheetPeriodByReportType[params.type]!.format("YYYY-MM")
    }
  );

// -------------------------------------------------------------------------------------------------
// - SimulatedExamsScore Report
// -------------------------------------------------------------------------------------------------

export const simulatedExamsScoreReport = async (params: ReportParams): Promise<ReportTaskResponse> =>
  await HttpClient.post(
    `/rest/studentRosters/${params.token}/simexams/report?${qString.stringify({
      limit: params.limit,
      offset: params.offset,
      sort: params.sort
    })}`,
    createHeaders(),
    {
      attempts: params.multipleAttemptsIncludedByReportType[params.type],
      sections: params.multipleSectionsIncludedByReportType[params.type]
    }
  );

// -------------------------------------------------------------------------------------------------
// - SimulatedExamsScore Report (Final Review)
// -------------------------------------------------------------------------------------------------

export const frSimulatedExamsScoreReport = async (params: ReportParams): Promise<ReportTaskResponse> =>
  await HttpClient.post(
    `/rest/studentRosters/${params.token}/frsimexams/report?${qString.stringify({
      limit: params.limit,
      offset: params.offset,
      sort: params.sort
    })}`,
    createHeaders(),
    {
      attempts: params.multipleAttemptsIncludedByReportType[params.type],
      sections: params.multipleSectionsIncludedByReportType[params.type]
    }
  );

// -------------------------------------------------------------------------------------------------
// - SimulatedExamPerformanceReport Report
// -------------------------------------------------------------------------------------------------

export const simulatedExamPerformanceReport = async (params: ReportParams): Promise<ReportTaskResponse> =>
  await HttpClient.post(
    `/rest/studentRosters/${params.token}/simexamperformance/report?${qString.stringify({
      limit: params.limit,
      offset: params.offset,
      sort: params.sort
    })}`,
    createHeaders(),
    {
      attempt: params.attemptByReportType[params.type]
    }
  );

// -------------------------------------------------------------------------------------------------
// - HomeworkMcqFirstAttempt Report
// -------------------------------------------------------------------------------------------------

export const homeworkMcqFirstAttemptReport = async (params: ReportParams): Promise<ReportTaskResponse> =>
  await HttpClient.post(
    `/rest/studentRosters/${params.token}/homeworkmcqanswers/report?${qString.stringify({
      limit: params.limit,
      offset: params.offset,
      sort: params.sort
    })}`,
    createHeaders(),
    {
      unitAltId: params.sectionUnitIncludedByReportType[params.type]
    }
  );

// -------------------------------------------------------------------------------------------------
// - HomeworkPerformance Report
// -------------------------------------------------------------------------------------------------

export const homeworkPerformanceReport = async (params: ReportParams): Promise<ReportTaskResponse> =>
  await HttpClient.post(
    `/rest/studentRosters/${params.token}/homeworkperformance/report?${qString.stringify({
      limit: params.limit,
      offset: params.offset,
      sort: params.sort
    })}`,
    createHeaders(),
    {
      level: params.hierarchyLevelIncludedByReportType[params.type],
      sections: params.multipleSectionsIncludedByReportType[params.type]
    }
  );

// -------------------------------------------------------------------------------------------------
// - SimulatedExamsScore Report
// -------------------------------------------------------------------------------------------------

export const simulatedExamsAnswersReport = async (params: ReportParams): Promise<ReportTaskResponse> =>
  await HttpClient.post(
    `/rest/studentRosters/${params.token}/simexamanswers/report?${qString.stringify({
      limit: params.limit,
      offset: params.offset,
      sort: params.sort
    })}`,
    createHeaders(),
    {
      attempt: params.attemptByReportType[params.type],
      section: params.singleSectionIncludedByReportType[params.type],
      examNumber: (() => {
        switch (params.examNumberByReportType[params.type]) {
          case ExamNumber._1:
            return 1;
          case ExamNumber._2:
            return 2;
          case ExamNumber._3:
            return 3;
        }
      })()
    }
  );

// -------------------------------------------------------------------------------------------------
// - MiniExamsAnswers Report
// -------------------------------------------------------------------------------------------------

export const miniExamsAnswersReport = async (params: ReportParams): Promise<ReportTaskResponse> =>
  await HttpClient.post(
    `/rest/studentRosters/${params.token}/miniexamanswers/report?${qString.stringify({
      limit: params.limit,
      offset: params.offset,
      sort: params.sort
    })}`,
    createHeaders(),
    {
      attempt: params.attemptByReportType[params.type],
      section: params.singleSectionIncludedByReportType[params.type],
      examNumber: (() => {
        switch (params.examNumberByReportType[params.type]) {
          case ExamNumber._1:
            return 1;
          case ExamNumber._2:
            return 2;
          case ExamNumber._3:
            return 3;
        }
      })()
    }
  );

// -------------------------------------------------------------------------------------------------
// - MiniExamScore Report
// -------------------------------------------------------------------------------------------------

export const miniExamScoreReport = async (params: ReportParams): Promise<ReportTaskResponse> =>
  await HttpClient.post(
    `/rest/studentRosters/${params.token}/miniexams/report?${qString.stringify({
      limit: params.limit,
      offset: params.offset,
      sort: params.sort
    })}`,
    createHeaders(),
    {
      attempts: params.multipleAttemptsIncludedByReportType[params.type],
      sections: params.multipleSectionsIncludedByReportType[params.type]
    }
  );

// -------------------------------------------------------------------------------------------------
// - MiniExamPerformance Report
// -------------------------------------------------------------------------------------------------

export const miniExamPerformanceReport = async (params: ReportParams): Promise<ReportTaskResponse> =>
  await HttpClient.post(
    `/rest/studentRosters/${params.token}/miniexamperformance/report?${qString.stringify({
      limit: params.limit,
      offset: params.offset,
      sort: params.sort
    })}`,
    createHeaders(),
    {
      attempt: params.attemptByReportType[params.type]
    }
  );
