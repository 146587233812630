import cloneDeep from "lodash/cloneDeep";

type Node<T = any> = { id: number; children: T[]; name: string };

const iterateTree = <T extends Node<T> = Node>(tree: T[], cb: (node: T) => any) => {
  const queue = cloneDeep(tree);
  while (queue.length) {
    const curNode = queue.shift()!;
    cb(curNode);
    queue.push(...curNode.children);
  }
};

export { iterateTree };
