import keyBy from "lodash/keyBy";

import { Tenant } from "../../../services/nav-api/tenants";
import {
  FETCH_TENANTS_COMPLETED,
  FETCH_TENANTS_STARTED,
  TENANTS_LIST_SETTINGS_CHANGED,
  FetchTenantsCompleted,
  DoChangeListSettingsPayload,
  WorkingFilter,
  WORKING_TENANTS_FILTER_UPDATED,
  WORKING_TENANTS_FILTER_CLEARED,
  UpdateWorkingFilterPayload
} from "../../actions/tenants/tenants";
import { RdxFetchStatus } from "../../types/status";
import { createReducer } from "../../utils/redux-utils";
import { Status } from "../../../services/nav-api/types";
import { DEFAULT_LIST_PAGE, DEFAULT_LIST_SIZE } from "../../../constants/lists";

export interface RdxTenantsSubState {
  workingFilter: WorkingFilter;
  activeFilter?: {
    filter?: WorkingFilter;
    loading: boolean;
  };
  byId: { [id: number]: Tenant };
  status: RdxFetchStatus;
  allIds: number[];
  tenants: Tenant[] | undefined;
  paging: {
    page: number;
    totalItems?: number;
    itemsPerPage: number;
  };
  error?: string;
}

export const initialState: RdxTenantsSubState = {
  byId: {},
  status: RdxFetchStatus.NONE,
  allIds: [],
  tenants: [],
  paging: {
    page: DEFAULT_LIST_PAGE,
    itemsPerPage: DEFAULT_LIST_SIZE,
    totalItems: 0
  },
  workingFilter: {
    filter: "",
    status: Status.Enabled,
    type: "All",
    products: "All"
  }
};

export default createReducer<RdxTenantsSubState>(initialState, {
  [WORKING_TENANTS_FILTER_UPDATED]: (state, payload: UpdateWorkingFilterPayload) => ({
    ...state,
    workingFilter: { ...state.workingFilter, ...payload }
  }),
  [WORKING_TENANTS_FILTER_CLEARED]: state => ({
    ...state,
    workingFilter: { ...initialState.workingFilter }
  }),
  [FETCH_TENANTS_STARTED]: state => ({
    ...state,
    status: RdxFetchStatus.LOADING,
    error: undefined,
    activeFilter: {
      ...(state.activeFilter || {}),
      loading: true
    }
  }),
  [FETCH_TENANTS_COMPLETED]: (state, { error, tenants, filter, total }: FetchTenantsCompleted) => {
    if (error) {
      return {
        ...state,
        status: RdxFetchStatus.FAILED,
        error: error.message,
        activeFilter: {
          ...state.activeFilter,
          loading: false
        }
      };
    }
    return {
      ...state,
      allIds: tenants!.map(t => t.id),
      byId: keyBy(tenants, "id"),
      tenants,
      paging: {
        ...state.paging,
        totalItems: total
      },
      status: RdxFetchStatus.COMPLETED,
      activeFilter: {
        filter,
        loading: false
      }
    };
  },
  [TENANTS_LIST_SETTINGS_CHANGED]: (
    state,
    { paging: { page, pageSize: itemsPerPage } }: DoChangeListSettingsPayload
  ) => ({
    ...state,
    paging: {
      ...state.paging,
      page,
      itemsPerPage
    }
  })
});
