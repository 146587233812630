import { createSelector } from "reselect";
import { TenantProducts } from "../../services/nav-api/tenants";
import { RdxStoreState } from "../types/state";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getActiveProduct: (state: RdxStoreState) => TenantProducts = createSelector(
  (state: RdxStoreState) => state.myself.userSettings,
  settings => settings?.activeProduct || "cpa"
);
