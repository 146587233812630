import noop from "lodash/noop";
import { ErrorTrackingService } from "../types";

export const mockErrorTracker: ErrorTrackingService = {
  init: noop,
  setUser: noop,
  setContext: noop,
  notifyError: noop,
  notifyEvent: noop
};
