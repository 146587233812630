import { Form } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { CheckableTreeView } from "../../../../../components/shared/checkable-tree-view";
import { CheckBehavior } from "../../../../../components/shared/checkable-tree-view/types";
import { getMyTenantTagsTree, TagNode } from "../../../../../redux/selectors/my-tenant-tags-tree";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TagsTreeOption = (props: {
  value: number[];
  onChange: (checked: number[]) => void;
  checkBehavior: CheckBehavior;
}) => {
  const { tree = [] } = useSelector(getMyTenantTagsTree);
  return (
    <Form.Item label="Tags">
      <CheckableTreeView
        loading={false}
        tree={tree}
        renderNode={(n: TagNode) => n.name}
        checkBehavior={props.checkBehavior}
        checked={props.value || []}
        onChange={({ checked }) => props.onChange(checked)}
      />
    </Form.Item>
  );
};
