import { HierarchyContent } from "../../../services/nav-api/hierarchy-content";
import {
  FetchHierarchyContentPayload,
  HIERARCHY_CONTENT_FETCH_COMPLETED,
  HIERARCHY_CONTENT_FETCH_STARTED
} from "../../actions/hierarchy-content";
import { UPDATE_CONTENT_VERSION } from "../../actions/myself/actions";
import { RdxFetchStatus } from "../../types/status";
import { createReducer } from "../../utils/redux-utils";

export type RdxHierarchyContent = Partial<HierarchyContent> & { status: RdxFetchStatus };

export const initialState: RdxHierarchyContent = {
  status: RdxFetchStatus.NONE
};

export default createReducer<RdxHierarchyContent>(initialState, {
  [HIERARCHY_CONTENT_FETCH_STARTED]: state => ({
    ...state,
    status: RdxFetchStatus.LOADING
  }),
  [HIERARCHY_CONTENT_FETCH_COMPLETED]: (_, { data, error }: FetchHierarchyContentPayload) => {
    if (error) {
      return {
        status: RdxFetchStatus.FAILED
      };
    }
    return { ...data, status: RdxFetchStatus.COMPLETED };
  },
  [UPDATE_CONTENT_VERSION]: state => ({
    ...state,
    status: RdxFetchStatus.STALE
  })
});
