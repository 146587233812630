import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { navApi } from "../../../services/nav-api";
import { GetMySelfResponse, UserSettings } from "../../../services/nav-api/myself/types";
import { Role } from "../../../services/nav-api/roles";
import { ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "../../types/status";
import { doCompleteFetchMySelf, doFailFetchMyself } from "./fetchCompletion";
import { doStartFetchMySelf } from "./fetchStart";

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const fetchUser = async (): Promise<GetMySelfResponse> => await navApi.myself.getMySelf();
const fetchUserRoles = async (): Promise<Role[]> => {
  const { roles } = await navApi.roles.getRoles();
  if (roles) {
    roles.sort((a, b) => a.name.localeCompare(b.name));
  }
  return roles;
};
const fetchUserSettings = async (): Promise<UserSettings> => {
  const s = await navApi.myself.getMySelfSettings();
  return { ...s, activeProduct: s.activeProduct ?? "cpa" };
};

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doFetchMySelf = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    if (_.isEqual(getState().myself.status, RdxFetchStatus.LOADING)) {
      return;
    }

    try {
      dispatch(doStartFetchMySelf());
      dispatch(
        doCompleteFetchMySelf(
          //
          await fetchUser(),
          await fetchUserSettings(),
          await fetchUserRoles()
        )
      );
    } catch (ex) {
      dispatch(doFailFetchMyself((ex as HttpClientErrorResponse).message || "error"));
    }
  };
};
