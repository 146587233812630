import uniq from "lodash/uniq";

import { getAllDescendantIds } from "../get-all-descendants";
import { Node } from "../types";
import { createNodeById } from "../create-mappers";

// should mark all descendants as checked

function getAllCheckedIds(tree: Node[], initialCheckedIds: number[]) {
  const nodeById = createNodeById(tree);
  const ids = initialCheckedIds.reduce(
    (ids: number[], cur) => [...ids, ...getAllDescendantIds(tree, cur)],
    initialCheckedIds.filter(nId => Boolean(nodeById[nId]))
  );
  return uniq(ids);
}

export { getAllCheckedIds };
