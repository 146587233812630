import { createSelector } from "reselect";
import { iterateTree } from "../../../utils/tree-utils/iterate";
import { getMyTenantTagsTree, TagNode } from "../my-tenant-tags-tree";
import { memoize } from "lodash";

function getTotalEnrollmentsByIdRecursive(curNode: TagNode): number {
  return (
    curNode.enrolledStudentCount + curNode.children.reduce((acc, cur) => acc + getTotalEnrollmentsByIdRecursive(cur), 0)
  );
}
const memoizedGetTotalEnrollmentsByIdRecursive = memoize(getTotalEnrollmentsByIdRecursive);

/***
 * Includes the enrollements of child tags.
 *     A(4)
 * B(2)     C(3)
 * D(2)
 * then
 * A: 11
 * B: 4
 * C: 3
 * D: 2
 */
export const getTotalEnrollmentsByTagId = createSelector(getMyTenantTagsTree, ({ tree }): Record<number, number> => {
  if (!tree || !tree.length) {
    return {};
  }

  const totalEnrollmentsById: Record<number, number> = {};

  iterateTree(tree, node => {
    totalEnrollmentsById[node.id] = memoizedGetTotalEnrollmentsByIdRecursive(node);
  });

  return totalEnrollmentsById;
});
