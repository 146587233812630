import { LoadingOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import React, { Suspense } from "react";
import { BaseLayout } from "../../components/layout/base-layout";
import { PageTitle } from "../../components/layout/page-title";
import { NavigationDrawerOptionKey } from "../../components/navigation-drawer/types";
import { PartnersTable } from "../../components/partners/list";
import { PartnersSidebar } from "../../components/partners/sidebar";
import { MESSAGES } from "../../i18n";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Partners = () => {
  return (
    <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_PARTNERS}>
      <Row gutter={16}>
        <Col xs={18} xxl={19}>
          <PageTitle>{MESSAGES.Partners}</PageTitle>
        </Col>
        <Col xs={6} xxl={5} />
      </Row>
      <Row gutter={16}>
        <Col xs={18} xxl={19}>
          <Card bodyStyle={{ overflowX: "auto" }}>
            <Suspense fallback={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
              <PartnersTable />
            </Suspense>
          </Card>
        </Col>
        <Col xs={6} xxl={5}>
          <PartnersSidebar />
        </Col>
      </Row>
    </BaseLayout>
  );
};
