import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { StudentFullDetails } from "../../../services/nav-api/student-details/types";
import { getShouldFetchStudentDetails } from "../../selectors/should-fetch-student-details";
import { Action, ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import {
  STUDENT_DETAILS_CURRENT_STUDENT_CHANGED,
  STUDENT_DETAILS_EDIT_COMPLETED,
  STUDENT_DETAILS_FETCH_COMPLETED,
  STUDENT_DETAILS_FETCH_STARTED
} from "./constants";
import { doFetchStudentPerformanceDashboard } from "./hierarchy-progress";

export type FetchStudentDetailsCompletedPayload = {
  details?: StudentFullDetails;
  error?: HttpClientErrorResponse;
  timestamp: number;
  id: number;
};

export const doFetchStudentDetailsStarted = (studentId: number): Action<number> => ({
  type: STUDENT_DETAILS_FETCH_STARTED,
  payload: studentId
});

export const doFetchStudentDetailsCompleted = (data: {
  id: number;
  details?: StudentFullDetails;
  error?: HttpClientErrorResponse;
}): Action<FetchStudentDetailsCompletedPayload> => ({
  type: STUDENT_DETAILS_FETCH_COMPLETED,
  payload: { ...data, timestamp: Date.now() }
});

export const doFetchStudentDetails = (): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    if (!getShouldFetchStudentDetails(getState())) {
      return;
    }
    const studentId = getState().studentDetails.curStudentId!;

    dispatch(doFetchStudentDetailsStarted(studentId));

    try {
      const details = await navApi.studentDetails.getStudentDetails(studentId);
      const studentToken = details.studentToken;
      const activeProduct = getState().myself.userSettings?.activeProduct || "cpa";
      dispatch(doFetchStudentDetailsCompleted({ id: studentId, details }));
      dispatch(doFetchStudentPerformanceDashboard(studentId, studentToken, activeProduct));
    } catch (ex) {
      const error: HttpClientErrorResponse = ex;
      dispatch(doFetchStudentDetailsCompleted({ id: studentId, error }));
    }
  };
};

export const doChangeCurrentStudent = (studentId: number): Action<number> => ({
  type: STUDENT_DETAILS_CURRENT_STUDENT_CHANGED,
  payload: studentId
});

export type EditStudentCompletedPayload = {
  data?: {
    tenantId: number;
    studentId: number;
    tags?: Array<{
      id: number;
    }>;
    excluded: boolean;
  };
  error?: HttpClientErrorResponse;
};

export const doEditStudentCompleted = (payload: EditStudentCompletedPayload) => ({
  type: STUDENT_DETAILS_EDIT_COMPLETED,
  payload
});
