import { StarFilled, StarOutlined } from "@ant-design/icons";
import React from "react";
import styled from "styled-components";

type Props = {
  active: boolean;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

const _FollowBtn: React.FC<Props> = ({ active, onClick, disabled, ...props }) => (
  <button {...props} onClick={e => !disabled && onClick?.(e)}>
    {active ? <StarFilled /> : <StarOutlined />}
  </button>
);

export const FollowBtn = styled(_FollowBtn)`
  border: none;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  width: 2rem;
  transition: opacity 0.25s ease-in-out;
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  opacity: ${props => (props.disabled ? "0.25!important" : 1)};

  &:hover {
    opacity: 0.5;
  }

  > * {
    width: 1rem;
    height: 1rem;
  }
`;
