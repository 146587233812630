import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { initialState } from "../../reducers/alerts-writer/constants";
import { AlertsPagination } from "../../reducers/alerts-writer/model";
import { Action, ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { doFetchAlerts } from "./fetch";

// -------------------------------------------------------------------------------------------------
// - Actions
// -------------------------------------------------------------------------------------------------

export const CHANGE_ALERTS_PAGINATION = "CHANGE_ALERTS_PAGINATION";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doChangeAlertsPagination = (paging: AlertsPagination): Action => ({
  type: CHANGE_ALERTS_PAGINATION,
  payload: {
    paging
  }
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doResetAlertsPagination = (): Action => doChangeAlertsPagination(initialState.paging);

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doChangeAlertsPaginationAndFetch = (paging: AlertsPagination): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    dispatch(doChangeAlertsPagination(paging));
    dispatch(doFetchAlerts());
  };
};

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doResetAlertsPaginationAndFetch = (paging: AlertsPagination): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    dispatch(doResetAlertsPagination());
    dispatch(doFetchAlerts());
  };
};
