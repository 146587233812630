import { Badge, Card, Col, Modal, Row, Tabs, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { BaseLayout } from "../../components/layout/base-layout";
import { PageTitle } from "../../components/layout/page-title";
import { NavigationDrawerOptionKey } from "../../components/navigation-drawer/types";
import { StudentsAddForm, StudentsAddFormProps } from "../../components/studentsadd/form";
import { MESSAGES, formatMsg } from "../../i18n";
import { ADD_NEW_STUDENTS_COMPLETED } from "../../redux/actions/students-list";
import { BAD_REQUEST, NOT_FOUND_STATUS_CODE } from "../../services/http-client/constant";
import { navApi } from "../../services/nav-api";
import { PostMannuallyAssociatedErrorResponse } from "../../services/nav-api/students-mannually-associated/types";
const { Text } = Typography;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const ResponseContentViewer = ({
  unmatchedUsernames,
  skippedUsernames,
  addedCount
}: {
  unmatchedUsernames?: string[];
  skippedUsernames?: string[];
  addedCount?: number;
}) => {
  const hasSkippedU = _.gt(skippedUsernames?.length, 0);
  const hasUnmatchedU = _.gt(unmatchedUsernames?.length, 0);
  return (
    <div className="flex flex-col space-y-2">
      {!_.isNil(addedCount) ? (
        <Text>
          {formatMsg(
            _.isEqual(addedCount, 1) //
              ? MESSAGES.StudentsAddCountSingular
              : MESSAGES.StudentsAddCountPlural,
            addedCount
          )}
        </Text>
      ) : null}
      <Tabs
        items={[
          ...(hasSkippedU
            ? [
                {
                  key: "s",
                  label: (
                    <Badge showZero offset={[10, 0]} count={skippedUsernames?.length ?? 0}>
                      <Text>{MESSAGES.StudentsAddTab_Skipped}</Text>
                    </Badge>
                  ),
                  children: (
                    <div className="flex flex-col space-y-4">
                      <Text>{MESSAGES.StudentsAddedSuccessfullyButWithExceptions_Skipped}</Text>
                      <Text>
                        <Code>{_.join(skippedUsernames, ", ")}</Code>
                      </Text>
                    </div>
                  )
                }
              ]
            : []),
          ...(hasUnmatchedU
            ? [
                {
                  key: "u",
                  label: (
                    <Badge showZero offset={[10, 0]} count={unmatchedUsernames?.length ?? 0}>
                      <Text>{MESSAGES.StudentsAddTab_Unmatched}</Text>
                    </Badge>
                  ),
                  children: (
                    <div className="flex flex-col space-y-4">
                      <Text>{MESSAGES.StudentsAddedSuccessfullyButWithExceptions_Unmatched}</Text>
                      <Text>
                        <Code>{_.join(unmatchedUsernames, ", ")}</Code>
                      </Text>
                    </div>
                  )
                }
              ]
            : [])
        ]}
      />
    </div>
  );
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const StudentsAdd: React.FC = () => {
  const dispatch = useDispatch();
  const handleSubmit = async (props: StudentsAddFormProps) => {
    try {
      const rsp = await navApi.studentsMannualyAssociated.postMannuallyAssociated({
        add: {
          usernames: props.usernames
            .replace(/\n/g, ",")
            .split(/[ ,]+/)
            .filter(e => e !== ""),
          tagIds: props.tagIds
        }
      });

      const unmatched = rsp?.unmatchedUsernames;
      const skipped = rsp?.skippedUsernames;
      const addedCount = rsp?.matchedCount;

      const hasUnmatchedU = _.gt(unmatched?.length, 0);
      const hasSkippedU = _.gt(skipped?.length, 0);

      const modal =
        hasUnmatchedU || hasSkippedU
          ? Modal.warning //
          : Modal.success;

      const title = MESSAGES.StudentsAddTitle;
      const content =
        hasUnmatchedU || hasSkippedU ? (
          <ResponseContentViewer unmatchedUsernames={unmatched} skippedUsernames={skipped} addedCount={addedCount} />
        ) : (
          formatMsg(
            _.isEqual(addedCount, 1) //
              ? MESSAGES.StudentsAddCountSingular
              : MESSAGES.StudentsAddCountPlural,
            addedCount
          )
        );

      modal({ title, content, width: 600, okText: MESSAGES.StudentsAddClose });

      dispatch({
        type: ADD_NEW_STUDENTS_COMPLETED
      });
    } catch (ex) {
      const err: PostMannuallyAssociatedErrorResponse = ex;

      switch (err.statusCode) {
        case BAD_REQUEST:
        case NOT_FOUND_STATUS_CODE:
          const { unmatchedUsernames, skippedUsernames } = err;
          if (unmatchedUsernames?.length! > 0 || skippedUsernames?.length! > 0) {
            Modal.error({
              width: 600,
              title: MESSAGES.StudentsAddTitle,
              okText: MESSAGES.StudentsAddClose,
              content: (
                <ResponseContentViewer //
                  unmatchedUsernames={unmatchedUsernames}
                  skippedUsernames={skippedUsernames}
                />
              )
            });
            return;
          } else if (err.message != null && err.message !== "No error msg") {
            Modal.error({
              width: 600,
              title: MESSAGES.Error,
              okText: MESSAGES.StudentsAddClose,
              content: (
                <div>
                  <Text>{err.message}</Text>
                </div>
              )
            });
            return;
          }
          break;
      }

      Modal.error({
        title: MESSAGES.Error,
        content: MESSAGES.StudentsAddError
      });
    }
  };

  return (
    <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.STUDENTS}>
      <PageTitle>{MESSAGES.AddStudents}</PageTitle>
      <Row>
        <Col span="12">
          <Card bodyStyle={{ overflowX: "auto" }}>
            <StudentsAddForm onSubmit={handleSubmit} />
          </Card>
        </Col>
      </Row>
    </BaseLayout>
  );
};

export default StudentsAdd;

const Code = styled.code`
  display: block;
  font-size: 80%;
  margin-top: 10px;
`;
