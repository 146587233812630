export type RequestInterceptor = (request: Request, info?: RequestInfo, requestId?: string) => Promise<Request>;
export type ResponseInterceptor = (response: Response, requestId: string) => Promise<Response>;

type Interceptors = {
  response: ResponseInterceptor[];
  request: RequestInterceptor[];
};

function createInterceptors(): Interceptors {
  return {
    response: [],
    request: []
  };
}

export { createInterceptors };
