import { createSelector } from "reselect";
import { StudentTenant } from "../../services/nav-api/student-details/types";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";
import { getMyTenant } from "./my-tenant";
import { getMyTenantTagsTree, TagNode } from "./my-tenant-tags-tree";

type StudentEditData = {
  tree?: {
    data: TagNode[];
    loading: boolean;
  };
  assignedTagIds?: number[];
  excluded?: boolean;
};

export const getStudentEditData: (state: RdxStoreState) => StudentEditData = createSelector(
  (state: RdxStoreState) => state.studentDetails,
  getMyTenant,
  getMyTenantTagsTree,
  ({ curStudentId, details, status }, tenant, { tree: data = [], loading }) => {
    if (!curStudentId || !details || !tenant) {
      return {};
    }

    if (!details || status === RdxFetchStatus.LOADING) {
      return {};
    }

    const [studentTenantTags] = (details.tenants as StudentTenant[]).filter(t => t.id === tenant.id);
    const assignedTagIds = studentTenantTags?.tags.map(t => t.id) || [];
    const excluded = Boolean(details.isExcluded);

    return { assignedTagIds, excluded, tree: { data, loading } };
  }
);
