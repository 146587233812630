import { alertsReader } from "./alerts-reader";
import { ParamlessRoute } from "./paramless-route";
import { paths } from "./paths";
import { setPassword } from "./set-password";
import { studentDetails } from "./student-details";
import { tenant } from "./tenant";
import { tenantEdit } from "./tenant-edit";
import { userDetails } from "./user-details";

const root = new ParamlessRoute(paths.root);
const login = new ParamlessRoute(paths.login);
const home = new ParamlessRoute(paths.home);
const dashboard = new ParamlessRoute(paths.dashboard);
const students = new ParamlessRoute(paths.students);
const studentsAdd = new ParamlessRoute(paths.studentsAdd);
const tenantUsers = new ParamlessRoute(paths.adminUsers);
const tags = new ParamlessRoute(paths.adminTags);
const users = new ParamlessRoute(paths.users);
const usersNew = new ParamlessRoute(paths.usersNew);
const tenants = new ParamlessRoute(paths.tenants);
const tenantNew = new ParamlessRoute(paths.tenantNew);
const partners = new ParamlessRoute(paths.partners);
const forgotPassword = new ParamlessRoute(paths.forgotPassword);
const profile = new ParamlessRoute(paths.profile);
const eula = new ParamlessRoute(paths.eula);
const alertsWriter = new ParamlessRoute(paths.alertsWriter);

export const routes = {
  alertsReader,
  alertsWriter,
  dashboard,
  eula,
  forgotPassword,
  home,
  login,
  partners,
  profile,
  root,
  setPassword,
  studentDetails,
  students,
  studentsAdd,
  tags,
  tenant,
  tenantEdit,
  tenantNew,
  tenants,
  tenantUsers,
  userDetails,
  users,
  usersNew
};
