import difference from "lodash/difference";
import { createSelector } from "reselect";
import { Role } from "../../services/nav-api/roles";
import { RdxStoreState } from "../types/state";
import { ActiveUser, getActiveUser } from "./active-user";
import { getHasTenant } from "./has-tenant";
import { getIsGlobalUser } from "./permissions/is-global-user";

// Should only be possible to select roles with the same or less permissions
// And if the user has tenant, or non global roles
// If user is not global should not be able to select global roles
export const getActiveUserRoles: (state: RdxStoreState) => Role[] = createSelector(
  getActiveUser,
  getHasTenant,
  getIsGlobalUser,
  (state: RdxStoreState) => state.myself.userRoles,
  (activeUser: ActiveUser, hasTenant: boolean, globalUser: boolean, roles: Role[]) => {
    if (activeUser && roles) {
      return roles
        .filter(role => difference(role.permissions, activeUser.permissions).length === 0)
        .filter(role => hasTenant === false || (hasTenant && role.isGlobal === false))
        .filter(role => globalUser || (!globalUser && role.isGlobal === false));
    }
    return [];
  }
);
