import { SaveOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Empty, Form, Input, Row, Select } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ErrorMessage, Field, FieldProps, Formik } from "formik";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { SYSTEM_CONFIG } from "../../../constants/system-config";
import { MESSAGES } from "../../../i18n";
import { getActiveUser } from "../../../redux/selectors/active-user";
import { getActiveUserRoles } from "../../../redux/selectors/active-user-roles";
import { getHasTenant } from "../../../redux/selectors/has-tenant";
import { TagNode, getMyTenantTagsTree } from "../../../redux/selectors/my-tenant-tags-tree";
import { getIsGlobalUser } from "../../../redux/selectors/permissions/is-global-user";
import { RdxStoreState } from "../../../redux/types/state";
import { routes } from "../../../routes";
import { Role, TenantUserName } from "../../../services/nav-api/roles";
import { User, UserType } from "../../../services/nav-api/users";
import { excludeRedundantNodes } from "../../../utils/tree-utils/assign/exclude-redundant-nodes";
import { CheckableTreeView } from "../../shared/checkable-tree-view";
import { TenantSelect } from "../../shared/tenant";
import { AccountTypeTag } from "../AccountTypeTag";

const { Option } = Select;

export interface OwnProps {
  user?: User;
  onSubmit: (props: LocalUserFormProps) => void;
  children?: React.ReactNode | React.ReactNode[];
}

export interface LocalUserFormProps {
  idp: UserType;
  email: string;
  lastName: string;
  firstName: string;
  roleId: number;
  tenantId: number | null;
  tagIds: number[];
  unsubscribeAlerts: boolean;
  allowAddStudents?: boolean;
}

const StyledErrorMessage = styled.div`
  color: red;
`;

const RightButtons = styled.div`
  float: right;
`;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const UserForm = (props: OwnProps): JSX.Element => {
  const navigate = useNavigate();
  const roles = useSelector(getActiveUserRoles);
  const activeUser = useSelector(getActiveUser);
  const globalUser = useSelector(getIsGlobalUser);
  const hasTenant = useSelector(getHasTenant);
  const userRoles = useSelector(({ myself: { userRoles } }: RdxStoreState) => userRoles ?? []);
  const roleId: number | null = props.user ? props.user.roleId : null;
  const { tree = [], loading } = useSelector(getMyTenantTagsTree);

  const initialValues = {
    idp: props.user ? props.user.idp : UserType.Local,
    email: props.user ? props.user.email : "",
    lastName: props.user ? props.user.lastName : "",
    firstName: props.user ? props.user.firstName : "",
    roleId: props.user ? props.user.roleId : -1,
    tenantId: props.user ? props.user.tenantId : null,
    tagIds: props.user ? excludeRedundantNodes(tree, props.user.tagIds) : [],
    allowAddStudents: props.user?.allowAddStudents ?? false,
    unsubscribeAlerts:
      props.user && props.user.unsubscribeAlerts != null
        ? props.user.unsubscribeAlerts
        : _.isEqual(SYSTEM_CONFIG.environment, "qa")
  };

  return (
    <Row>
      <Col span={12}>
        <Formik<LocalUserFormProps>
          onSubmit={values => props.onSubmit({ ...values, tagIds: excludeRedundantNodes(tree, values.tagIds) })}
          initialValues={initialValues}
          enableReinitialize
        >
          {formProps => {
            let filteredRoles = cloneDeep(roles);
            if (formProps.values.idp === UserType.Bim) {
              filteredRoles = filteredRoles.filter(r => r.isGlobal === true);
            }
            const selectedRole: Role = roles.filter(r => r.id === formProps.values.roleId)[0];
            const hasPermToEditTags: boolean =
              tree && //
              selectedRole &&
              !globalUser &&
              _.isEqual(selectedRole?.name, TenantUserName);
            const hasPermToSelectTenant: boolean = !hasTenant && selectedRole && selectedRole.isGlobal === false;
            const hasPermissionToEditRoles: boolean = props.user
              ? filteredRoles.filter(r => r.id === roleId).length !== 0
              : true;

            const isTenantAdmin = _.isEqual(
              _.chain(roles)
                .filter({ id: activeUser?.roleId })
                .head()
                .value().name,
              "Tenant Admin"
            );

            const userType = !isTenantAdmin ? (
              <Form.Item label="Account Type" required>
                <Field name="idp" validateStatus={formProps.submitCount && formProps.errors.idp ? "error" : "success"}>
                  {({ field }: FieldProps<UserType>) =>
                    props.user ? (
                      <AccountTypeTag userType={field.value} />
                    ) : (
                      <ButtonGroup>
                        <Button
                          type={_.isEqual(UserType.Local, field.value) ? "primary" : undefined}
                          onClick={() => {
                            if (!props.user) {
                              formProps.resetForm();
                              formProps.setFieldValue("idp", UserType.Local);
                            }
                          }}
                        >
                          Local
                        </Button>
                        <Button
                          type={_.isEqual(UserType.Bim, field.value) ? "primary" : undefined}
                          onClick={() => {
                            if (!props.user) {
                              formProps.resetForm();
                              formProps.setFieldValue("idp", UserType.Bim);
                            }
                          }}
                        >
                          BIM
                        </Button>
                      </ButtonGroup>
                    )
                  }
                </Field>
              </Form.Item>
            ) : (
              <>
                <input hidden={true} value={UserType.Local} id="idp" name="idp" readOnly />
              </>
            );

            const email = (
              <Form.Item label="Email" required>
                <Field
                  name="email"
                  validateStatus={formProps.submitCount && formProps.errors.email ? "error" : "success"}
                  validate={(v: string) => {
                    if (!v) {
                      return "Email must be defined";
                    } else if (!/\S+@\S+\.\S+/.test(v)) {
                      return "Must be a valid email address";
                    }
                  }}
                >
                  {({ field }: FieldProps<string, LocalUserFormProps>) => (
                    <Input {...field} type="email" disabled={props.user !== undefined} />
                  )}
                </Field>
                {formProps.submitCount && formProps.errors.email ? (
                  <ErrorMessage component={StyledErrorMessage} name="email" />
                ) : null}
              </Form.Item>
            );

            const role = (
              <>
                {hasPermissionToEditRoles ? (
                  <Form.Item label="Role" required>
                    <Field
                      name="roleId"
                      validateStatus={formProps.submitCount && formProps.errors.roleId ? "error" : "success"}
                      validate={(v: string) => (!v || parseInt(v) === -1 ? "Role must be defined" : undefined)}
                    >
                      {({ field }: FieldProps<number, LocalUserFormProps>) => (
                        <Select
                          getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                          onBlur={field.onBlur}
                          value={_.isEqual(field.value, -1) ? undefined : field.value}
                          onChange={id => {
                            const value = //
                              _.chain(filteredRoles) //
                                .filter({ id })
                                .head()
                                .value();

                            if (!(_.isEqual(value.name, "Tenant Admin") || _.isEqual(value.name, "Tenant User"))) {
                              formProps.setFieldValue("tenantId", null);
                            }

                            formProps.setFieldValue("roleId", id);
                          }}
                        >
                          {filteredRoles
                            ? filteredRoles.map(role => (
                                <Option key={role.id} value={role.id}>
                                  {role.name}
                                </Option>
                              ))
                            : null}
                        </Select>
                      )}
                    </Field>
                  </Form.Item>
                ) : userRoles && roleId ? (
                  <span>{userRoles.filter(r => r.id === roleId)[0].name}</span>
                ) : null}
                {formProps.submitCount && formProps.errors.roleId ? (
                  <ErrorMessage component={StyledErrorMessage} name="roleId" />
                ) : null}
              </>
            );

            const name = (
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item label="First Name" required>
                    <Field
                      name="firstName"
                      validateStatus={formProps.submitCount && formProps.errors.firstName ? "error" : "success"}
                      validate={(v: string) => (!v ? "First Name must be defined" : undefined)}
                    >
                      {({ field }: FieldProps<string, LocalUserFormProps>) => <Input {...field} />}
                    </Field>
                    {formProps.submitCount && formProps.errors.firstName ? (
                      <ErrorMessage component={StyledErrorMessage} name="firstName" />
                    ) : null}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Last Name" required>
                    <Field
                      name="lastName"
                      validateStatus={formProps.submitCount && formProps.errors.lastName ? "error" : "success"}
                      validate={(v: string) => (!v ? "Last Name must be defined" : undefined)}
                    >
                      {({ field }: FieldProps<string, LocalUserFormProps>) => <Input {...field} />}
                    </Field>
                    {formProps.submitCount && formProps.errors.lastName ? (
                      <ErrorMessage component={StyledErrorMessage} name="lastName" />
                    ) : null}
                  </Form.Item>
                </Col>
              </Row>
            );

            const permissions = hasPermToEditTags ? (
              <Card className="mb-6" title={MESSAGES.Permissions}>
                <Form.Item label="Limited access to the following tags:">
                  <Field name="tagIds">
                    {({ field }: FieldProps<number[]>) =>
                      tree.length == 0 ? (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      ) : (
                        <CheckableTreeView
                          tree={tree}
                          loading={loading}
                          checked={field.value}
                          renderNode={({ name }: TagNode) => name}
                          checkBehavior="assign"
                          onChange={({ checked }) => {
                            formProps.setFieldValue("tagIds", checked);
                          }}
                        />
                      )
                    }
                  </Field>
                </Form.Item>
                <Form.Item>
                  <Field name="allowAddStudents">
                    {({ field }: FieldProps<boolean>) => (
                      <Checkbox
                        checked={!!field.value}
                        onChange={(e: CheckboxChangeEvent) => {
                          formProps.setFieldValue("allowAddStudents", e.target.checked);
                        }}
                      >
                        Allow adding students
                      </Checkbox>
                    )}
                  </Field>
                </Form.Item>
              </Card>
            ) : null;

            const bimUsername = (
              <Form.Item label="BIM Username" required>
                <Field
                  name="email"
                  validateStatus={formProps.submitCount && formProps.errors.email ? "error" : "success"}
                  validate={(v: string) => {
                    if (!v) {
                      return "Email must be defined";
                    }
                  }}
                >
                  {({ field }: FieldProps<string, LocalUserFormProps>) => (
                    <Input {...field} disabled={props.user !== undefined} />
                  )}
                </Field>
                {formProps.submitCount && formProps.errors.email ? (
                  <ErrorMessage component={StyledErrorMessage} name="email" />
                ) : null}
              </Form.Item>
            );

            const tenant = (
              <Form.Item label="Tenant" required>
                <Field
                  name="tenantId"
                  validateStatus={formProps.submitCount && formProps.errors.tenantId ? "error" : "success"}
                  validate={(v: string) => (!v || parseInt(v) === -1 ? "Tenant must be defined" : undefined)}
                >
                  {({ field }: FieldProps<number, LocalUserFormProps>) => (
                    <TenantSelect
                      hideExtraValues
                      value={field.value}
                      onChange={val => formProps.setFieldValue("tenantId", val)}
                    />
                  )}
                </Field>
                {formProps.submitCount && formProps.errors.tenantId ? (
                  <ErrorMessage component={StyledErrorMessage} name="tenantId" />
                ) : null}
              </Form.Item>
            );

            const unsubscribeAlerts = (
              <Card className="mb-6" title={MESSAGES.Notifications}>
                <Form.Item>
                  <Field name="unsubscribeAlerts">
                    {({ field: { value } }: FieldProps<boolean>) => (
                      <Checkbox
                        checked={value}
                        onChange={(e: CheckboxChangeEvent) => {
                          formProps.setFieldValue("unsubscribeAlerts", e.target.checked);
                        }}
                      >
                        {MESSAGES.UnsubscribeEmailNotifications}
                      </Checkbox>
                    )}
                  </Field>
                </Form.Item>
              </Card>
            );

            return (
              <Form layout="vertical" onFinish={(e: React.FormEvent<any>) => formProps.handleSubmit(e!)}>
                <Card className="mb-6" title="Information">
                  {userType}
                  {_.isEqual(UserType.Local, formProps.values.idp) ? (
                    <>
                      {email}
                      {name}
                      {role}
                      {hasPermToSelectTenant ? tenant : null}
                    </>
                  ) : (
                    <>
                      {bimUsername}
                      {role}
                    </>
                  )}
                </Card>
                <>
                  {permissions}
                  {unsubscribeAlerts}
                  <Card className="mb-6">
                    <Button
                      onClick={() => {
                        navigate(routes.users.resolve());
                      }}
                    >
                      Cancel
                    </Button>{" "}
                    &nbsp;
                    <Button type="primary" htmlType="submit" disabled={!formProps.isValid} icon={<SaveOutlined />}>
                      Save
                    </Button>
                    <RightButtons>{props.children}</RightButtons>
                  </Card>
                </>
              </Form>
            );
          }}
        </Formik>
      </Col>
    </Row>
  );
};
