import _ from "lodash";
import { useSelector } from "react-redux";
import type {} from "redux-thunk/extend-redux";
import { formatMsg, MESSAGES } from "../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../redux/actions/users-list";
import { useAppDispatch } from "../../../redux/dispatch";
import { initialState } from "../../../redux/reducers/users-list";
import { getUsersListActiveFilter } from "../../../redux/selectors/users-list";
import { RdxStoreState } from "../../../redux/types/state";
import { FilterTag } from "./model";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const initialValue = initialState.workingFilter.tenantId;

// -------------------------------------------------------------------------------------------------
// - Hooks: useTenantFilter
// -------------------------------------------------------------------------------------------------

export const useTenantFilter = (): FilterTag[] => {
  const dispatch = useAppDispatch();
  const { tenantById = {} } = useSelector((state: RdxStoreState) => state.tenants.tenantsDropdown!);
  const activeFilter = useSelector(getUsersListActiveFilter);
  const tenantId = activeFilter?.filter?.tenantId;

  return !_.isEqual(tenantId, initialValue)
    ? [
        {
          onClear: () => {
            dispatch(
              doUpdateWorkingFilter({
                tenantId: initialValue
              })
            );
            dispatch(doApplyFilter());
          },
          text: formatMsg(MESSAGES.FilterTagTenant, tenantById[tenantId!]?.name ?? "")
        }
      ]
    : [];
};
