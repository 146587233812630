import React from "react";
import NavigatorLogo from "../../../assets/images/ic_navigator_logo_dark.svg";
import { END_USER_LINKS } from "../../../constants/bim-links";
import { MESSAGES } from "../../../i18n";

export const unauthLayoutProps = {
  logo: <NavigatorLogo />,
  footer: {
    links: [
      {
        href: END_USER_LINKS.contactUs,
        text: MESSAGES.ContactUs,
        "data-qa-label": "contact-us",
        target: "_blank",
        className: "text-white"
      },
      {
        href: END_USER_LINKS.privacyPolicy,
        text: MESSAGES.PrivacyPolicy,
        target: "_blank",
        className: "text-white"
      },
      {
        href: END_USER_LINKS.cookiePolicy,
        text: MESSAGES.CookiePolicy,
        target: "_blank",
        className: "text-white"
      },
      {
        text: MESSAGES.CookiePreferenceCenter,
        className: "text-white optanon-toggle-display"
      }
    ],
    socialLinks: [
      {
        type: "instagram",
        target: "_blank",
        href: END_USER_LINKS.instagram,
        className: "text-white"
      },
      {
        type: "youtube",
        target: "_blank",
        href: END_USER_LINKS.youtube,
        className: "text-white"
      },
      {
        type: "twitter",
        target: "_blank",
        href: END_USER_LINKS.twitter,
        className: "text-white"
      },
      {
        type: "facebook",
        target: "_blank",
        href: END_USER_LINKS.facebook,
        className: "text-white"
      },
      {
        type: "linkedin",
        target: "_blank",
        href: END_USER_LINKS.linkedin,
        className: "text-white"
      }
    ],
    copyrightMsg: MESSAGES.CopyrightMsg
  }
};
