import { Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Alert } from "../../../services/nav-api/alerts-reader/model";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  alert: Alert;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertsBody = ({ alert }: OwnProps): JSX.Element => (
  <StyledBodyContainer>
    <Row>
      <div
        className="ql-editor"
        dangerouslySetInnerHTML={{
          __html: alert.body
        }}
      />
    </Row>
    <Row>
      <StyledDateContainer>
        <Typography.Text type="secondary">
          {moment(alert.publishDate, "YYYY-MM-DD").format("MM-DD-YYYY")}
        </Typography.Text>
      </StyledDateContainer>
    </Row>
  </StyledBodyContainer>
);

// -------------------------------------------------------------------------------------------------
// - Styled Component
// -------------------------------------------------------------------------------------------------

const StyledDateContainer = styled(Typography.Text)`
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 24px;
  align-items: center;
`;

// -------------------------------------------------------------------------------------------------
// - Styled Component
// -------------------------------------------------------------------------------------------------

const StyledBodyContainer = styled.div`
  padding-top: 30px;
`;
