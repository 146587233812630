import { Alert, Button, Password } from "@beckersdp/becker-ds";
import React, { useState } from "react";
import { useSetPassword } from "../../hooks/use-set-password";
import { MESSAGES } from "../../i18n";
import { HttpClientErrorResponse } from "../../services/http-client/types";
import { navApi } from "../../services/nav-api";
import { ReturnToLoginButton } from "./return-login-btn";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const Alerts = ({ errorMsg, succeeded, onClose }: { errorMsg?: string; succeeded: boolean; onClose: () => void }) => {
  if (errorMsg) {
    return (
      <Alert type="danger" className="overflow-hidden relative mb-6 max-h-56" onClose={onClose}>
        {errorMsg}
      </Alert>
    );
  }
  if (succeeded) {
    return (
      <Alert type="success" className="overflow-hidden relative mb-6 max-h-56">
        {MESSAGES.SetPasswordSuccess}
      </Alert>
    );
  }
  return null;
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const SetPasswordForm = ({ token }: { token: string }) => {
  const { onPasswordChange, password, retypePassword, onRetypePasswordChange, passwordHelp, retypePasswordHelp } =
    useSetPassword();
  const [errorMsg, setErrorMsg] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);

  const formDisabled = loading || succeeded || Boolean(passwordHelp?.error) || Boolean(retypePasswordHelp?.error);

  return (
    <div className="mt-2">
      <Alerts errorMsg={errorMsg} succeeded={succeeded} onClose={() => setErrorMsg("")} />
      {!succeeded && (
        <form
          onSubmit={async (e: React.SyntheticEvent) => {
            e.preventDefault();
            setLoading(true);
            try {
              await navApi.unauth.resetPassword(password, token);
              setSucceeded(true);
            } catch (ex) {
              const error: HttpClientErrorResponse = ex;
              setErrorMsg((error && error.message) || MESSAGES.GenericRequestFailedDefaultMessage);
            }
            setLoading(false);
          }}
        >
          <Password
            label={MESSAGES.NewPassword}
            value={password}
            onChange={(e: any) => onPasswordChange((e.target as HTMLInputElement).value)}
            error={passwordHelp.error && passwordHelp.touched ? passwordHelp.text : ""}
            hint={passwordHelp.text}
          />

          <Password
            label={MESSAGES.RetypeNewPassword}
            value={retypePassword}
            onChange={(e: any) => onRetypePasswordChange((e.target as HTMLInputElement).value)}
            error={retypePasswordHelp.error && retypePasswordHelp.touched ? retypePasswordHelp.text : ""}
          />

          <Button block type="primary" htmlType="submit" loading={loading} disabled={formDisabled}>
            {MESSAGES.SubmitNewPassword}
          </Button>
        </form>
      )}
      <ReturnToLoginButton />
    </div>
  );
};
