import { SYSTEM_CONFIG } from "./system-config";

export const ENV_MAP: Record<Enviroment, string> = {
  prod: "https://bim.becker.com",
  qa: "https://bim-qa.becker.com",
  dev: "https://bim-dev.becker.com",
  local: "https://bim-dev.becker.com"
};

const curEnv: Enviroment = (SYSTEM_CONFIG.environment?.toLowerCase() as Enviroment) || "qa";

export const BIM_BASE_URL = ENV_MAP[curEnv];

function getEndUserLink(key: string): string {
  return `${BIM_BASE_URL}/external/links/${key}?productId=113&redirect`;
}

export const END_USER_LINKS = {
  cookiePolicy: getEndUserLink("CookiePolicy"),
  privacyPolicy: getEndUserLink("PrivacyPolicy"),
  contactUs: getEndUserLink("ContactUs"),
  facebook: "https://www.facebook.com/BeckerACCT/",
  linkedin: "https://www.linkedin.com/company/-becker-professional-education",
  twitter: "https://twitter.com/beckercpa",
  instagram: "https://www.instagram.com/cpabecker/",
  youtube: "https://www.youtube.com/user/BeckerProfessionalED",
  reportsDocs: "https://docs.navigator.becker.com/students/reports",
  userGuide: "https://docs.navigator.becker.com/"
};
