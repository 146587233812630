import { createSelector } from "reselect";
import { getActiveProduct } from "../redux/selectors/active-product";
import { storeRegistry } from "../redux/store/store-registry";
import { configCMA } from "./cma";
import { configCPA } from "./cpa";
import { ProductConfig } from "./types";

const productConfig = createSelector(getActiveProduct, (activeProduct): ProductConfig => {
  if (activeProduct === "cma") {
    return configCMA();
  }
  return configCPA();
});

export const getProductConfig = (): ProductConfig => productConfig(storeRegistry.getState()!);
