import * as HttpClient from "../http-client";
import { createHeaders } from "./headers";

export type HierarchyItemBaseProps = { altId: string; name: string; abbrev: string; variant: string };

export type HierarchyModule = HierarchyItemBaseProps;
export type HierarchyUnit = HierarchyItemBaseProps & {
  modules: HierarchyModule[];
};
export type HierarchySection = HierarchyItemBaseProps & {
  units: HierarchyUnit[];
};

export type HierarchyContent = {
  content: {
    sections: HierarchySection[];
  };
};

const getHierarchyContent = async (isCpaPreEvolution: boolean): Promise<HierarchyContent> =>
  HttpClient.get("/rest/hierarchyContent", createHeaders(), {
    ccontent: isCpaPreEvolution ? "Stable:4337" : undefined
  });

export { getHierarchyContent };
