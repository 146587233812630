import React from "react";
import { END_USER_LINKS } from "../../../constants/bim-links";
import { MESSAGES } from "../../../i18n";

const key = "privacy-policy";
const qaLabel = "menu-privacy-policy";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default [
  {
    key,
    label: (
      <a href={END_USER_LINKS.privacyPolicy} target="_blank" rel="noopener noreferrer" data-qa-label={qaLabel}>
        {MESSAGES.NavPrivacyPolicy}
      </a>
    )
  }
];
