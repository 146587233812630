import { CloseOutlined } from "@ant-design/icons";
import { Tag, Typography } from "@beckersdp/becker-ds";
import { Button } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../../../i18n";
import { doResetFilter } from "../../../../redux/actions/students-list/index";
import { initialWorkingFilter } from "../../../../redux/reducers/students-list/index";
import { RdxStoreState } from "../../../../redux/types/state";
import { RdxFetchStatus } from "../../../../redux/types/status";
import { useFilterTags } from "./useFilterTags";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TagsList = () => {
  const dispatch = useDispatch();
  const filterTags = useFilterTags();
  const isLoading = useSelector(
    ({ studentsList: { activeFilter, status } }: RdxStoreState) =>
      _.isEqual(status, RdxFetchStatus.LOADING) || activeFilter?.loading
  );
  const isResetDisabled = useSelector(({ studentsList }: RdxStoreState) =>
    _.isEqual(studentsList.workingFilter, initialWorkingFilter)
  );

  return filterTags?.length > 0 ? (
    <div className="flex items-center flex-wrap -mt-1 mb-1 gap-x-1">
      <Typography.Text level="2">{MESSAGES.FilteredBy}</Typography.Text>
      {_.map(filterTags, ({ text, onClear }) => (
        <Tag key={text} disabled={isLoading} onClose={onClear} className="m-0">
          {text}
        </Tag>
      ))}
      <Button
        type="text"
        className="m-0"
        icon={<CloseOutlined />}
        disabled={isLoading || isResetDisabled}
        onClick={() => {
          dispatch(doResetFilter());
        }}
      >
        {MESSAGES.ResetFilters}
      </Button>
    </div>
  ) : null;
};
