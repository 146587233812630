// tslint:disable:no-reference
// tslint:disable:no-unused-expression
// tslint:disable:no-shadowed-variable
import "whatwg-fetch";
import "./webpack_define_public_path";

declare global {
  interface Window {
    requestIdleCallback: (callback: IdleRequestCallback, options?: IdleRequestOptions | undefined) => number;
    cancelIdleCallback: (handle: number) => void;
  }
}

(function ricShim() {
  window.requestIdleCallback =
    window.requestIdleCallback ||
    ((cb: any) => {
      return setTimeout(() => {
        const start = Date.now();
        cb({
          didTimeout: false,
          timeRemaining() {
            return Math.max(0, 50 - (Date.now() - start));
          }
        });
      }, 1);
    });
  window.cancelIdleCallback = window.cancelIdleCallback || (id => window.clearTimeout(id));
})();

(function abortControllerShim() {
  class DummyAbortController implements AbortController {
    signal = {} as AbortSignal;
    abort = () => null;
  }
  window.AbortController = window.AbortController || DummyAbortController;
})();
