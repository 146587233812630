import _ from "lodash";
import { labelByAttemptType } from "../../settings";
import { ReportParams } from "../../types";

// -------------------------------------------------------------------------------------------------
// - Helper
// -------------------------------------------------------------------------------------------------

export const reduceAttempts = (params: ReportParams) =>
  _.chain(params.multipleAttemptsIncludedByReportType[params.type]) //
    .map(a => labelByAttemptType[a].replace(" ", ""))
    .join("_")
    .value();
