import { Menu, Modal } from "antd";
import React from "react";
import { MESSAGES } from "../../../i18n";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { navApi } from "../../../services/nav-api";
import { User } from "../../../services/nav-api/users";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const ForgotPassword = (props: { user: User }) => (
  <Menu.Item
    key="forgot-password"
    onClick={(): void => {
      Modal.confirm({
        title: "Forgot Password",
        content: "Are you sure that you want to trigger forgot password?",
        onOk() {
          const errorModal = (msg: string) =>
            Modal.error({
              title: "Forgot Password",
              content: msg
            });
          navApi.unauth
            .initiate({ username: props.user.username })
            .then(response => {
              response.success
                ? Modal.success({
                    title: "Forgot Password",
                    content: response.userMsg
                  })
                : errorModal(response.userMsg);
            })
            .catch((err: HttpClientErrorResponse) => errorModal(err.message ? err.message : MESSAGES.UnknownError));
        }
      });
    }}
  >
    {MESSAGES.TriggerForgotPassword}
  </Menu.Item>
);
