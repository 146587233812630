import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import cloneDeep from "lodash/cloneDeep";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { GetPartnersResponse, Partner } from "../../../services/nav-api/partners";
import { Action, ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "./../../types/status";
import { RdxPartnersState } from "../../reducers/partners";
import { getIsPartnersListLoading } from "../../selectors/is-partners-list-loading";
import { getIsPartnersListFilterEqualsToAppliedFilter } from "../../selectors/is-partners-list-filter-equals-to-active";
import { Tenant } from "../../../services/nav-api/tenants";
import { Status } from "../../../services/nav-api/types";
import { StatusFilterAllValue } from "../../../components/shared/status";
import { DEFAULT_LIST_PAGE, DEFAULT_LIST_SIZE } from "../../../constants/lists";

export const FETCH_PARTNERS_STARTED = "FETCH_PARTNERS_STARTED";
export const FETCH_PARTNERS_COMPLETED = "FETCH_PARTNERS_COMPLETED";
export const PARTNERS_LIST_SETTINGS_CHANGED = "PARTNERS_LIST_SETTINGS_CHANGED";
export const PARTNERS_LIST_FILTER_STARTED = "PARTNERS_LIST_FILTER_STARTED";
export const PARTNERS_LIST_FILTER_COMPLETED = "PARTNERS_LIST_FILTER_COMPLETED";
export const WORKING_PARTNERS_FILTER_UPDATED = "WORKING_PARTNERS_FILTER_UPDATED";
export const WORKING_PARTNERS_FILTER_CLEARED = "WORKING_PARTNERS_FILTER_CLEARED";

export const WorkingFilterTypeTypesWithoutAll: string[] = [
  "Firm",
  "University",
  "Government",
  "Society",
  "International Partner"
];

export const WorkingFilterTypeTypes: string[] = ["All", ...WorkingFilterTypeTypesWithoutAll];

export type WorkingFilterType = "All" | "Firm" | "University" | "Government" | "Society" | "International Partner";
export type WorkingFilterTypeWithoutAll = "Firm" | "University" | "Government" | "Society" | "International Partner";
export type WorkingFilter = {
  tenantId?: number;
  name?: string;
  type?: WorkingFilterType;
  limit?: number;
  offset?: number;
  status?: Status;
};

export type UpdateWorkingFilterPayload = Partial<WorkingFilter>;

export type CompletePartnersListFilterPayload = {
  error?: HttpClientErrorResponse;
  filter?: WorkingFilter;
};

export type FetchPartnersCompleted = Partial<GetPartnersResponse> & {
  error?: HttpClientErrorResponse;
  filter?: WorkingFilter;
  partners?: Partner[];
  tenants?: Tenant[];
  total: number;
};

export const doUpdateWorkingFilter = (filter: UpdateWorkingFilterPayload): Action<UpdateWorkingFilterPayload> => ({
  type: WORKING_PARTNERS_FILTER_UPDATED,
  payload: filter
});

export const doStartFetchPartners = (): Action => ({
  type: FETCH_PARTNERS_STARTED
});

const doCompleteFetchPartners = (
  response: GetPartnersResponse,
  filter?: WorkingFilter
): Action<FetchPartnersCompleted> => ({
  type: FETCH_PARTNERS_COMPLETED,
  payload: { partners: response.partners, tenants: response.tenants, total: response.total, filter }
});

const doFailFetchPartners = (error: HttpClientErrorResponse): Action<FetchPartnersCompleted> => ({
  type: FETCH_PARTNERS_COMPLETED,
  payload: { error, total: 0 }
});

const shouldFetchPartners = (state: RdxStoreState) => state.partners.status !== RdxFetchStatus.LOADING;

export type DoChangeListSettingsPayload = {
  paging: { page: number; pageSize: number };
};

export const doFetchPartners = (): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    if (!shouldFetchPartners(getState())) {
      return;
    }

    dispatch(doStartFetchPartners());

    try {
      const state: RdxPartnersState | undefined = cloneDeep(getState().partners);
      const filter: WorkingFilter | undefined = state ? state.workingFilter : undefined;

      const apiFilter = cloneDeep(filter);
      if (apiFilter) {
        if (apiFilter.type === StatusFilterAllValue) {
          delete apiFilter.type;
        }
        if ((apiFilter.status as any) === StatusFilterAllValue) {
          delete apiFilter.status;
        }
        apiFilter.limit = state.paging.itemsPerPage;
        apiFilter.offset = (state.paging.page - 1) * state.paging.itemsPerPage;
      }
      const response = await navApi.partners.getPartners(apiFilter);
      dispatch(doCompleteFetchPartners(response, filter));
    } catch (ex) {
      const error: HttpClientErrorResponse = ex;
      dispatch(doFailFetchPartners(error));
    }
  };
};

export const doChangeListSettings = (page: number, pageSize: number): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    if (getState().usersList.status === RdxFetchStatus.LOADING) {
      return;
    }
    const payload: DoChangeListSettingsPayload = {
      paging: { page, pageSize }
    };
    dispatch({
      type: PARTNERS_LIST_SETTINGS_CHANGED,
      payload
    });
    dispatch(doFetchPartners());
  };
};

export const doResetListSettings = (): ThunkAction => doChangeListSettings(DEFAULT_LIST_PAGE, DEFAULT_LIST_SIZE);

export const doApplyFilter = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    if (getIsPartnersListFilterEqualsToAppliedFilter(getState()) || getIsPartnersListLoading(getState())) {
      return;
    }
    dispatch({
      type: PARTNERS_LIST_FILTER_STARTED
    });
    const state = getState().partners;
    if (state) {
      const newFilter = cloneDeep(state.workingFilter);
      try {
        dispatch(doFetchPartners());
        const payload: CompletePartnersListFilterPayload = {
          filter: newFilter
        };
        dispatch({
          type: PARTNERS_LIST_FILTER_COMPLETED,
          payload
        });
      } catch (ex) {
        const payload: CompletePartnersListFilterPayload = {
          error: ex
        };
        dispatch({
          type: PARTNERS_LIST_FILTER_COMPLETED,
          payload
        });
      }
    }
  };
};

export const doClearFilter = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch({
      type: WORKING_PARTNERS_FILTER_CLEARED
    });

    dispatch(doApplyFilter());
  };
};
