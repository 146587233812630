import _ from "lodash";
import { createSelector } from "reselect";
import { RdxStoreState } from "../../types/state";
import { ActiveUser, getActiveUser } from "../active-user";
import { Permission } from "./model";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getIsSystemAdmin: (state: RdxStoreState) => boolean = createSelector(
  getActiveUser,
  (user: ActiveUser): boolean => {
    return _.filter(user?.permissions ?? [], p => _.isEqual(p, Permission.SYSTEM_ADMIN)).length > 0;
  }
);
