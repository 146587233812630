import includes from "lodash/includes";
import uniq from "lodash/uniq";

import { getAllAncestorIds } from "../get-all-ancestors";
import { Node } from "../types";

export type Action = { id: number; check: boolean };
export type CheckedFilterNodes = { checked: number[]; indeterminate?: number[] };

function calcIndeterminatedNodes(tree: Node[], checkedIds: number[]) {
  return uniq(
    checkedIds.reduce((ancestors: number[], id) => {
      return [...ancestors, ...getAllAncestorIds(tree, id)];
      // }, checkedIds)
    }, [])
  ).filter(ancestorId => !includes(checkedIds, ancestorId));
}

export { calcIndeterminatedNodes };
