import { createSelector } from "reselect";
import { RdxTenantsState } from "../reducers/tenants";
import { RdxStoreState } from "../types/state";

export const getTenantsDropdownDataSource: (state: RdxStoreState) => Array<{
  id: number;
  name: string;
}> = createSelector(
  (state: RdxStoreState) => state.tenants,
  ({ tenantsDropdown }: RdxTenantsState) =>
    tenantsDropdown?.allIds.map(id => {
      const tenant = tenantsDropdown.tenantById[id];
      return {
        id: tenant.id,
        name: tenant.name
      };
    }) || []
);
