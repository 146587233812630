import type { MenuProps } from "antd";
import { Menu } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { doChangeActiveProduct } from "../../redux/actions/myself";
import { getProductSwitcherOptions } from "../../redux/selectors/product-switcher-options";
import { TenantProducts } from "../../services/nav-api/tenants";

type MenuItem = Required<MenuProps>["items"][number];

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const ProductOption = (props: React.HTMLProps<HTMLDivElement>) => (
  <div
    className={_.join(
      [
        "text-center",
        "text-blue-950",
        "font-extrabold",
        "items-center" //
      ],
      " "
    )}
  >
    {props.children}
  </div>
);

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const ProductSwitcher: React.FC = () => {
  const options = useSelector(getProductSwitcherOptions);
  const dispatch = useDispatch();

  if (!options) {
    return null;
  }

  if (!options.otherProducts.length) {
    return <ProductOption>{options.active.toUpperCase()}</ProductOption>;
  }

  return (
    <Menu
      mode="vertical"
      title={options.active.toUpperCase()}
      onClick={({ key }) => {
        dispatch(doChangeActiveProduct(key as TenantProducts));
      }}
      items={[
        {
          label: <ProductOption>{options.active.toUpperCase()}</ProductOption>,
          key: "PSWITCHM_1",
          children: [
            {
              type: "group",
              label: "Switch instance to",
              disabled: true
            } as MenuItem,
            ..._.map(
              options.otherProducts,
              product =>
                ({
                  label: <ProductOption>{product.toUpperCase()}</ProductOption>,
                  key: product
                }) as MenuItem
            )
          ]
        }
      ]}
    />
  );
};
