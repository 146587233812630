import { Form } from "antd";
import React from "react";
import { TenantSelect } from "../../shared/tenant";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantFilter = (props: { onChange: (value?: number) => void; value: number | undefined }) => (
  <Form.Item label="Tenant">
    <TenantSelect //
      onChange={props.onChange}
      value={props.value}
      style={{ width: "100%" }}
    />
  </Form.Item>
);
