import { TeamOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MESSAGES } from "../../../i18n";
import { ActiveUser } from "../../../redux/selectors/active-user";
import { routes } from "../../../routes";
import { AlertsBadge } from "./common";
import menuItemMyAccountLogout from "./mi-account-logout";
import menuItemMyAccountNotifications from "./mi-account-notifications";
import menuItemMyAccountPrivacy from "./mi-account-privacy";
import menuItemMyAccountUserGuide from "./mi-account-user-guide";

const keyGroup = "my-account-group";
const qaLabelGroup = "menu-user-details";

const keyItem = "my-account";
const qaLabelItem = "menu-my-account";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default (activeUser: ActiveUser | undefined, isCookiePrefVisible: boolean) => [
  {
    key: keyGroup,
    label: (
      <>
        <AlertsBadge qaLabel={qaLabelGroup}>
          <TeamOutlined />
          <StyledAccountLabel>{`${activeUser!.firstName} ${activeUser!.lastName}`}</StyledAccountLabel>
        </AlertsBadge>
      </>
    ),
    children: [
      {
        key: keyItem,
        label: (
          <Link to={routes.profile.resolve()} data-qa-label={qaLabelItem}>
            <span>{MESSAGES.NavMyAccount}</span>
          </Link>
        )
      },
      ...menuItemMyAccountNotifications,
      ...menuItemMyAccountUserGuide,
      ...menuItemMyAccountPrivacy(isCookiePrefVisible),
      ...menuItemMyAccountLogout
    ]
  }
];

// -------------------------------------------------------------------------------------------------
// - Styled Components
// -------------------------------------------------------------------------------------------------

const StyledAccountLabel = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 10px;
`;
