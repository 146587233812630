import { Node } from "./types";
import cloneDeep from "lodash/cloneDeep";

export function getFullPath(tree: Node[], destNodeId: number) {
  const stack = cloneDeep(tree);
  const parentIdById: { [id: number]: number } = {};
  let found = false;

  while (stack.length && !found) {
    const curNode = stack.pop()!;

    if (curNode.id === destNodeId) {
      found = true;
      break;
    }
    curNode.children.forEach(n => {
      parentIdById[n.id] = curNode.id;
    });
    stack.push(...curNode.children);
  }

  if (!found) {
    return;
  }

  let baseNodeId = destNodeId;
  const path = [];
  while (baseNodeId) {
    path.unshift(baseNodeId);
    baseNodeId = parentIdById[baseNodeId];
  }

  return path;
}
