import { StyleProvider } from "@ant-design/cssinjs";
import { defaultTheme, EDSGlobalStyles } from "@beckersdp/becker-ds";
import { App as AntdApplication } from "antd";
import React from "react";
import { Provider } from "react-redux";
import { Store } from "redux";
import { ThemeProvider } from "styled-components";
import { ApiFailureModal } from "./components/api-failure-modal";
import { EnvironmentInfo } from "./components/support/environment-info";
import { Router } from "./router";
import { TrackUnhandledErrorsBoundary } from "./services/error-tracking/unhandled-errors-boundary";
import { setAntdDefaults } from "./styles/antd-set-defaults";
import { theme } from "./styles/theming";

import "./public/styles/global-styles";

setAntdDefaults();

type Props = {
  store: Store;
};

const App: React.FC<Props> = ({ store }) => (
  <div
    ref={() => {
      //
      // Hack to allow users to launch Privace Preference Center from the main menu
      //
      const elInput = document.createElement("input");
      elInput.setAttribute("type", "hidden");
      elInput.setAttribute("role", "button");
      elInput.setAttribute("id", "ot-sdk-btn");
      elInput.setAttribute("class", "ot-sdk-show-settings");

      document.body.insertBefore(elInput, document.body.firstChild);
    }}
  >
    <TrackUnhandledErrorsBoundary>
      <StyleProvider hashPriority="high">
        <AntdApplication>
          <ThemeProvider theme={{ ...theme, ...defaultTheme }}>
            <Provider store={store}>
              <Router />
              <ApiFailureModal />
              <EnvironmentInfo />
            </Provider>
            <EDSGlobalStyles />
          </ThemeProvider>
        </AntdApplication>
      </StyleProvider>
    </TrackUnhandledErrorsBoundary>
  </div>
);

export default App;
