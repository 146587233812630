import { useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doNotifyApiFailure } from "../../../../redux/actions/api-failure";
import { getStudentRosterToken } from "../../../../redux/selectors/student-roster-token";
import { getStudentsTableDetails } from "../../../../redux/selectors/students-table-data-source";
import { navApi } from "../../../../services/nav-api";
import { createNotifier } from "../../../../services/task/notifier";
import { StudentTableActionsContextProps } from "./";

const GENERATE_TASK_COMPLETED = "GENERATE_TASK_COMPLETED";

const BULK_CHANGE_CUR_PAGE_CLICKED = "BULK_CHANGE_CUR_PAGE_CLICKED";
const BULK_CHANGE_CUR_PAGE_CONFIRMED = "BULK_CHANGE_CUR_PAGE_CONFIRMED";
const BULK_CHANGE_MODAL_CLOSED = "BULK_CHANGE_MODAL_CLOSED";
const BULK_CHANGE_ALL_ITEMS_CLICKED = "BULK_CHANGE_ALL_ITEMS_CLICKED";

const SELECTION_CHANGED = "SELECTION_CHANGED";
const SELECTION_CANCELED = "SELECTION_CANCELED";
const SELECTION_CONFIRMED = "SELECTION_CONFIRMED";

export type StudentTableActionTypes = "bulk-change";

type StudentsTableActionsState = {
  selection: {
    active: boolean;
    ids: number[];
    loading: boolean;
  };
  activeOperation?: StudentTableActionTypes;
  bulkChange: {
    modalOpen: boolean;
    token?: string;
    total?: number;
  };
};

const initialState: StudentsTableActionsState = {
  selection: {
    active: false,
    ids: [],
    loading: false
  },
  bulkChange: {
    modalOpen: false
  }
};

function reducer(
  state: StudentsTableActionsState,
  { type, payload }: { type: string; payload?: any }
): StudentsTableActionsState {
  switch (type) {
    case GENERATE_TASK_COMPLETED:
    case SELECTION_CANCELED:
      return {
        ...state,
        activeOperation: undefined,
        selection: {
          active: false,
          ids: [],
          loading: false
        }
      };
    case SELECTION_CHANGED:
      return {
        ...state,
        selection: {
          active: true,
          ids: payload.ids,
          loading: false
        }
      };
    case SELECTION_CONFIRMED:
      return state.activeOperation === "bulk-change"
        ? { ...state, selection: { ...state.selection, loading: true } }
        : {
            ...state,
            selection: {
              ...state.selection,
              active: false
            }
          };
    case BULK_CHANGE_CUR_PAGE_CLICKED:
      return {
        ...state,
        activeOperation: "bulk-change",
        selection: {
          active: true,
          ids: payload.ids || [],
          loading: false
        }
      };

    case BULK_CHANGE_ALL_ITEMS_CLICKED:
    case BULK_CHANGE_CUR_PAGE_CONFIRMED:
      return {
        ...state,
        selection: {
          ...state.selection,
          loading: false
        },
        activeOperation: "bulk-change",
        bulkChange: {
          modalOpen: true,
          token: payload.token,
          total: payload.total
        }
      };

    case BULK_CHANGE_MODAL_CLOSED:
      return {
        ...state,
        activeOperation: undefined,
        selection: {
          active: false,
          ids: [],
          loading: false
        },
        bulkChange: {
          modalOpen: false
        }
      };
    default:
      return state;
  }
}

export const useStudentTableActionsContextProvider = (): StudentTableActionsContextProps => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchRdx = useDispatch();
  const [showFilters, setShowFilters] = useState(false);
  const activeStudentFilter = useSelector(getStudentRosterToken);
  const { dataSource } = useSelector(getStudentsTableDetails);
  const curPageStudentIds = (dataSource || []).map(s => s.id);

  return {
    selection: state.selection,
    bulkChange: state.bulkChange,
    operation: state.activeOperation,
    showFilters,
    onShowFiltersChange: setShowFilters,
    onBulkCurPage: () => {
      dispatch({ type: BULK_CHANGE_CUR_PAGE_CLICKED, payload: { ids: curPageStudentIds } });
    },
    onBulkAllItems: () => {
      dispatch({
        type: BULK_CHANGE_ALL_ITEMS_CLICKED,
        payload: {
          token: activeStudentFilter.token,
          total: activeStudentFilter.total
        }
      });
    },
    onSelectionConfirm: async () => {
      dispatch({ type: SELECTION_CONFIRMED });
      let notifier: ReturnType<typeof createNotifier> | undefined;
      try {
        if (state.activeOperation === "bulk-change") {
          const { token, total } = await navApi.studentRoster.createStudentRoster({ studentIds: state.selection.ids });
          dispatch({ type: BULK_CHANGE_CUR_PAGE_CONFIRMED, payload: { token, total } });
          return;
        }
      } catch (error) {
        dispatch({ type: SELECTION_CANCELED });
        if (notifier) {
          notifier.hide();
        }
        dispatchRdx(doNotifyApiFailure(error));
      }
    },
    onSelectionChange: (ids: number[]) => {
      dispatch({ type: SELECTION_CHANGED, payload: { ids } });
    },
    onSelectionCancel: () => {
      dispatch({ type: SELECTION_CANCELED });
    },
    onBulkChangeModalClose: () => {
      dispatch({ type: BULK_CHANGE_MODAL_CLOSED });
    }
  };
};
