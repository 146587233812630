import { useDispatch, useSelector } from "react-redux";
import { MESSAGES, formatMsg } from "../../../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../../../redux/actions/students-list";
import { initialState } from "../../../../../redux/reducers/students-list";
import { RdxStoreState } from "../../../../../redux/types/state";
import { studyPlanLabelByValue } from "../../../students-filter/metadata";
import { FilterTag } from "../model";

// -------------------------------------------------------------------------------------------------
// - Hooks: useStudyPlan
// -------------------------------------------------------------------------------------------------

export const useStudyPlan = (): FilterTag[] => {
  const dispatch = useDispatch();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);
  return activeFilter?.filter?.studyPlan && activeFilter.filter.studyPlan !== initialState.workingFilter.studyPlan
    ? [
        {
          onClear: () => {
            dispatch(doUpdateWorkingFilter({ studyPlan: initialState.workingFilter.studyPlan }));
            dispatch(doApplyFilter());
          },
          text: formatMsg(MESSAGES.FilterTagStudyPlan, studyPlanLabelByValue[activeFilter.filter.studyPlan])
        }
      ]
    : [];
};
