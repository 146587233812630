import dayjs from "dayjs";
import { AltID } from "../../../../constants/courses";
import { SimulatedExamsReportOption } from "../../../../services/nav-api/student-roster/types";

export enum ReportType {
  EXAM_TRACKER_REPORT = "EXAM_TRACKER_REPORT",
  HOMEWORK_MCQ_FIRST_ATTEMPT_REPORT = "HOMEWORK_MCQ_FIRST_ATTEMPT_REPORT",
  HOMEWORK_PERFORMANCE_REPORT = "HOMEWORK_PERFORMANCE_REPORT",

  MINI_EXAMS_PERFORMANCE_REPORT = "MINI_EXAMS_PERFORMANCE_REPORT",
  MINI_EXAMS_SCORE_REPORT = "MINI_EXAMS_SCORE_REPORT",
  PERFORMANCE_DASHBOARD_REPORT = "PERFORMANCE_DASHBOARD_REPORT",
  PERFORMANCE_DASHBOARD_FR_REPORT = "PERFORMANCE_DASHBOARD_FR_REPORT",
  SIMULATED_EXAMS_ANSWERS_REPORT = "SIMULATED_EXAMS_ANSWERS_REPORT",
  SIMULATED_EXAMS_PERFORMANCE_REPORT = "SIMULATED_EXAMS_PERFORMANCE_REPORT",
  SIMULATED_EXAMS_SCORE_REPORT = "SIMULATED_EXAMS_SCORE_REPORT",
  SIMULATED_EXAMS_SCORE_FR_REPORT = "SIMULATED_EXAMS_SCORE_FR_REPORT",
  STUDENTS_LIST_REPORT = "STUDENTS_LIST_REPORT",
  STUDY_TIME_REPORT = "STUDY_TIME_REPORT",
  TIMESHEET_REPORT = "TIMESHEET_REPORT",

  MINI_EXAMS_ANSWERS_REPORT = "MINI_EXAMS_ANSWERS_REPORT"
}

export enum HierarchyLevel {
  SECTION = "SECTION",
  UNIT = "UNIT",
  MODULE = "MODULE"
}

export enum ExamNumber {
  _1 = "#1",
  _2 = "#2",
  _3 = "#3"
}

export type AttemptByReportType = {
  [key in ReportType]?: SimulatedExamsReportOption;
};

export type MultipleAttemptsByReportType = {
  [key in ReportType]?: SimulatedExamsReportOption[];
};

export type ModulesIncludeByReportType = {
  [key in ReportType]?: boolean;
};

export type YearMonthByReportType = {
  [key in ReportType]?: dayjs.Dayjs;
};

export type SectionUnitIncludedByReportType = {
  [key in ReportType]?: string;
};

export type SectionsIncludedByReportType = {
  [key in ReportType]?: AltID[];
};

export type SectionIncludedByReportType = {
  [key in ReportType]?: AltID;
};

export type HierarchyLevelIncludedByReportType = {
  [key in ReportType]?: HierarchyLevel;
};

export type ExamNumberByReportType = {
  [key in ReportType]?: ExamNumber;
};

export type ReportParams = {
  type: ReportType;
  token: string;
  limit: number;
  offset: number;
  sort: string;
  attemptByReportType: AttemptByReportType;
  timesheetPeriodByReportType: YearMonthByReportType;
  singleSectionIncludedByReportType: SectionIncludedByReportType;
  multipleAttemptsIncludedByReportType: MultipleAttemptsByReportType;
  multipleSectionsIncludedByReportType: SectionsIncludedByReportType;
  hierarchyLevelIncludedByReportType: HierarchyLevelIncludedByReportType;
  sectionUnitIncludedByReportType: SectionUnitIncludedByReportType;
  examNumberByReportType: ExamNumberByReportType;
  plainUnitsList: Array<{
    unitAltId: string;
    filenameSuffix: string;
  }>;
};

export type ReportResponse = {
  filename: string;
  blob: Blob;
};

export type ReportGenerator = (params: ReportParams) => Promise<ReportResponse>;
