import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { TaskResponse } from "./types";

const getTask = async (taskToken: string): Promise<TaskResponse> => {
  const url = `/rest/tasks/${taskToken}`;
  return HttpClient.get(url, createHeaders());
};

const getTaskBlog = async (taskToken: string): Promise<Blob> => {
  return HttpClient.get(`/rest/tasks/${taskToken}/file/binary`, createHeaders());
};

export { getTask, getTaskBlog };
