import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { Action, ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { HttpClientErrorResponse } from "./../../../services/http-client/types";

export const TAG_EDITOR_EDIT_OPENED = "TAG_EDITOR_EDIT_OPENED";
export const TAG_EDITOR_CREATE_OPENED = "TAG_EDITOR_CREATE_OPENED";
export const TAG_EDITOR_CANCELED = "TAG_EDITOR_CANCELED";
export const TAG_EDITOR_TAG_DRAG_STARTED = "TAG_EDITOR_TAG_DRAG_STARTED";
export const TAG_EDITOR_TAG_DRAG_COMPLETED = "TAG_EDITOR_TAG_DRAG_COMPLETED";

export type TagEditorEditPayload = {
  id: number;
  parentId?: number;
  name: string;
  dragged?: boolean;
};

export type TagEditorDragStartedPayload = {
  id: number;
  parentId?: number;
};

export type TagEditorDragCompletedPayload = {
  tenantId: number;
  id: number;
  parentId?: number;
  error?: HttpClientErrorResponse;
};

export const doEditTenantTag = (id: number, name: string, parentId?: number): Action => ({
  type: TAG_EDITOR_EDIT_OPENED,
  payload: { id, name, parentId }
});

export const doCreateTenantTag = (parentId?: number): Action => ({
  type: TAG_EDITOR_CREATE_OPENED,
  payload: parentId
});

export const doCancelEditingTag = (): Action => ({
  type: TAG_EDITOR_CANCELED
});

export const doDragTenantTag = (tenantId: number, id: number, parentId?: number): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    dispatch({
      type: TAG_EDITOR_TAG_DRAG_STARTED,
      payload: { id, parentId }
    });
    const tagById = getState().tenants.tags!.byId;

    try {
      await navApi.tenants.updateTenantTag(tenantId, id, tagById[id].name, parentId);
      const payload: TagEditorDragCompletedPayload = {
        id,
        parentId,
        tenantId
      };
      dispatch({
        type: TAG_EDITOR_TAG_DRAG_COMPLETED,
        payload
      });
    } catch (error) {
      dispatch({
        type: TAG_EDITOR_TAG_DRAG_COMPLETED,
        payload: { error, id }
      });
    }
  };
};
