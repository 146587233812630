import { LoadingOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import React, { Suspense } from "react";
import { BaseLayout } from "../../components/layout/base-layout";
import { PageTitle } from "../../components/layout/page-title";
import { NavigationDrawerOptionKey } from "../../components/navigation-drawer/types";
import { TenantsTable } from "../../components/tenants/list";
import { TenantsTableActions } from "../../components/tenants/list/actions";
import { TenantsSidebar } from "../../components/tenants/sidebar";

// -------------------------------------------------------------------------------------------------
// - Tenants
// -------------------------------------------------------------------------------------------------

export const Tenants = () => (
  <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_TENANTS}>
    <PageTitle>Tenants</PageTitle>
    <div className="float-right -mt-12 gap-2">
      <TenantsTableActions />
    </div>
    <Row gutter={16}>
      <Col xs={18} xxl={19}>
        <Card bodyStyle={{ overflowX: "auto" }}>
          <Suspense fallback={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
            <TenantsTable />
          </Suspense>
        </Card>
      </Col>
      <Col xs={6} xxl={5}>
        <TenantsSidebar />
      </Col>
    </Row>
  </BaseLayout>
);
