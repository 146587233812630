import { PlusOutlined } from "@ant-design/icons";
import { Button, message, Tree } from "antd";
import RCTree from "rc-tree";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { MESSAGES } from "../../../i18n";
import { getMyTenant } from "../../../redux/selectors/my-tenant";
import { getTagsTreeEditor } from "../../../redux/selectors/tags-tree-editor";
import { Loader } from "../../loader";
import { MAX_DEPTH_LEVEL_ALLOWED } from "./constants";
import { TagsEditorContext } from "./context";
import { useTagsEditorHook } from "./tags-editor-hook";
import { renderTreeNodes } from "./tags-tree-node";

type OnDrop = React.ComponentProps<typeof Tree>["onDrop"];

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TagsEditor = () => {
  const { loading, tree = [] } = useSelector(getTagsTreeEditor);
  const treeRef = useRef<RCTree>(null);
  const tenant = useSelector(getMyTenant);
  const tagsManagerProviderValue = useTagsEditorHook(tenant!.id, treeRef);

  if (loading || !tree) {
    return <Loader />;
  }

  return (
    <TagsEditorContext.Provider value={tagsManagerProviderValue}>
      <div className="flex gap-x-2">
        <Tree
          autoExpandParent
          selectable={false}
          draggable
          showLine={{ showLeafIcon: false }}
          ref={treeRef}
          onDrop={info => {
            const id = parseInt(info.dragNode.key as string);
            const dragNodeMaxDepth: number = (info.dragNode as { title: React.ReactElement<any> }).title.props.maxDepth;
            const dropNodeDepthLevel: number = (info.node as any).props.title.props.depthLevel;
            let parentId: number | undefined = parseInt((info.node as any).props.eventKey!);

            if (dropNodeDepthLevel === 1 && info.node.dragOverGapTop) {
              parentId = undefined;
            }

            if (dragNodeMaxDepth + dropNodeDepthLevel > MAX_DEPTH_LEVEL_ALLOWED) {
              return message.error(MESSAGES.CantDropMaxLevelReached);
            }

            tagsManagerProviderValue.onDrop(id, parentId);
          }}
        >
          {tree.map(renderTreeNodes)}
        </Tree>

        <Button
          icon={<PlusOutlined />}
          onClick={() => tagsManagerProviderValue.onCreate()}
          data-qa-label="add-category-btn"
        >
          {MESSAGES.AddNewRootLevelTag}
        </Button>
      </div>
    </TagsEditorContext.Provider>
  );
};
