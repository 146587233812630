import { getActiveUser } from "../redux/selectors/active-user";
import { RdxStore } from "../redux/types/store";
import { errorTracker } from "../services/error-tracking";

export function updateErrorTrackerUserId(store: RdxStore) {
  let oldMySelfState: any;

  store.subscribe(() => {
    if (oldMySelfState === store.getState().myself) {
      return;
    }
    const user = getActiveUser(store.getState());
    if (user && user.userId) {
      errorTracker.setUser(user.userId.toString());
    }
    oldMySelfState = store.getState().myself;
  });
}
