import { Button } from "@beckersdp/becker-ds";
import React from "react";
import { Link } from "react-router-dom";
import { MESSAGES } from "../../i18n";
import { routes } from "../../routes";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const ReturnToLoginButton: React.FC = () => (
  <Link to={routes.login.resolve()}>
    <Button block htmlType="button" style={{ marginTop: "20px" }} icon="arrow-left">
      {MESSAGES.ReturnToSignInBtnCaption}
    </Button>
  </Link>
);
