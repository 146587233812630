import { Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTenantsDropdown } from "../../redux/actions/tenants/tenants-dropdown";
import { getTenantsDropdownDataSource } from "../../redux/selectors/tenants-dropdown-data-source";

const { Option } = Select;
export const NoTenantFilterAllValue = 0;
export const TenantIdFilterAllValue = -1;

interface ComponentProps {
  onChange: (value: number) => void;
  value: number | undefined;
  hideExtraValues?: boolean;
  allowClear?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const TenantSelect: React.FC<ComponentProps> = props => {
  const dispatch = useDispatch();
  const tenants = useSelector(getTenantsDropdownDataSource);

  useEffect(() => {
    dispatch(fetchTenantsDropdown());
  }, []);

  return (
    <Select
      placeholder="Tenant name"
      className={props.className}
      style={props.style}
      value={tenants && tenants.length ? props.value : undefined}
      onChange={props.onChange}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => {
        const optText = option!.props.children as string;
        return optText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      allowClear={props.allowClear}
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      data-qa-label="tenant-select"
    >
      {tenants
        .filter(tenant => !props.hideExtraValues || tenant.id > 0)
        .map(t => (
          <Option key={t.id} value={t.id}>
            {t.name}
          </Option>
        ))}
    </Select>
  );
};
