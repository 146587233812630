import { Card, Table, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleCellPercentage, handleCellText } from "../../../components/shared/table/table-cell-value";
import { MESSAGES } from "../../../i18n";
import { getProductConfig } from "../../../product-config";
import { getActiveProduct } from "../../../redux/selectors/active-product";
import {
  PerformanceDashboardItem,
  getStudentPerformanceDashboard
} from "../../../redux/selectors/student-performance-dashboard";

const { Column, ColumnGroup } = Table;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentPerformanceDashboard = () => {
  const { dataSource, loading } = useSelector(getStudentPerformanceDashboard);
  const activeProduct = useSelector(getActiveProduct);
  const isCPA = _.isEqual(activeProduct, "cpa");
  return (
    <Card
      loading={loading}
      title={
        <div className="ant-card-head-wrapper">
          <div className="ant-card-head-title" data-qa-label="sd-performance-title">
            {MESSAGES.StudentPerformance}
          </div>
        </div>
      }
    >
      <StyledTable
        rowClassName="cursor-pointer"
        dataSource={dataSource}
        pagination={false}
        loading={loading}
        size="middle"
        rowKey="key"
        columns={[
          // ---------------------------------------------------------------------------------------
          // - Section
          // ---------------------------------------------------------------------------------------

          {
            title: "Section",
            align: "left",
            render: (obj: PerformanceDashboardItem) => (
              <Typography.Text key={`section-${obj.altId}`} title={obj.name}>
                {obj.name}
              </Typography.Text>
            )
          },

          // ---------------------------------------------------------------------------------------
          // - Videos
          // ---------------------------------------------------------------------------------------

          {
            title: "Videos",
            key: "videos",
            children: [
              {
                title: "% Completed",
                dataIndex: "conceptVideosProgress",
                render: handleCellPercentage
              }
            ]
          },

          // ---------------------------------------------------------------------------------------
          // - Multiple Choice Questions
          // ---------------------------------------------------------------------------------------

          {
            title: "Multiple Choice Questions",
            key: "mcq-group",
            children: [
              {
                title: "# Questions",
                dataIndex: "mcqsTotal",
                key: "mcqsTotal",
                render: handleCellText
              },
              {
                title: "# Correct",
                dataIndex: "mcqsCorrect",
                key: "mcqsCorrect",
                render: handleCellText
              },
              {
                title: "% Correct",
                dataIndex: "mcqsProgress",
                key: "mcqsProgress",
                render: handleCellPercentage
              }
            ]
          },

          // ---------------------------------------------------------------------------------------
          // - Simulations
          // ---------------------------------------------------------------------------------------

          ...(getProductConfig().performanceDashboard.simulations
            ? [
                {
                  title: "Simulations",
                  children: [
                    {
                      title: "# Questions",
                      dataIndex: "simsTotal",
                      render: handleCellText
                    },
                    {
                      title: "# Correct",
                      dataIndex: "simsCorrect",
                      render: handleCellText
                    },
                    {
                      title: "% Correct",
                      dataIndex: "simsProgress",
                      render: handleCellPercentage
                    }
                  ]
                }
              ]
            : []),

          // ---------------------------------------------------------------------------------------
          // - Essays
          // ---------------------------------------------------------------------------------------

          ...(getProductConfig().performanceDashboard.essays
            ? [
                {
                  title: "Essays",
                  children: [
                    {
                      render: handleCellText,
                      title: "# Questions",
                      dataIndex: "essaysTotal"
                    },
                    {
                      render: handleCellText,
                      title: "# Correct",
                      dataIndex: "essaysCorrect"
                    },
                    {
                      render: handleCellPercentage,
                      title: "% Correct",
                      dataIndex: "essaysProgress"
                    }
                  ]
                }
              ]
            : []),

          // ---------------------------------------------------------------------------------------
          // -
          // ---------------------------------------------------------------------------------------

          {
            render: handleCellPercentage,
            title: isCPA ? "EDR" : "% Total",
            dataIndex: "progress",
            key: "progress"
          }
        ]}
      />
    </Card>
  );
};

// -------------------------------------------------------------------------------------------------
// - Styled Components
// -------------------------------------------------------------------------------------------------

const StyledTable = styled(Table)`
  .ant-table-cell {
    text-align: center;
  }

  .ant-table-row-level-0 {
    font-weight: 600;
  }

  tr.ant-table-row-level-1 {
    td:first-child {
      font-weight: 600;
    }
  }

  .section-col {
    width: 17.1875rem;
    max-width: 17.1875rem;
    min-width: 17.1875rem;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  th.section-col {
    text-align: center;
  }

  tbody {
    tr:nth-child(even) {
      background: rgb(251, 252, 254);
    }
  }
`;
