import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Drawer, Typography } from "antd";
import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DetailsWrapper } from ".";
import { ContentVersionSwitch } from "../../components/contentversion-switch";
import { BaseLayout } from "../../components/layout/base-layout";
import { NavigationDrawerOptionKey } from "../../components/navigation-drawer/types";
import { Can } from "../../components/permissions/can";
import { FEATURES } from "../../constants/ui-features";
import { MESSAGES } from "../../i18n";
import { doRefreshStudentsListIfNeeded } from "../../redux/actions/students-list";
import { getContentVersion } from "../../redux/selectors/contentversion";
import { getIsGlobalUser } from "../../redux/selectors/permissions/is-global-user";
import { getStudentDetailsPageTitle } from "../../redux/selectors/student-details-page-title";
import { routes } from "../../routes";
import { StudentDetails } from "../StudentDetails";
import { FollowStudent } from "./student-info/follow-student";
import { StudentsFilter } from "./students-filter";
import { StudentsTable } from "./students-table";
import { StudentTableActions } from "./students-table/table-actions";
import { StudentTableActionsContext } from "./students-table/table-actions-context";
import { useStudentTableActionsContextProvider } from "./students-table/table-actions-context/context-provider";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Students = (props: { showStudentDetails?: boolean }) => {
  const studentsTableActionsContextProps = useStudentTableActionsContextProvider();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const studentId = parseInt(id!);
  const contentversion = useSelector(getContentVersion);
  const pageTitle: string = useSelector(getStudentDetailsPageTitle);
  const isGlobalUser = useSelector(getIsGlobalUser);

  useEffect(() => {
    dispatch(doRefreshStudentsListIfNeeded(true));
  }, [contentversion]);

  return (
    <BaseLayout activeNavDrawerKey={NavigationDrawerOptionKey.STUDENTS}>
      <StudentTableActionsContext.Provider value={studentsTableActionsContextProps}>
        <div className="flex items-center gap-x-2">
          <Typography.Title level={2} className="grow m-0" data-qa-label="students-page">
            {MESSAGES.StudentsPageTitle}
          </Typography.Title>
          <ContentVersionSwitch />
          <Can feature={FEATURES.ADD_TENANT_STUDENTS}>
            <Button
              type="primary"
              data-qa-label="addStudentsBtn"
              onClick={() => {
                navigate(routes.studentsAdd.resolve());
              }}
            >
              <PlusOutlined />
              {MESSAGES.AddStudents}
            </Button>
          </Can>
        </div>
        <StudentTableActions />
        <Card bodyStyle={{ overflowX: "auto" }}>
          <Suspense fallback={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
            <StudentsTable />
          </Suspense>
        </Card>
        <Drawer
          closable
          width={430}
          title={MESSAGES.FiltersDrawerTitle}
          placement="right"
          open={studentsTableActionsContextProps.showFilters}
          onClose={() => {
            studentsTableActionsContextProps.onShowFiltersChange(false);
          }}
        >
          <StudentsFilter />
        </Drawer>
        <Drawer
          placement="right"
          closable={true}
          title={pageTitle}
          extra={isGlobalUser ? null : <FollowStudent />}
          width={Math.min(1100, window.innerWidth)}
          onClose={() => {
            navigate(routes.students.resolve());
          }}
          open={!!props.showStudentDetails}
        >
          <DetailsWrapper>
            <StudentDetails id={studentId} />
          </DetailsWrapper>
        </Drawer>
      </StudentTableActionsContext.Provider>
    </BaseLayout>
  );
};
