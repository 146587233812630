import dayjs from "dayjs";
import React from "react";
import { initialTimesheetDatesByReportType } from "../settings";
import { ReportType, YearMonthByReportType } from "../types";

// -------------------------------------------------------------------------------------------------
// - Hook
// -------------------------------------------------------------------------------------------------

export const useTimesheetByReportType = (reportType: ReportType) => {
  const [timesheetPeriodByReportType, setTimesheetPeriodByReportType] = React.useState<YearMonthByReportType>(
    initialTimesheetDatesByReportType
  );

  return {
    value: timesheetPeriodByReportType,
    valueForCurReportType: !!reportType ? timesheetPeriodByReportType[reportType] : null,
    isAvailableForReportType: initialTimesheetDatesByReportType[reportType] != null,

    setValue: (reportType: ReportType, option: dayjs.Dayjs) => {
      setTimesheetPeriodByReportType(previous => ({ ...previous, [reportType]: option }));
    },

    reset: () => {
      setTimesheetPeriodByReportType(initialTimesheetDatesByReportType);
    }
  };
};
