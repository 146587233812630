import React from "react";
import { errorTracker } from ".";

export class TrackUnhandledErrorsBoundary extends React.Component<React.HTMLProps<HTMLDivElement>> {
  componentDidCatch(error: Error) {
    errorTracker.notifyError(error);
  }

  render() {
    return this.props.children;
  }
}
