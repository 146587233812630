import { createSelector } from "reselect";
import { RdxStoreState } from "../../types/state";
import { ActiveUser, getActiveUser } from "../active-user";
import { Permission } from "./model";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getHasUsersPermission: (state: RdxStoreState) => boolean | undefined = createSelector(
  getActiveUser,
  (user: ActiveUser | undefined): boolean => {
    if (!user || !user.permissions) {
      return false;
    }
    return user.permissions.indexOf(Permission.MANAGE_USERS) !== -1;
  }
);
