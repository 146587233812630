import React from "react";
import styled from "styled-components";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

type Alignment = "left" | "center" | "right";
type OwnProps = React.HTMLProps<HTMLDivElement> & { alignment?: Alignment };

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const DashboardCardTitle = (props: OwnProps): JSX.Element => (
  <StyledTitle alignment={props.alignment}>{props.children}</StyledTitle>
);

// -------------------------------------------------------------------------------------------------
// - Styled Components
// -------------------------------------------------------------------------------------------------

const StyledTitle = styled.div.attrs((props: OwnProps) => props)`
  color: #6b6974;
  font-size: 9pt;
  text-transform: uppercase;
  width: 100%;
  text-align: ${({ alignment }) => alignment ?? "center"};
  padding-bottom: 3pt;
`;
