import { GetMySelfResponse } from "../../../services/nav-api/myself";
import { UserSettings } from "../../../services/nav-api/myself/types";
import { Role } from "../../../services/nav-api/roles";
import { MySelfPayload } from "../../reducers/myself";
import { Action } from "../../types/action";
import { FETCH_MYSELF_COMPLETED, FETCH_MYSELF_FAILED } from "./actions";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doCompleteFetchMySelf = (
  user: GetMySelfResponse,
  userSettings: UserSettings,
  userRoles: Role[]
): Action<MySelfPayload> => ({
  type: FETCH_MYSELF_COMPLETED,
  payload: {
    user,
    userSettings,
    userRoles
  }
});

export const doFailFetchMyself = (payload: string): Action<string> => ({
  type: FETCH_MYSELF_FAILED,
  payload
});
