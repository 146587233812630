import { Action } from "../../../types/action";
import { GLOBAL_DASHBOARD_SELECT_PARTNER } from "./actions";

// -------------------------------------------------------------------------------------------------
// - Action Builders
// -------------------------------------------------------------------------------------------------

export const doSelectPartner = (payload: string): Action<string> => ({
  type: GLOBAL_DASHBOARD_SELECT_PARTNER,
  payload
});
