import React from "react";
import { Link } from "react-router-dom";
import { MESSAGES } from "../../../i18n";
import { routes } from "../../../routes";
import { NavigationDrawerOptionKey } from "../types";

const key = NavigationDrawerOptionKey.ADMIN_TENANTS;
const qaLabel = "menu-item-tenants";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default (isEnabled: boolean) =>
  isEnabled
    ? [
        {
          key,
          label: (
            <Link to={routes.tenants.resolve()} data-qa-label={qaLabel}>
              {MESSAGES.NavTenants}
            </Link>
          )
        }
      ]
    : [];
