import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { navApi } from "../../../services/nav-api";
import { ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { UPDATE_PUSHED_ALERTS } from "../myself/actions";

// -------------------------------------------------------------------------------------------------
// - Actions
// -------------------------------------------------------------------------------------------------

export const PUSH_ALERTS = "PUSH_ALERTS";
export const PUSH_ALERTS_RESET = "PUSH_ALERTS_RESET";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doPushAlert = (alertId: number): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    dispatch({
      type: PUSH_ALERTS,
      payload: alertId
    });

    const pushedAlertIds = _.orderBy(
      _.uniq([
        ...(getState().alertsReader.pushedAlertIds ?? []),
        ...(getState().myself.userSettings?.pushedAlertIds ?? []),
        alertId
      ]),
      "asc"
    ) as number[];

    try {
      navApi.myself.updateMySelfSettings({
        pushedAlertIds
      });

      dispatch({
        type: UPDATE_PUSHED_ALERTS,
        payload: pushedAlertIds
      });
    } catch (ex) {
      console.error(ex);
    }
  };
};

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doResetPushedAlerts = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    dispatch({ type: PUSH_ALERTS });

    try {
      navApi.myself.updateMySelfSettings({ pushedAlertIds: [] });
    } catch (ex) {
      console.error(ex);
    }
  };
};
