import { Form, Select } from "antd";
import React from "react";
import { BulkFields } from "./types";

// -------------------------------------------------------------------------------------------------
// - OwnProps
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  value: BulkFields;
  onChange: (type: BulkFields) => void;
}

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const operations: Array<{ label: string; value: BulkFields }> = [
  {
    label: "Tags",
    value: "tags"
  },
  {
    label: "Visibility",
    value: "status"
  }
];

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const FieldSelect = ({ value, onChange }: OwnProps) => (
  <Form.Item label="Field">
    <Select<string>
      value={value}
      onChange={onChange}
      style={{ width: "100%" }}
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
    >
      {operations.map(({ label, value }) => (
        <Select.Option value={value} key={value} className={`option-${value}`}>
          {label}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);
