import uniq from "lodash/uniq";
import mapValues from "lodash/mapValues";
import { RefObject } from "react";
import { useDispatch, useSelector } from "react-redux";

import { doDeleteTag, doSubmitEditingTag, doMoveStudents, doCloneTag } from "../../../redux/actions/tenants";
import {
  doCancelEditingTag,
  doCreateTenantTag,
  doDragTenantTag,
  doEditTenantTag
} from "../../../redux/actions/tenants/editor-tags";
import { RdxStoreState } from "../../../redux/types/state";
import { RdxFetchStatus } from "../../../redux/types/status";
import { TagsEditorContextProps } from "./context";
import { getTotalEnrollmentsByTagId } from "../../../redux/selectors/total-enrollments-by-tag-id";
import RCTree from "rc-tree";

export const useTagsEditorHook = (tenantId: number, treeRef: RefObject<RCTree>): TagsEditorContextProps => {
  const dispatch = useDispatch();
  const { byId, statusById } = useSelector((state: RdxStoreState) => state.tenants.tags!);
  const loadingByTagId = mapValues(statusById, (value: RdxFetchStatus) => value === RdxFetchStatus.LOADING);
  const tagEditor = useSelector((state: RdxStoreState) => state.tenants.tagEditor!);
  const totalEnrollmentsByTagId = useSelector(getTotalEnrollmentsByTagId);

  return {
    totalEnrollmentsByTagId,
    loadingByTagId,
    isCreatingTag: tagEditor ? tagEditor.isCreatingTag : false,
    onEdit: (id: number) => {
      const tag = byId[id];
      dispatch(doEditTenantTag(tag.id, tag.name, tag.parentId));
    },
    onDelete: (id: number) => {
      dispatch(doDeleteTag(tenantId, id));
    },
    onCreate: (parentId?: number) => {
      // BEWARE: This is totally coupled to the interals of tree antd.
      // Its a workaround to expand the parent of the node that it's going to be created.
      // The cleanest solution would be to make the expandedKeys controlled but antd has some issues like
      // if you have A > B > C and you want to collapse A it would only work if you collapse B first.
      if (treeRef.current && parentId) {
        const expandedKeys = uniq([...treeRef.current!.state.expandedKeys, parentId.toString()]);
        treeRef.current!.setUncontrolledState({ expandedKeys });
      }
      dispatch(doCreateTenantTag(parentId));
    },
    onSubmitEdit: (name: string) => {
      dispatch(doSubmitEditingTag(tenantId, name));
    },
    onDrop: (id: number, parentId?: number) => {
      dispatch(doDragTenantTag(tenantId, id, parentId));
    },
    onCancelEdit: () => dispatch(doCancelEditingTag()),
    onMoveStudents: (srcTagId: number, destTagId: number) => {
      dispatch(doMoveStudents(tenantId, srcTagId, destTagId));
    },
    onClone: (id: number) => {
      const { name, parentId } = byId[id];
      dispatch(doCloneTag(tenantId, `CLONE-${name}`, id, parentId));
    }
  };
};
