import { descriptionByReportType } from "../settings";
import { ReportType } from "../types";

// -------------------------------------------------------------------------------------------------
// - Hook
// -------------------------------------------------------------------------------------------------

export const useDescriptionByReportType = (reportType: ReportType) => ({
  value: descriptionByReportType[reportType]
});
