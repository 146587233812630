import { navApi } from "../../../../../../services/nav-api";
import { getTaskBlobWhenReady } from "../../../../../../services/task";
import { getCurDateForReportFilename } from "../../../../../../utils/date-utils";
import { ReportParams, ReportResponse } from "../../types";

// -------------------------------------------------------------------------------------------------
// - Generator
// -------------------------------------------------------------------------------------------------

export const genSimulatedExamsAnswersReport = async (params: ReportParams): Promise<ReportResponse> => ({
  filename: `simulated-exams-answers-${params.attemptByReportType[params.type]}-${getCurDateForReportFilename()}.xlsx`,
  blob: await getTaskBlobWhenReady((await navApi.studentRoster.simulatedExamsAnswersReport(params)).taskToken)
});
