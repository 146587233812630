import * as HttpClient from "../../http-client";
import { createUnAuthHeaders } from "../headers";

export type GetUserInviteParams = {
  token: string;
};

export type GetUserInviteResponse = {
  email: string;
  firstName: string;
  lastName: string;
};

export type PutUserInviteParams = {
  password: string;
  firstName: string;
  lastName: string;
};

const getUserInvite = async (params: GetUserInviteParams): Promise<GetUserInviteResponse> => {
  return HttpClient.get(`/rest/unauth/userInvites/${params.token}`, createUnAuthHeaders());
};

const putUserInvite = async (token: string, params: PutUserInviteParams): Promise<{}> => {
  return HttpClient.put(`/rest/unauth/userInvites/${token}`, createUnAuthHeaders(), params);
};

export { getUserInvite, putUserInvite };
