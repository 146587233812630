import { createSelector } from "reselect";
import { UserSettings } from "../../../services/nav-api/myself/types";
import { RdxStudentDetails } from "../../reducers/student-details";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "./../../types/status";
import { createProgressMappers } from "./create-progress-mappers";
import { createDataSource } from "./createDataSource";

export type PerformanceDashboardItem = Partial<{
  altId: string;
  children: PerformanceDashboardItem[];
  conceptVideosProgress: number;
  mcqsCorrect: number;
  mcqsProgress: number;
  mcqsTotal: number;
  name: string;
  progress: number;
  simsCorrect: number;
  simsProgress: number;
  simsTotal: number;
  essaysCorrect: number;
  essaysProgress: number;
  essaysTotal: number;
  skillsPracticeProgress: number;
}>;

export const getStudentPerformanceDashboard: (state: RdxStoreState) => {
  dataSource?: PerformanceDashboardItem[];
  loading?: boolean;
} = createSelector(
  (state: RdxStoreState) => state.studentDetails,
  (state: RdxStoreState) => state.myself.userSettings,
  ({ curStudentId, hierarchyProgress, status }: RdxStudentDetails, userSettings: UserSettings) => {
    if (!curStudentId || !userSettings?.activeProduct) {
      return {};
    }

    if (hierarchyProgress && hierarchyProgress.data) {
      const isCPA = userSettings.activeProduct == "cpa";
      const progressMappers = createProgressMappers(hierarchyProgress.data);
      return {
        dataSource: createDataSource(progressMappers, isCPA)
      };
    }

    const isStudentProgressLoading = hierarchyProgress && hierarchyProgress.status === RdxFetchStatus.LOADING;
    const isStudentDetailsLoading = status === RdxFetchStatus.LOADING;
    const shouldConsiderAsLoading = isStudentProgressLoading || isStudentDetailsLoading;

    if (shouldConsiderAsLoading) {
      return { loading: true };
    }

    return {};
  }
);
