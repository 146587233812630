import { navApi } from "../../../../../../services/nav-api";
import { getTaskBlobWhenReady } from "../../../../../../services/task";
import { getCurDateForReportFilename } from "../../../../../../utils/date-utils";
import { ReportParams, ReportResponse } from "../../types";

// -------------------------------------------------------------------------------------------------
// - Generator
// -------------------------------------------------------------------------------------------------

export const genPerformanceReport = async (params: ReportParams): Promise<ReportResponse> => ({
  filename: `performance-dashboard-${getCurDateForReportFilename()}.xlsx`,
  blob: await getTaskBlobWhenReady((await navApi.studentRoster.performanceDashboardReport(params)).taskToken)
});

// -------------------------------------------------------------------------------------------------
// - Generator (For The Final Review)
// -------------------------------------------------------------------------------------------------

export const genPerformanceFrReport = async (params: ReportParams): Promise<ReportResponse> => ({
  filename: `performance-dashboard-fr-${getCurDateForReportFilename()}.xlsx`,
  blob: await getTaskBlobWhenReady((await navApi.studentRoster.frPerformanceSummary(params)).taskToken)
});
