import { CloseCircleFilled } from "@ant-design/icons";
import { Alert, Button, Modal, Typography } from "antd";
import React from "react";
import { MESSAGES } from "../../i18n";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const GenericApiFailureModal = ({
  message = MESSAGES.ApiFailureModalGenericMessage,
  title = MESSAGES.ApiFailureModalGenericTitle,
  buttons = []
}: {
  message: string;
  title?: string;
  buttons?: { label: string; onClick: () => void }[];
}) => (
  <Modal open={true} closable={false} footer={null}>
    <div className="flex items-center pb-6 text-xl block">
      <CloseCircleFilled className="text-red mr-2.5" />
      <Typography.Title level={4} style={{ margin: "0" }}>
        {title}
      </Typography.Title>
    </div>
    <Alert message={message} type="error" />
    {buttons.length ? (
      <div className="text-right mt-8">
        {buttons.map(({ label, onClick }) => (
          <Button type="primary" onClick={onClick} key={label}>
            {label}
          </Button>
        ))}
      </div>
    ) : null}
  </Modal>
);
