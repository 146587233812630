import {
  GLOBAL_DASHBOARD_FETCH_COMPLETED,
  GLOBAL_DASHBOARD_FETCH_STARTED,
  GLOBAL_DASHBOARD_SELECT_PARTNER
} from "../../../actions/dashboard/global";
import { RdxFetchStatus } from "../../../types/status";
import { createReducer } from "../../../utils/redux-utils";
import { initialState } from "./constants";
import { RdxGlobalDashboardState } from "./model";

export default createReducer<RdxGlobalDashboardState>(initialState, {
  [GLOBAL_DASHBOARD_SELECT_PARTNER]: (state, selectedPartner) => ({ ...state, selectedPartner }),
  [GLOBAL_DASHBOARD_FETCH_STARTED]: state => ({ ...state, status: RdxFetchStatus.LOADING }),
  [GLOBAL_DASHBOARD_FETCH_COMPLETED]: (state, ns) => ({
    ...state,
    ...ns,
    status: ns.error ? RdxFetchStatus.FAILED : RdxFetchStatus.COMPLETED
  })
});
