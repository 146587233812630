import { Modal } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { MESSAGES } from "../../../i18n";
import { routes } from "../../../routes";
import { navApi } from "../../../services/nav-api";
import { User } from "../../../services/nav-api/users";

const { error, success } = Modal;

// -------------------------------------------------------------------------------------------------
// - Title
// -------------------------------------------------------------------------------------------------

const Title = ({ user }: { user: User }) => (
  <span>
    Delete{" "}
    <strong>
      {user.firstName} {user.lastName}
    </strong>
  </span>
);

// -------------------------------------------------------------------------------------------------
// - Hooks: useDeleteUserApi
// -------------------------------------------------------------------------------------------------

export const useDeleteUserApi = (user: User) => {
  const navigate = useNavigate();
  return React.useMemo(
    () => ({
      deleteUser: () =>
        Modal.confirm({
          title: <Title user={user} />,
          content: MESSAGES.DeleteUserQuestion,
          okText: MESSAGES.Delete,
          okButtonProps: { danger: true },
          onOk() {
            callApiMethod(user, () => {
              navigate(routes.users.resolve());
            });
          }
        })
    }),
    [user]
  );
};

// -------------------------------------------------------------------------------------------------
// - Helpers: callApiMethod
// -------------------------------------------------------------------------------------------------

const callApiMethod = async (user: User, onOk: () => void): Promise<void> => {
  const handleErr = (content: string) => error({ title: <Title user={user} />, content });
  const handleScc = (content: string) =>
    success({
      title: <Title user={user} />,
      content,
      onOk
    });

  // -----------------------------------------------------------------------------------------------
  // - Call Api
  // -----------------------------------------------------------------------------------------------

  try {
    await navApi.users.deleteUser(user.id);
    handleScc(`User ${user.firstName} ${user.lastName} deleted successfully`);
  } catch (err: any) {
    switch (err.statusCode) {
      case 405:
        handleErr(MESSAGES.MethodNotAllowed);
        break;
      case 400:
        handleErr(MESSAGES.UserNotDisabled);
        break;
      default:
        handleErr(
          err.message //
            ? err.message
            : MESSAGES.UnknownError
        );
    }
  }
};
