import { navApi } from "../nav-api";

const TIMEOUT = 30 * 60 * 1000;

export const getTaskBlobWhenReady = (taskToken: string): Promise<Blob> =>
  new Promise((resolve, reject) => {
    let timeoutId = 0;
    const unsubscribe = navApi.webSockets.taskTokenChange(taskToken, async data => {
      const destroy = () => {
        unsubscribe();
        clearTimeout(timeoutId);
      };
      if (data.status === "Failed") {
        destroy();
        reject(data?.message);
      }
      if (data.status === "Success") {
        destroy();
        try {
          const blob = await navApi.tasks.getTaskBlog(taskToken);
          resolve(blob);
        } catch (e) {
          reject();
        }
      }
    });
    timeoutId = window.setTimeout(() => {
      reject();
      unsubscribe();
    }, TIMEOUT);
  });
