import qString from "query-string";
import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { Alert, ApiAlertStatus, GetAlertResponse } from "./model";

// -------------------------------------------------------------------------------------------------
// - Fetch alerts
// -------------------------------------------------------------------------------------------------

export const fetchAlerts = async (limit: number, offset: number, status: ApiAlertStatus[]): Promise<GetAlertResponse> =>
  HttpClient.get(`/rest/admin/alerts?${qString.stringify({ limit, offset, status })}`, createHeaders());

// -------------------------------------------------------------------------------------------------
// - Save alerts
// -------------------------------------------------------------------------------------------------

export const saveAlert = async (alert?: Alert): Promise<GetAlertResponse> =>
  alert != null
    ? alert.id < 0
      ? HttpClient.post("/rest/admin/alerts", createHeaders(), alert)
      : HttpClient.put(`/rest/admin/alerts/${alert.id}`, createHeaders(), alert)
    : Promise.resolve();

// -------------------------------------------------------------------------------------------------
// - Delete alerts
// -------------------------------------------------------------------------------------------------

export const deleteAlert = async (alert?: Alert): Promise<GetAlertResponse> =>
  alert != null ? HttpClient.del(`/rest/admin/alerts/${alert.id}`, createHeaders()) : Promise.resolve();

// -------------------------------------------------------------------------------------------------
// - Save alerts
// -------------------------------------------------------------------------------------------------

export const expireAlert = async (alert?: Alert): Promise<GetAlertResponse> =>
  alert != null ? HttpClient.put(`/rest/admin/alerts/${alert.id}/expired`, createHeaders()) : Promise.resolve();
