import { FileExcelOutlined } from "@ant-design/icons";
import { Alert, Button, Col, DatePicker, Divider, Form, Modal, Radio, Row, Select, Space } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { $enum } from "ts-enum-util";
import { END_USER_LINKS } from "../../../../constants/bim-links";
import { SYSTEM_CONFIG } from "../../../../constants/system-config";
import { MESSAGES, formatMsg } from "../../../../i18n";
import { getActiveProduct } from "../../../../redux/selectors/active-product";
import { SimulatedExamsReportOption } from "../../../../services/nav-api/student-roster/types";
import { isReportAvailableForProduct, labelByAttemptType, labelByReportType } from "./settings";
import { HierarchyLevel, ReportType } from "./types";
import { useReports } from "./use-report";

const attemptOptions: SimulatedExamsReportOption[] = $enum(SimulatedExamsReportOption).getValues();

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const ReportsModal = () => {
  const activeProduct = useSelector(getActiveProduct);
  const [reportsModalVisible, setReportsModalVisible] = useState(false);
  const {
    attemptByReportType,
    descriptionByReportType,
    handleGenerateReport,
    isCreateButtonAvailable,
    isLoadingReport,
    limits,
    singleSectionIncludedByReportType,
    multipleSectionsIncludedByReportType,
    multipleAttemptsByReportType,
    sectionUnitIncludedByReportType,
    hierarchyLevelIncludedByReportType,
    timesheetPeriodByReportType,
    horizontalSeparatorByReportType,
    examNumberByReportType,
    reportType
  } = useReports();

  React.useEffect(() => {
    isLoadingReport.reset();
    attemptByReportType.reset();
    examNumberByReportType.reset();
    singleSectionIncludedByReportType.reset();
    multipleSectionsIncludedByReportType.reset();
    multipleAttemptsByReportType.reset();
    hierarchyLevelIncludedByReportType.reset();
    sectionUnitIncludedByReportType.reset();
    timesheetPeriodByReportType.reset();
    reportType.reset();
  }, [reportsModalVisible]);

  return (
    <>
      <Button data-qa-label="reportsBtn" onClick={() => setReportsModalVisible(true)}>
        <FileExcelOutlined />
        {MESSAGES.Reports}
      </Button>
      <Modal
        title={MESSAGES.ReportsModalTitle}
        open={reportsModalVisible}
        onCancel={() => {
          setReportsModalVisible(false);
        }}
        footer={null}
        width={720}
      >
        <Form layout="vertical">
          {
            // -------------------------------------------------------------------------------------
            //  Choose Report
            // -------------------------------------------------------------------------------------
          }
          <Form.Item required label={MESSAGES.ChooseReport}>
            <Row>
              <Col span={12}>
                <Select
                  virtual={_.isEqual(SYSTEM_CONFIG.environment, "prod")}
                  popupClassName="report_list"
                  data-qa-label="report_list"
                  value={reportType.value}
                  showSearch
                  disabled={isLoadingReport.value}
                  getPopupContainer={({ parentNode }) => parentNode as HTMLElement}
                  onSelect={(value: ReportType) => {
                    reportType.setValue(value);
                  }}
                >
                  {_.chain($enum(ReportType).getValues())
                    .filter(isReportAvailableForProduct(activeProduct))
                    .sortBy()
                    .map(o => (
                      <Select.Option key={o} value={o} data-qa-label={o}>
                        {labelByReportType[o]}
                      </Select.Option>
                    ))
                    .value()}
                </Select>
              </Col>
              <Col className="flex items-center mx-2">
                <a
                  href={END_USER_LINKS.reportsDocs}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-qa-label="need_help_link"
                >
                  {MESSAGES.NeedHelp}
                </a>
              </Col>
            </Row>
          </Form.Item>

          {horizontalSeparatorByReportType.isEnabled ? (
            <Divider plain type="horizontal" orientation="left" orientationMargin={0}>
              {MESSAGES.ReportSettings}
            </Divider>
          ) : null}

          {
            // -------------------------------------------------------------------------------------
            //  Single Section
            // -------------------------------------------------------------------------------------

            singleSectionIncludedByReportType.isAvailableForReportType ? (
              <Form.Item required label={MESSAGES.SelectSingleSection}>
                <Row>
                  <Col span={12}>
                    <Select
                      allowClear
                      data-qa-label="single_sections_select"
                      virtual={_.isEqual(SYSTEM_CONFIG.environment, "prod")}
                      disabled={isLoadingReport.value}
                      value={singleSectionIncludedByReportType.valueForCurReportType}
                      getPopupContainer={({ parentNode }) => parentNode as HTMLElement}
                      onChange={value => {
                        singleSectionIncludedByReportType.setValue(reportType.value, value);
                      }}
                    >
                      {_.chain(singleSectionIncludedByReportType.sections)
                        .map(({ label, value }) => (
                          <Select.Option key={value} value={value} data-qa-label={value}>
                            {label}
                          </Select.Option>
                        ))
                        .value()}
                    </Select>
                  </Col>
                </Row>
              </Form.Item>
            ) : null
          }

          {
            // -------------------------------------------------------------------------------------
            //  Multiple Sections
            // -------------------------------------------------------------------------------------

            multipleSectionsIncludedByReportType.isAvailableForReportType ? (
              <Form.Item required label={MESSAGES.SelectMultipleSections}>
                <Row>
                  <Col span={12}>
                    <Select
                      allowClear
                      mode="multiple"
                      data-qa-label="multi_sections_select"
                      virtual={_.isEqual(SYSTEM_CONFIG.environment, "prod")}
                      disabled={isLoadingReport.value}
                      value={multipleSectionsIncludedByReportType.valueForCurReportType}
                      getPopupContainer={({ parentNode }) => parentNode as HTMLElement}
                      onChange={value => {
                        multipleSectionsIncludedByReportType.setValue(reportType.value, value);
                      }}
                    >
                      {_.chain(multipleSectionsIncludedByReportType.sections)
                        .map(({ label, value }) => (
                          <Select.Option key={value} value={value} data-qa-label={value}>
                            {label}
                          </Select.Option>
                        ))
                        .value()}
                    </Select>
                  </Col>
                </Row>
              </Form.Item>
            ) : null
          }

          {
            // -------------------------------------------------------------------------------------
            //  Section Details
            // -------------------------------------------------------------------------------------

            hierarchyLevelIncludedByReportType.isAvailableForReportType ? (
              <Form.Item required label={MESSAGES.SelectSectionDetails}>
                <Row>
                  <Col span={12}>
                    <Select
                      allowClear
                      data-qa-label="section_details"
                      disabled={isLoadingReport.value}
                      value={hierarchyLevelIncludedByReportType.valueForCurReportType}
                      getPopupContainer={({ parentNode }) => parentNode as HTMLElement}
                      virtual={_.isEqual(SYSTEM_CONFIG.environment, "prod")}
                      onChange={value => {
                        hierarchyLevelIncludedByReportType.setValue(reportType.value, value as HierarchyLevel);
                      }}
                    >
                      {_.chain(hierarchyLevelIncludedByReportType.options)
                        .map(({ label, value }) => (
                          <Select.Option key={value} value={value} data-qa-label={value}>
                            {label}
                          </Select.Option>
                        ))
                        .value()}
                    </Select>
                  </Col>
                </Row>
              </Form.Item>
            ) : null
          }

          {
            // -------------------------------------------------------------------------------------
            //  Exam Number
            // -------------------------------------------------------------------------------------

            examNumberByReportType.isAvailableForReportType ? (
              <Form.Item required label={examNumberByReportType.optionsLabel}>
                <Row>
                  <Col span={12}>
                    <Select
                      allowClear
                      data-qa-label="simulated-exam-number"
                      disabled={isLoadingReport.value}
                      virtual={_.isEqual(SYSTEM_CONFIG.environment, "prod")}
                      value={examNumberByReportType.valueForCurReportType}
                      getPopupContainer={({ parentNode }) => parentNode as HTMLElement}
                      onChange={value => {
                        examNumberByReportType.setValue(reportType.value, value);
                      }}
                    >
                      {_.chain(examNumberByReportType.options)
                        .map(({ label, value }) => (
                          <Select.Option key={value} value={value} data-qa-label={value}>
                            {label}
                          </Select.Option>
                        ))
                        .value()}
                    </Select>
                  </Col>
                </Row>
              </Form.Item>
            ) : null
          }

          {
            // -------------------------------------------------------------------------------------
            //  Attempts
            // -------------------------------------------------------------------------------------

            attemptByReportType.isAvailableForReportType ? (
              <Form.Item required label={MESSAGES.SelectMultipleAttempts}>
                <Space direction="vertical">
                  <Radio.Group
                    defaultValue={_.head(attemptOptions)}
                    data-qa-label="attempts_radio_button_group"
                    value={attemptByReportType.valueForCurReportType}
                    buttonStyle="solid"
                    disabled={isLoadingReport.value}
                    onChange={o => {
                      attemptByReportType.setValue(reportType.value, o.target.value);
                    }}
                  >
                    {_.map(attemptOptions, o => (
                      <Radio key={o} value={o} data-qa-label={o}>
                        {labelByAttemptType[o]}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Space>
              </Form.Item>
            ) : null
          }

          {
            // -------------------------------------------------------------------------------------
            //  Multiple Attempts
            // -------------------------------------------------------------------------------------

            multipleAttemptsByReportType.isAvailableForReportType ? (
              <Form.Item required label={MESSAGES.SelectMultipleAttempts}>
                <Row>
                  <Col span={12}>
                    <Select
                      allowClear
                      mode="multiple"
                      data-qa-label="multi_attempts_select"
                      disabled={isLoadingReport.value}
                      virtual={_.isEqual(SYSTEM_CONFIG.environment, "prod")}
                      getPopupContainer={({ parentNode }) => parentNode as HTMLElement}
                      value={multipleAttemptsByReportType.valueForCurReportType}
                      onChange={value => {
                        multipleAttemptsByReportType.setValue(reportType.value, value);
                      }}
                    >
                      {_.chain(multipleAttemptsByReportType.values)
                        .map(({ label, value }) => (
                          <Select.Option key={value} value={value} data-qa-label={value}>
                            {label}
                          </Select.Option>
                        ))
                        .value()}
                    </Select>
                  </Col>
                </Row>
              </Form.Item>
            ) : null
          }

          {
            // -------------------------------------------------------------------------------------
            //  Sections - Unit
            // -------------------------------------------------------------------------------------

            sectionUnitIncludedByReportType.isAvailableForReportType ? (
              <Form.Item required label={formatMsg(MESSAGES.SelectProductSectionUnit, _.upperCase(activeProduct))}>
                <Row>
                  <Col span={12}>
                    <Select
                      virtual={_.isEqual(SYSTEM_CONFIG.environment, "prod")}
                      data-qa-label="section_unit_select"
                      disabled={isLoadingReport.value}
                      value={sectionUnitIncludedByReportType.valueForCurReportType}
                      getPopupContainer={({ parentNode }) => parentNode as HTMLElement}
                      onChange={value => {
                        sectionUnitIncludedByReportType.setValue(reportType.value, value);
                      }}
                    >
                      {_.map(sectionUnitIncludedByReportType.unitsBySection, section => (
                        <Select.OptGroup key={section.value} data-qa-label={section.value} label={section.label}>
                          {_.map(section.units, unit => (
                            <Select.Option key={unit.value} value={unit.value} data-qa-label={unit.value}>
                              {unit.label}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Form.Item>
            ) : null
          }

          {
            // ---------------------------------------------------------------------------------------
            //  Timesheet Period
            // ---------------------------------------------------------------------------------------

            timesheetPeriodByReportType.isAvailableForReportType ? (
              <Form.Item label={MESSAGES.Month}>
                <Space direction="vertical">
                  <DatePicker
                    picker="month"
                    allowClear={false}
                    data-qa-label="timesheet_period_picker"
                    disabled={isLoadingReport.value}
                    value={timesheetPeriodByReportType.valueForCurReportType}
                    onChange={(e: dayjs.Dayjs) => {
                      timesheetPeriodByReportType.setValue(reportType.value, e);
                    }}
                  />
                </Space>
              </Form.Item>
            ) : null
          }

          {
            // -------------------------------------------------------------------------------------
            //  Info
            // -------------------------------------------------------------------------------------

            <Form.Item hidden={!limits.message}>
              <Alert message={limits.message} type={limits.isWarning ? "warning" : "info"} />
            </Form.Item>
          }

          {
            // -------------------------------------------------------------------------------------
            //  Create Button
            // -------------------------------------------------------------------------------------
          }
          <div className="flex flex-col items-end">
            <Button
              type="primary"
              loading={isLoadingReport.value}
              disabled={!isCreateButtonAvailable}
              onClick={() => handleGenerateReport()}
            >
              {MESSAGES.ReportCreateBtn}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
