import React from "react";

import { Tree } from "antd";
import { TagEditorNode } from "../../../../redux/selectors/tags-tree-editor";
import { Loader } from "../../../loader";
import { TagsEditorContext, TagsEditorContextProps } from "./../context";
import { TagContextMenu } from "./../context-menu";
import { EditModeNode } from "./edit-node";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const InnerNode = (props: TagEditorNode) => (
  <TagsEditorContext.Consumer>
    {({ loadingByTagId, isCreatingTag, totalEnrollmentsByTagId }: TagsEditorContextProps) => {
      const loading = !props.id ? isCreatingTag : loadingByTagId[props.id];
      return (
        <div className="flex items-center">
          {props.edit ? (
            <EditModeNode {...props} />
          ) : (
            <TagContextMenu
              id={props.id!}
              enrolledStudentsCount={props.enrolledStudentCount}
              totalEnrolledStudentsCount={totalEnrollmentsByTagId[props.id!]}
              depthLevel={props.depthLevel!}
              name={props.name}
              hasChildren={Boolean(props.children?.length)}
            >
              {props.name}
            </TagContextMenu>
          )}
          {loading ? <Loader className="font-xs" /> : null}
        </div>
      );
    }}
  </TagsEditorContext.Consumer>
);

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

export const renderTreeNodes = (node: TagEditorNode) => (
  <Tree.TreeNode
    title={<InnerNode {...node} />}
    key={(node.id || -1).toString()}
    selectable={false}
    data-qa-label={`tree-node-${node.name}`}
  >
    {node.children.map(renderTreeNodes)}
  </Tree.TreeNode>
);
