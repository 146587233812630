import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// -------------------------------------------------------------------------------------------------
// - OwnProps
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertTextEditor = ({ value, onChange }: OwnProps): JSX.Element => (
  <ReactQuill theme="snow" value={value} onChange={onChange} />
);
