import includes from "lodash/includes";

import { createNodeParentById } from "../create-mappers";
import { Node } from "../types";

function isAnyAncestorChecked(tree: Node[], nodeId: number, checkedIds: number[]) {
  const parentById = createNodeParentById(tree);

  if (!parentById[nodeId]) {
    return false;
  }

  let parentId = parentById[nodeId];
  while (parentId) {
    if (includes(checkedIds, parentId)) {
      return true;
    }
    parentId = parentById[parentId];
  }

  return false;
}

function excludeRedundantNodes(tree: Node[], checkedIds: number[]) {
  return checkedIds.filter(nId => !isAnyAncestorChecked(tree, nId, checkedIds));
}

export { excludeRedundantNodes };
