import { createSelector } from "reselect";

import { RdxTenantsState } from "../reducers/tenants";
import { RdxStoreState } from "../types/state";
import { getMyTenant } from "./my-tenant";
import { TagNode } from "./my-tenant-tags-tree";

export const getTenantTags: (state: RdxStoreState) => { byId?: { [id: number]: TagNode }; ids?: number[] } =
  createSelector(
    getMyTenant,
    (state: RdxStoreState) => state.tenants,
    (tenant, { tags, tenantTags }: RdxTenantsState) => {
      if (!tenant) {
        return {};
      }

      const tenantTagsIds = tenantTags!.tagsIdsbyTenantId[tenant.id] || [];

      const tenantTagsById: { [id: number]: TagNode } = tenantTagsIds.reduce(
        (acc: { [id: number]: TagNode }, tagId) => {
          acc[tagId] = { ...tags!.byId[tagId], children: [] };
          return acc;
        },
        {}
      );

      return {
        byId: tenantTagsById,
        ids: tenantTagsIds
      };
    }
  );
