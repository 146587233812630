import React from "react";

import { MESSAGES } from "../../i18n";
import { GenericApiFailureModal } from "./modal";

export const MultipleSessionModal: React.FC = () => {
  return (
    <GenericApiFailureModal
      message={MESSAGES.ApiFailureMultipleSession}
      buttons={[
        {
          onClick: () => window.location.reload(),
          label: MESSAGES.ApiFailureMultipleSessionBtnCaption
        }
      ]}
    />
  );
};
