import _ from "lodash";
import {
  StudentHierarchyProgress,
  StudentModuleHierarchyProgress,
  StudentSectionHierarchyProgress,
  StudentUnitHierarchyProgress
} from "../../actions/student-details";

export type ProgressMappers = {
  sections: StudentSectionHierarchyProgress[];
  unitsBySectionId: { [key: number]: StudentUnitHierarchyProgress[] };
  modulesByUnitId: { [key: number]: StudentModuleHierarchyProgress[] };
};

export function createProgressMappers(progress: StudentHierarchyProgress): ProgressMappers {
  const sections = progress.sections
    .map(cur => {
      return {
        ...cur,
        // https://beckeredu.atlassian.net/browse/NAV-731 -> remove FR from simExams
        simExams: _.reject(cur.simExams, {
          kind: "fr"
        })
      };
    })
    .sort((a, b) => a.menuOrder - b.menuOrder);

  const unitsBySectionId = _.mapValues(
    progress.units.reduce(
      (acc, cur) => {
        let curList = acc[cur.sectionId];
        if (curList == null) {
          curList = [];
          acc[cur.sectionId] = curList;
        }
        curList.push(cur);

        return acc;
      },
      {} as { [key: number]: StudentUnitHierarchyProgress[] }
    ),
    list => list.sort((a, b) => a.menuOrder - b.menuOrder)
  );

  const modulesByUnitId = _.mapValues(
    progress.modules.reduce(
      (acc, cur) => {
        let curList = acc[cur.unitId];
        if (curList == null) {
          curList = [];
          acc[cur.unitId] = curList;
        }
        curList.push(cur);

        return acc;
      },
      {} as { [key: number]: StudentModuleHierarchyProgress[] }
    ),
    list => list.sort((a, b) => a.menuOrder - b.menuOrder)
  );

  return {
    sections,
    unitsBySectionId,
    modulesByUnitId: modulesByUnitId
  };
}
