import { CopyOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SwapOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Modal } from "antd";

import React, { useContext } from "react";
import styled from "styled-components";

import { formatMsg, MESSAGES } from "../../../i18n";
import { MAX_DEPTH_LEVEL_ALLOWED } from "./constants";
import { TagsEditorContext } from "./context";
import { MoveStudentsTree } from "./move-students-tree";

export type ComponentProps = {
  enrolledStudentsCount: number;
  totalEnrolledStudentsCount: number;
  depthLevel: number;
  name: string;
  hasChildren: boolean;
  id: number;
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TagContextMenu = ({
  id,
  name,
  children,
  depthLevel,
  enrolledStudentsCount,
  totalEnrolledStudentsCount
}: { children?: React.ReactNode | React.ReactNode[] } & ComponentProps) => {
  const context = useContext(TagsEditorContext);

  const remove = (
    <Menu.Item
      key="delete"
      data-qa-label="contextMenuDeleteBtn"
      onClick={() => {
        Modal.confirm({
          title: `Delete ${name}`,
          okText: "Delete",
          okType: "danger",
          content: (
            <>
              {depthLevel === 1 && totalEnrolledStudentsCount > 0 && (
                <>
                  {MESSAGES.TenantTagsDeleteCategory}
                  <br />
                </>
              )}
              {depthLevel > 1 && totalEnrolledStudentsCount > 0 && (
                <>
                  {MESSAGES.TenantTagsDeleteTag}
                  <br />
                </>
              )}
              {MESSAGES.TenantTagsDeleteConfirmation}
            </>
          ),
          onOk() {
            context.onDelete(id);
          }
        });
      }}
    >
      <IconWrapper>
        <DeleteOutlined />
      </IconWrapper>
      {MESSAGES.DeleteLabel}
    </Menu.Item>
  );

  const createNew = (
    <Menu.Item
      key="new"
      onClick={() => context.onCreate(id)}
      disabled={MAX_DEPTH_LEVEL_ALLOWED <= depthLevel}
      data-qa-label="contextMenuNewBtn"
    >
      <IconWrapper>
        <PlusOutlined />
      </IconWrapper>
      {MESSAGES.CreateNewLabel}
    </Menu.Item>
  );

  const edit = (
    <Menu.Item key="edit" onClick={() => context.onEdit(id)} data-qa-label="contextMenuEditBtn">
      <IconWrapper>
        <EditOutlined />
      </IconWrapper>
      {MESSAGES.EditLabel}
    </Menu.Item>
  );

  const clone = (
    <Menu.Item key="clone" onClick={() => context.onClone(id)} data-qa-label="contextMenuCloneBtn">
      <IconWrapper>
        <CopyOutlined />
      </IconWrapper>
      {MESSAGES.CloneLabel}
    </Menu.Item>
  );

  const move = (
    <Menu.SubMenu
      key="move"
      title={
        <>
          <IconWrapper>
            <SwapOutlined />
          </IconWrapper>
          {MESSAGES.MoveStudentsLabel}
        </>
      }
      disabled={enrolledStudentsCount === 0}
      data-qa-label="contextMenuMoveBtn"
    >
      <MoveStudentsTree
        id={id}
        onClick={({ tagId, tagName }: { tagId: number; tagName: string }) => {
          Modal.confirm({
            title: `Move students`,
            content: (
              <>
                <i>{MESSAGES.MoveStudentsConfirmationMessageLine1}</i>
                <br />
                <br />
                {formatMsg(MESSAGES.MoveStudentsConfirmationMessageLine2, enrolledStudentsCount, name, tagName)}
              </>
            ),
            onOk() {
              context.onMoveStudents(id, tagId);
            }
          });
        }}
      />
    </Menu.SubMenu>
  );

  const menu = <Menu children={[createNew, edit, clone, remove, move]} mode="vertical" />;

  return (
    <div className="flex items-center">
      <Dropdown overlay={menu} trigger={["contextMenu", "click"]}>
        <span>{children}</span>
      </Dropdown>
    </div>
  );
};

const IconWrapper = styled.div`
  width: 28px;
  text-align: center;
  display: inline-block;
  position: relative;
  left: -5px;
`;
