import intersection from "lodash/intersection";

import { getAllDescendantIds } from "./get-all-descendants";
import { Node } from "./types";

export function getRootsWithCheckedDescendants(tree: Node[], checked: number[]) {
  return tree
    .filter(rootNode => Boolean(intersection(getAllDescendantIds(tree, rootNode.id), checked).length))
    .map(rootNode => rootNode.id);
}
