import { ProductConfig } from "./types";

export const configCPA: () => ProductConfig = () => ({
  numberOfSections: 4,
  examTrackerMinExamGrade: 75,
  performanceDashboard: {
    simulations: true,
    essays: false
  },
  hasStudyPlan: true
});
