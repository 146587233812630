import { EditStudentCompletedPayload, STUDENT_DETAILS_EDIT_COMPLETED } from "../../actions/student-details";
import {
  CreateTagCompletedPayload,
  DeleteTagCompletedPayload,
  FetchTenantTagsCompleted,
  TENANT_TAGS_CREATE_TAG_COMPLETED,
  TENANT_TAGS_DELETE_TAG_COMPLETED,
  TENANT_TAGS_FETCH_COMPLETED,
  TENANT_TAGS_FETCH_STARTED,
  BulkChangeCompletedPayload,
  BULK_CHANGE_STUDENT_TAGS_COMPLETED
} from "../../actions/tenants";
import { RdxFetchStatus } from "../../types/status";
import { createReducer } from "../../utils/redux-utils";

export interface RdxTenantTagsSubState {
  tagsIdsbyTenantId: { [tenantId: number]: number[] };
  statusByTenantId: { [id: number]: RdxFetchStatus };
  staleByTenantId: { [id: number]: boolean };
}

const initialState: RdxTenantTagsSubState = {
  tagsIdsbyTenantId: {},
  statusByTenantId: {},
  staleByTenantId: {}
};

export default createReducer<RdxTenantTagsSubState>(initialState, {
  [TENANT_TAGS_FETCH_STARTED]: (state, tenantId: number) => ({
    ...state,
    statusByTenantId: { ...state.statusByTenantId, [tenantId]: RdxFetchStatus.LOADING }
  }),
  [TENANT_TAGS_FETCH_COMPLETED]: (state, { error, tags, tenantId }: FetchTenantTagsCompleted) => {
    if (error) {
      return {
        ...state,
        statusByTenantId: { ...state.statusByTenantId, [tenantId]: RdxFetchStatus.FAILED }
      };
    }
    return {
      ...state,
      tagsIdsbyTenantId: { ...state.tagsIdsbyTenantId, [tenantId]: tags!.map(t => t.id) },
      statusByTenantId: { ...state.statusByTenantId, [tenantId]: RdxFetchStatus.COMPLETED },
      staleByTenantId: {
        [tenantId]: false
      }
    };
  },
  [TENANT_TAGS_DELETE_TAG_COMPLETED]: (state, { error, tagId, tenantId }: DeleteTagCompletedPayload) => {
    if (error) {
      return state;
    }
    return {
      ...state,
      tagsIdsbyTenantId: {
        ...state.tagsIdsbyTenantId,
        [tenantId]: (state.tagsIdsbyTenantId[tenantId] || []).filter(id => id !== tagId)
      }
    };
  },
  [TENANT_TAGS_CREATE_TAG_COMPLETED]: (state, { error, ...tag }: CreateTagCompletedPayload) => {
    if (error) {
      return state;
    }
    return {
      ...state,
      tagsIdsbyTenantId: {
        ...state.tagsIdsbyTenantId,
        [tag.tenantId]: [...(state.tagsIdsbyTenantId[tag.tenantId] || []), tag.id]
      }
    };
  },
  [STUDENT_DETAILS_EDIT_COMPLETED]: (state, { error, data }: EditStudentCompletedPayload) => {
    if (error) {
      return state;
    }
    return {
      ...state,
      staleByTenantId: {
        [data!.tenantId]: true
      }
    };
  },
  [BULK_CHANGE_STUDENT_TAGS_COMPLETED]: (state, { error, tenantId }: BulkChangeCompletedPayload) => {
    if (error) {
      return state;
    }
    return {
      ...state,
      staleByTenantId: {
        [tenantId!]: true
      }
    };
  }
});
