import { Store } from "redux";
import { METHOD_FAILURE_STATUS_CODE } from "../../services/http-client/constant";
import { doNotifyApiFailure } from "../actions/api-failure";

export const createDispatchGlobalErrorHttpInterceptor = (store: Store) => async (response: Response, id: string) => {
  if (response.status === METHOD_FAILURE_STATUS_CODE) {
    store.dispatch(doNotifyApiFailure({ statusCode: METHOD_FAILURE_STATUS_CODE, id }));
  }
  return response;
};
