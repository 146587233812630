import { createSelector } from "reselect";

import { RdxStoreState } from "../types/state";
import isEqual from "lodash/isEqual";
import { getIsTenantsListLoading } from "./is-tenants-list-loading";
import { RdxTenantsSubState, initialState } from "../reducers/tenants/tenants";

export const getIsClearTenantsListFilterDisabled: (state: RdxStoreState) => boolean = createSelector(
  (state: RdxStoreState) => state.tenants.tenants,
  getIsTenantsListLoading,
  ({ workingFilter, activeFilter }: RdxTenantsSubState, loading: boolean): boolean => {
    return (
      (isEqual(initialState.workingFilter, workingFilter) &&
        (!activeFilter || isEqual(initialState.workingFilter, activeFilter.filter))) ||
      loading
    );
  }
);
