import React from "react";
import { handleCellText, handleDateCell } from "../../shared/table/table-cell-value";
import { Delete } from "./delete";

export const columns = (onDelete: (partnerId: number) => void) => [
  {
    title: "Name",
    dataIndex: "name",
    render: handleCellText,
    width: "60%"
  },
  {
    title: "Type",
    dataIndex: "type",
    render: handleCellText,
    width: "15%"
  },
  {
    title: "Segment ID",
    dataIndex: "refKey",
    render: handleCellText,
    width: "15%"
  },
  {
    title: "Creation date",
    dataIndex: "created",
    render: handleDateCell,
    width: "15%"
  },
  {
    title: "Actions",
    width: "10%",
    dataIndex: "id",
    render: function DeleteCell(id: number) {
      return <Delete onDelete={() => onDelete(id)} />;
    }
  }
];
