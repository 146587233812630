import { FilePdfOutlined } from "@ant-design/icons";
import { PromptScreenTemplate } from "@beckersdp/becker-ds";
import { Button, Typography } from "antd";
import debounce from "lodash/debounce";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { eulaHtml, eulaPdfHref, eulaVersion } from "../../constants/eula";
import { doLogout } from "../../redux/actions/authentication";
import { doAcceptEula } from "../../redux/actions/myself";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const Eula = (): JSX.Element => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const scrollDiv = useRef<HTMLDivElement>(null);
  const handleAcceptClick = async () => {
    setLoading(true);
    try {
      await dispatch(doAcceptEula(eulaVersion.checksum));
      window.location.reload();
    } catch (e) {
      //
    }
    setLoading(false);
  };
  const handleDeclineClick = () => {
    dispatch(doLogout());
  };

  useLayoutEffect(() => {
    if (!scrollDiv.current) {
      return;
    }
    const handleScroll = debounce((e: Event) => {
      const elem = e.target as HTMLDivElement;
      const Y_SCROLL_END_MARGIN = 20;
      const hasScrollBottom = elem.scrollTop + elem.clientHeight >= elem.scrollHeight - Y_SCROLL_END_MARGIN;
      if (hasScrollBottom) {
        setDisabled(false);
      }
    }, 200);
    scrollDiv.current.addEventListener("scroll", handleScroll);

    return function cleanUp() {
      scrollDiv.current && scrollDiv.current.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <StyledBeckerTemplate>
      <Header>
        <Button
          type="link"
          icon={<FilePdfOutlined />}
          href={eulaPdfHref}
          target="_blank"
          download
          style={{ fontWeight: "bold", padding: 0 }}
          data-qa-label="download-eula"
        >
          Download PDF
        </Button>
        <Typography.Text data-qa-label="last-modified-date">
          Last modified,&nbsp;
          <Typography.Text strong>{eulaVersion.lastUpdated}</Typography.Text>
        </Typography.Text>
      </Header>
      <Wrapper ref={scrollDiv} data-qa-label="content-eula">
        <div dangerouslySetInnerHTML={{ __html: eulaHtml }} />
      </Wrapper>
      <ActionWrapper>
        <Button onClick={handleDeclineClick} data-qa-label="decline-eula">
          Decline
        </Button>
        <Button
          type="primary"
          onClick={handleAcceptClick}
          disabled={disabled}
          loading={loading}
          data-qa-label="accept-eula"
        >
          Accept
        </Button>
      </ActionWrapper>
    </StyledBeckerTemplate>
  );
};

// -------------------------------------------------------------------------------------------------
// - Styled Component
// -------------------------------------------------------------------------------------------------

const StyledBeckerTemplate = styled(PromptScreenTemplate)`
  height: 100vh;
  width: 100vw;
`;

const Header = styled.div`
  height: 60px;
  background-color: #f7f9fc;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

const Wrapper = styled.div`
  background: #fff;
  padding: 20px;
  flex: 1;
  overflow: auto;

  font-family: Verdana, Arial, Helvetica, sans-serif;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
  background: #fff;
  height: 60px;
  align-items: center;
  padding-right: 15px;
  > * {
    margin: 0 10px;
    &:last-child {
      margin-right: 0px;
    }
  }
`;

export default Eula;
