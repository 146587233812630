import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { Alert } from "./model";

// -------------------------------------------------------------------------------------------------
// - Fetch alerts
// -------------------------------------------------------------------------------------------------

export const fetchAlerts = async (): Promise<Alert[]> =>
  (await HttpClient.get("/rest/alerts/all", createHeaders()))?.alerts ?? [];

// -------------------------------------------------------------------------------------------------
// - Mark Alert as read
// -------------------------------------------------------------------------------------------------

export const markAsRead = async (alertId: number): Promise<void> =>
  HttpClient.put(`/rest/alerts/${alertId}/isRead`, createHeaders());

// -------------------------------------------------------------------------------------------------
// - Mark Alert as unread
// -------------------------------------------------------------------------------------------------

export const markAsUnRead = async (alertId: number): Promise<void> =>
  HttpClient.del(`/rest/alerts/${alertId}/isRead`, createHeaders());
