import { FileExcelOutlined, FilterOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Drawer, Input } from "antd";
import download from "downloadjs";
import isString from "lodash/isString";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { MESSAGES, formatMsg } from "../../../i18n";
import { createGetUserParams, doApplyFilter, doUpdateWorkingFilter } from "../../../redux/actions/users-list";
import { RdxStoreState } from "../../../redux/types/state";
import { RdxFetchStatus } from "../../../redux/types/status";
import { routes } from "../../../routes";
import { navApi } from "../../../services/nav-api";
import { getTaskBlobWhenReady } from "../../../services/task";
import { createNotifier } from "../../../services/task/notifier";
import { getCurDateForReportFilename } from "../../../utils/date-utils";
import { UsersSidebar } from "../sidebar";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const SEARCH_MIN_CHARS = 3;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const UsersTableActions = () => {
  const dispatch = useDispatch();
  const {
    lastUpdated,
    activeFilter,
    workingFilter,
    status,
    data,
    paging //
  } = useSelector((state: RdxStoreState) => state.usersList);
  const navigate = useNavigate();
  const filter = activeFilter?.filter!;
  const mySelf = useSelector((state: RdxStoreState) => state.myself);
  const hasRecords = Boolean(data?.total);
  const loading = status === RdxFetchStatus.LOADING;
  const [drawerVisible, setDrawerVisible] = React.useState(false);

  const handleCreateReport = async () => {
    const notifier = createNotifier();
    notifier.loading();
    try {
      const { taskToken } = await navApi.users.generateReport(createGetUserParams(filter!, mySelf, paging));
      const blob = await getTaskBlobWhenReady(taskToken);
      download(blob, `users-report-${getCurDateForReportFilename()}.xlsx`, blob.type);
      notifier.success();
    } catch (err) {
      console.error(err);
      notifier.error(isString(err) ? err : undefined);
    }
  };

  React.useEffect(() => {
    if (lastUpdated! > 0) {
      setDrawerVisible(false);
    }
  }, [lastUpdated]);

  return (
    <div className="flex flex-row gap-x-2 mb-2">
      <form
        style={{ flex: 1, display: "flex" }}
        onSubmit={e => {
          e.preventDefault();
          dispatch(doApplyFilter());
        }}
      >
        <Input.Search
          data-qa-label="search-field"
          value={workingFilter.searchTerm}
          placeholder={formatMsg(MESSAGES.FilterSearchPlaceholderWMinChars, SEARCH_MIN_CHARS)}
          enterButton={<Button type="primary" icon={<SearchOutlined />} data-qa-label="search-btn" />}
          onChange={e => dispatch(doUpdateWorkingFilter({ searchTerm: e.currentTarget.value }))}
          onSearch={() => {
            dispatch(doApplyFilter());
          }}
        />
      </form>

      <div className="flex flex-row gap-x-2">
        <Button icon={<FilterOutlined />} onClick={() => setDrawerVisible(true)}>
          {MESSAGES.FilterBtnLabel}
        </Button>

        <Button
          onClick={handleCreateReport}
          icon={<FileExcelOutlined />}
          disabled={!hasRecords || loading}
          data-qa-label="createReportBtn"
        >
          Create Report
        </Button>

        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            navigate(routes.usersNew.resolve());
          }}
        >
          {MESSAGES.NewUser}
        </Button>
      </div>

      <Drawer
        closable
        width={430}
        title={MESSAGES.FiltersDrawerTitle}
        placement="right"
        open={drawerVisible}
        onClose={() => {
          setDrawerVisible(false);
        }}
      >
        <UsersSidebar />
      </Drawer>
    </div>
  );
};
