import { History } from "history";
import { Action, combineReducers } from "redux";
import { LOGOUT_COMPLETED } from "../actions/authentication";
import alertsReader from "../reducers/alerts-reader";
import alertsWriter from "../reducers/alerts-writer";
import apiFailure from "../reducers/api-failure";
import authentication from "../reducers/authentication";
import core from "../reducers/core";
import globalDashboard from "../reducers/dashboard/global";
import tenantDashboard from "../reducers/dashboard/tenant";
import hierarchyContent from "../reducers/hierarchy-content";
import myself from "../reducers/myself";
import navigationDrawer from "../reducers/navigation-drawer";
import partners from "../reducers/partners";
import studentDetails from "../reducers/student-details";
import studentsList from "../reducers/students-list";
import tenants from "../reducers/tenants";
import usersList from "../reducers/users-list";
import { RdxStoreState } from "../types/state";

export default (history: History) => (state: any, action: Action) => {
  if (action.type === LOGOUT_COMPLETED) {
    state = undefined;
  }
  return combineReducers<RdxStoreState>({
    core,
    alertsReader,
    alertsWriter,
    myself,
    apiFailure,
    authentication,
    navigationDrawer,
    studentsList,
    tenants,
    globalDashboard,
    tenantDashboard,
    studentDetails,
    hierarchyContent,
    usersList,
    partners
  })(state, action);
};
