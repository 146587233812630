import { Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckableTreeView } from "../../../../../components/shared/checkable-tree-view";
import { doUpdateFilter } from "../../../../../redux/actions/dashboard/tenant";
import { TagNode } from "../../../../../redux/selectors/my-tenant-tags-tree";
import { getStudentFilterTagsTree } from "../../../../../redux/selectors/student-filter-tags-tree";
import { getStudentFilterTenantId } from "../../../../../redux/selectors/student-filter-tenant-id";
import { RdxStoreState } from "../../../../../redux/types/state";
import { NoTagsAlert } from "./NoTagsAlert";
import { WithoutTags } from "./WithoutTags";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TagsFilter = () => {
  const dispatch = useDispatch();
  const studentsTree = useSelector(getStudentFilterTagsTree);
  const selectedTenantId = useSelector(getStudentFilterTenantId);
  const workingFilter = useSelector(({ tenantDashboard: { workingFilter } }: RdxStoreState) => workingFilter);

  return selectedTenantId ? (
    <Form.Item label="Tags">
      <CheckableTreeView
        checkBehavior="filter"
        tree={studentsTree.tree}
        loading={studentsTree.loading}
        checked={workingFilter.tags}
        renderNode={({ name }: TagNode) => name}
        onChange={({ checked }) => {
          dispatch(doUpdateFilter({ tags: checked, withoutTags: false }));
        }}
      />
      <NoTagsAlert />
      <WithoutTags />
    </Form.Item>
  ) : null;
};
