import { Menu, MenuProps } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getActiveUser } from "../../../redux/selectors/active-user";
import { getHasPartnersPermission } from "../../../redux/selectors/permissions/has-manage-partners";
import { getHasTagsPermission } from "../../../redux/selectors/permissions/has-manage-tags";
import { getHasTenantsPermission } from "../../../redux/selectors/permissions/has-manage-tenants";
import { getHasUsersPermission } from "../../../redux/selectors/permissions/has-manage-users";
import { getIsSystemAdmin } from "../../../redux/selectors/permissions/is-system-admin";
import { AppMenuContext } from "./context";
import menuItemMyAccount from "./mi-account";
import menuItemAdmin from "./mi-admin";
import menuItemDashboard from "./mi-dashboard";
import menuItemStudents from "./mi-students";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

type MenuItem = Required<MenuProps>["items"][number];

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const getParentItemsByKey = (parentKey: string | null, items: MenuItem[]) => {
  return _.reduce(
    items,
    (a, c: MenuItem) => {
      let childrenMap = {};
      if ((c as any).children) {
        childrenMap = getParentItemsByKey((c as any).key, (c as any).children);
      }

      return c?.key ? { ...a, ...childrenMap, [c.key as string]: parentKey } : a;
    },
    {} as Record<string, string | null>
  );
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AppMenu = () => {
  const hasTenantsPermission = useSelector(getHasTenantsPermission);
  const hasPartnersPermission = useSelector(getHasPartnersPermission);
  const hasTagsPermission = useSelector(getHasTagsPermission);
  const hasUsersPermission = useSelector(getHasUsersPermission);
  const isSystemAdmin = useSelector(getIsSystemAdmin);
  const activeUser = useSelector(getActiveUser);
  const isGpcEnabled = true; //useSelector(getIsGpcEnabled);
  const menuItems = React.useMemo(() => {
    return [
      ...menuItemDashboard,
      ...menuItemStudents,
      ...menuItemAdmin({
        hasTenantsPermission,
        hasPartnersPermission,
        hasUsersPermission,
        hasTagsPermission,
        isSystemAdmin
      }),
      ...menuItemMyAccount(activeUser, isGpcEnabled)
    ];
  }, [
    //
    activeUser,
    hasTenantsPermission,
    hasPartnersPermission,
    hasUsersPermission,
    hasTagsPermission,
    isSystemAdmin,
    isGpcEnabled
  ]);

  return (
    <AppMenuContext.Consumer>
      {({ active }) => <Menu mode="inline" items={menuItems} selectedKeys={[active!]} className="font-semibold" />}
    </AppMenuContext.Consumer>
  );
};
