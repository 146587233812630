import { AnyAction } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import { Action, ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { UPDATE_CONTENT_VERSION } from "../myself";

// Model
export enum ContentVersion {
  CPA_PRE_EVOLUTION = "CPA_PRE_EVOLUTION", // v4.*
  CPA_EVOLUTION = "CPA_EVOLUTION" // v1.0
}

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doSwitchContentVersion =
  (version: ContentVersion, onCompletion?: () => void): ThunkAction =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState, { navApi }): Promise<void> => {
    try {
      await navApi.myself.updateMySelfSettings({ ...getState().myself.userSettings, contentVersion: version });

      dispatch<Action<{ version: ContentVersion }>>({
        type: UPDATE_CONTENT_VERSION,
        payload: {
          version
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      if (onCompletion) {
        onCompletion();
      }
    }
  };
