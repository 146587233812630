import { createSelector } from "reselect";

import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";
import { RdxPartnersState } from "../reducers/partners";

export const getIsPartnersListLoading: (state: RdxStoreState) => boolean = createSelector(
  (state: RdxStoreState) => state.partners,
  (partners: RdxPartnersState) =>
    partners.status === RdxFetchStatus.LOADING || Boolean(partners.activeFilter && partners.activeFilter.loading)
);
