import { Form } from "antd";
import React from "react";
import { CheckableTreeView } from "../../../components/shared/checkable-tree-view";
import { CheckedNodes } from "../../../components/shared/checkable-tree-view/types";
import { TagNode } from "../../../redux/selectors/my-tenant-tags-tree";

// -------------------------------------------------------------------------------------------------
// - OwnProps
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  tree: TagNode[];
  onChange: (value: CheckedNodes) => void;
  checked: number[];
  loading: boolean;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudentEditTags = (props: OwnProps) => (
  <Form.Item label="Tags">
    <CheckableTreeView {...props} renderNode={(n: TagNode) => n.name} checkBehavior="assign" />
  </Form.Item>
);
