import { createSelector } from "reselect";
import { RdxStoreState } from "../types/state";
import { getActiveUser, ActiveUser } from "./active-user";

export const getHasTenant: (state: RdxStoreState) => boolean | undefined = createSelector(
  getActiveUser,
  (user: ActiveUser | undefined): boolean => {
    if (!user) {
      return false;
    }
    return user.tenant !== undefined && user.tenant !== null;
  }
);
