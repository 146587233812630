import { message } from "antd";
import { MESSAGES } from "../../i18n";
import React from "react";

export const createNotifier = () => {
  let hide: () => void;
  return {
    loading: () => {
      hide = message.loading(<span data-qa-label="reportLoadingMsg">{MESSAGES.ReportLoading}</span>, 0);
    },
    error: (msg?: string) => {
      hide?.();
      message.error(<span data-qa-label="reportErrorMsg">{msg || MESSAGES.ReportError}</span>);
    },
    success: () => {
      hide?.();
      message.success(<span data-qa-label="reportSuccessMsg">{MESSAGES.ReportSuccess}</span>);
    },
    hide: () => {
      hide?.();
    }
  };
};
