import { LockOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input } from "antd";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import { useSetPassword } from "../../../hooks/use-set-password";
import { MESSAGES } from "../../../i18n";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const ChangePasswordForm = (
  props: {
    onSubmit: (props: {
      oldPassword: string;
      newPassword: string; //
    }) => void;
  } & React.HTMLProps<HTMLDivElement>
) => {
  const {
    //
    password,
    onPasswordChange,
    retypePassword,
    onRetypePasswordChange,
    passwordHelp,
    retypePasswordHelp
  } = useSetPassword();
  const [oldPassword, setOldPassword] = useState<string>("");
  const oldPasswordValid = oldPassword && Boolean(oldPassword.trim());
  const newPasswordValid = !passwordHelp.error && !retypePasswordHelp.error;

  return (
    <Card title={MESSAGES.ChangePassword}>
      <Formik<any>
        initialValues={{ oldPassword, password, retypePassword }}
        enableReinitialize
        onSubmit={() => {
          props.onSubmit({ oldPassword, newPassword: password });
        }}
      >
        {({ handleSubmit }) => (
          <Form
            layout="vertical"
            name="passwords"
            autoComplete="off"
            onFinish={() => {
              handleSubmit();
            }}
          >
            <Form.Item label={`${MESSAGES.OldPassword}*`} style={{ flexDirection: "column" }}>
              <Field>
                {() => (
                  <Input.Password
                    value={oldPassword}
                    placeholder={MESSAGES.OldPassword}
                    onChange={e => setOldPassword(e.target.value)}
                    type="password"
                    prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  />
                )}
              </Field>
            </Form.Item>

            <Form.Item
              label={`${MESSAGES.NewPassword}*`}
              validateStatus={passwordHelp.error && passwordHelp.touched ? "error" : ""}
              help={passwordHelp.text}
            >
              <Field>
                {() => (
                  <Input.Password
                    placeholder={MESSAGES.NewPassword}
                    value={password}
                    onChange={e => onPasswordChange(e.target.value)}
                    prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  />
                )}
              </Field>
            </Form.Item>

            <Form.Item
              label={`${MESSAGES.RetypeNewPassword}*`}
              validateStatus={retypePasswordHelp.error && retypePasswordHelp.touched ? "error" : ""}
              help={retypePasswordHelp.error && retypePasswordHelp.touched ? retypePasswordHelp.text : ""}
            >
              <Field>
                {() => (
                  <Input.Password
                    placeholder={MESSAGES.RetypeNewPassword}
                    value={retypePassword}
                    onChange={e => onRetypePasswordChange(e.target.value)}
                    prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  />
                )}
              </Field>
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              disabled={!oldPasswordValid || !newPasswordValid}
              icon={<SaveOutlined />}
            >
              {MESSAGES.ChangePassword}
            </Button>
            <div className="float-right">{props.children}</div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};
