import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { initialState } from "../../reducers/alerts-writer/constants";
import { AlertsFilters } from "../../reducers/alerts-writer/model";
import { Action, ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { doFetchAlerts } from "./fetch";
import { doResetAlertsPagination } from "./pagination";

// -------------------------------------------------------------------------------------------------
// - Actions
// -------------------------------------------------------------------------------------------------

export const SET_ALERTS_FILTER = "SET_ALERTS_FILTER";
export const ACTIVATE_ALERTS_FILTER = "ACTIVATE_ALERTS_FILTER";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doSetAlertsFilter = (filter: Partial<AlertsFilters>): Action<Partial<AlertsFilters>> => ({
  type: SET_ALERTS_FILTER,
  payload: filter
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

const doActivateAlertsFilter = (): Action => ({
  type: ACTIVATE_ALERTS_FILTER
});

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doApplyAlertsFilter = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    dispatch(doActivateAlertsFilter());
    dispatch(doResetAlertsPagination());
    dispatch(doFetchAlerts());
  };
};

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doResetAlertsFilter = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    dispatch(doSetAlertsFilter(initialState.filters));
    dispatch(doActivateAlertsFilter());
    dispatch(doResetAlertsPagination());
    dispatch(doFetchAlerts());
  };
};
