import { createSelector } from "reselect";
import { RdxStudentsListState } from "../reducers/students-list";
import { RdxStoreState } from "../types/state";

export const getStudentRosterToken: (state: RdxStoreState) => {
  token?: string;
  total?: number;
  itemsPerPage?: number;
  page?: number;
  sort?: string;
} = createSelector(
  (state: RdxStoreState) => state.studentsList,
  ({ activeFilter, paging, sort }: RdxStudentsListState) => {
    if (!activeFilter || !activeFilter.token) {
      return { token: undefined, total: undefined };
    }

    let existentSort: string | undefined;

    if (sort?.key) {
      existentSort = `${sort.order === "ascend" ? "+" : "-"}${sort.key}`;
    }

    return {
      token: activeFilter.token,
      total: paging.totalItems!,
      itemsPerPage: paging.itemsPerPage,
      page: paging.page,
      sort: existentSort
    };
  }
);
