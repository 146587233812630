import { createSelector } from "reselect";

import { RdxStoreState } from "../types/state";
import { RdxStudentsListState, initialState } from "./../reducers/students-list";
import isEqual from "lodash/isEqual";
import { getIsStudentsListLoading } from "./is-students-list-loading";

export const getIsClearStudentsListFilterDisabled: (state: RdxStoreState) => boolean = createSelector(
  (state: RdxStoreState) => state.studentsList,
  getIsStudentsListLoading,
  ({ workingFilter, activeFilter }: RdxStudentsListState, loading: boolean): boolean => {
    return (
      (isEqual(initialState.workingFilter, workingFilter) &&
        (!activeFilter || isEqual(initialState.workingFilter, activeFilter.filter))) ||
      loading
    );
  }
);
