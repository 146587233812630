import React from "react";
import { END_USER_LINKS } from "../../../constants/bim-links";
import { MESSAGES } from "../../../i18n";

const key = "user-guide";
const qaLabel = "menu-user-guide";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default [
  {
    key,
    label: (
      <a data-qa-label={qaLabel} href={END_USER_LINKS.userGuide} target="_blank" rel="noopener noreferrer">
        {MESSAGES.NavUserGuide}
      </a>
    )
  }
];
