import Pusher from "pusher-js";
import { PUSHER_APP_KEY } from "../../../constants/pusher";
import { TaskStatus } from "../tasks/types";

const pusher = new Pusher(PUSHER_APP_KEY, { cluster: "mt1" });

export type TaskStatusChangeData = {
  status: TaskStatus;
  message: string;
};

const taskTokenChange = (token: string, handler: (data: TaskStatusChangeData) => void) => {
  const channel = pusher.subscribe(`task_${token}`);
  channel.bind("StatusChange", handler);
  return () => channel.unbind();
};

export { pusher, taskTokenChange };
