import _ from "lodash";
import { createSelector } from "reselect";
import { RdxMySelfState } from "../reducers/myself";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getHasUserData: (state: RdxStoreState) => boolean = createSelector(
  ({ myself }: RdxStoreState) => myself,
  ({ status, user, userSettings }: RdxMySelfState): boolean => {
    return _.isEqual(status, RdxFetchStatus.COMPLETED) && !!user && !!userSettings;
  }
);
