import { LoadingOutlined } from "@ant-design/icons";
import { Card, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseLayout } from "../../components/layout/base-layout";
import { PageTitle } from "../../components/layout/page-title";
import { NavigationDrawerOptionKey } from "../../components/navigation-drawer/types";
import { TenantFormProps, TenantsForm } from "../../components/tenants/form";
import { routes } from "../../routes";
import { HttpClientErrorResponse } from "../../services/http-client/types";
import { navApi } from "../../services/nav-api";
import { Tenant } from "../../services/nav-api/tenants";
import { Status } from "../../services/nav-api/types";

export const TenantEdit = () => {
  const navigate = useNavigate();
  const [tenant, setTenant] = useState<Tenant>();
  const { id } = useParams<{ id: string }>();
  const tenantId = parseInt(id!);

  useEffect(() => {
    navApi.tenants.getTenant(tenantId).then(tenant => setTenant(tenant));
  }, []);

  const handleSubmit = (props: TenantFormProps) => {
    navApi.tenants
      .editTenant(
        tenantId,
        props.name,
        props.status === true ? Status.Enabled : Status.Disabled,
        props.products,
        props.type!
      )
      .then(() =>
        Modal.success({
          title: "Tenant edited with success",
          onOk() {
            navigate(routes.tenant.resolve({ id: tenantId.toString() }));
          }
        })
      )
      .catch((err: HttpClientErrorResponse) =>
        Modal.error({
          title: "Error editing tenant",
          content: err.message
        })
      );
  };

  return (
    <BaseLayout
      activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_TENANTS}
      breadcrumb={[
        { name: "Tenants", href: routes.tenants.resolve() },
        { name: tenant ? tenant.name : "Tenant", href: routes.tenant.resolve({ id: tenantId.toString() }) },
        { name: "Edit" }
      ]}
    >
      <PageTitle>Edit Tenant</PageTitle>
      <Card>
        {tenant ? (
          <TenantsForm tenantId={tenantId} tenant={tenant} onSubmit={handleSubmit} />
        ) : (
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        )}
      </Card>
    </BaseLayout>
  );
};
