import { limitsByReportType, messageByReportType } from "../settings";
import { ReportType } from "../types";

// -------------------------------------------------------------------------------------------------
// - Hook
// -------------------------------------------------------------------------------------------------

export const useLimits = (limit: number, total: number, reportType: ReportType) => {
  const reportLimits = limitsByReportType[reportType];
  const reportMessage = {
    message: messageByReportType[reportType],
    isWarning: total! > (reportLimits ?? -1)
  };

  if (reportLimits == undefined) {
    reportMessage.isWarning = false;
  }

  return {
    ...reportMessage,
    value: limitsByReportType[reportType] ?? limit
  };
};
