import { Card } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { PageTitle } from "../../../components/layout/page-title";
import { MESSAGES } from "../../../i18n";
import { doFetchDataAsync } from "../../../redux/actions/dashboard/global";
import { getActiveProduct } from "../../../redux/selectors/active-product";
import { getGlobalDashboardStats, isFetchCompleted } from "../../../redux/selectors/dashboard/global";
import { DashboardCardTitle } from "../common/DashboardCardTitle";
import { DashboardCol } from "../common/DashboardCol";
import { DashboardNumber } from "../common/DashboardNumber";
import { DashboardRow } from "../common/DashboardRow";
import { TenantsByTypeChart } from "./TenantsByTypeChart";
import { Top5FirmsTable } from "./Top5FirmsTable";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const BeckerDashboard = (): JSX.Element => {
  const dispatch = useDispatch();
  const stats = useSelector(getGlobalDashboardStats);
  const isDataReady = useSelector(isFetchCompleted);
  const product = useSelector(getActiveProduct);

  React.useEffect(() => {
    dispatch(doFetchDataAsync());
  }, []);

  return (
    <React.Fragment>
      <PageTitle>
        {_.isEqual(product, "cma") //
          ? MESSAGES.CMADashboard
          : MESSAGES.CPADashboard}
      </PageTitle>
      <DashboardRow bp>
        <DashboardCol xs={24} md={8}>
          <StyledCard>
            <DashboardCardTitle>{MESSAGES.Tenants}</DashboardCardTitle>
            <DashboardNumber data-qa-label="tenants-count" value={stats?.tenantCount} loading={!isDataReady} />
          </StyledCard>
        </DashboardCol>
        <DashboardCol xs={24} md={8}>
          <StyledCard>
            <DashboardCardTitle>{MESSAGES.Users}</DashboardCardTitle>
            <DashboardNumber data-qa-label="users-count" value={stats?.userCount} loading={!isDataReady} />
          </StyledCard>
        </DashboardCol>
        <DashboardCol xs={24} md={8}>
          <StyledCard>
            <DashboardCardTitle>{MESSAGES.Students}</DashboardCardTitle>
            <DashboardNumber data-qa-label="students-count" value={stats?.studentCount} loading={!isDataReady} />
          </StyledCard>
        </DashboardCol>
      </DashboardRow>
      <DashboardRow>
        <DashboardCol xs={24} lg={12}>
          <StyledCard>
            <DashboardCardTitle alignment="left">{MESSAGES.TenantsByType}</DashboardCardTitle>
            <TenantsByTypeChart />
          </StyledCard>
        </DashboardCol>
        <DashboardCol xs={24} lg={12}>
          <StyledCard>
            <Top5FirmsTable />
          </StyledCard>
        </DashboardCol>
      </DashboardRow>
    </React.Fragment>
  );
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const DashboardCard = (
  props: React.HTMLProps<HTMLDivElement> //
) => <StyledCard bodyStyle={{ display: "flex", height: "100%" }}>{props.children}</StyledCard>;

// -------------------------------------------------------------------------------------------------
// - Styled Components
// -------------------------------------------------------------------------------------------------

const StyledCard = styled(Card)`
  height: 100%;
`;
