import { RightOutlined } from "@ant-design/icons";
import React from "react";
import { Motion, spring } from "react-motion";
import styled from "styled-components";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertsCaret = ({
  isActive
}: {
  isActive: boolean;
} & React.HTMLProps<HTMLDivElement>): JSX.Element => (
  <StyledExpandIconContainer>
    <Motion
      style={{
        value: spring(isActive ? 90 : 0)
      }}
      defaultStyle={{
        value: 0
      }}
    >
      {s => <StyledIcon style={{ transform: `rotateZ(${s.value}deg)` }} />}
    </Motion>
  </StyledExpandIconContainer>
);

// -------------------------------------------------------------------------------------------------
// - Styled Component
// -------------------------------------------------------------------------------------------------

const StyledIcon = styled(RightOutlined)`
  font-size: 15px;
  font-weight: bold;
`;

const StyledExpandIconContainer = styled.div`
  position: absolute;
  top: 40px;
  height: 76px;
  min-height: 76px;
  max-height: 76px;
`;
