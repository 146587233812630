import { createReducer as createReducerUtils, UseReducerAction } from "../../../utils/hooks-utils";
import { GetTenantStudentEntryTagsResponse, PutTenantStudentEntryTagsRequest } from "../../../services/nav-api/tenants";
import keyBy from "lodash/keyBy";
import { navApi } from "../../../services/nav-api";
import difference from "lodash/difference";
import { TagItem } from "./tags-table";
import { Dispatch } from "react";
import xor from "lodash/xor";
import isEqual from "lodash/isEqual";
import pickBy from "lodash/pickBy";
import { Modal } from "antd";
import { MESSAGES } from "../../../i18n";

export const DELETE = "DELETE" as const;
export const ADD = "ADD" as const;
export const SELECT_CHANGED = "SELECT_CHANGED" as const;
export const TOGGLE_REQUIRED = "TOGGLE_REQUIRED" as const;
export const RESET = "RESET" as const;
export const INITIAL_STATE_LOADED = "INITIAL_STATE_LOADED" as const;
export const TOGGLE_ENABLED = "TOGGLE_ENABLED" as const;
export const SUBMIT_STARTED = "SUBMIT_STARTED" as const;
export const SUBMIT_COMPLETED = "SUBMIT_COMPLETED" as const;

type RootTag = { id: number; name: string };

const ActionTypes = [
  DELETE,
  ADD,
  TOGGLE_REQUIRED,
  RESET,
  INITIAL_STATE_LOADED,
  TOGGLE_ENABLED,
  SELECT_CHANGED,
  SUBMIT_STARTED,
  SUBMIT_COMPLETED
];
type ActionTypes = (typeof ActionTypes)[number];

type Payload = { tagId: number };

export type CPATagFormState = {
  enabled: boolean;
  selectedId: number | undefined;
  requiredIds: number[];
  tagById: Record<number, RootTag>;
  includedIds: number[];
  allIds: number[];
  initialState: Partial<CPATagFormState & { loading: boolean }>;
  submitting: boolean;
};

export const initialState: CPATagFormState = {
  enabled: false,
  requiredIds: [],
  selectedId: undefined,
  tagById: {},
  includedIds: [],
  allIds: [],
  initialState: { loading: true },
  submitting: false
};

export default createReducerUtils<CPATagFormState, ActionTypes>({
  [DELETE]: (state, { tagId }: Payload) => ({
    ...state,

    includedIds: state.includedIds.filter(id => id !== tagId),
    requiredIds: state.requiredIds.filter(id => id !== tagId)
  }),
  [ADD]: state =>
    !state.selectedId
      ? state
      : {
          ...state,
          selectedId: undefined,

          includedIds: [...state.includedIds, state.selectedId],
          requiredIds: [...state.requiredIds, state.selectedId]
        },
  [TOGGLE_REQUIRED]: (state, { tagId }: Payload) => ({
    ...state,
    requiredIds: xor([...state.requiredIds], [tagId])
  }),
  [SELECT_CHANGED]: (state, { tagId }: Payload) => ({
    ...state,
    selectedId: tagId
  }),
  [TOGGLE_ENABLED]: state => ({
    ...state,
    enabled: !state.enabled
  }),
  [RESET]: state => ({ ...state, ...state.initialState, numOfOperations: 0 }),
  [INITIAL_STATE_LOADED]: (state, payload: GetTenantStudentEntryTagsResponse) => {
    const allIds = [...payload.availableRootTags, ...payload.activeRootTags].map(t => t.id);
    const includedIds = payload.activeRootTags.map(t => t.id);
    const tagById = { ...keyBy(payload.availableRootTags, "id"), ...keyBy(payload.activeRootTags, "id") };
    const requiredIds = payload.activeRootTags
      .filter(t => t.maxSelections === 1 && t.minSelections === 1)
      .map(t => t.id);

    const initialState = {
      allIds,
      includedIds,
      tagById,
      requiredIds,
      enabled: payload.mode?.toLowerCase() === "on"
    };
    return {
      ...state,
      ...initialState,
      initialState: {
        ...initialState,
        loading: false
      }
    };
  },
  [SUBMIT_STARTED]: state => ({
    ...state,
    submitting: true
  }),
  [SUBMIT_COMPLETED]: (state, payload) => {
    if (payload?.error) {
      return {
        ...state,
        submitting: false
      };
    }
    return {
      ...state,
      submitting: false,
      initialState: {
        allIds: state.allIds,
        includedIds: state.includedIds,
        tagById: state.tagById,
        requiredIds: state.requiredIds,
        enabled: state.enabled,
        loading: false
      }
    };
  }
});

export const loadInitialState = async (dispatch: Dispatch<any>, tenantId: number) => {
  try {
    const response = await navApi.tenants.getTenantStudentEntryTags(tenantId);
    const action: UseReducerAction<GetTenantStudentEntryTagsResponse, ActionTypes> = {
      type: INITIAL_STATE_LOADED,
      payload: response
    };
    dispatch(action);
  } catch (e) {
    console.error(e);
  }
};

export const submitChanges = async (dispatch: Dispatch<any>, tenantId: number, state: CPATagFormState) => {
  const activeRootTags = state.includedIds.map(id => {
    const selections = state.requiredIds.includes(id)
      ? {
          minSelections: 1,
          maxSelections: 1
        }
      : {
          minSelections: 0,
          maxSelections: 1
        };
    return {
      id,
      ...selections
    };
  });
  const request: PutTenantStudentEntryTagsRequest = {
    activeRootTags,
    mode: state.enabled ? "on" : "off"
  };

  dispatch({ type: SUBMIT_STARTED });

  try {
    await navApi.tenants.putTenantStudentEntryTags(tenantId, request);
    dispatch({ type: SUBMIT_COMPLETED });
  } catch (error) {
    Modal.error({
      title: MESSAGES.StudentEntryTagsErrorTitle,
      content: error?.message || MESSAGES.StudentEntryTagsErrorDescription
    });
    dispatch({ type: SUBMIT_COMPLETED, payload: { error } });
  }
};

// selectors
export const getDropdownOptions = (state: CPATagFormState): RootTag[] => {
  const nonIncludedIds = difference(state.allIds, state.includedIds);
  return nonIncludedIds.map(id => state.tagById[id]);
};

export const getTagsTableItems = (state: CPATagFormState): TagItem[] =>
  state.includedIds.map(id => ({
    id,
    name: state.tagById[id].name,
    required: state.requiredIds.includes(id)
  }));

export const getIsLoading = (state: CPATagFormState) => Boolean(state.initialState.loading || state.submitting);

export const getFormDisabled = (state: CPATagFormState) => {
  const { loading, ...baseState } = state.initialState;
  const curState = pickBy(state, (v: any, key: string) => Object.keys(baseState).includes(key));
  return isEqual(baseState, curState);
};

export const getHasNoTags = (state: CPATagFormState) => !state.initialState.loading && !state.allIds.length;
