import { createSelector } from "reselect";

import { RdxStoreState } from "../types/state";
import isEqual from "lodash/isEqual";
import { getIsPartnersListLoading } from "./is-partners-list-loading";
import { RdxPartnersState, initialState } from "../reducers/partners";

export const getIsClearPartnersListFilterDisabled: (state: RdxStoreState) => boolean = createSelector(
  (state: RdxStoreState) => state.partners,
  getIsPartnersListLoading,
  ({ workingFilter, activeFilter }: RdxPartnersState, loading: boolean): boolean => {
    return (
      (isEqual(initialState.workingFilter, workingFilter) &&
        (!activeFilter || isEqual(initialState.workingFilter, activeFilter.filter))) ||
      loading
    );
  }
);
