import { isHttpError } from "../../../services/http-client/types";
import { LOGIN_COMPLETED, LOGIN_FAILED, LOGIN_STARTED } from "../../actions/authentication";
import { RdxFetchStatus } from "../../types/status";
import { createReducer } from "../../utils/redux-utils";
import { UNAUTHORIZED_STATUS_CODE } from "./../../../services/http-client/constant";
import { ANY_ACTION_TYPE } from "./../../utils/redux-utils";

// -------------------------------------------------------------------------------------------------
// - Model
// -------------------------------------------------------------------------------------------------
export interface RdxAuthenticationState {
  error?: string;
  status: RdxFetchStatus;
}

const initialState: RdxAuthenticationState = {
  status: RdxFetchStatus.NONE
};

// -------------------------------------------------------------------------------------------------
// - Reducer
// -------------------------------------------------------------------------------------------------

export default createReducer<RdxAuthenticationState>(initialState, {
  [LOGIN_STARTED]: () => {
    return {
      status: RdxFetchStatus.LOADING
    };
  },

  [LOGIN_COMPLETED]: () => {
    return {
      status: RdxFetchStatus.COMPLETED
    };
  },

  [LOGIN_FAILED]: (_, error: string) => {
    return {
      status: RdxFetchStatus.FAILED,
      error
    };
  },

  [ANY_ACTION_TYPE]: (state, payload: any) => {
    if (payload && isHttpError(payload.error) && payload.error.statusCode === UNAUTHORIZED_STATUS_CODE) {
      return {
        status: RdxFetchStatus.NONE
      };
    }
    return state;
  }
});
