import React from "react";
import { END_USER_LINKS } from "../../../constants/bim-links";
import { MESSAGES } from "../../../i18n";

const key = "cookie-policy";
const qaLabel = "menu-cookie-policy";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default [
  {
    key,
    label: (
      <a target="_blank" data-qa-label={qaLabel} href={END_USER_LINKS.cookiePolicy} rel="noopener noreferrer">
        {MESSAGES.NavCookiePolicy}
      </a>
    )
  }
];
