import _ from "lodash";
import { altIdsByProduct, sectionAltIdsOrder, sectionNameByAltId } from "../../../constants/courses";
import {
  CurrentActivityValue,
  LicenseValue,
  StudyPlanValue,
  StudyTimeValue
} from "../../../redux/actions/students-list";
import { TenantProducts } from "./../../../services/nav-api/tenants/tenants";

type StudyTimeOption = { label: string; value: StudyTimeValue };
type LicenseOption = { label: string; value: LicenseValue };

export const studyTimeOptions: StudyTimeOption[] = [
  { label: "Any", value: "any" },
  { label: "None", value: "none" },
  { label: "Up to 4 hours", value: "up_to_4_hours" },
  { label: "4 to 48 hours", value: "4_to_48_hours" },
  { label: "48 to 240 hours", value: "48_to_240_hours" },
  { label: "Over 240 hours", value: "over_240_hours" }
];

export const studyTimeLabelByValue = Object.fromEntries(studyTimeOptions.map(item => [item.value, item.label]));

export const licenseOptions: LicenseOption[] = [
  { label: "Any", value: "any" },
  { label: "Active", value: "active" },
  { label: "Expired", value: "expired" },
  { label: "Expiring in less than 3 months", value: "in_less_than_3_months" },
  { label: "Expiring in more than 3 months", value: "in_more_than_3_months" },
  { label: "License length under 6 months", value: "length_under_6_months" }
];
export const licenseLabelByValue = Object.fromEntries(licenseOptions.map(item => [item.value, item.label]));

type StudyPlanOption = { label: string; value: StudyPlanValue };

export const studyPlanOptions: StudyPlanOption[] = [
  { label: "Any", value: "any" },
  { label: "No", value: "no" },
  { label: "Yes", value: "yes" },
  { label: "Yes & On Track", value: "yes_on_track" },
  { label: "Yes & Behind Schedule", value: "yes_behind_schedule" }
];

export const studyPlanLabelByValue = Object.fromEntries(studyPlanOptions.map(item => [item.value, item.label]));

type CurrentActivityOption = {
  label: string;
  value: CurrentActivityValue;
};

export const currentActivityOptions = (product: TenantProducts): CurrentActivityOption[] => [
  {
    label: "Any",
    value: "any"
  },
  // ---------------------------------------------------------//
  // ---------------------------------------------------------//
  // ---------------------------------------------------------//
  ..._.chain(altIdsByProduct[product])
    .sortBy(altId => _.indexOf(sectionAltIdsOrder, altId))
    .map(altId => ({
      label: sectionNameByAltId[altId],
      value: altId
    }))
    .value(),
  // ---------------------------------------------------------//
  // ---------------------------------------------------------//
  // ---------------------------------------------------------//
  {
    label: "No Activity",
    value: "no_activity"
  }
];

export const currentActivityLabelByValue = (product: TenantProducts) =>
  Object.fromEntries(currentActivityOptions(product).map(item => [item.value, item.label]));
