import {
  TAG_EDITOR_CANCELED,
  TAG_EDITOR_CREATE_OPENED,
  TAG_EDITOR_EDIT_OPENED,
  TagEditorEditPayload,
  TagEditorDragStartedPayload,
  TAG_EDITOR_TAG_DRAG_STARTED,
  TAG_EDITOR_TAG_DRAG_COMPLETED
} from "../../actions/tenants/editor-tags";
import { createReducer } from "../../utils/redux-utils";
import {
  TENANT_TAGS_CREATE_TAG_COMPLETED,
  TENANT_TAGS_DELETE_TAG_COMPLETED,
  TENANT_TAGS_UPDATE_TAG_COMPLETED,
  TENANT_TAGS_CREATE_TAG_STARTED
} from "../../actions/tenants";

export type RdxTagEditorSubState = {
  name?: string;
  id?: number; // if exists means edit
  parentId?: number;
  dragged?: boolean;
  isCreatingTag?: boolean;
} | null;

const initialState: RdxTagEditorSubState = null;

export default createReducer<RdxTagEditorSubState>(initialState, {
  [TAG_EDITOR_EDIT_OPENED]: (_, { id, name, parentId }: TagEditorEditPayload) => ({
    name,
    parentId,
    id
  }),
  [TAG_EDITOR_CREATE_OPENED]: (_, parentId: number) => ({
    name: "",
    parentId
  }),
  [TAG_EDITOR_TAG_DRAG_STARTED]: (_, { parentId, id }: TagEditorDragStartedPayload) => ({
    id,
    parentId,
    dragged: true
  }),
  [TAG_EDITOR_TAG_DRAG_COMPLETED]: () => null,
  [TAG_EDITOR_CANCELED]: () => null,
  [TENANT_TAGS_UPDATE_TAG_COMPLETED]: () => null,
  [TENANT_TAGS_DELETE_TAG_COMPLETED]: () => null,
  [TENANT_TAGS_CREATE_TAG_COMPLETED]: () => null,
  [TENANT_TAGS_CREATE_TAG_STARTED]: state => ({
    ...state,
    isCreatingTag: true
  })
});
