import { createSelector } from "reselect";
import { RdxStoreState } from "../../types/state";
import { ActiveUser, getActiveUser } from "../active-user";
import { getHasTenant } from "../has-tenant";
import { getHasTenantPermission } from "./has-manage-tenant";

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getHasTagsPermission: (state: RdxStoreState) => boolean | undefined = createSelector(
  getActiveUser,
  getHasTenant,
  getHasTenantPermission,
  (user: ActiveUser | undefined, hasTenantPermission: boolean, hasTenant: boolean): boolean => {
    if (!user || !user.permissions) {
      return false;
    }
    return hasTenantPermission && hasTenant;
  }
);
