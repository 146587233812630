import { createSelector } from "reselect";
import { RdxStudentDetails } from "../reducers/student-details";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "../types/status";

export type StudentFollowed = {
  loading: boolean;
  followed: boolean;
  curStudentId?: number;
};

export const getIsCurStudentFollowed: (state: RdxStoreState) => StudentFollowed = createSelector(
  (state: RdxStoreState) => state.studentDetails,
  ({ curStudentId, details, status }: RdxStudentDetails) => {
    if (!curStudentId) {
      return { followed: false, loading: true };
    }

    if (details && details.id === curStudentId) {
      return { loading: false, followed: Boolean(details.followed), curStudentId };
    }

    return { loading: status === RdxFetchStatus.LOADING, followed: false };
  }
);
