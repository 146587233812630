import { useDispatch, useSelector } from "react-redux";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../../../redux/actions/students-list";
import { RdxStoreState } from "../../../../../redux/types/state";
import { FilterTag } from "../model";

// -------------------------------------------------------------------------------------------------
// - Hooks: useSearchTerm
// -------------------------------------------------------------------------------------------------

export const useSearchTerm = (): FilterTag[] => {
  const dispatch = useDispatch();
  const { activeFilter } = useSelector((state: RdxStoreState) => state.studentsList);
  return activeFilter?.filter?.searchTerm
    ? [
        {
          onClear: () => {
            dispatch(doUpdateWorkingFilter({ searchTerm: "" }));
            dispatch(doApplyFilter());
          },
          text: activeFilter.filter.searchTerm
        }
      ]
    : [];
};
