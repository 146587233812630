import { MESSAGES } from "../../../../../../i18n";
import { StatsScope, StatsSlice } from "./model";

export const colorBySlice: { [key in StatsSlice]: string } = {
  [StatsSlice.S1]: "#1f5488",
  [StatsSlice.S2]: "#378dc8"
};

export const textColorBySlice: { [key in StatsSlice]: string } = {
  [StatsSlice.S1]: "text-[#1f5488]",
  [StatsSlice.S2]: "text-[#378dc8]"
};

export const titleByStatsScope: { [key in StatsScope]: string } = {
  [StatsScope.SPS_USAGE]: MESSAGES.StudyPlanUsage,
  [StatsScope.SPS_PERFORMANCE]: MESSAGES.StudyPlanPerformance,
  [StatsScope.LICENSES]: MESSAGES.Licenses
};

export const topLabelByStatsScope: { [key in StatsScope]: string } = {
  [StatsScope.SPS_USAGE]: MESSAGES.WithStudyPlan.toLowerCase(),
  [StatsScope.SPS_PERFORMANCE]: MESSAGES.StudentsTotalOnTrack.toLowerCase(),
  [StatsScope.LICENSES]: MESSAGES.ExpiredInLessThan3Months.toLowerCase()
};

export const labelByStatsScopeAndSlice: {
  [key in StatsScope]: {
    [key in StatsSlice]: string;
  };
} = {
  [StatsScope.SPS_USAGE]: {
    [StatsSlice.S1]: MESSAGES.WithStudyPlan,
    [StatsSlice.S2]: MESSAGES.WithoutStudyPlan
  },
  [StatsScope.SPS_PERFORMANCE]: {
    [StatsSlice.S1]: MESSAGES.OnTrack,
    [StatsSlice.S2]: MESSAGES.BehindSchedule
  },
  [StatsScope.LICENSES]: {
    [StatsSlice.S1]: MESSAGES.ExpiredInLessThan3Months,
    [StatsSlice.S2]: MESSAGES.ExpiredInMoreThan3Months
  }
};
