import { compile } from "path-to-regexp";

export interface SetPasswordRouteParams {
  token: string;
}

export const setPassword = (() => {
  const path = "/set-password";
  const toPath = compile(path);
  return {
    path,
    resolve(params: SetPasswordRouteParams) {
      return toPath(params);
    }
  };
})();
