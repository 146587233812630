import { AntTreeNodeCheckedEvent } from "antd/lib/tree";

import { calcCheckedIdsBasedOnAction as calcCheckedIdsBasedOnActionAssign } from "../../../utils/tree-utils/assign/calc-checked-ids";
import { calcCheckedIdsBasedOnAction as calcCheckedIdsBasedOnActionFilter } from "../../../utils/tree-utils/filter/calc-checked-ids";
import { Node } from "../../../utils/tree-utils/types";
import { CheckBehavior, CheckedNodes } from "./types";

function createOnCheckHandlers(tree: Node[]): {
  [key in CheckBehavior]: (
    antdCheckedKeys: CheckedNodes<string>,
    e: AntTreeNodeCheckedEvent,
    curCheckedIds?: number[]
  ) => CheckedNodes;
} {
  return {
    strict: checkedKeys => ({ checked: checkedKeys.checked.map(k => parseInt(k)) }),
    filter: (_, e, curCheckedIds: number[] = []) => {
      const check = Boolean(e.checked);
      const id = parseInt(e.node.props.eventKey || "");
      const newCheckedIds = calcCheckedIdsBasedOnActionFilter(tree, curCheckedIds, { id, check });
      return { checked: newCheckedIds };
    },
    assign: (_, e, curCheckedIds: number[] = []) => {
      const check = Boolean(e.checked);
      const id = parseInt(e.node.props.eventKey || "");
      const newCheckedIds = calcCheckedIdsBasedOnActionAssign(tree, curCheckedIds, { id, check });
      return { checked: newCheckedIds };
    }
  };
}

export { createOnCheckHandlers };
