import { Checkbox } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { doUpdateWorkingFilter } from "../../../../../redux/actions/students-list";
import { getHasRestrictedUserTags } from "../../../../../redux/selectors/my-tenant-tags-tree";
import { getStudentFilterTagsTree } from "../../../../../redux/selectors/student-filter-tags-tree";
import { RdxStoreState } from "../../../../../redux/types/state";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const WithoutTags = () => {
  const dispatch = useDispatch();
  const hasRestrictedUserTags = useSelector(getHasRestrictedUserTags);
  const studentsTree = useSelector(getStudentFilterTagsTree);
  const workingFilter = useSelector((state: RdxStoreState) => state.studentsList.workingFilter);
  const withoutTags = Boolean(workingFilter.withoutTags);

  return !hasRestrictedUserTags && !studentsTree.loading && (studentsTree.tree ?? []).length > 0 ? (
    <Checkbox
      checked={withoutTags}
      onChange={() => {
        dispatch(doUpdateWorkingFilter({ withoutTags: !withoutTags }));
      }}
    >
      Without Tags
    </Checkbox>
  ) : null;
};
