import { initializeStore } from "../redux/store/configure-store";
import { storeRegistry } from "../redux/store/store-registry";
import { createDispatchGlobalErrorHttpInterceptor } from "../redux/utils/dispatch-global-error-http-interceptor";
import { errorTracker } from "../services/error-tracking";
import { httpErrorTrackingInterceptor } from "../services/error-tracking/http-error-interceptor";
import * as httpClient from "../services/http-client";
import { updateErrorTrackerUserId } from "./update-error-tracker-user-id";

function bootstrap() {
  errorTracker.init();
  const store = initializeStore();
  storeRegistry.register(store);

  httpClient.interceptors.response.push(createDispatchGlobalErrorHttpInterceptor(store));
  httpClient.interceptors.response.push(httpErrorTrackingInterceptor);

  updateErrorTrackerUserId(store);

  return { store };
}
//
export { bootstrap };
