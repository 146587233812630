import { DownOutlined, LoadingOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { BaseLayout } from "../../components/layout/base-layout";
import { PageTitle } from "../../components/layout/page-title";
import { NavigationDrawerOptionKey } from "../../components/navigation-drawer/types";
import { DeleteUser } from "../../components/users/DeleteUser";
import { ForgotPassword } from "../../components/users/ForgotPassword";
import { LocalUserFormProps, UserForm } from "../../components/users/form";
import { UserState } from "../../components/users/status";
import { RdxMySelfState } from "../../redux/reducers/myself";
import { RdxStoreState } from "../../redux/types/state";
import { routes } from "../../routes";
import { HttpClientErrorResponse } from "../../services/http-client/types";
import { navApi } from "../../services/nav-api";
import { Status } from "../../services/nav-api/types";
import { User, UserType } from "../../services/nav-api/users";

const UserDetails = () => {
  const [user, setUser] = useState<User>();
  const [disabled, setDisabled] = useState(false);
  const myself: RdxMySelfState = useSelector((state: RdxStoreState) => state.myself);
  const { id } = useParams<{ id: string }>();
  const userId = parseInt(id!);

  useEffect(() => {
    (async () => {
      const user = await navApi.users.getUser(userId);
      setUser(user);
      setDisabled(_.isEqual(user.status, Status.Disabled));
    })();
  }, []);

  const handleSubmit = (props: LocalUserFormProps) => {
    navApi.users
      .putUser(userId, props)
      .then(() => {
        Modal.success({
          title: "User details edited successfully"
        });
        setUser({ ...user!, ...props });
      })
      .catch((err: HttpClientErrorResponse) =>
        Modal.error({
          title: "Error editing user details",
          content: err.message
        })
      );
  };

  const renderUserState = () => {
    if (user && myself.user && myself.user.userId !== user.id) {
      return <UserState userId={user.id} isDisabled={disabled} onChangeState={setDisabled} />;
    }
    return null;
  };

  return (
    <BaseLayout
      activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_USERS}
      breadcrumb={[{ name: "Users", href: routes.users.resolve() }, { name: "Details" }]}
    >
      {user ? (
        <>
          {user.idp === UserType.Local && //
          !_.isEmpty(user.firstName) &&
          !_.isEmpty(user.lastName) ? (
            <PageTitle>
              {user.firstName} {user.lastName}
            </PageTitle>
          ) : null}
          <UserForm user={user} onSubmit={handleSubmit}>
            <Dropdown
              placement="bottomRight"
              overlay={
                <Menu>
                  {renderUserState()}
                  {_.isEqual(disabled, false) ? <ForgotPassword user={user} key="forgot-pass" /> : null}
                  {_.isEqual(disabled, true) ? (
                    <>
                      <Menu.Divider key="divider-enabled" />
                      <DeleteUser user={user} key="delete-user" />
                    </>
                  ) : null}
                </Menu>
              }
            >
              <Button>
                <SettingOutlined />
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          </UserForm>
        </>
      ) : (
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      )}
    </BaseLayout>
  );
};

export default UserDetails;
