import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Action, ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import {
  UPDATE_EULA_ACCEPTED_COMPLETED,
  UPDATE_EULA_ACCEPTED_STARTED //
} from "./actions";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doAcceptEula = (version: string): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    dispatch({ type: UPDATE_EULA_ACCEPTED_STARTED });

    try {
      await navApi.myself.updateMySelfSettings({
        ...getState().myself.userSettings,
        acceptedEulaVersion: version
      });

      dispatch<Action<string>>({
        type: UPDATE_EULA_ACCEPTED_COMPLETED,
        payload: version
      });
    } catch (error) {
      console.error(error);
    }
  };
};
