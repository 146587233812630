import { Button, Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RangeDateFilter } from "../../../pages/Students/shared/range-date-filter";
import {
  doApplyFilter,
  doClearFilter,
  doResetListSettings,
  doUpdateWorkingFilter
} from "../../../redux/actions/users-list";
import { getIsGlobalUser } from "../../../redux/selectors/permissions/is-global-user";
import {
  getIsApplyUsersListFilterDisabled,
  getIsClearUsersListFilterDisabled,
  getUsersListWorkingFilter
} from "../../../redux/selectors/users-list";
import { StatusFilter } from "../../shared/status";
import { RoleFilter } from "./role";
import { TenantFilter } from "./tenant";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const UsersSidebar = () => {
  const dispatch = useDispatch();
  const clearFilterDisabled = useSelector(getIsClearUsersListFilterDisabled);
  const applyFilterDisabled = useSelector(getIsApplyUsersListFilterDisabled);
  const workingFilter = useSelector(getUsersListWorkingFilter);
  const globalUser = useSelector(getIsGlobalUser);
  const [form] = Form.useForm();

  return (
    <Form layout="vertical" form={form}>
      {globalUser ? (
        <TenantFilter
          value={workingFilter.tenantId}
          onChange={tenantId => dispatch(doUpdateWorkingFilter({ tenantId }))}
        />
      ) : null}
      <RoleFilter value={workingFilter.roleId} onChange={roleId => dispatch(doUpdateWorkingFilter({ roleId }))} />
      <StatusFilter value={workingFilter.status} onChange={status => dispatch(doUpdateWorkingFilter({ status }))} />
      <RangeDateFilter
        label="Creation Date"
        id="creation-date-filter"
        value={workingFilter.creationDate}
        onChange={creationDate => dispatch(doUpdateWorkingFilter({ creationDate }))}
      />
      <RangeDateFilter
        label="Last Login Date"
        id="last-login-date-filter"
        value={workingFilter.lastLoginDate}
        onChange={lastLoginDate => dispatch(doUpdateWorkingFilter({ lastLoginDate }))}
      />
      <div className="flex justify-center gap-x-2">
        <Button disabled={clearFilterDisabled} htmlType="button" onClick={() => dispatch(doClearFilter())}>
          Reset
        </Button>
        <Button
          type="primary"
          disabled={applyFilterDisabled}
          onClick={() => {
            if (applyFilterDisabled) {
              return;
            }

            dispatch(doResetListSettings());
            dispatch(doApplyFilter());
          }}
        >
          Apply
        </Button>
      </div>
    </Form>
  );
};
