import _ from "lodash";
import { createSelector } from "reselect";
import { StringUtils } from "../../../utils/stringUtils";
import { RdxAlertsWriterState } from "../../reducers/alerts-writer";
import { OperationType } from "../../reducers/alerts-writer/model";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "../../types/status";

// -------------------------------------------------------------------------------------------------
// - Selectors
// -------------------------------------------------------------------------------------------------

export const getOperation = createSelector(
  ({ alertsWriter: alerts }: RdxStoreState) => alerts,
  ({ operation }: RdxAlertsWriterState) => {
    return operation;
  }
);

export const getHasAlertsOperation = createSelector(getOperation, op => !!op);
export const getOperationType = createSelector(getOperation, op => op?.type);
export const getOperationAlert = createSelector(getOperation, op => op?.alert);
export const getOperationError = createSelector(getOperation, op => op?.error);
export const getOperationStatus = createSelector(getOperation, op => op?.status);
export const getOperationStatusLoading = createSelector(getOperation, op =>
  _.isEqual(RdxFetchStatus.LOADING, op?.status)
);

export const getOperationTypeEditing = createSelector(getOperationType, opType =>
  _.isEqual(OperationType.EDIT, opType)
);

export const getOperationTypeEditingNew = createSelector(
  getOperationType,
  getOperationAlert,
  (opType, alert) => _.isEqual(OperationType.EDIT, opType) && alert!.id < 0
);

export const getOperationTypeDeleting = createSelector(getOperationType, opType =>
  _.isEqual(OperationType.DELETE, opType)
);

export const getOperationTypeExpiring = createSelector(getOperationType, opType =>
  _.isEqual(OperationType.EXPIRE, opType)
);

export const getCanSaveOperation = createSelector(
  getOperation,
  op =>
    !StringUtils.isBlank(op?.alert?.body) &&
    !StringUtils.isBlank(op?.alert?.title) &&
    !StringUtils.isBlank(op?.alert?.publishDate) &&
    !StringUtils.isBlank(op?.alert?.expirationDate)
);
