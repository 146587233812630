import React from "react";
import { MESSAGES } from "../../../i18n";

const key = "cookie-pref";
const qaLabel = "menu-cookie--preference-policy";

// -------------------------------------------------------------------------------------------------
// - Menu Item
// -------------------------------------------------------------------------------------------------

export default (isVisible: boolean) => [
  ...(isVisible
    ? [
        {
          key,
          label: (
            <a
              data-qa-label="menu-cookie--preference-policy"
              onClick={() => {
                document.getElementById("ot-sdk-btn")!.click();
              }}
            >
              {MESSAGES.NavCookiePrefCenter}
            </a>
          )
        }
      ]
    : [])
];
