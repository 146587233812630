import { ColumnProps } from "antd/lib/table";
import memoize from "lodash/memoize";
import React from "react";
import { routes } from "../../../routes";
import { Partner } from "../../../services/nav-api/partners";
import { Tenant } from "../../../services/nav-api/tenants";
import { Status } from "../../../services/nav-api/types";
import { composeHandlers, handleCellText, renderWithMaxWidth } from "../../shared/table/table-cell-value";
import { Checked } from "./checked";

export const createColumns = memoize((tenants: Tenant[] | undefined): ColumnProps<{}>[] => [
  {
    title: "Partner Name",
    dataIndex: "name",
    render: composeHandlers(handleCellText, renderWithMaxWidth(500)),
    width: "55%",
    className: "td-test",
    ellipsis: true
  },
  {
    title: "Tenant",
    dataIndex: "tenantId",
    render: function RoleCol(tenantId: number) {
      if (tenants) {
        try {
          const tenant = tenants.filter(t => t.id === tenantId)[0];
          return <a href={routes.tenant.resolve({ id: tenant.id.toString() })}>{tenant.name}</a>;
        } catch (err) {
          return tenantId;
        }
      }
      return tenantId;
    },
    width: "15%",
    ellipsis: true
  },
  {
    title: "Type",
    dataIndex: "type",
    render: handleCellText,
    width: "10%",
    ellipsis: true
  },
  {
    title: "Segment ID",
    dataIndex: "refKey",
    render: handleCellText,
    width: "10%",
    ellipsis: true
  },
  {
    title: "Status",
    key: "status",
    width: "100px",
    render: function renderStatus(text, record: Partner) {
      return <Checked id={record.id} value={record.status === Status.Enabled} />;
    }
  }
]);
