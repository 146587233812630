import { Typography } from "antd";
import styled from "styled-components";

export const PageTitle = styled(Typography.Title).attrs({
  level: 2
})`
  font-weight: 400;

  /* kind of hack just for title element to preserve the space*/
  &:after {
    content: "-";
    opacity: 0;
  }
`;
