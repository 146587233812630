import { Alert, Button } from "antd";
import React, { useContext } from "react";
import { MESSAGES, formatMsg } from "../../../../i18n";
import { StudentTableActionsContext } from "../table-actions-context";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const SelectionBar = (props: { className?: string }) => {
  const { selection, onSelectionCancel, onSelectionConfirm, operation } = useContext(StudentTableActionsContext);
  const numOfSelections = selection.ids.length;
  const title = operation === "bulk-change" ? MESSAGES.BulkChangeSelectedItemsMessage : MESSAGES.GenerateReportMessage;

  return (
    <Alert
      className={props.className}
      style={{
        marginBottom: 20,
        paddingTop: 5,
        paddingBottom: 5
      }}
      message={
        <div className="flex items-center justify-between">
          {formatMsg(title, numOfSelections)}
          <div>
            <Button size="small" type="link" onClick={onSelectionCancel} disabled={selection.loading}>
              {MESSAGES.CancelLabel}
            </Button>
            <Button
              disabled={numOfSelections === 0}
              size="small"
              type="link"
              style={{ marginLeft: "20px" }}
              onClick={onSelectionConfirm}
              loading={selection.loading}
            >
              {MESSAGES.ConfirmLabel}
            </Button>
          </div>
        </div>
      }
      type="info"
    />
  );
};
