import memoize from "lodash/memoize";
import cloneDeep from "lodash/cloneDeep";

type Node = { id: number; children: Node[]; name: string };

const createNodeById = (tree: Node[]) => {
  const queue = cloneDeep(tree);
  const map: { [id: number]: Node } = {};
  while (queue.length) {
    const curNode = queue.shift()!;
    map[curNode.id] = curNode;
    queue.push(...curNode.children);
  }
  return map;
};

const createNodeParentById = memoize((nodes: Node[]) => {
  const map: { [key: number]: number | undefined } = {};
  const queue = cloneDeep(nodes);

  queue.forEach(curNode => {
    map[curNode.id] = undefined;
  });

  while (queue.length) {
    const curNode = queue.shift()!;
    queue.push(...curNode.children);
    curNode.children.forEach(childNode => {
      map[childNode.id] = curNode.id;
    });
  }
  return map;
});

const createNodeChildrenIdsById = memoize((nodes: Node[]): { [key: number]: number[] } => {
  const map: { [key: number]: number[] } = {};
  const queue = cloneDeep(nodes);
  while (queue.length) {
    const curNode = queue.shift()!;
    queue.push(...curNode.children);
    map[curNode.id] = curNode.children.map(n => n.id);
  }
  return map;
});

export { createNodeChildrenIdsById, createNodeParentById, createNodeById };
