import { GetMySelfResponse } from "../../../services/nav-api/myself";
import { TenantProducts } from "../../../services/nav-api/tenants";
import { ContentVersion } from "../../actions/contentversion-switch";
import { FETCH_MYSELF_COMPLETED, FETCH_MYSELF_STARTED } from "../../actions/myself";
import {
  FETCH_MYSELF_FAILED,
  UPDATE_ACTIVE_PRODUCT,
  UPDATE_ACTIVE_USER,
  UPDATE_CONTENT_VERSION,
  UPDATE_EULA_ACCEPTED_COMPLETED,
  UPDATE_PUSHED_ALERTS
} from "../../actions/myself/actions";
import { RdxFetchStatus } from "../../types/status";
import { createReducer } from "../../utils/redux-utils";
import { initialState } from "./constants";
import { MySelfPayload, RdxMySelfState } from "./model";
export * from "./constants";
export * from "./model";

// -------------------------------------------------------------------------------------------------
// - Reducer
// -------------------------------------------------------------------------------------------------

export default createReducer(initialState, {
  [FETCH_MYSELF_STARTED]: (): RdxMySelfState => ({
    status: RdxFetchStatus.LOADING,
    user: undefined,
    userSettings: undefined,
    userRoles: undefined,
    error: undefined
  }),

  [FETCH_MYSELF_COMPLETED]: (_, { user, userSettings, userRoles }: MySelfPayload): RdxMySelfState => ({
    status: RdxFetchStatus.COMPLETED,
    error: undefined,
    user,
    userRoles,
    userSettings
  }),

  [UPDATE_ACTIVE_USER]: (state, activeUser: Partial<GetMySelfResponse>): RdxMySelfState => {
    const ns = { ...state };
    ns.user! = {
      ...ns.user!,
      ...activeUser
    };
    return ns;
  },

  [FETCH_MYSELF_FAILED]: (_, error): RdxMySelfState => ({
    status: RdxFetchStatus.FAILED,
    user: undefined,
    userSettings: undefined,
    userRoles: undefined,
    error
  }),

  [UPDATE_EULA_ACCEPTED_COMPLETED]: (state: RdxMySelfState, acceptedEulaVersion: string): RdxMySelfState => {
    const ns = { ...state };
    if (ns.userSettings) {
      ns.userSettings = { ...(ns.userSettings || {}), acceptedEulaVersion };
    }

    return ns;
  },

  [UPDATE_PUSHED_ALERTS]: (state: RdxMySelfState, pushedAlertIds: number[]): RdxMySelfState => {
    const ns = { ...state };
    if (ns.userSettings) {
      ns.userSettings = { ...(ns.userSettings || {}), pushedAlertIds };
    }

    return ns;
  },

  [UPDATE_ACTIVE_PRODUCT]: (state, activeProduct: TenantProducts): RdxMySelfState => {
    const ns = { ...state };
    if (ns.userSettings) {
      ns.userSettings = { ...(ns.userSettings || {}), activeProduct };
    }

    return ns;
  },

  [UPDATE_CONTENT_VERSION]: (state, { version }: { version: ContentVersion }): RdxMySelfState => {
    const ns = { ...state };
    if (ns.userSettings && version) {
      ns.userSettings = { ...(ns.userSettings || {}), contentVersion: version };
    }

    return ns;
  }
});
