import { Tree } from "antd";
import includes from "lodash/includes";
import React from "react";
import { useSelector } from "react-redux";
import { MoveStudentsTreeNode } from "./tree-node";

import { getMyTenantTagsTree, TagNode } from "../../../../redux/selectors/my-tenant-tags-tree";
import { getAllRootIds } from "../../../../utils/tree-utils/get-all-roots";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const MoveStudentsTree = (props: {
  id: number;
  onClick: (value: {
    tagId: number;
    tagName: string; //
  }) => void;
}) => {
  const { tree = [] } = useSelector(getMyTenantTagsTree);
  const rootIds = getAllRootIds(tree);
  const disabledIds = [...rootIds, props.id];

  const renderTreeNodes = (node: TagNode) => {
    const disabled = includes(disabledIds, node.id);
    return (
      <Tree.TreeNode
        disabled={disabled}
        title={
          <MoveStudentsTreeNode
            disabled={disabled}
            onClick={() => props.onClick({ tagId: node.id, tagName: node.name })}
          >
            {node.name}
          </MoveStudentsTreeNode>
        }
        key={(node.id || -1).toString()}
        selectable={false}
      >
        {node.children.map(renderTreeNodes)}
      </Tree.TreeNode>
    );
  };

  return (
    <div className="overflow-auto min-w-[300px] min-h-[95vh]">
      <Tree selectable={false} blockNode defaultExpandAll>
        {tree.map(renderTreeNodes)}
      </Tree>
    </div>
  );
};
