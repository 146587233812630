import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { formatMsg, MESSAGES } from "../../../i18n";
import { doApplyFilter, doUpdateWorkingFilter } from "../../../redux/actions/users-list";
import { initialState } from "../../../redux/reducers/users-list";
import { getActiveUserRoles } from "../../../redux/selectors/active-user-roles";
import { getUsersListActiveFilter } from "../../../redux/selectors/users-list";
import { FilterTag } from "./model";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const initialValue = initialState.workingFilter.roleId;

// -------------------------------------------------------------------------------------------------
// - Hooks: useRoleFilter
// -------------------------------------------------------------------------------------------------

export const useRoleFilter = (): FilterTag[] => {
  const dispatch = useDispatch();
  const activeFilter = useSelector(getUsersListActiveFilter);
  const userRoles = useSelector(getActiveUserRoles);
  const roleId = activeFilter?.filter?.roleId;

  return !_.isEqual(roleId, initialValue) && roleId! > 0
    ? [
        {
          onClear: () => {
            dispatch(
              doUpdateWorkingFilter({
                roleId: initialValue
              })
            );
            dispatch(doApplyFilter());
          },
          text: formatMsg(
            MESSAGES.FilterTagRole,
            _.chain(userRoles)
              .filter({ id: roleId })
              .head() //
              .value()?.name ?? ""
          )
        }
      ]
    : [];
};
