import { Action } from "../../types/action";
import { LOGIN_COMPLETED } from "./actions";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const doCompleteLogin = (): Action<void> => {
  return { type: LOGIN_COMPLETED };
};
