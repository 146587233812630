import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";

// -------------------------------------------------------------------------------------------------
// - Actions
// -------------------------------------------------------------------------------------------------

export const ONETRUST_SET_FLAG = "ONETRUST_SET_FLAG";

// -------------------------------------------------------------------------------------------------
// - Action Builder
// -------------------------------------------------------------------------------------------------

export const setOnetrustFlag = (payload: boolean): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    if (!_.isEqual(getState().core.isGpcEnabled, payload)) {
      dispatch(
        dispatch({
          type: ONETRUST_SET_FLAG,
          payload
        })
      );
    }
  };
};
