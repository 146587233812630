import React from "react";
import styled from "styled-components";
import { SIDER_WIDTH } from "../../constants/ui-constants";
import IcNavigator from "../../assets/images/ic_navigator_logo_dark.svg";
import IcNavigatorSmall from "../../assets/images/ic_navigator_logo_small.svg";

const StyledLogo = styled(IcNavigator)`
  height: 32px;
  width: auto;
`;

const StyledLogoSmall = styled(IcNavigatorSmall)`
  height: 24px;
  width: auto;
`;

const LongLogo = styled.div.attrs({
  children: <StyledLogo />
})`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex: 0 0 ${SIDER_WIDTH}px;
`;

const SmallLogo = styled.div.attrs({
  children: <StyledLogoSmall />
})`
  height: 64px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 80px;
`;

const LogosWrapper = styled.div<{ collapsed: boolean }>`
  width: 100%;
  box-sizing: border-box;
  transition: all linear 0.15s;
  display: flex;

  ${props =>
    props.collapsed
      ? `
transform: translateX(-200px);
  `
      : ""};
`;

const Wrapper = styled.div`
  overflow: hidden;
`;

export const NavigatorLogo: React.FC<{ collapsed: boolean }> = props => (
  <Wrapper>
    <LogosWrapper collapsed={props.collapsed}>
      <LongLogo />
      <SmallLogo />
    </LogosWrapper>
  </Wrapper>
);

// width: 100%;
// box-sizing: border-box;
// transition: all ease-in-out 0.2s;
// display: flex;

// ${props =>
//   props.collapsed
//     ? `
// transform: translateX(-200px);
// `
//     : ""};

// styled.div.attrs({
//   children: (
//     <>
//       <InnerWrapper />
//       <InnerWrapperSmall />
//     </>
//   )
// })`
//   overflow: hidden;
// `;
