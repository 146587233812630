import { Alert } from "antd";
import queryString from "qs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { SetPasswordForm } from "../../components/forgot-password/set-password-form";
import { ForgotPasswordLayout } from "../../components/layout/unauth/forgot-password-Layout";
import { Loader } from "../../components/loader";
import { navApi } from "../../services/nav-api";

const SetPassword = () => {
  const location = useLocation();
  const { token } = queryString.parse((location.search || "").substr(1)) as { token: string };
  const [invalidToken, setInvalidToken] = useState<boolean>(!token); // if no token, set invalidToken to true
  const [loading, setLoading] = useState(Boolean(token)); // if token exists, init loading as true.

  useEffect(() => {
    if (invalidToken) {
      return;
    }

    (async function validateToken() {
      try {
        await navApi.unauth.validateToken(token);
        setInvalidToken(false);
        setLoading(false);
      } catch (e) {
        setInvalidToken(true);
        setLoading(false);
      }
    })();
  }, []);

  const renderContent = () => {
    if (invalidToken) {
      return <Alert type="error" message="Invalid token!" />;
    }
    if (loading) {
      return <Loader />;
    }
    return <SetPasswordForm token={token} />;
  };

  return (
    <ForgotPasswordLayout step="reset" title="Set new password">
      <>{renderContent()}</>
    </ForgotPasswordLayout>
  );
};

export default SetPassword;
