import { compile } from "path-to-regexp";

export interface TenantEditRouteParams {
  id: string;
}

export const tenantEdit = (() => {
  const path = "/admin/tenant-edit/:id";
  const toPath = compile(path);
  return {
    path,
    resolve(params: TenantEditRouteParams) {
      return toPath(params);
    }
  };
})();
