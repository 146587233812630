import { PaginationProps } from "antd/lib/pagination";
import { SorterResult } from "antd/lib/table/interface";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { doChangeListSettings } from "../../../redux/actions/students-list";
import { routes } from "../../../routes";
import { StudentRosterDetails } from "../../../services/nav-api/student-roster/types";

export const useStudentTableHandlers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return {
    onChange: (
      { current: page, pageSize }: PaginationProps,
      _: any,
      { field: sortKey, order: sortOrder }: SorterResult<{}>
    ) => {
      const sort = sortKey && sortOrder ? { key: sortKey as keyof StudentRosterDetails, order: sortOrder } : undefined;
      dispatch(doChangeListSettings(page!, pageSize!, sort));
    },

    onRow: (record: StudentRosterDetails) => ({
      onClick: () => {
        navigate(routes.studentDetails.resolve({ id: record.id.toString() }));
      }
    })
  };
};
