import { Table } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import _ from "lodash";
import { default as React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { doChangeListSettings, doFetchUsers } from "../../../redux/actions/users-list";
import { RdxMySelfState } from "../../../redux/reducers/myself";
import { RdxUsersState } from "../../../redux/reducers/users-list";
import { RdxStoreState } from "../../../redux/types/state";
import { RdxFetchStatus } from "../../../redux/types/status";
import { routes } from "../../../routes";
import { Role } from "../../../services/nav-api/roles";
import { User } from "../../../services/nav-api/users";
import { createColumns } from "./columns";

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

const useUsersTableHandlers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return {
    onChange: ({ current: page, pageSize }: PaginationProps) => {
      dispatch(doChangeListSettings(page!, pageSize!));
    },

    onRow: (record: User) => ({
      onClick: () => navigate(routes.userDetails.resolve({ id: record.id.toString() }))
    })
  };
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const UsersTable: React.FC = () => {
  const dispatch = useDispatch();
  const users: RdxUsersState = useSelector((state: RdxStoreState) => state.usersList);
  const mySelf: RdxMySelfState = useSelector((state: RdxStoreState) => state.myself);
  const roles: Role[] = useSelector((state: RdxStoreState) => state.myself.userRoles ?? []);

  const { onChange, onRow } = useUsersTableHandlers();

  useEffect(() => {
    if (mySelf.user) {
      dispatch(doFetchUsers());
    }
  }, []);

  return (
    <Table
      rowClassName="cursor-pointer"
      dataSource={_.map(users.data ? users.data.users : [], (r, key) => ({ ...r, key }))}
      loading={_.isEqual(RdxFetchStatus.LOADING, users.status)}
      onRow={onRow}
      onChange={onChange}
      columns={createColumns(roles ? roles : undefined)}
      pagination={{
        current: users.paging.page,
        pageSize: users.paging.itemsPerPage,
        pageSizeOptions: ["50", "100", "250"],
        showLessItems: true,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        total: users.data ? users.data.total : 0
      }}
    />
  );
};
