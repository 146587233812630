import {
  DASHBOARD_ACTIVATE_FILTER,
  DASHBOARD_CLEAR_FILTER,
  DASHBOARD_FETCH_COMPLETED,
  DASHBOARD_FETCH_STARTED,
  DASHBOARD_UPDATE_FILTER
} from "../../../actions/dashboard/tenant";
import { WorkingFilter } from "../../../actions/students-list";
import { RdxFetchStatus } from "../../../types/status";
import { createReducer } from "../../../utils/redux-utils";
import { initialState } from "./constants";
import { RdxTenantDashboardState } from "./model";

// -------------------------------------------------------------------------------------------------
// - Reducer
// -------------------------------------------------------------------------------------------------

export default createReducer<RdxTenantDashboardState>(initialState, {
  // -------------------------------------------------------------------------------------------------
  // -
  // - Fetch Reducers
  // -
  // -------------------------------------------------------------------------------------------------

  [DASHBOARD_FETCH_STARTED]: (state: RdxTenantDashboardState) => ({
    ...state,
    status: RdxFetchStatus.LOADING
  }),
  [DASHBOARD_FETCH_COMPLETED]: (state: RdxTenantDashboardState, ns: Partial<RdxTenantDashboardState>) => ({
    ...state,
    ...ns,
    status: ns.error ? RdxFetchStatus.FAILED : RdxFetchStatus.COMPLETED
  }),

  // -------------------------------------------------------------------------------------------------
  // -
  // - Filter Reducers
  // -
  // -------------------------------------------------------------------------------------------------

  [DASHBOARD_CLEAR_FILTER]: () => initialState,
  [DASHBOARD_UPDATE_FILTER]: (state: RdxTenantDashboardState, workingFilter: Partial<WorkingFilter>) => ({
    ...state,
    workingFilter: { ...state.workingFilter, ...workingFilter }
  }),
  [DASHBOARD_ACTIVATE_FILTER]: (state: RdxTenantDashboardState) => ({
    ...state,
    activeFilter: { ...state.workingFilter }
  })
});
