import { Column } from "@ant-design/charts";
import { Empty } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { AltID, sectionColorByAltId } from "../../../../constants/courses";
import { MESSAGES } from "../../../../i18n";
import { DailyStudyTimeResponse } from "../../../../services/external-api";
import { ChartTitle, ChartWrapper, TimeInfo } from "../styles";
import { formatter as legendFormatter, minutesToHM, roundToMinutes } from "../utils";

type ChartData = Array<{ studyTime: number; unitAltId: string }>;

type Props = {
  rawData: DailyStudyTimeResponse | undefined;
  loading: boolean;
  section?: AltID;
};

export const BarChart: React.FC<Props> = ({ rawData, loading, section }) => {
  const [data, setData] = useState<ChartData>([]);
  const [totalStudyTime, setTotalStudyTime] = useState(0);

  useEffect(() => {
    if (!rawData || !section) {
      return;
    }

    const sectionData = _.chain(rawData.sections) //
      .filter({ sectionAltId: section })
      .head()
      .value();

    const rawUnits = sectionData?.units || [];
    const weekData = rawUnits.map(unit => ({
      studyTime: roundToMinutes(unit.secs),
      unitAltId: unit.unitAltId
    })) as ChartData;

    const unitsTotalMinutes = weekData.reduce((a, b) => a + b.studyTime, 0);
    let sectionTotalMinutes = roundToMinutes(sectionData?.secs || 0);

    if (unitsTotalMinutes > sectionTotalMinutes) {
      sectionTotalMinutes = unitsTotalMinutes;
    }

    setTotalStudyTime(sectionTotalMinutes);

    setData(weekData);
  }, [loading, rawData, section]);

  const config = {
    data,
    loading,
    height: 200,
    xField: "unitAltId",
    yField: "studyTime",
    xAxis: { label: { autoRotate: false } },
    yAxis: {
      tickCount: Math.min(Math.max(...data.map(d => d.studyTime)) + 1, 5)
    },
    color: sectionColorByAltId[section!],
    meta: {
      studyTime: {
        formatter: legendFormatter,
        alias: MESSAGES.StudyTime
      }
    }
  };

  const showNoData = !loading && data.reduce((a, b) => a + b.studyTime, 0) === 0;

  return (
    <ChartWrapper>
      <ChartTitle type="secondary">{MESSAGES.UnitStudyTime.toUpperCase()}</ChartTitle>
      {showNoData && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

      {!showNoData && data.length > 0 && (
        <>
          <TimeInfo>
            <strong>{minutesToHM(totalStudyTime)}</strong>
            {MESSAGES.TotalTime}
          </TimeInfo>
          <Column {...config} />
        </>
      )}
    </ChartWrapper>
  );
};
