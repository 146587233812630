import { navApi } from "../../../../../../services/nav-api";
import { getTaskBlobWhenReady } from "../../../../../../services/task";
import { getCurDateForReportFilename } from "../../../../../../utils/date-utils";
import { ReportParams, ReportResponse } from "../../types";

// -------------------------------------------------------------------------------------------------
// - Generator
// -------------------------------------------------------------------------------------------------

export const genHomeworkPerformanceReport = async (params: ReportParams): Promise<ReportResponse> => ({
  filename: `homework-performance-${getCurDateForReportFilename()}.xlsx`,
  blob: await getTaskBlobWhenReady((await navApi.studentRoster.homeworkPerformanceReport(params)).taskToken)
});
