import * as HttpClient from "../http-client";
import { getUrlWithEncodedParams } from "../http-client/utils";
import { createHeaders } from "./headers";

const login = async (username: string, password: string, rememberMe = false): Promise<{}> => {
  const url = getUrlWithEncodedParams("/rest/session", { rememberMe });
  return HttpClient.post(url, createHeaders(), { username, password });
};

const logout = async () => {
  return HttpClient.del("/rest/session", createHeaders());
};

export { login, logout };
