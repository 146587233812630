import { Action } from "../types/action";

export const ANY_ACTION_TYPE = "ANY_ACTION_TYPE";

export function createReducer<S>(initialState: S, mapping: { [type: string]: (state: S, payload: any) => S }) {
  return (state = initialState, action: Action<any>): S => {
    const mappedReducer = mapping[action.type] || mapping[ANY_ACTION_TYPE];
    return mappedReducer === undefined ? state : mappedReducer(state, action.payload);
  };
}

export function createActionCreator<T extends object | number | boolean | string | undefined>(type: string) {
  return (value: T): Action<T> => {
    return value === undefined ? { type } : { type, payload: value };
  };
}

export function createVoidActionCreator(type: string) {
  return (): Action<void> => {
    return { type };
  };
}
