import { DeleteOutlined } from "@ant-design/icons";
import { Button, Checkbox, Table } from "antd";
import React from "react";

export type TagItem = { id: number; name: string; required?: boolean; loading?: boolean };

type Props = {
  onRequiredChange: (id: number) => void;
  onRemove: (id: number) => void;
  loading: boolean;
  disabled: boolean;
  tags: TagItem[];
};

export const TagsTable: React.FC<Props> = props => {
  return (
    <Table
      rowClassName="cursor-pointer"
      columns={[
        {
          title: "Name",
          dataIndex: "name",
          width: "80%"
        },
        {
          title: "Required",
          key: "required",
          align: "center",
          render({ required, id, loading }: TagItem) {
            return (
              <Checkbox
                disabled={loading || props.disabled}
                checked={Boolean(required)}
                onChange={() => props.onRequiredChange(id)}
              />
            );
          }
        },
        {
          title: "Actions",
          key: "actions",
          render({ loading, id }: TagItem) {
            return (
              <Button
                disabled={props.disabled}
                icon={<DeleteOutlined />}
                onClick={() => props.onRemove(id)}
                loading={loading}
                danger
              >
                Delete
              </Button>
            );
          }
        }
      ]}
      dataSource={props.tags}
      size="small"
      pagination={false}
      loading={props.loading}
      data-qa-label="tags-table"
    />
  );
};
