import { createNodeChildrenIdsById } from "./create-mappers";
import { Node } from "./types";

export function getAllDescendantIds(tree: Node[], nodeId: number) {
  const nodeChildrenIdsById = createNodeChildrenIdsById(tree);
  const children = nodeChildrenIdsById[nodeId];
  if (!children || !children.length) {
    return [];
  }

  const childrenIdsQueue = [...children];
  const descendants: number[] = [];

  while (childrenIdsQueue.length) {
    const childrenId = childrenIdsQueue.shift()!;
    childrenIdsQueue.push(...nodeChildrenIdsById[childrenId]);
    descendants.push(childrenId);
  }

  return descendants;
}
