import { Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TenantSelect } from "../../../../components/shared/tenant";
import { doUpdateWorkingFilter } from "../../../../redux/actions/students-list";
import { getMyTenant } from "../../../../redux/selectors/my-tenant";
import { RdxStoreState } from "../../../../redux/types/state";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const buildSectionQaLabel = (tenantId?: number) => `tenant-sfilter-${tenantId}`;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantFilter = () => {
  const dispatch = useDispatch();
  const isTenantUser = Boolean(useSelector(getMyTenant));
  const tenantId = useSelector(
    ({
      studentsList: {
        workingFilter: { tenantId }
      }
    }: RdxStoreState) => tenantId
  );

  return !isTenantUser ? (
    <Form.Item label="Tenant" data-qa-label={buildSectionQaLabel(tenantId)}>
      <TenantSelect
        hideExtraValues
        allowClear
        data-qa-label="select-tenants"
        value={tenantId}
        onChange={(tenantId: number) => dispatch(doUpdateWorkingFilter({ tenantId }))}
      />
    </Form.Item>
  ) : null;
};
