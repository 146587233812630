import { createSelector } from "reselect";

import { RdxStoreState } from "../types/state";
import { getIsStudentsListLoading } from "./is-students-list-loading";
import { getIsWorkingFilterEqualsToAppliedFilter } from "./working-filter-equals-to-active";

export const getIsApplyStudentsListFilterDisabled: (state: RdxStoreState) => boolean = createSelector(
  getIsStudentsListLoading,
  getIsWorkingFilterEqualsToAppliedFilter,
  (loading: boolean, equals: boolean): boolean => {
    return equals || loading;
  }
);
