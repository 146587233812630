import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Modal, Button } from "antd";
import { MESSAGES } from "../../../i18n";
const { confirm } = Modal;

interface ComponentProps {
  onDelete: () => void;
}

export const Delete: React.FC<ComponentProps> = props => {
  const handleDelete = () => {
    confirm({
      title: MESSAGES.TenantPartnerRemove,
      okText: MESSAGES.YesLabel,
      cancelText: MESSAGES.NoLabel,
      okType: "danger",
      onOk: props.onDelete
    });
  };
  return (
    <Button onClick={handleDelete} icon={<DeleteOutlined />} danger>
      Delete
    </Button>
  );
};
