import { navApi } from "../../services/nav-api";
import * as httpClient from "../../services/http-client";

export interface RdxThunkExtraArgs {
  navApi: typeof navApi;
  httpClient: typeof httpClient;
}

export const createExtraArgs = (): RdxThunkExtraArgs => ({
  navApi,
  httpClient
});
