import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Descriptions, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { BaseLayout } from "../../components/layout/base-layout";
import { PageTitle } from "../../components/layout/page-title";
import { NavigationDrawerOptionKey } from "../../components/navigation-drawer/types";
import { EMPTY_CELL_SYMBOL } from "../../components/shared/table/table-cell-value";
import { CPATagForm } from "../../components/tenant/cpa-tag-form";
import { AddPartnerForm, AddPartnerFormProps } from "../../components/tenant/form";
import { PartnersTable } from "../../components/tenant/partners-table";
import { MESSAGES } from "../../i18n";
import { routes } from "../../routes";
import { HttpClientErrorResponse } from "../../services/http-client/types";
import { navApi } from "../../services/nav-api";
import { Partner } from "../../services/nav-api/partners";
import { Tenant } from "../../services/nav-api/tenants";
import { format } from "../../utils/date-utils";

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const getValue = (key: keyof Tenant, info?: Tenant) =>
  !info || !info[key] ? EMPTY_CELL_SYMBOL : typeof info[key] === "object" ? (info[key] as any).join(", ") : info[key];

const getDateValue = (key: keyof Tenant, info?: Tenant) =>
  !info || !info[key] ? EMPTY_CELL_SYMBOL : format(info![key] as any, "P");

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantDetails = () => {
  const navigate = useNavigate();
  const [tenant, setTenant] = useState<Tenant>();
  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState<Partner[]>();
  const [modal, setModal] = useState(false);
  const { id } = useParams<{ id: string }>();
  const tenantId = parseInt(id!);

  const fetchPartners = () => {
    setLoading(true);
    navApi.partners
      .getPartners({ tenantId })
      .then(data => setPartners(data.partners))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    navApi.tenants
      .getTenant(tenantId)
      .then(tenant => {
        if (tenant.products) {
          tenant.products = tenant.products.map(p => p.toUpperCase()) as any;
        }
        return tenant;
      })
      .then(tenant => setTenant(tenant));
    fetchPartners();
  }, []);

  const renderContent = () => {
    if (!tenant) {
      return <LoadingOutlined style={{ fontSize: 24 }} spin />;
    }

    return (
      <>
        <PageTitle>{tenant.name}</PageTitle>
        <Card
          title={
            <div className="flex justify-between items-center">
              <span>Information</span>

              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  navigate(routes.tenantEdit.resolve({ id: tenantId.toString() }));
                }}
              >
                {MESSAGES.Edit}
              </Button>
            </div>
          }
        >
          <Descriptions bordered size="small">
            <Descriptions.Item label="Name" span={3}>
              {getValue("name", tenant)}
            </Descriptions.Item>
            <Descriptions.Item label="Type" span={3}>
              {getValue("type", tenant)}
            </Descriptions.Item>
            <Descriptions.Item label="Products" span={3}>
              {getValue("products", tenant)}
            </Descriptions.Item>
            <Descriptions.Item label="Creation Date" span={3}>
              {getDateValue("created", tenant)}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <br />
        <PartnersTable
          tenantId={tenantId}
          loading={loading}
          partners={partners}
          onDelete={(partnerId: number) => {
            navApi.partners
              .deletePartnerTenant(partnerId)
              .then(() => {
                fetchPartners();
                Modal.success({
                  title: MESSAGES.Success,
                  content: MESSAGES.TenantPartnerRemoveSuccess
                });
              })
              .catch((err: HttpClientErrorResponse) =>
                Modal.error({
                  title: MESSAGES.Error,
                  content: err.message ? err.message : MESSAGES.UnknownError
                })
              );
          }}
          onAdd={() => setModal(true)}
        />
        <Modal title={MESSAGES.AssignPartnerToTenant} open={modal} footer={null} onCancel={() => setModal(false)}>
          <AddPartnerForm
            onSubmit={(args: AddPartnerFormProps) => {
              navApi.partners
                .putPartnerTenant(parseInt(args.partner as string), tenantId)
                .then(() => {
                  fetchPartners();
                  Modal.success({
                    title: MESSAGES.Success,
                    content: MESSAGES.TenantPartnerSuccess
                  });
                  setModal(false);
                })
                .catch((err: HttpClientErrorResponse) =>
                  Modal.error({
                    title: MESSAGES.Error,
                    content: err.message ? err.message : MESSAGES.UnknownError
                  })
                );
            }}
            type={tenant.type}
          />
        </Modal>
        <CPATagForm tenantId={tenantId} />
      </>
    );
  };

  return (
    <BaseLayout
      activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_TENANTS}
      breadcrumb={[
        {
          name: MESSAGES.Tenants,
          href: routes.tenants.resolve()
        },
        {
          name: MESSAGES.Details
        }
      ]}
    >
      {renderContent()}
    </BaseLayout>
  );
};
