export enum Permission {
  SYSTEM_ADMIN = "SYSTEM_ADMIN",
  MANAGE_TENANTS = "MANAGE_TENANTS",
  MANAGE_PARTNERS = "MANAGE_PARTNERS",
  MANAGE_TENANT = "MANAGE_TENANT",
  MANAGE_USERS = "MANAGE_USERS",
  MANAGE_TENANT_STUDENTS = "MANAGE_TENANT_STUDENTS",
  MANAGE_STUDENT_EXAM_INFO = "MANAGE_STUDENT_EXAM_INFO",
  MANAGE_STUDENT_AFFILIATION = "MANAGE_STUDENT_AFFILIATION",
  IMPERSONATE = "IMPERSONATE",
  MANAGE_ALERTS = "MANAGE_ALERTS"
}
