import { Card, Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckableTreeView } from "../../../../../components/shared/checkable-tree-view";
import { doUpdateWorkingFilter } from "../../../../../redux/actions/students-list";
import { TagNode } from "../../../../../redux/selectors/my-tenant-tags-tree";
import { getStudentFilterTagsTree } from "../../../../../redux/selectors/student-filter-tags-tree";
import { getStudentFilterTenantId } from "../../../../../redux/selectors/student-filter-tenant-id";
import { RdxStoreState } from "../../../../../redux/types/state";
import { NoTagsAlert } from "./no-tags-alert";
import { WithoutTags } from "./without-tags";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TagsFilter = () => {
  const dispatch = useDispatch();
  const studentsTree = useSelector(getStudentFilterTagsTree);
  const selectedTenantId = useSelector(getStudentFilterTenantId);
  const workingFilter = useSelector((state: RdxStoreState) => state.studentsList.workingFilter);

  return selectedTenantId ? (
    <Form.Item label="Tags">
      <Card>
        <CheckableTreeView
          checkBehavior="filter"
          tree={studentsTree.tree}
          loading={studentsTree.loading}
          checked={workingFilter.tags}
          renderNode={({ name }: TagNode) => name}
          onChange={({ checked }) => {
            dispatch(doUpdateWorkingFilter({ tags: checked }));
          }}
        />
        <NoTagsAlert />
        <WithoutTags />
      </Card>
    </Form.Item>
  ) : null;
};
