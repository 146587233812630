import React from "react";

// -------------------------------------------------------------------------------------------------
// - Hook
// -------------------------------------------------------------------------------------------------

export const useLoadingStatus = () => {
  const [state, setState] = React.useState<boolean>(false);
  return {
    value: state,
    reset: () => setState(false),
    runBlock: async (block: () => Promise<void>) => {
      setState(true);
      await block();
      setState(false);
    }
  };
};
