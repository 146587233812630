import { PaginationProps } from "antd/lib/pagination";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doChangeListSettings, doFetchPartners } from "../../../redux/actions/partners";
import { RdxPartnersState } from "../../../redux/reducers/partners";
import { RdxStoreState } from "../../../redux/types/state";
import { RdxFetchStatus } from "../../../redux/types/status";
import { PartnersPureTable } from "./table-pure";

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

const useUsersTableHandlers = () => {
  const dispatch = useDispatch();

  return {
    onChange: ({ current: page, pageSize }: PaginationProps) => {
      dispatch(doChangeListSettings(page!, pageSize!));
    },
    onRow: () => ({
      onClick: () => {
        //
      }
    })
  };
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const PartnersTable = () => {
  const dispatch = useDispatch();
  const partners: RdxPartnersState = useSelector((state: RdxStoreState) => state.partners);

  const { onChange, onRow } = useUsersTableHandlers();

  useEffect(() => {
    dispatch(doFetchPartners());
  }, []);

  return (
    <PartnersPureTable
      partners={partners.partners}
      tenants={partners.tenants}
      loading={partners.status === RdxFetchStatus.LOADING}
      onChange={onChange}
      onRow={onRow}
      paging={{
        current: partners.paging.page,
        pageSize: partners.paging.itemsPerPage,
        total: partners.total,
        showSizeChanger: true
      }}
    />
  );
};
