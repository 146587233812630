import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getIsCPAPreEvolutionSelected } from "../../selectors/contentversion";
import { ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "../../types/status";
import { HttpClientErrorResponse } from "./../../../services/http-client/types";
import { HierarchyContent } from "./../../../services/nav-api/hierarchy-content";

export const HIERARCHY_CONTENT_FETCH_STARTED = "HIERARCHY_CONTENT_FETCH_STARTED";
export const HIERARCHY_CONTENT_FETCH_COMPLETED = "HIERARCHY_CONTENT_FETCH_COMPLETED";

export type FetchHierarchyContentPayload = {
  error?: HttpClientErrorResponse;
  data?: HierarchyContent;
};

export const doFetchHierarchyContent = (): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    if (getState().hierarchyContent.status === RdxFetchStatus.LOADING) {
      return;
    }

    dispatch({
      type: HIERARCHY_CONTENT_FETCH_STARTED
    });

    try {
      const response = await navApi.hierarchyContent.getHierarchyContent(getIsCPAPreEvolutionSelected(getState()));
      const payload: FetchHierarchyContentPayload = {
        data: response
      };
      dispatch({
        type: HIERARCHY_CONTENT_FETCH_COMPLETED,
        payload
      });
    } catch (ex) {
      const error: HttpClientErrorResponse = ex;
      const payload: FetchHierarchyContentPayload = {
        error
      };
      dispatch({
        type: HIERARCHY_CONTENT_FETCH_COMPLETED,
        payload
      });
    }
  };
};
