import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { BaseLayout } from "../../components/layout/base-layout";
import { PageTitle } from "../../components/layout/page-title";
import { NavigationDrawerOptionKey } from "../../components/navigation-drawer/types";
import { LocalUserFormProps, UserForm } from "../../components/users/form";
import { MESSAGES } from "../../i18n";
import { RdxMySelfState } from "../../redux/reducers/myself";
import { RdxStoreState } from "../../redux/types/state";
import { routes } from "../../routes";
import { HttpClientErrorResponse } from "../../services/http-client/types";
import { navApi } from "../../services/nav-api";
import { PostUserInviteParams } from "../../services/nav-api/user-invites";
import { UserType } from "../../services/nav-api/users";

const UsersNew: React.FC = () => {
  const myself: RdxMySelfState = useSelector((state: RdxStoreState) => state.myself);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (props: LocalUserFormProps) => {
    const finalProps: PostUserInviteParams = { ...props };
    if (myself.user && myself.user.tenant) {
      finalProps.tenantId = myself.user.tenant.id;
    }
    if (props.idp === UserType.Bim) {
      delete finalProps.firstName;
      delete finalProps.lastName;
      delete finalProps.tagIds;
      delete finalProps.tenantId;
    }
    navApi.userInvites
      .postUserInvite(finalProps)
      .then(() =>
        Modal.success({
          title: MESSAGES.UserCreatedSuccess,
          onOk() {
            navigate(routes.users.resolve());
          }
        })
      )
      .catch((err: HttpClientErrorResponse) =>
        Modal.error({
          title: MESSAGES.UserCreatedError,
          content: err.message
        })
      );
  };

  return (
    <BaseLayout
      activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_USERS}
      breadcrumb={[
        {
          name: "Users",
          href: routes.users.resolve()
        },
        {
          name: "New User"
        }
      ]}
    >
      <PageTitle>New User</PageTitle>
      <UserForm onSubmit={handleSubmit} />
    </BaseLayout>
  );
};

export default UsersNew;
