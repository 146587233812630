import { ONETRUST_SET_FLAG } from "../../actions/core/onetrust";
import { createReducer } from "../../utils/redux-utils";
import { RdxCoreState } from "./model";

export const initialState: RdxCoreState = {
  isGpcEnabled: false
};

// -------------------------------------------------------------------------------------------------
// - Reducer
// -------------------------------------------------------------------------------------------------

export default createReducer(initialState, {
  [ONETRUST_SET_FLAG]: (state, flag: boolean): RdxCoreState => ({
    ...state,
    isGpcEnabled: flag
  })
});
