import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getUnitsGroupedBySection } from "../../../../../redux/selectors/sections-units";
import { initialSectionUnitIncludedByReportType } from "../settings";
import { ReportType, SectionUnitIncludedByReportType } from "../types";

// -------------------------------------------------------------------------------------------------
// - Hook
// -------------------------------------------------------------------------------------------------

export const useSectionUnitReportType = (reportType: ReportType) => {
  const unitsBySection = useSelector(getUnitsGroupedBySection);
  const [sectionUnitIncludedByReportType, setSectionUnitIncludedByReportType] =
    React.useState<SectionUnitIncludedByReportType>(initialSectionUnitIncludedByReportType);

  return {
    value: sectionUnitIncludedByReportType,
    valueForCurReportType: !!reportType ? sectionUnitIncludedByReportType[reportType] : null,
    isAvailableForReportType: _.includes(_.keys(initialSectionUnitIncludedByReportType), reportType),
    unitsBySection: unitsBySection,
    setValue: (reportType: ReportType, option: string) => {
      setSectionUnitIncludedByReportType(previous => ({ ...previous, [reportType]: option }));
    },

    reset: () => {
      setSectionUnitIncludedByReportType(initialSectionUnitIncludedByReportType);
    }
  };
};
