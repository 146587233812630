import { Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import memoize from "lodash/memoize";
import React from "react";

import _ from "lodash";
import { Role } from "../../../services/nav-api/roles";
import { Status } from "../../../services/nav-api/types";
import { UserType } from "../../../services/nav-api/users";
import {
  composeHandlers,
  handleCellText,
  handleDateCell,
  renderWithMaxWidth
} from "../../shared/table/table-cell-value";

// -------------------------------------------------------------------------------------------------
// - Columns
// -------------------------------------------------------------------------------------------------

export const createColumns = memoize((roles: Role[] | undefined): ColumnProps<{}>[] => [
  //
  // Last Name
  //
  {
    title: "Last Name",
    dataIndex: "lastName",
    render: composeHandlers(handleCellText, renderWithMaxWidth(150))
  },

  //
  // First Name
  //
  {
    title: "First Name",
    dataIndex: "firstName",
    render: composeHandlers(handleCellText, renderWithMaxWidth(150))
  },

  //
  // Email
  //
  {
    title: "Email",
    dataIndex: "email",
    width: 200,
    render: composeHandlers(handleCellText, renderWithMaxWidth(200))
  },

  //
  // Role
  //
  {
    title: "Role",
    dataIndex: "roleId",
    render: (roleId: number) => {
      if (roles === undefined) {
        return roleId;
      }
      try {
        return roles.filter(role => role.id === roleId)[0].name;
      } catch (err) {
        return roleId;
      }
    }
  },

  //
  // Creation Date
  //
  {
    title: "Creation date",
    dataIndex: "created",
    render: handleDateCell
  },

  //
  // Last Login
  //
  {
    title: "Last Login",
    dataIndex: "lastLogin",
    render: handleDateCell
  },

  //
  // Type
  //
  {
    title: "Type",
    dataIndex: "idp",
    render: (userType: UserType) => (_.isEqual(UserType.Local, userType) ? "Local" : "BIM")
  },

  //
  // Status
  //
  {
    title: "Status",
    dataIndex: "status",
    render: function RoleCol(status: Status) {
      return <Tag color={status === Status.Disabled ? "red" : "green"}>{status}</Tag>;
    }
  }
]);
