import { RdxTagEditorSubState } from "../../reducers/tenants/tag-editor";
import { TagEditorNode } from "./types";

function createTreeTags(
  ids: number[],
  byId: { [id: number]: TagEditorNode },
  editingTag?: RdxTagEditorSubState
): TagEditorNode[] {
  const tree: TagEditorNode[] = [];

  ids
    .sort((id1, id2) => byId[id1].name?.localeCompare(byId[id2].name))
    .forEach(tagId => {
      let tag: TagEditorNode = byId![tagId];
      const isTagBeingEdited = editingTag && editingTag.id && editingTag.id === tag.id;
      if (isTagBeingEdited) {
        tag = {
          ...tag,
          ...editingTag,
          edit: !editingTag!.dragged
        };
      }

      if (!tag.parentId) {
        tree.push(tag);
      } else {
        byId[tag.parentId]?.children?.push(tag);
      }
    });

  return tree;
}

function addEditingTagToTreeIfNewTag(
  tree: TagEditorNode[],
  byId: { [id: number]: TagEditorNode },
  editingTag?: RdxTagEditorSubState
) {
  if (!editingTag || editingTag.id) {
    return;
  }

  const tag: TagEditorNode = {
    ...editingTag,
    edit: !editingTag.dragged,
    children: [],
    enrolledStudentCount: 0,
    name: editingTag.name || ""
  };

  if (editingTag.parentId) {
    const parentTag: TagEditorNode = byId[editingTag.parentId];
    parentTag.children.push(tag);
  } else {
    tree.push(tag);
  }
}

export { addEditingTagToTreeIfNewTag, createTreeTags };
