import { useState } from "react";
import { useDispatch } from "react-redux";

import { doEditStudentCompleted } from "../redux/actions/student-details";
import { navApi } from "../services/nav-api";
import { TagNode } from "../redux/selectors/my-tenant-tags-tree";
import { excludeRedundantNodes } from "../utils/tree-utils/assign/exclude-redundant-nodes";

const useEditStudentRequest = (tenantId: number, studentId: number, tree: TagNode[] = []) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  return {
    onFormSubmit: async (tagIds: number[], excluded: boolean) => {
      setLoading(true);
      try {
        tagIds = excludeRedundantNodes(tree, tagIds);
        await navApi.studentDetails.editStudent(tenantId, studentId, tagIds, excluded);
        dispatch(doEditStudentCompleted({ data: { tenantId, studentId, excluded } }));
      } catch (error) {
        dispatch(doEditStudentCompleted({ error }));
      }
      setLoading(false);
    },
    loading
  };
};

export { useEditStudentRequest };
