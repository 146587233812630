import { createSelector } from "reselect";
import { RdxStudentDetails } from "../reducers/student-details";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "./../types/status";

export const getShouldFetchStudentDetails: (state: RdxStoreState) => boolean = createSelector(
  (state: RdxStoreState) => state.studentDetails,
  (studentDetails: RdxStudentDetails): boolean =>
    Boolean(studentDetails.curStudentId) && studentDetails?.status !== RdxFetchStatus.LOADING
);
