import { Menu, Modal } from "antd";
import React from "react";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { navApi } from "../../../services/nav-api";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const UserState = (props: {
  userId: number;
  isDisabled: boolean;
  onChangeState: (disabled: boolean) => void;
}) => (
  <Menu.Item
    key="user-state"
    {...props}
    onClick={(): void => {
      const ErrorModal = (err: HttpClientErrorResponse) => {
        props.onChangeState(props.isDisabled);
        return Modal.error({
          title: "Error editing user status",
          content: err.message
        });
      };

      if (props.isDisabled) {
        navApi.users
          .enableUser(props.userId)
          .then(() => props.onChangeState(false))
          .catch(err => ErrorModal(err));
      } else {
        navApi.users
          .disableUser(props.userId)
          .then(() => props.onChangeState(true))
          .catch(err => ErrorModal(err));
      }
    }}
  >
    {props.isDisabled ? "Enable" : "Disable"}
  </Menu.Item>
);
