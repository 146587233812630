import { createNodeParentById } from "./create-mappers";
import { Node } from "./types";

export function getAllAncestorIds(tree: Node[], nodeId: number) {
  const nodeParentById = createNodeParentById(tree);
  const ancestorIds = [];
  let curParent = nodeParentById[nodeId];

  // get all ancestors
  while (curParent) {
    ancestorIds.push(curParent);
    curParent = nodeParentById[curParent];
  }

  return ancestorIds;
}
