import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import { WorkingFilter } from "../../../redux/actions/partners";
import { Tenant } from "../tenants";
import { Status } from "../types";

export type Partner = {
  id: number;
  name: string;
  type: string;
  tenantId?: number;
  parentKey?: string;
  refKey: string;
  status: Status;
};

export type GetPartnersResponse = {
  partners: Partner[];
  tenants: Tenant[];
  total: number;
};

const getPartners = async (args?: WorkingFilter): Promise<GetPartnersResponse> => {
  const url = `/rest/admin/partners`;
  return HttpClient.get(url, createHeaders(), args);
};

const putPartnerStatus = async (partnerId: number, status: Status): Promise<{}> => {
  const url = `/rest/admin/partners/${partnerId}/status`;
  return HttpClient.put(url, createHeaders(), { status });
};

const putPartnerTenant = async (partnerId: number, tenantId: number): Promise<{}> => {
  const url = `/rest/admin/partners/${partnerId}/tenant`;
  return HttpClient.put(url, createHeaders(), { tenantId });
};

const deletePartnerTenant = async (partnerId: number): Promise<{}> => {
  const url = `/rest/admin/partners/${partnerId}/tenant`;
  return HttpClient.del(url, createHeaders());
};

export { getPartners, putPartnerStatus, putPartnerTenant, deletePartnerTenant };
