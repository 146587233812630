import isEqual from "lodash/isEqual";
import { createSelector } from "reselect";

import { RdxStoreState } from "../types/state";
import { RdxStudentsListState } from "./../reducers/students-list";

export const getIsWorkingFilterEqualsToAppliedFilter: (state: RdxStoreState) => boolean = createSelector(
  (state: RdxStoreState) => state.studentsList,
  ({ workingFilter, activeFilter }: RdxStudentsListState): boolean => {
    if (!activeFilter) {
      return false;
    }
    return isEqual({ ...activeFilter.filter, searchTerm: "" }, { ...workingFilter, searchTerm: "" });
  }
);
