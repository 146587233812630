import { ColumnProps } from "antd/lib/table";
import memoize from "lodash/memoize";

import { handleCellText, handleDateCell } from "../../shared/table/table-cell-value";
import { Tag } from "antd";
import React from "react";
import { Status } from "../../../services/nav-api/types";

export const createColumns = memoize((): ColumnProps<{}>[] => [
  {
    title: "Name",
    dataIndex: "name",
    render: handleCellText,
    width: "35%"
  },
  {
    title: "Type",
    dataIndex: "type",
    render: handleCellText,
    width: "30%"
  },

  {
    title: "Products",
    dataIndex: "products",
    render: products => handleCellText(products.join(", ").toUpperCase()),
    width: "10%"
  },
  {
    title: "Creation date",
    dataIndex: "created",
    render: handleDateCell,
    width: "15%"
  },
  {
    title: "Status",
    dataIndex: "status",
    render: function RoleCol(status: Status) {
      return <Tag color={status === Status.Disabled ? "red" : "green"}>{status}</Tag>;
    },
    width: "10%"
  }
]);
