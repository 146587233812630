import _ from "lodash";
import { createSelector } from "reselect";
import { RdxGlobalDashboardState } from "../../reducers/dashboard/global/model";
import { RdxStoreState } from "../../types/state";
import { RdxFetchStatus } from "../../types/status";
import { GlobalCounters, TenantPair } from "./../../../services/nav-api/global-counters/types";

export const shouldFetch: (state: RdxStoreState) => boolean = createSelector(
  ({ globalDashboard }: RdxStoreState) => globalDashboard,
  ({ status }: RdxGlobalDashboardState): boolean => !_.isEqual(status, RdxFetchStatus.LOADING)
);

export const isFetching: (state: RdxStoreState) => boolean = createSelector(
  ({ globalDashboard }: RdxStoreState) => globalDashboard,
  ({ status }: RdxGlobalDashboardState): boolean => _.isEqual(status, RdxFetchStatus.LOADING)
);

export const isFetchCompleted: (state: RdxStoreState) => boolean = createSelector(
  ({ globalDashboard }: RdxStoreState) => globalDashboard,
  ({ status }: RdxGlobalDashboardState): boolean => _.isEqual(status, RdxFetchStatus.COMPLETED)
);

export const getGlobalDashboardStats: (state: RdxStoreState) => GlobalCounters = createSelector(
  ({ globalDashboard }: RdxStoreState) => globalDashboard,
  ({ stats }: RdxGlobalDashboardState): GlobalCounters => stats!
);

export const getSelectedPartner: (state: RdxStoreState) => string = createSelector(
  ({ globalDashboard }: RdxStoreState) => globalDashboard,
  ({ selectedPartner }: RdxGlobalDashboardState): string => selectedPartner!
);

export const getSelectedPartnerTop5Firms: (state: RdxStoreState) => TenantPair[] = createSelector(
  ({ globalDashboard }: RdxStoreState) => globalDashboard,
  ({ selectedPartner, stats }: RdxGlobalDashboardState): TenantPair[] => {
    if (!(selectedPartner && stats?.topTenantsByType)) {
      return [];
    }

    return _.slice(_.orderBy(stats.topTenantsByType[selectedPartner], "studentCount", "desc"), 0, 5);
  }
);
