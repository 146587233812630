import { errorTracker } from ".";
import { ResponseInterceptor } from "../http-client/interceptor";

export const httpErrorTrackingInterceptor: ResponseInterceptor = async response => {
  if (response.status >= 500) {
    errorTracker.notifyEvent({
      message: `[HTTP ERROR]    ${response.status}    ${response.url}`,
      extra: {
        status: response.status,
        statusText: response.statusText,
        // trailer: response.trailer,
        type: response.type,
        url: response.url
      }
    });
  }
  return response;
};
