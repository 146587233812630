import React from "react";
import noop from "lodash/noop";

export type TagsEditorContextProps = {
  onClone: (id: number) => void;
  onEdit: (id: number) => void;
  onCancelEdit: () => void;
  onSubmitEdit: (name: string) => void;
  onCreate: (parentId?: number) => void;
  onDelete: (id: number) => void;
  onDrop: (id: number, parentId?: number) => void;
  onMoveStudents: (srcId: number, destId: number) => void;
  loadingByTagId: { [tagId: number]: boolean };
  totalEnrollmentsByTagId: { [tagId: number]: number };
  isCreatingTag?: boolean;
};

export const TagsEditorContext = React.createContext<TagsEditorContextProps>({
  onClone: noop,
  onEdit: noop,
  onCreate: noop,
  onDelete: noop,
  onSubmitEdit: noop,
  onCancelEdit: noop,
  onMoveStudents: noop,
  onDrop: noop,
  loadingByTagId: {},
  totalEnrollmentsByTagId: {}
});
