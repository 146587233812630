import React from "react";
import { useDispatch } from "react-redux";

import { MESSAGES } from "../../i18n";
import { doCompleteLogout } from "../../redux/actions/authentication";
import { GenericApiFailureModal } from "./modal";

export const SessionExpiredModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const dispatch = useDispatch();
  return (
    <GenericApiFailureModal
      message={MESSAGES.ApiFailureSessionExpired}
      title={MESSAGES.ApiFailureSessionTitle}
      buttons={[
        {
          onClick: () => {
            onClose();
            dispatch(doCompleteLogout());
            setTimeout(() => {
              window.location.reload();
            }, 500);
          },
          label: MESSAGES.ApiFailureSessionExpiredBtnCaption
        }
      ]}
    />
  );
};
