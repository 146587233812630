// -------------------------------------------------------------------------------------------------
// - Model
// -------------------------------------------------------------------------------------------------

export enum StatsScope {
  SPS_USAGE = "SPS_USAGE",
  SPS_PERFORMANCE = "SPS_PERFORMANCE",
  LICENSES = "LICENSES"
}

export enum StatsSlice {
  S1 = "S1",
  S2 = "S2"
}

export interface StatsData {
  slice: StatsSlice;
  value: number;
}
