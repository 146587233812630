import { Pie } from "@ant-design/charts";
import { Button, Col, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { DashboardRow } from "../../../../common/DashboardRow";
import { colorBySlice, labelByStatsScopeAndSlice, textColorBySlice } from "./constants";
import { StatsData, StatsScope } from "./model";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

interface OwnProps {
  value: StatsData[];
  statsType: StatsScope;
  onClick: (data: StatsData) => void;
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Chart = (props: OwnProps): JSX.Element => {
  const total = _.sumBy(props.value, v => v.value);
  return (
    <DashboardRow>
      <Col xs={15} sm={16}>
        <Pie
          autoFit={true}
          height={120}
          data={props.value}
          angleField="value"
          colorField="slice"
          radius={1}
          innerRadius={0.5}
          legend={false}
          label={false}
          tooltip={false}
          statistic={{
            title: false,
            content: false
          }}
          color={(
            value: StatsData //
          ) => colorBySlice[value.slice]}
          interactions={[{ type: "element-active" }]}
          pieStyle={{ cursor: "pointer" }}
          onEvent={(e, pe) => {
            if (_.startsWith(pe.type, "plot:click") && pe.data) {
              const { data } = pe.data as { data: StatsData };
              props.onClick(data);
            }
          }}
        />
      </Col>
      <Col xs={9} sm={8}>
        {_.chain(props.value)
          .map((data: StatsData) => {
            const { slice, value } = data;
            const percentage = total > 0 ? (((value * 1.0) / total) * 100).toFixed() : 0;
            const textColor = textColorBySlice[slice];
            const name = labelByStatsScopeAndSlice[props.statsType][slice];
            const qaLabel = `${_.kebabCase(`${props.statsType}`)}-${_.toLower(slice)}-percentage`;
            return (
              <Typography.Text className="flex flex-col flex-1" key={`${slice}-${props.statsType}`}>
                <Button
                  type="link"
                  className={[
                    "m-0",
                    "p-0",
                    "font-bold",
                    "text-left",
                    "text-xl",
                    textColor //
                  ].join(" ")}
                  onClick={() => {
                    props.onClick(data);
                  }}
                >
                  <span data-qa-label={qaLabel}>{percentage}</span>%
                </Button>
                <Typography.Text className="m-0 p-0 text-xs text-[#6b6974]">{name}</Typography.Text>
              </Typography.Text>
            );
          })
          .value()}
      </Col>
    </DashboardRow>
  );
};
