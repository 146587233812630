import { Checkbox, Form } from "antd";
import { Field } from "formik";
import isNil from "lodash/isNil";
import React from "react";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const VisibilityOptions = () => (
  <Field
    name="hidden"
    validate={(value?: boolean) => (isNil(value) ? "No value selected." : undefined)}
    className="bulk-change-visibility"
  >
    {({ field, form }: any) => (
      <Form.Item label="Visibility">
        <Checkbox
          onChange={() => {
            form.setFieldValue("hidden", !field.value);
            form.setFieldTouched("hidden");
          }}
          value={field.value}
          style={{ marginLeft: "5px" }}
        >
          Hidden
        </Checkbox>
      </Form.Item>
    )}
  </Field>
);
