import { Table } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import { SorterResult, TableCurrentDataSource, TablePaginationConfig } from "antd/lib/table/interface";
import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { Partner } from "../../../services/nav-api/partners";
import { Tenant } from "../../../services/nav-api/tenants";
import { createColumns } from "./columns";

type ComponentProps = {
  partners: Partner[] | undefined;
  tenants: Tenant[] | undefined;
  loading?: boolean;
  paging: PaginationProps;
  onChange: (
    pagination: TablePaginationConfig,
    filters: Record<keyof Partner, string[]>,
    sorter: SorterResult<Partner>,
    extra: TableCurrentDataSource<Partner>
  ) => void;
  onRow: (record: Partner) => { onClick: () => any };
};

const Component: React.FC<ComponentProps> = props => (
  <StyledTable
    rowClassName="cursor-pointer"
    columns={createColumns(props.tenants)}
    dataSource={_.map(props.partners, (r, key) => ({ ...r, key }))}
    loading={props.loading}
    onRow={props.onRow}
    pagination={{
      ...props.paging,
      pageSizeOptions: ["50", "100", "250"],
      showQuickJumper: true,
      showLessItems: true,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
    }}
    onChange={props.onChange}
  />
);

export const PartnersPureTable = React.memo(Component);

const StyledTable = styled(Table)`
  td {
    white-space: nowrap;
  }

  td,
  th {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
