import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Table } from "antd";
import React from "react";
import { MESSAGES } from "../../../i18n";
import { Partner } from "../../../services/nav-api/partners";
import { columns } from "./columns";

type ComponentProps = {
  tenantId: number;
  loading: boolean;
  partners?: Partner[];
  onDelete: (partnerId: number) => void;
  onAdd: () => void;
};

export const PartnersTable: React.FC<ComponentProps> = props => {
  return (
    <Card title={MESSAGES.Partners}>
      <Table
        rowClassName="cursor-pointer"
        loading={props.loading}
        columns={columns(props.onDelete)}
        dataSource={props.partners || []}
        size="small"
        pagination={false}
      />
      <br />
      <Button type="primary" onClick={props.onAdd} icon={<PlusOutlined />}>
        {MESSAGES.AssignPartner}
      </Button>
    </Card>
  );
};
