import { Input } from "antd";
import React, { useContext, useState } from "react";

import { TagsEditorContext, TagsEditorContextProps } from "./../context";
import { MESSAGES } from "../../../../i18n";
import { TagEditorNode } from "../../../../redux/selectors/tags-tree-editor";

export const EditModeNode: React.FC<TagEditorNode> = ({ name }) => {
  const [value, setValue] = useState(name);
  const contextProps: TagsEditorContextProps = useContext(TagsEditorContext);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();

        if (value === name || !value || !value.trim()) {
          return;
        }
        contextProps.onSubmitEdit(value);
      }}
    >
      <Input
        size="small"
        placeholder={MESSAGES.TagNamePlaceholder}
        value={value}
        onChange={e => setValue(e.target.value)}
        autoFocus
        onBlur={contextProps.onCancelEdit}
        type="text"
      />
    </form>
  );
};
